import _ from 'lodash';

export const empty = (mixedVar) => {
    let undef;
    let key;
    let i;
    let len;
    let emptyValues = [undef, null, false, ''];

    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true
        }
    }

    if (typeof mixedVar === 'object') {
        for (key in mixedVar) {
            if (mixedVar.hasOwnProperty(key)) {
                return false
            }
        }
        return true
    }

    return false
};

export const exists = (mixedVar, path=null) => {
    if(path !== null) {
        return !empty(_.get(mixedVar, path, null));
    }
    return !empty(mixedVar);
};

export const getRootFromPath = (path) => {
    if(path.startsWith('/')) {
        path = path.substring(1);
    }

    return path.split('/')[0];
};

export const loadScript = (url, props={}) => {
    const { async=true, id, callback} = props;
    const script = document.createElement('script');
    script.src = url;
    script.async = async;
    if(id) script.id = id;
    document.body.appendChild(script);

    script.onload = () => {
        if (callback) callback();
    };
}
