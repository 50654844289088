// if user presses enter on an element with an onClick, trigger the click.
export const triggerClickOnEnter = (event) => {

    const isEnter = (event.key === 'Enter' || event.which === 13);
    const isSpacebar = (event.key === "Spacebar" || event.which === 32);
    if(event.target) {
        let nodeName = event.target.nodeName.toLowerCase();
        if (isEnter) {
            if(!['button'].includes(nodeName) && typeof event.target.click === "function")
            {
                event.target.click();
                const descendantClick = event.target.querySelector("[onclick]");
                if(descendantClick && typeof descendantClick.onclick === "function") descendantClick.click();
            }
        }
        else if (isSpacebar) {
            let typeAtt = event.target.getAttribute("type");
            if(!(['input'].includes(nodeName) && ['checkbox'].includes(typeAtt)) && typeof event.target.click === "function")
            {
                    // if(event.preventDefault) event.preventDefault(); // Note: this stops space character from being entered
                    event.target.click();
            }
        }
    }
}

// add the trap tab listener to an element
export const tabTrap = (rootEl) => {

    if (typeof rootEl === "string") rootEl = document.querySelector(`#${rootEl}`);
    if (!rootEl) return;

    rootEl.addEventListener('keydown', tabTrapListener);

    const tabs = rootEl.querySelectorAll("[tabindex]:not([tabindex='-1'])");
    if (tabs.length > 0) tabs[0].focus();
}

// remove the trap tab listener from an element. optionally pass in an element on which to set focus.
export function removeTabTrap  (el=null,focusEl=null)  {

    if(typeof el === "string")el = document.querySelector(`#${el}`);
    if(el != null) el.removeEventListener("keydown",tabTrapListener);

    if(typeof focusEl === "string")focusEl = document.querySelector(`#${focusEl}`);
    if(focusEl != null){
        setTimeout(() => focusEl.focus(),10);
    }
}

// trap tab inside of a root element, using tabIndex >= 0 to find tabbable elements
function tabTrapListener (e)  {

    let isEscPressed = e.key === 'Escape' || e.keyCode === 27 || e.which === 27;
    if(isEscPressed){
        removeTabTrap(this);
        return;
    }
    const tabs = this.querySelectorAll("[tabindex]:not([tabindex='-1'])");
    if(tabs.length > 0){
        const firstTab = tabs[0];
        const lastTab = tabs[tabs.length - 1];

        let isTabPressed = e.key === 'Tab' || e.keyCode === 9 || e.which === 9;
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) { // if shift key pressed for shift + tab combination
            if (document.activeElement === firstTab) {
                lastTab.focus(); // add focus for the last focusable element
                e.preventDefault();
            }
        } else { // if tab key is pressed
            if (document.activeElement === lastTab) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                firstTab.focus(); // add focus for the first focusable element
                e.preventDefault();
            }
        }
    }
}
/*
 keyboard nav - set focus
 example config:

 [{
    el: element (or element id) to focus
    //fallback: id of element (or element itself) to focus if focus is not found
    focus_timeout: time to wait before setting focus
    fallback_timeout: time to wait before searching for fallback
    search_focus: boolean whether to search within the focus for element with tabindex != -1 (default: just focus on element itself)
    search_fallback: boolean whether to search within the fallback for element with tabindex != -1 (default: just focus on element itself)
 }]
 */
// export const setFocus = (configs = []) => {
//
//     console.log("setFocus  configs.length = ",configs.length);
//
//     if(!(configs instanceof Array)) return;
//     const config = configs.shift();
//     if(!(config && config.el)) return;
//
//     //config.fallback =         config.fallback        ||null;
//     config.focus_timeout =      config.focus_timeout    ??10;
//     config.failed_timeout =     config.failed_timeout   ??10;
//     config.search =             config.search||false;
//     //config.search_fallback = config.search_fallback ||false;
//     let el = config.el;
//     if(typeof config.el === "string") el = document.querySelector(`#${el}`);
//     if(typeof el === "object") // dom element passed in
//     {
//         if(config.search) {
//             const f = el.querySelector("[tabindex]:not([tabindex='-1']");
//             if(f){
//                 if(config.focus_timeout !== 0) setTimeout(() => f.focus(),config.focus_timeout);
//                 else f.focus();
//             }
//         }
//         else if(config.focus_timeout !== 0) setTimeout(()=>    el.focus && el.focus(),   config.focus_timeout);
//         else el.focus && el.focus();
//         return;
//     }
//     // wait for element
//     {
//         let focused = false;
//         let retry = 0;
//         let pageContainer = document.querySelector('#page-container');
//         if(pageContainer){
//
//             let observer = new MutationObserver(((mutationRecords, mutationObserver) => {
//                 let observed = document.querySelector(`#${config.el}`);
//                 if(observed){
//                     if(config.search){
//                         const f = observed.querySelector("[tabindex]:not([tabindex='-1']");
//                         if(f){
//                             clearTimeout(retry);
//                             focused = true;
//                             if(config.focus_timeout !== 0) setTimeout(() => f.focus(),config.focus_timeout);
//                             else f.focus();
//                         }
//                     }
//                     else
//                     {
//                         clearTimeout(retry);
//                         focused = true;
//                         observed.focus();
//                     }
//                     mutationObserver.disconnect();
//                 }
//             }));
//             observer.observe(pageContainer,{childList:true});
//
//             retry = setTimeout(()=>{
//                 observer.disconnect();
//                 if(!focused) setFocus(configs);
//             },config.failed_timeout);
//         }
//     }
// }
// keyboard nav - set focus
export const setFocus = (id,observe= false) => {
    if(typeof id === "object") // dom element passed in
    {
        const f = id.querySelector("[tabindex]:not([tabindex='-1']");
        if(f) setTimeout(() => f.focus({preventScroll:true}),10); //timeout gives time for onExited focus to complete
        return;
    }
    if(!observe){
        const f = document.querySelector(`#${id}`);
        if(f) setTimeout(() => f.focus({preventScroll:true}),10);
    }
    else{ // navigation action - wait for element
        let pageContainer = document.querySelector('#page-container');
        if(pageContainer){
            let observer = new MutationObserver(((mutationRecords, mutationObserver) => {
                let observed = document.querySelector(`#${id}`);
                if(observed){
                    observed.focus({preventScroll:true});
                    mutationObserver.disconnect();
                }
            }));
            observer.observe(pageContainer,{childList:true});
        }
    }
}
/*
    Go thru anchor tags and replace href="#" with href="javascript:void(0)" to resolve issue where react nav breaks when use href="#"

    Args: root = root dom element.
 */
// export const updateAnchorsForReact = (root) => {
//     if(!root) return;
//     const anchors = root.getElementsByTagName("a");
//     for(let a = 0; a < anchors.length; a++){
//         if(anchors[a].getAttribute("href") === "#") anchors[a].setAttribute("href","javascript:void(0)");
//     }
// }
/*
    Go thru anchor tags and replace href="#" with href="javascript:void(0)"
     and replace href="#something" with href="javascript:window.scrollToId('something')" to resolve issue where react nav breaks when use href="#"

    Args: root = root dom element or array of elements to check
*/
export const updateAnchorsForReact = (root) => {
    if(!root) return;
    const anchors = Array.isArray(root) ? root : root.getElementsByTagName("a");
    for(let a = 0; a < anchors.length; a++){
        let href = anchors[a].getAttribute("href");
        if(typeof href === "string"){
            href = href.trim();
            if(href === "#"){
                anchors[a].setAttribute("href","javascript:void(0)");
            }
            else if(/^#.+/.test(href)){
                anchors[a].setAttribute("href", href.replace(/^#(.+)/,"javascript:window.scrollToId('$1')"));
            }
        }
    }
}
