import React, {useImperativeHandle, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import parse from "html-react-parser";
import Button from "react-bootstrap/Button";
import {isJsonString, resolveContext} from "../../utils";
import {getCurrentLocationPath, isLegacyRoute, processAjax} from "../../ajaxActions";
import {getRootFromPath} from "../../../utilities/helpers";
import Modal from "react-bootstrap/Modal";

export const DynamicModal = React.forwardRef((props, ref) => {
    const {id, title, message, buttons, dismiss = true, size, animation, backdrop, className, restoreFocus} = props;
    let {
        cleanup = () => {
        }, onExited = () => {
        }, onEntered = () => {
        }, onEntering = () => {
        }, refreshOnExit = false
    } = props
    const [cleanedUp, setCleanedUp] = useState(false);
    const [show, setShow] = useState(true);
    const handleOnHide = () => setShow(false);

    //Used to give a handle for this component instance to the parent modal manager
    useImperativeHandle(ref, () => ({
        closeModal(callback=()=>{}) {
            setShow(false);
            cleanup();
            setCleanedUp(true);
            callback();
        }
    }));

    const handleOnEntered = () => {
        onEntered();
    }

    const handleOnEntering = () => {
        onEntering();
    }

    const handleOnExited = () => {
        onExited();
        if (restoreFocus && restoreFocus.focus) {
            restoreFocus.focus();
        }
        handleRefreshOnExit();
        if (!cleanedUp) {
            cleanup();
        }
    }

    const handleRefreshOnExit = async () => {
        if (refreshOnExit === true) {
            let pathName = getCurrentLocationPath();
            pathName = getRootFromPath(pathName);
            if (isLegacyRoute(pathName)) {
                await processAjax('AHJRefresh');
            }
        }
    }

    return (
        <Modal
            id={id}
            show={show}
            size={size}
            onHide={handleOnHide}
            onEntered={handleOnEntered}
            onEntering={handleOnEntering}
            onExited={handleOnExited}
            animation={animation}
            backdrop={backdrop}
            dialogClassName={className}
            aria-live={"polite"}
        >
            <Modal.Header closeButton={!!dismiss}>
                {title && <Modal.Title>{parse(title)}</Modal.Title>}
            </Modal.Header>
            {props.children
                ? <Modal.Body>{props.children}</Modal.Body>
                : <Modal.Body dangerouslySetInnerHTML={{__html: message}}/>
            }
            <Modal.Footer>
                {renderButtons(buttons, handleOnHide)}
            </Modal.Footer>
        </Modal>
    );
});
const handleStringifiedFunction = (action, e) => {
    let [callback, params] = JSON.parse(action);
    callback = resolveContext(callback);
    let args = params.map(arg => {
        if (arg === "this") {
            return e.target
        } else {
            return arg;
        }
    });
    callback(...args);
}
const renderButtons = (buttons, handleClose) => {

    return buttons.map((button) => {
        const {
            text,
            action = () => {
            },
            type: variant = 'default',
            dismiss = true,
            disabled = false,
            popover = false
        } = button;

        const handleClick = (e) => {
            if (typeof action === "function") {
                action(e.target);
            } else if (typeof action === "string") {
                // check first to see if it has been packaged up in JSON
                if (isJsonString(action)) {
                    handleStringifiedFunction(action, e);
                } else {
                    console.error(`Error executing ${action}`);
                }
            }

            if (dismiss) {
                handleClose();
            }
        }

        if (popover) {
            return (
                <ButtonWithPopover
                    popover={popover}
                    variant={variant}
                    disabled={disabled}
                    handleClick={handleClick}
                    text={text}
                />
            )
        } else {
            return (
                <ModalButton
                    variant={variant}
                    disabled={disabled}
                    handleClick={handleClick}
                    text={text}
                />
            )
        }
    });

}
const ModalButton = (props) => {
    const {
        variant,
        disabled,
        handleClick,
        text,
        style = {}
    } = props;
    return (
        <Button
            variant={variant}
            disabled={disabled}
            onClick={handleClick}
            style={style}
        >
            {text}
        </Button>
    );
}
const PopoverOverlay = (props) => {
    return (
        <OverlayTrigger
            trigger={props.trigger}
            overlay={
                <Popover id="button-popover">
                    <Popover.Content>
                        {parse(props.content)}
                    </Popover.Content>
                </Popover>
            }>
            {props.children}
        </OverlayTrigger>
    )
}
const ButtonWithPopover = (props) => {
    return (
        <PopoverOverlay
            trigger={props.popover.trigger}
            content={props.popover.content}
        >
            {props.disabled ? (
                <span className="d-inline-block">
                    <ModalButton {...props} style={{pointerEvents: 'none'}}/>
                </span>
            ) : (
                <ModalButton {...props} />
            )}
        </PopoverOverlay>
    );
}