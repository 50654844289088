import { processJSON } from "../ajaxActions";
import {removeLoader, replaceWithLoader} from "../utils";
import {TinyMceEditor} from "../lib/TinyMceEditor";

export const exportWcGlobals = () => {
    window['WellnessChampionsController'] = WellnessChampionsController;
}

class WellnessChampionsController {

    constructor() {
        this.pageContainerId = 'wellness-champions-page';
        this.pageContainer = document.getElementById(this.pageContainerId);
    }

    initialize = () => {
        this.initializeWYSIWYGInputs();
    }

    openMessageBoard = async (topicId) => {
        let obj = {};
        if(topicId) {
            obj.topicId = topicId;
            obj.deepLinkIntoTopic = true;
            obj.selectedMenuItem = 'wc_success_stories'
        }
        await processJSON('AHJWellnessChampions', obj, 'displayMessageBoard');
    }

    submitEeActivity = async (id) => {
        let parent = window.jQuery('#'+id+'_card');
        let btn = parent.find('button.activity-submit-btn');
        let title = btn.attr('data-activity-title');
        let category = btn.attr('data-category');
        let activityPath = btn.attr('data-activity-path');

        let obj = {
            'category': category,
            'activityPath': activityPath,
            'data': {
                'activity': {
                    'activityTitle': title,
                    'activityPath': activityPath,
                    'activityId': activityPath,
                    'value': '',
                }
            }
        };

        replaceWithLoader(btn);
        await processJSON('AHJWellnessChampions', obj, 'submitEeActivity', {
            callBack: function () {
                removeLoader(btn.attr('id'));
            }
        });
    }

    submitTeamActivity = async (id) => {
        let parent = window.jQuery('#'+id+'_card');
        let btn = parent.find('button.activity-submit-btn');
        let input = parent.find('textarea');
        let title = btn.attr('data-activity-title');
        let category = btn.attr('data-category');
        let activityPath = btn.attr('data-activity-path');

        let obj = {
            'title': title,
            'category': category,
            'activityPath': activityPath,
            'data': {
                'value': input.value
            }
        };

        replaceWithLoader(btn);
        await processJSON('AHJWellnessChampions', obj, 'submitTeamActivity', {
            callBack: function () {
                removeLoader(btn.attr('id'));
            }
        });
    }

    initializeWYSIWYGInputs = (props) => {
        const instance = this;
        let inputs = window.jQuery(this.pageContainer).find('.wc-reporting-input[data-input-type="WYSIWYG"]:not(.disabled)');
        if(inputs.length > 0){
            let height = 200;
            let expandedHeight = 500;
            inputs.each(function(){
                let input = window.jQuery(this);
                let inputContainer = input.closest('.wysiwyg-container');
                let completed = inputContainer.find('input[name="completed"]').val() === '1';

                const tinyMceEditor = new TinyMceEditor(input.attr('id'));
                tinyMceEditor.initialize({
                    selector: '#'+input.attr('id'),
                    height: height,
                    readonly: completed,
                    init_instance_callback:function(editor){
                        editor.on('KeyUp Paste Change', function(event){
                            inputContainer.find('.activity-submit-btn').removeClass('disabled')
                        });
                    }
                });

                inputContainer.find('.activity-submit-btn').off('click').on('click', async function() {
                    let btn = window.jQuery(this);
                    const obj = {
                        'eeTeamActivityNo': btn.attr('data-ee-team-activity-no'),
                        'title': btn.attr('data-activity-title'),
                        'category': btn.attr('data-category'),
                        'activityPath': btn.attr('data-activity-path'),
                        'data': {
                            'value': tinyMceEditor.getInstance().getContent()
                        }
                    };

                    replaceWithLoader(btn, 'inline-block');
                    await processJSON('AHJWellnessChampions', obj, 'submitTeamActivity', {
                        callBack: function () {
                            removeLoader(btn.attr('id'));
                        }
                    });
                });

                inputContainer.find('.enable-edit-mode-btn').off('click').on('click', function() {
                    window.jQuery(this).css('display','none');
                    inputContainer.find('.activity-submit-btn').css('display','inline-block');
                    inputContainer.find('.disable-edit-mode-btn').css('display','inline-block');
                    TinyMceEditor.getEditorInstance(input.attr('id')).setMode('design');
                    tinyMceEditor.setHeight(expandedHeight+'px');
                });

                inputContainer.find('.disable-edit-mode-btn').off('click').on('click', function() {
                    window.jQuery(this).css('display','none');
                    inputContainer.find('.activity-submit-btn').css('display','none');
                    inputContainer.find('.enable-edit-mode-btn').css('display','inline-block');
                    TinyMceEditor.getEditorInstance(input.attr('id')).setMode('readonly');
                    tinyMceEditor.setHeight(height+'px');
                });
            });
        }
    }

    toggleChecklistView = (ref, mode) => {
        let btn = window.jQuery(ref);
        let cardBody = btn.closest('.card-body');

        btn.addClass('hidden');
        btn.siblings('.btn').removeClass('hidden');

        if(mode === 'edit') {
            cardBody.find('.wizard-checklist-wrapper').removeClass('hidden');
            cardBody.find('.wizard-scoring-wrapper').addClass('hidden');
        } else {
            cardBody.find('.wizard-checklist-wrapper').addClass('hidden');
            cardBody.find('.wizard-scoring-wrapper').removeClass('hidden');
        }
    }
}
