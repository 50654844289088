import React from 'react';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {HandleTranslation, SetupTranslations} from "../../redux/translations/translator";
import "./IncentiveDetailsComponent.scss";


let isDetailButtonEnabled = false;
let buttonReturned = '';

const IncentiveDetailsComponent = (props) => {
    /**
     * todo: translate button text, action on homepage, action on other pages
     * This needs to know which menu item is active at onClick. ///Redux
     */
    let buttonText = props.displaySettings.view_details_button.value;
    const translationDomain = 'main_navigation';
    SetupTranslations(translationDomain);
    buttonText = HandleTranslation('incentive_progress_view_detail_button_text', buttonText, translationDomain);
    if(props.displaySettings !== null){
        isDetailButtonEnabled = props.displaySettings.view_details_button.enabled === 'Y';
        if(isDetailButtonEnabled){
            buttonReturned = (
                <Col className="progressRatioTextSmall">
                    <DetailsButton buttonText={buttonText}/>
                </Col>
            );
        }
    }
    return buttonReturned;
}
export default IncentiveDetailsComponent;

const DetailsButton = props => {
    /**
     * Pull in the JS reference to incenitve_progress.js
     * @type {React.RefObject<any> | null | TaskListProgressBar}
     */
    const ProgressBar = window.progressBar;
    return (
        <Button variant="primary" className="incentiveDetailsButton" onClick={() => {ProgressBar.handleViewDetails()}}>
            {props.buttonText}
        </Button>
    );
}
