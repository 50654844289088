import ReactDOM from "react-dom";
import React, {useEffect, useState} from 'react';
import './SkipLink.scss';

/*
    Accessiblility skip link to allow skipping to a specific dom element in the app to bypass some tiresome keyboard navigation
 */
export const SkipLink = (props) => {

    const [parent,setParent] = useState(null);
    const [selector] = useState(props.selector ?? '#page-container > :first-child');
    const [node,setNode] = useState(null);
    const [text] = useState(props.text ?? "skip to main content");

    const skip = (e) => {
        e && e.preventDefault();
        if(!selector) return;
        let skipTo = document.querySelector(selector);
        if(skipTo) {
            let tabindex = skipTo.getAttribute("tabindex");
            if(tabindex === null || isNaN(tabindex)) skipTo.setAttribute("tabindex","-1");
            skipTo.focus();
        }
    }

    useEffect(()=>{
        if(parent === null) {
            let domEl = props.node;
            if(typeof props.node === "function") domEl = props.node();
            else if(!props.node) domEl = document.body;
            setNode(domEl);
            setParent(document.createElement("div"));
        }
    },[]);

    if(parent === null) return null;
    parent.classList.add("skiptocontent");
    let firstChild = node.firstChild;
    if(firstChild) node.insertBefore(parent,firstChild);
    else node.append(parent);

    return ReactDOM.createPortal(<a tabIndex={0} onClick={skip}>{text}</a>,parent);
}
