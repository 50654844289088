import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "react-loading-skeleton";

// import './skeleton.scss';
/*
 Note: currently showing the same skeleton page for all pages, but the idea is to add more legacy-page-specific skeletons below and get the specific one for the page
 */
const PageContentSkeleton = (props) => {
    let version = 'default';
    if(props.page)
    {
        if(['/','home'].includes(props.page)) version = 'home';
    }

    const defaultHeight = 125;
    const homeHeight = 250;
    return (
        <>
            {version === 'default' &&
            <div className="skeleton">
                <Row noGutters>
                    <Col xs="4" className="">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                    <Col xs="4" className="px-3">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                    <Col xs="4" className="">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs="4" className="">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                    <Col xs="4" className="px-3">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                    <Col xs="4" className="">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs="4" className="">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                    <Col xs="4" className="px-3">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                    <Col xs="4" className="">
                        <Skeleton className="my-3" height={defaultHeight}/>
                    </Col>
                </Row>
            </div>
            }
            {version === 'home' &&
            <div className="skeleton">
                <Row noGutters>
                    <Col>
                        <Skeleton className="my-3" height={homeHeight}/>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs="4" className="px-3">
                        <Skeleton className="my-3" height={homeHeight}/>
                    </Col>
                    <Col xs="4" className="px-3">
                        <Skeleton className="my-3" height={homeHeight}/>
                    </Col>
                    <Col xs="4" className="px-3">
                        <Skeleton className="my-3" height={homeHeight}/>
                    </Col>
                </Row>

                <Row noGutters>
                    <Col>
                        <div className="bone tile my-3">
                            <Row style={{alignItems:"center",height:homeHeight+"px"}}>
                                <Col sm={12} md={2}>
                                    <Skeleton circle={true} width={80} height={80} className={"ml-5"}/>
                                </Col>
                                <Col sm={12} md={2}>
                                    <Skeleton circle={true} width={80} height={80} className={"ml-5"}/>
                                </Col>
                                <Col sm={12} md={2}>
                                    <Skeleton circle={true} width={80} height={80} className={"ml-5"}/>
                                </Col>
                                <Col sm={12} md={2}>
                                    <Skeleton circle={true} width={80} height={80} className={"ml-5"}/>
                                </Col>
                                <Col sm={12} md={2}>
                                    <Skeleton circle={true} width={80} height={80} className={"ml-5"}/>
                                </Col>
                                <Col sm={12} md={2}>
                                    <Skeleton circle={true} width={80} height={80} className={"ml-5"}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            }
        </>
    );
}
export default PageContentSkeleton;