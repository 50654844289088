import { processAjax } from "../ajaxActions";

export class UploadController {
    constructor(parent) {
        this.parent = parent;
    }

    load = () => {
        //This is only called from a wizard-based upload
        this.init(window.jQuery(this.parent.wrapper.find('input[name="multi_upload_tile_id"]').val()));
    }

    save = () => {
        //Wizard-based upload opbject needs a save function in order to move backwards.
    }

    init = params => {
        this.options = params;

        let parent = this.parent;
        let tile = params;
        if (typeof (params) === 'object' && typeof (params.tile) !== 'undefined') {
            tile = params.tile;
        }
        let $tile = window.jQuery(tile);
        let $upload_complete = $tile.find('.complete_check');
        let $upload_drop = $tile.find('.upload_drop');
        let uploadController = this;
        let $file_form = $tile.find('form').has('input[type="file"]');
        let $filename_input = $file_form.find('input[type="text"]:visible');
        let $browse_button = $file_form.find('.btn-browse');
        let $progress_bar = $tile.find('.progress > .progress-bar');
        let $upload_button = $tile.find('.btn-upload');
        let upload_button_mode = $upload_button.css('visibility') === 'hidden' ? 'visibility' : 'display';
        $tile.fileupload({
            dataType: 'json',
            autoUpload: false,
            singleFileUploads: false,
            sequentialUploads: true,
            dragover: function (e, data) {
                e.preventDefault();
                if (!window.dropZoneTimeout) {
                    $upload_drop.fadeTo(250, 0.75).css('line-height', $upload_drop.height() + 'px');
                } else {
                    clearTimeout(window.dropZoneTimeout);
                }
                window.dropZoneTimeout = setTimeout(function () {
                    window.dropZoneTimeout = null;
                    $upload_drop.fadeTo(250, 0, function () {
                        window.jQuery(this).hide();
                    });
                }, 100);
            },
            drop: function (e, data) {
                e.preventDefault();
            }
        });
        $file_form.find('input[type="file"]').fileupload({
            dataType: 'json',
            autoUpload: false,
            singleFileUploads: false,
            sequentialUploads: true,
            add: function (e, data) {  // per file added to queue
                // Reset display elements
                $progress_bar.closest('.progress').css('visibility', 'hidden');
                if (!$upload_button.hasClass('v8-btn-upload')) {
                    if (upload_button_mode === 'visibility') {
                        $upload_button.css('visibility', 'hidden');
                    } else {
                        $upload_button.hide();
                    }
                } else { // button is v8
                    !$upload_button.hasClass('disabled') && $upload_button.addClass('disabled');
                }
                // Front-end limit selected files
                let acceptFileTypes = new RegExp($file_form.find('input[name="acceptFileTypes"]').val(), 'i');
                let maxFileSize = parseInt($file_form.find('input[name="maxFileSize"]').val());
                for (let i = 0; i < data.files.length; i++) {
                    if (!acceptFileTypes.test(data.files[i].name)) {
                        window.core_messager.initModal({message: $tile.find('.UPLOAD_ERR_FILE_EXTENSION, .UPLOAD_ERR_DEFAULT').eq(0).html()});
                        return false;
                    }
                    if (typeof (data.files[i].size) !== 'undefined' && data.files[i].size > maxFileSize) {
                        window.core_messager.initModal({message: $tile.find('.UPLOAD_ERR_FORM_SIZE, .UPLOAD_ERR_DEFAULT').eq(0).html()});
                        return false;
                    }
                }
                // Handle display elements
                $filename_input.val(window.jQuery.map(data.files, function (val, i) {
                    return data.files[i].name.replace(/\\/g, '/').replace(/.*\//, '')
                }).join(', '));
                $progress_bar.closest('.progress').css('visibility', 'visible');
                if (!$upload_button.hasClass('v8-btn-upload')) {
                    if (upload_button_mode === 'visibility') {
                        $upload_button.css('visibility', 'visible');
                    } else {
                        $upload_button.show();
                    }
                } else { // button is v8
                    $upload_button.hasClass('disabled') && $upload_button.removeClass('disabled');
                }
                $upload_button.unbind('click').bind('click', function () {
                    if (!$upload_button.hasClass('v8-btn-upload')) {
                        if (upload_button_mode === 'visibility') {
                            $upload_button.css('visibility', 'hidden');
                        } else {
                            $upload_button.hide();
                        }
                    } else { // button is v8
                        !$upload_button.hasClass('disabled') && $upload_button.addClass('disabled');
                    }

                    $progress_bar.addClass('progress-bar-striped active');
                    $browse_button.addClass('disabled');
                    if (!$progress_bar.length) {
                        $tile.mask_overlay();
                    }
                    data.submit();
                });
            },
            submit: function (e, data) {  // AJAX submit
                let formData = {};
                $file_form.find('input[name]').not('[type="file"]').each(function () {
                    let $input = window.jQuery(this);
                    formData[$input.attr('name')] = $input.val();
                });
                data.formData = formData;
            },
            progressall: function (e, data) {  // AJAX total upload progress
                let percent = Math.floor(data.loaded * 100 / data.total);
                $progress_bar.width(percent + '%').html(percent + '%');
            },
            done: function (e, data) {  // AJAX success
                $progress_bar.removeClass('progress-bar-primary progress-bar-striped active');
                for (let i = 0; i < data.files.length; i++) {  // look for PHP failures
                    if (typeof (data.result.files) === 'undefined' || typeof (data.result.files[i].error) !== 'undefined') {
                        $progress_bar.addClass('progress-bar-danger');
                        $browse_button.removeClass('disabled');
                        window.core_messager.initModal({message: $tile.find('.' + data.result.files[i].error + ', .UPLOAD_ERR_DEFAULT').eq(0).html()});
                        return;
                    }
                }
                $progress_bar.addClass('progress-bar-success');
                if ($upload_complete.length > 0) {
                    $upload_complete.removeClass('hidden');
                }

                //Handle Callbacks
                let $upload_callback = $file_form.find('input[name="postUploadCallback"]').val();
                let $upload_params = $file_form.find('input[name="postUploadParams"]').val() || null;
                if (typeof ($upload_callback) !== 'undefined') {
                    let callbacks = $upload_callback.split('|');
                    let callbackParams = $upload_params ? $upload_params.split('|') : [];
                    callbacks.forEach((cb, index) => {
                        let cbp = typeof (callbackParams[index]) !== 'undefined' ? JSON.parse(callbackParams[index]) : {};
                        cbp.field = parent;

                        let context = window.resolveContext(cb);
                        if(typeof(context) === 'function') {
                            context(cbp);
                        }
                    })
                } else if (typeof (uploadController.options.callbacks) !== 'undefined' && typeof (uploadController.options.callbacks.done) === 'string') {
                    window.resolveAndCall(uploadController.options.callbacks.done);
                } else {
                    processAjax('AHJUpload', '', 'refresh', {'maskElementId': $tile.attr('id')});
                }
            },
            fail: function (e, data) {  // AJAX fail
                $progress_bar.removeClass('progress-bar-primary active');
                $progress_bar.addClass('progress-bar-danger');
                $browse_button.removeClass('disabled');
                window.core_messager.initModal({message: $tile.find('.UPLOAD_ERR_DEFAULT').eq(0).html()});
            }
        });
    }
}
