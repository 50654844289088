import {useState, useEffect} from "react";
import { store } from "../store";
import produce from "immer";
import CloneDeep from 'lodash/cloneDeep';
import app from '../../setupAxios';
import {getState, resetStoreKeys, subscribe} from "../helpers";
import {DependencyMapper} from "../../util";

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["app","routes"];
const initialState = {
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storePackageRoutes: (state, payload) =>

        produce(state, draft => {
            if(payload.manifest)
            {
                const page = (payload.deepLink && payload.deepLink.page) ? '/' +  payload.deepLink.page : null;
                Object.entries(payload.manifest).map(([id,pkg],index) => {
                    if(pkg.routes){
                        if(typeof pkg.routes === 'string') pkg.routes = JSON.parse(pkg.routes).routes;
                        pkg.routes.map(route => {
                            if(typeof route.path === 'string') route.path = [route.path];
                            if(draft[route.id])
                            {
                                let obj = CloneDeep(draft[route.id]);
                                obj.path = [...new Set(draft[route.id].path.concat(route.path))];

                               //delete draft[route.id].path;
                                draft[route.id] = obj
                            }
                            else {
                                let obj = CloneDeep(route);
                                draft[route.id] = obj;
                            }
                            if(page && route.path.includes(page))draft[route.id].deepLink = payload.deepLink;
                        })
                    }
                });
            }
        }),
    'reset.app.routes': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
    reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/
export const getCurrentRoutes = () => {
    return getState(storePath);
}
export const useRoutes = () => {
    const [state, setState] = useState(getState(storePath));
    useEffect(() => subscribe(setState,storePath), [setState]);
    useEffect(() => {
            DependencyMapper.on('dataFetchReady','getPackageRoutes',() => {
                if (!(state.routes && state.routes.length > 0)) {
                    const { deepLink } = getState('session');
                    getPackageRoutes(deepLink);
                }
            });

        }, []);
    return state;
}

export const getPackageRoutes = async (deepLink = null) => {
    try {
        const response = await app.get('api/v1/features/manifest');
        const { manifest } = response.data;
        if (response.status === 200) {
            storePackageRoutes({ manifest, deepLink });
            DependencyMapper.reg("routes");
        }
    }catch(e){
        console.error("Error in getPackageRoutes(): ",e);
    }
};

export const storePackageRoutes = response => store.dispatch({ type: "storePackageRoutes", payload: response });