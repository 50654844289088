import { processAjax } from "../ajaxActions";

export const trackLink = async (text, link, title) => {
    if (typeof text == 'object') {
        // If called as handler, 'this' is the anchor
        if (!text.href) text = this;
        // If text is now an anchor element, get data; otherwise this is an error
        if (text.href) {
            link = text.href;
            title = text.title;
            text = text.text;
        } else {
            link = '';
            title = '';
            text = 'Bad Link';
        }
    }
    // From here we assume link is the href to track
    await processAjax('AHJTrackLink', '', 'trackLink|' + text + '|' + link + '|' + title);
}
