import React, {useState, useContext, useEffect} from 'react';
import {slide as Menu} from 'react-burger-menu';
import {HandleTranslation, SetupTranslations} from "../redux/translations/translator";
import TourQuickIcon from "./TourQuickIcon";
import MobileAppQuickIcon from "./MobileAppQuickIcon";
import Skeleton from "react-loading-skeleton";
import {exists} from "../utilities/helpers";
import parse from "html-react-parser/index";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import {Accordion} from "react-bootstrap";
import {getMenuItemState,
   mergeMenuState,
   setMenuItemState, useMenu} from "../redux/app/menus";
import {parsePath} from "../legacy/ajaxActions";
import {usePage} from "../redux/app/navigation";
const translationDomain = 'main_navigation';
const MyContext = React.createContext();
import {useMediaMatches} from "../components/utils/shared";
import "./QuickIconsMenu.scss"

const BuildMainNavComponents = () => {
   const ctx = useContext(MyContext)
   const translations = SetupTranslations(translationDomain);
   const page = usePage();
   let currentPathURL = parsePath(page.currentPath).url;
   const version = 'v1',menuId='main';
   const [{data, isLoading, isError }] = useMenu({version,id:menuId});
   const selectedClass = 'menu-item-selected';
   const selectedChildClass = 'child-menu-item-selected';
   let childSelected = '';

   const internalHandleTranslation = (key,  defaultContent = '') => {
      let translatedValue = defaultContent;
      if(exists(translations,`${translationDomain}.${key}`)){
         translatedValue = parse(translations[translationDomain][key]);
      }
      return translatedValue;
   };
   const generateNavigationAction = (menuItem={}) => {
      if(menuItem.customJs) return new Function(menuItem.customJs);

      let url = [menuItem.path];
      if(menuItem.action){
         if(url.indexOf("?") < 0) url.push('?');
         url.push('&ax_xmlaction='+menuItem.action);
      }

      return ()=> navigation.followPath(url.join(""),menuItem.submissionData);
   }

   const handleAccordionOnSelect = (key) => {

      try {
         let itemState = getMenuItemState(version, menuId, key);//['5411_4']
         const toggled = !itemState.isOpen;
         setMenuItemState({isOpen: toggled},version, menuId, key);//['5411_4']
      }
      catch(e)
      {
         console.error("Error:handleAccordionOnSelect",e);
      }
   }

   return (
      <>
         {isError && <div>Something went wrong ...</div>}
         {(isLoading) ?
            <Skeleton width={1140} height={48}/>
            : (data && data.menu && data.menu.items &&
               <div id="">
                  <Nav id="" className="" variant=""
                       defaultActiveKey="/">
                     <div className="main-menu-collapsed-items">
                        {Object.entries(data.menu.items).map(([id, item], key) => {
                           let pathURL = parsePath(item.path).url;
                           let selected = (currentPathURL === pathURL) ? selectedClass : '';
                           let isChildSelected = false;
                           let activeKey = (item.state && item.state.isOpen) ? id : null; // controls expand/collapse accordion
                           let navigationAction = generateNavigationAction(item);

                           return (item.path ?
                                 <Nav.Item key={key} id={`${item.id}-collapsed`} className={`menu-item-text ${selected}`}>
                                    <a onClick={() => {
                                       navigationAction();
                                       ctx.toggleMenu();
                                    }}
                                          className={`nav-link item`}>
                                       {internalHandleTranslation('navigation_' + item.id, item.title)}
                                    </a>
                                 </Nav.Item> :
                                 <Accordion key={key} onSelect={()=>handleAccordionOnSelect(item.id)} activeKey={activeKey}>
                                    <Card>
                                       <Accordion.Toggle as={Card.Header} eventKey={id}>
                                          <Nav.Item key={key} id={`${item.id}-collapsed`}
                                                    className={`menu-item-text ${isChildSelected ? selectedClass : ''}`}>
                                             <a onClick={() => { }}
                                                className={`nav-link item${selected}`} tabIndex={0}
                                                data-toggle={"popover"}>
                                                {internalHandleTranslation('navigation_' + item.id, item.title)}
                                                <span
                                                   className="md-icons menu-item-icon-has-children">arrow_drop_down</span>
                                             </a>
                                          </Nav.Item>
                                       </Accordion.Toggle>
                                       <Accordion.Collapse eventKey={id}>
                                          <Card.Body>
                                             {Object.entries(item.children).map(([childId, child], childKey) => {
                                                //iterate over the children of each main menu item.
                                                const childPathURL = parsePath(child.path).url;
                                                if (currentPathURL === childPathURL) {
                                                   isChildSelected = true;
                                                   childSelected = selectedChildClass;
                                                } else {
                                                   childSelected = '';
                                                }
                                                let childNavigationAction = generateNavigationAction(child);
                                                return (
                                                   <Card className={"main-nav-collapsed-card"}
                                                         key={key + "_" + childKey}>
                                                      <div className="main-nav-card-container">
                                                         <Nav.Item id={`${child.id}-collapsed`}>
                                                            <a onClick={() => {
                                                                     document.body.click();
                                                                     childNavigationAction();
                                                                     ctx.toggleMenu();
                                                                  }}
                                                                  className={`nav-link item${selected}`}>
                                                               <Card.Img variant="top"
                                                                         alt={child.title}
                                                                         src={child.image ? child.image : `${process.env.REACT_APP_ASSETS_URL_OVERRIDE}/apps/resources/self_service/images/v5/resources/newsletters/AHTalk_PreviewImage_April_2020.jpg`}/>
                                                               <Card.Body>
                                                                  <Card.Title
                                                                     className={childSelected}>{internalHandleTranslation('navigation_' + child.id, child.title)}</Card.Title>
                                                               </Card.Body>
                                                            </a>
                                                         </Nav.Item>
                                                      </div>
                                                   </Card>
                                                )
                                             })}
                                          </Card.Body>
                                       </Accordion.Collapse>
                                    </Card>

                                 </Accordion>
                           )
                        })}
                     </div>
                  </Nav>
               </div>
            )}
      </>
   );
}

const BuildQuickIconsMenu = props => {
   SetupTranslations(translationDomain);
   const [{data, isLoading, isError }] = useMenu({version:'v1',id:'quick_menu'} );
   let pullOutIconExcess = 0;

   return (
      <>
         {isError && <div>Unable to load QuickIconsMenu data</div>}
         {isLoading ? (
            <>
               <Skeleton width={48} height={48} className={"ml-2"}/>
               <Skeleton width={48} height={48} className={"ml-2"}/>
               <Skeleton width={48} height={48} className={"ml-2"}/>
            </>
         ) : (data && data.menu && data.menu.items &&
            <div>
               <div className="">
                  {Object.entries(data.menu.items).map(([id,item], key) => {
                     return (
                        <div key={item.id} className="collapsed-menu-quick-links">
                             <BuildQuickLinkEntity item={item} itemId={item.id} index={++pullOutIconExcess} isFromOutside={false}/>
                         </div>
                     )
                  })}
               </div>
            </div>
         )
         }
      </>
   );
}

const BuildQuickLinkEntity = (props) => {
   const {item, index, itemId, isFromOutside} = props;
   let visibleIcon =           (<span key={item.id}/>);
   let visibleIconContent =    (<></>);
   let iconIsNavigation =      false;
   let iconIsResourceLink =    false;
   let iconIsProcessJSON =     false;
   const ctx = useContext(MyContext);
   function menuAction(){
      if(!isFromOutside){
         ctx.toggleMenu();
      }
   }
   if(item.path){
      iconIsNavigation =      true;
   } else if(item.custom_js){
      iconIsResourceLink =    true; //usually this is a link to a resource
   } else if(item.action && item.class){
      iconIsProcessJSON =     true; //AHJTracking,"{...}",displayLegacyTrackingHistory
   }
   let pullOutIconClass = 'initial';//"compressed" class will hide on smaller screens
   //this will handle setting up the links for each element

   visibleIconContent = (
      <div className={`quick-icon-wrapper icon-display-count-${pullOutIconClass} d-flex justify-content-center align-items-center flex-wrap`}>
         <div className="quick-icon-container">
            <span className={`${item.class} quick-icon`}>{item.icon}</span>
         </div>
         <div className="quick-icon-text">
            {HandleTranslation('quick_icon_'+itemId, item.title, translationDomain)}
         </div>
      </div>
   );

   if(iconIsNavigation){
      visibleIcon = (
         <a onClick={() => { if(!isFromOutside){ctx.toggleMenu();} navigation.followPath(item.path) }}>
            {visibleIconContent}
         </a>
      );
   } else if(iconIsResourceLink){
      visibleIcon = (
         <span onClick={ () => {if(!isFromOutside){ctx.toggleMenu();} }}>
                {visibleIconContent}
            </span>
      );
   } else if(iconIsProcessJSON){
      visibleIcon = (
         <span onClick={ () => {if(!isFromOutside){ctx.toggleMenu();}}}>
                {visibleIconContent}
            </span>
      );
   } else {
      visibleIcon = (
         <span>
                {visibleIconContent}
            </span>
      );
   }
   if(item.id === 'tour'){

      visibleIcon = (
         <>
            <TourQuickIcon
               visibleIconContent={visibleIconContent}
               menuAction={menuAction}
               shouldAutoOpenTour={ctx?.shouldAutoOpenTour ?? false}
            />
         </>
      );
   } else if(item.id === 'mobile_app'){

      visibleIcon = (
         <>
            <MobileAppQuickIcon
               visibleIconContent={visibleIconContent}
               menuAction={menuAction}
            />
         </>
      );
   }
   return visibleIcon
}


const MyProvider = (props) => {
   const version = 'v1';
   const menuId = 'main';
   const [{data, isLoading, isError,state:menuState={}}] = useMenu({version,id:menuId});
   const mediaMatches = useMediaMatches();

   return (
      <MyContext.Provider value={{
         isMenuOpen: menuState.isOpen,
         toggleMenu: () =>  mergeMenuState(`${version}.${menuId}.menu`,{isOpen:!menuState.isOpen}),
         stateChangeHandler: (newState) => mergeMenuState(`${version}.${menuId}.menu`,{isOpen:newState.isOpen}),
         shouldAutoOpenTour: !mediaMatches.media.large
      }}>
         {props.children}
      </MyContext.Provider>
   )
}
const MenuOpenButton = () => {
   const ctx = useContext(MyContext)
   return (
      <div onClick={ctx.toggleMenu} className={`md-icons quick-icon hamburger`} tabIndex={0}>menu</div>
   )
}

const QuickMenu = (props) => {
   const ctx = useContext(MyContext);

   return (
      <div id="quick-Icons-menu-hamburger" className="quick-icons-menu-hamburger quick-icon-wrapper">
         <Menu
            width={'435px'}
            right
            outerContainerId={ "quick-Icons-menu-hamburger" }
            customCrossIcon={ <i className="md-icons">close</i> }
            customBurgerIcon={false}
            isOpen={ctx.isMenuOpen}
            onStateChange={(state) => ctx.stateChangeHandler(state)}
         >
            <BuildMainNavComponents />
            <BuildQuickIconsMenu />
         </Menu>
      </div>
   )
}


const CollapsedMenu = () => {


   return(
      <MyProvider>
         <MenuOpenButton />
         <QuickMenu />
      </MyProvider>
   );
}

export default CollapsedMenu;
