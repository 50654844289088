import { CarouselController } from "./carouselController";
import { initializeAHPopover } from "../components/popoverManager";

export class ApplicationNotifications {
    constructor() {
        this.carouselId = 'notification-carousel';
        this.notificationModalCount = 0;
        this.popoverTimeToDisplay = 7000;
        this.DISPLAY_MODE_DISMISS_ON_CLICK = 'on_click';
        this.DISPLAY_MODE_DISMISS_AFTER_TIME_ELAPSED = 'after_time_elapsed';

        this.carousel = new CarouselController(this.carouselId);
    }

    /*
     * Notification Display
      _   _       _   _  __ _           _   _               _____  _           _
     | \ | |     | | (_)/ _(_)         | | (_)             |  __ \(_)         | |
     |  \| | ___ | |_ _| |_ _  ___ __ _| |_ _  ___  _ __   | |  | |_ ___ _ __ | | __ _ _   _
     | . ` |/ _ \| __| |  _| |/ __/ _` | __| |/ _ \| '_ \  | |  | | / __| '_ \| |/ _` | | | |
     | |\  | (_) | |_| | | | | (_| (_| | |_| | (_) | | | | | |__| | \__ \ |_) | | (_| | |_| |
     |_| \_|\___/ \__|_|_| |_|\___\__,_|\__|_|\___/|_| |_| |_____/|_|___/ .__/|_|\__,_|\__, |
                                                                        | |             __/ |
                                                                        |_|            |___/
     */


    /**
     * @author KCupp
     * @param {{}} parameters
     */
    appendNotificationModal = (parameters) => {
        //Find or display the modal
        let modal = this.findModal();
        if (modal == null || !modal.find('.modal-dialog').hasClass('carousel')) {
            this.clearNotificationModalList();
            modal = this.showModal().findModal();
            this.carousel.init();
        }
        let carouselInner = modal.find('.carousel-inner');

        //Add the notification content itself to the carousel
        carouselInner.append(this.generateCarouselItem(parameters.content));
        //Add an indicator for this notification
        modal.find(".carousel-indicators").append(this.generateCarouselIndicator());
        //Keep track of how many notifications are in the carousel
        this.notificationModalCount++;

        this.carousel.setControls();
    };

    /**
     * @author KCupp
     * @param {{}} parameters
     */
    displayPopover = (parameters) => {
        if (typeof(parameters.content) !== 'undefined' && typeof (parameters.target) !== 'undefined') {
            //Configure / clean the parameters
            parameters.trigger = 'manual';
            parameters.show = true;
            if(typeof parameters.dismissOnClick === 'undefined'){
                parameters.dismissOnClick = true;
            }
            if (typeof parameters.timeToDisplay === 'undefined') {
                parameters.timeToDisplay = this.popoverTimeToDisplay;
            }
            parameters.container = window.jQuery(parameters.target).parent();
            initializeAHPopover(parameters.target, parameters);
        }
    };




    /*
     * Modal Utility
      __  __           _       _   _    _ _   _ _ _ _
     |  \/  |         | |     | | | |  | | | (_) (_) |
     | \  / | ___   __| | __ _| | | |  | | |_ _| |_| |_ _   _
     | |\/| |/ _ \ / _` |/ _` | | | |  | | __| | | | __| | | |
     | |  | | (_) | (_| | (_| | | | |__| | |_| | | | |_| |_| |
     |_|  |_|\___/ \__,_|\__,_|_|  \____/ \__|_|_|_|\__|\__, |
                                                         __/ |
                                                        |___/
     */


    /**
     * @author KCupp
     * @returns {ApplicationNotifications}
     */
    showModal = () => {
        window.core_messager.initModal({
            sourceElement:'#carousel-skeleton',
            size: 'large',
            buttons: [],
            title: '',
            class: 'carousel'
        });
        initializeAHPopover('#notification-show-fewer', {container:'.modal-body'});
        return this;
    };

    /**
     * @author KCupp
     * @returns {null|window.jQuery}
     */
    findModal = () => {
        let modal = window.jQuery('.modal');
        modal = modal.length === 0 ? null : modal;
        return modal;
    };

    /**
     * @author KCupp
     */
    clearNotificationModalList = () => {
        this.notificationModalCount = 0;
    };

    /**
     * @author KCupp
     * @param content
     * @returns {window.jQuery}
     */
    generateCarouselItem = (content) => {
        return window.jQuery('<div class="carousel-item' + (this.notificationModalCount === 0 ? ' active' : '') + '">' + content + '</div>');
    };

    /**
     * @author KCupp
     * @returns {window.jQuery}
     */
    generateCarouselIndicator = () => {
        return window.jQuery('<li data-target="#' + this.carouselId + '" data-slide-to="' + this.notificationModalCount + '"' + (this.notificationModalCount === 0 ? ' class="active"' : '') + '></li>')
    };



    dismissNotification = () => {
        if(this.carousel.countSlides() > 1 && !this.carousel.onLastSlide()){
            this.carousel.nextSlide();
        }else{
            this.carousel.dispose();
        }
    }
}
