import {useState, useEffect} from "react";
import { store } from "../store";
import produce from "immer"
import axios from "axios";
import {getState, resetStoreKeys, subscribe} from "../helpers";
//import { courseProgressReceived } from "./courseProgress";
import app from "../../setupAxios"

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["incentive"];
const initialState = {};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
   incentiveReceived: (state, payload) =>
      produce(state, draft => {
         const key = "id";
         Object.keys(payload).forEach(key => { draft[key] = payload[key] });
      }),
   'reset.incentive': (state, payload) =>
       produce(state, draft => {
          resetStoreKeys(state,draft,initialState,payload);
       }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
   reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/
export const useIncentive = () => {
   const [state, setState] = useState(getState(storePath));
   useEffect(() => subscribe(setState,storePath), [setState]);
   return state;
};

export const incentiveReceived = measures => store.dispatch({ type: "incentiveReceived", payload: measures });

export const getIncentiveAsync = async () => {
   try {
      const response = await app.get(`/api/v2/features/incentive_status_bar/`);
      if (response.status === 200 && response.data) {
         incentiveReceived(response.data);
         console.log('Incentive has been REFRESHED.');
      }
   }catch(e){
      console.error("Error in getIncentiveAsync(): ",e);
   }
};

export const recalculateIncentive = async () => {
   try {
      const response = await app.post(`/api/v2/features/incentive/calculate`);
      if (response.status === 200 && response.data) {

         console.log('Incentive has been RECALCULATED.');
         getIncentiveAsync();
      }
   }catch(e){
      console.error("Error in recalculateIncentive(): ",e);
   }
};
