import axios from 'axios';
import { store } from "./redux/store";
import { refreshSessionTimer } from "./redux/app/session";

let app = axios.create();

app.interceptors.request.use(function (config) {
    let { auth={} } = store.getState();
    let { accessToken } = auth;
    config.headers.Authorization = 'Bearer ' + accessToken;
    return config;
});
app.interceptors.response.use(function (response) {
    refreshSessionTimer();
    return response;
}, function (error) {
    refreshSessionTimer();
    return Promise.reject(error);
});

export default app;
