import { refreshToken, storeTimeoutHandler } from "../redux/auth/auth";

const DEFAULT_REFRESH_INTERVAL = 900;
const DEFAULT_REFRESH_INTERVAL_PERCENTAGE = .9;

export const getFormattedTimeout = (timeout = null) => {
    if(!timeout) timeout = DEFAULT_REFRESH_INTERVAL;
    return timeout * 1000; //server returns the time in seconds, convert to ms
}

export const setRefreshTokenTimer = (currentTimeout, timeout) => {
    if(currentTimeout || currentTimeout === 0) clearTimeout(currentTimeout); //clear the timeout if one exists.
    if(!timeout) timeout = 900000; //default to 15 minutes;

    const truncatedTimeout = timeout * DEFAULT_REFRESH_INTERVAL_PERCENTAGE; //Refresh early using the defined percentage
    // console.log('truncatedTimeout', truncatedTimeout, truncatedTimeout / 1000 + " seconds");
    const handler = setTimeout(async () => {
        // console.log("Call refreshToken")
        await refreshToken();
    }, truncatedTimeout);

    storeTimeoutHandler(handler);
}
