export const exportAhGlobals = () => {
    window['scrollToElement'] = scrollToElement;
    window['scrollElementToPosition'] = scrollElementToPosition;
    window['calculateRelativePosition'] = calculateRelativePosition;
    window['isInView'] = isInView;
    window['isVisibleBreakPoint'] = isVisibleBreakPoint;
    window['toggleSideBar'] = toggleSideBar;
    window['whichTransitionEvent'] = whichTransitionEvent;
    window['alignNeighbors'] = alignNeighbors;
    window['initHttpsAccess'] = initHttpsAccess;
}

/**
 * Scroll the indicated element, to the indicated descendant element
 * @author KCupp, JBonnell
 * @param descendant
 * @param scrollTarget
 * @param complete
 */
export const scrollToElement = (descendant, scrollTarget, complete) => {
    if (typeof(descendant) === 'string') {
        descendant = window.jQuery(descendant);
    } else if (typeof(descendant) === 'undefined') {
        throw new Error('Parameter: descendant was not passed to scrollToElement');
    } else {
        descendant = window.jQuery(descendant);
    }

    let newScrollTop = null;
    let relativePosition = null;

    if (typeof(scrollTarget) === 'string') {
        scrollTarget = window.jQuery(scrollTarget);
        relativePosition = calculateRelativePosition(scrollTarget, descendant).y;
    } else if (typeof(scrollTarget) === 'undefined' || scrollTarget === null) {
        scrollTarget = window.jQuery('html,body');
        if(!isInView(descendant)) {
            newScrollTop = descendant.offset().top - window.jQuery('#header').outerHeight(true);
        }else{
            newScrollTop = scrollTarget.scrollTop();
        }
    } else {
        relativePosition = calculateRelativePosition(scrollTarget, descendant).y;
    }

    if (relativePosition !== null) {
        newScrollTop = scrollTarget.scrollTop() + relativePosition;
    }

    scrollTarget.animate({'scrollTop': newScrollTop}, 500, 'swing', complete);
}

/**
 * @author KCupp, JBonnell
 * @param scrollTarget
 * @param scrollTop
 * @param complete
 */
export const scrollElementToPosition = (scrollTarget, scrollTop, complete) => {
    window.jQuery(scrollTarget).animate({'scrollTop': scrollTop}, 500, 'swing', complete);
}

/**
 * Find the position of one element relative to one of its ancestors, even if that ancestor is not the element's offsetParent()
 * @author KCupp, JBonnell
 * @param ancestor
 * @param descendant
 * @returns {{x: number, y: number}}
 */
export const calculateRelativePosition = (ancestor, descendant) => {
    let relativePosition = {x: 0, y: 0};
    ancestor = window.jQuery(ancestor);
    descendant = window.jQuery(descendant);
    //If the descendant is actually somewhere beneath ancestor in the DOM...
    if (ancestor.find(descendant).length > 0) {
        let parent = descendant.parent();
        let child = descendant;
        do {
            if (parent[0] === child.offsetParent()[0]) {
                let position = child.position();
                relativePosition.x += (position.left);
                relativePosition.y += (position.top);
                child = parent;
                if(parent.parent() !== ancestor){}
                parent = parent.parent();
            } else {
                parent = parent.parent();
            }
        } while (parent[0] !== ancestor[0]);

        //After the while loop, we'll have ended up on the original ancestor, but the position of the most
        // recent child relative to the ancestor will not have been included. We need to do so now.
        let position = child.position();
        relativePosition.x += (position.left);
        relativePosition.y += (position.top);
    }
    return relativePosition;
}


export const isInView = (elem, centerOnly) => {
    let $elem = window.jQuery(elem);
    centerOnly = typeof(centerOnly) === 'undefined' ? false : centerOnly;
    let $window = window.jQuery(window);

    let docViewTop = 0;
    let docViewBottom = docViewTop + $window.height();

    let elemTop = $elem.offset().top - $window.scrollTop();
    let elemBottom = elemTop + $elem.height();

    if(centerOnly){
        let elemCenter = $elem.height() / 2 + elemTop;
        return (elemCenter >= docViewTop && elemCenter <= docViewBottom);
    }else {
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
}

export const toggleSideBar = (reference, openMenu, forceOpen, callBack) => {
    let id = 'sidebar-menu';
    let menuOpenClass = 'active';

    console.log('toggleSideBar');
    if(typeof(forceOpen) === 'undefined'){ forceOpen = false; }
    if(typeof(callBack) !== 'function'){ callBack = function(){}; }

    if (typeof(reference) !== 'undefined' && reference !== null) {
        let elem = window.jQuery(reference);
        if (!elem.hasClass('disabled')) { // do not set disabled menu items to active
            elem.closest('.sidebar-item').siblings().each(function(){
                window.jQuery(this).removeClass('sidebar-active');
            });

            elem.closest('.sidebar-item').addClass('sidebar-active');
        }
    }

    //only need to toggle if we are in mobile view or forced
    if(isVisibleBreakPoint('xs|sm|md') || forceOpen){
        let sidebar = window.jQuery('#' + id);
        let side = sidebar.attr('data-side');
        if(typeof(openMenu) === 'undefined'){
            openMenu = !sidebar.closest('.row-offcanvas-' + side).hasClass(menuOpenClass);
        }
        if(openMenu) {
            let transitionEvent = whichTransitionEvent(); //Determine browser specific transition event
            sidebar.addClass(menuOpenClass);
            sidebar.closest('.row-offcanvas-' + side).addClass(menuOpenClass).on(transitionEvent, function(){
                callBack();
                window.jQuery(this).off(transitionEvent);
            });
        }else {
            sidebar.removeClass(menuOpenClass);
            sidebar.closest('.row-offcanvas-' + side).removeClass(menuOpenClass);
        }
    }
}

export const isVisibleBreakPoint = (size) => {
    size = size.split('|');
    for (let i = 0; i < size.length; i++) {
        if (window.jQuery('.breakpoint.device-' + size[i]).is(':visible')) {
            return true;
        }
    }

    return false;
}

export const getPopoverContent = () => {
    let elem = window.jQuery(this);
    return elem.find('.popover_content,.popover-content').html();
}

export const initializePopovers = () => {
    window.jQuery('[data-toggle="popover"]').popover({content: getPopoverContent, html:true});
}

export const whichTransitionEvent = () => {
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition':'transitionend',
        'OTransition':'oTransitionEnd',
        'MozTransition':'transitionend',
        'WebkitTransition':'webkitTransitionEnd'
    }

    for(t in transitions){
        if( el.style[t] !== undefined ){
            return transitions[t];
        }
    }
}

/**
 * @author KCupp, JBonnell
 * @param neighbors
 * @param alignmentSelector
 */
export const alignNeighbors = (neighbors, alignmentSelector) => {
    let maxHeight = 0;
    let neighbor;
    let neighborIndex;
    let alignmentElement;

    for (neighborIndex = 0; neighborIndex < neighbors.length; neighborIndex++) {
        neighbor = window.jQuery(neighbors[neighborIndex]);
        alignmentElement = neighbor;
        if (typeof alignmentSelector !== 'undefined') {
            alignmentElement = window.jQuery(alignmentElement.find(alignmentSelector)[0]);
        }
        if (alignmentElement.outerHeight() > maxHeight) {
            maxHeight = alignmentElement.outerHeight();
        }
    }

    for (neighborIndex = 0; neighborIndex < neighbors.length; neighborIndex++) {
        neighbor = window.jQuery(neighbors[neighborIndex]);
        alignmentElement = neighbor;
        if (typeof alignmentSelector !== 'undefined') {
            alignmentElement = window.jQuery(alignmentElement.find(alignmentSelector)[0]);
        }
        //If this neighbor is not full-width, we need to vertically align it with the tallest neighbor
        if (neighbor.outerWidth() < neighbor.parent().innerWidth()) {
            alignmentElement.css('top', maxHeight / 2 - alignmentElement.outerHeight() / 2);
        } else {
            alignmentElement.css('top', 0);
        }
    }
}

export const initHttpsAccess = () => {
    let location = window.location;
    if(location.protocol === 'http:'){
        window.location = 'https://' + location.hostname + location.pathname;
    }
}
