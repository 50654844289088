export class ppcController {
    constructor(element) {
        this.elem = window.jQuery(element);
        this.initialized = this.elem.find('input.initialized');

        if (this.initialized.length == 0) {
            this.canInitialize = true;
        } else {
            this.canInitialize = this.initialized.val() == '0';
        }

        this.animationRunningElem = this.elem.find('.animation-running');
        if (this.animationRunningElem.length === 0) {
            this.elem.append('<input type="hidden" class="animation-running" name="animation-running" value="N"/>');
            this.animationRunningElem = this.elem.children('.animation-running');
        }
        this.animationRunning = this.animationRunningElem.val() === 'Y';

        this.animationTargetElem = this.elem.find('.animation-target');
        if (this.animationTargetElem.length === 0) {
            this.elem.append('<input type="hidden" class="animation-target" name="animation-target" value=""/>');
            this.animationTargetElem = this.elem.children('.animation-target');
        }
        this.animationTarget = parseFloat(this.animationTargetElem.val());

        this.value = parseInt(this.elem.find('.ppc-number').html());
        this.maximumNumber = parseInt(this.elem.find('.maximum').val());
        if (isNaN(this.maximumNumber)) {
            this.maximumNumber = 100;
        }

        this.percent = parseInt(this.elem.attr('data-percent'));
        if (isNaN(this.percent)) {
            this.setPercent();
        }
    }



    //Set the percentage based on the current value and max value
    setPercent = () => {
        this.percent = Math.min(Math.max(this.value / this.maximumNumber * 100, 0), 100);
    }

    setInitialized = (initialized) => {
        if (this.initialized.length !== 0) {
            this.initialized.val(initialized);
        }
    }

    animate = (value, allowInterrupt=true, duration) => {
        if (!this.animationRunning || allowInterrupt) {
            //Stop any running animation
            this.stopAnimation();

            this.setAnimationTarget(value);

            //Make sure the animation value is set to the previous value
            let animationElem = this.elem.find('.animation');
            animationElem.css('width', this.value + 'px');

            //Get the new value
            if (typeof (value) === 'undefined') {
                value = this.value;
            }
            this.value = value;

            if (typeof (duration) === 'undefined') {
                duration = 1000;
            }
            let ppc = this;
            this.setPercent();
            let goalPercentage = this.percent;
            //Stop any running animation
            animationElem.animate({'width': this.value + 'px'}, {
                'duration': duration, 'step': function (now, tween) {
                    ppc.setRotation(now / ppc.maximumNumber * 100);
                    ppc.displayValue(now);
                }, 'complete': function () {
                    ppc.stopAnimation();
                }
            });
            this.setAnimationRunning(true);
        }
    }

    setAnimationRunning = (running) => {
        this.animationRunningElem.val(running ? 'Y' : 'N');
        this.animationRunning = running;
    }

    setAnimationTarget = (target) => {
        this.animationTargetElem.val(target);
        this.animationTarget = target;
    }

    //Jump to the end of the currently running animation.
    stopAnimation =  () => {
        if (this.animationRunning) {
            this.elem.find('.animation').stop();
            this.value = this.animationTarget;
            this.setRotation(this.value / this.maximumNumber * 100);
            this.displayValue(this.value);
            this.setAnimationRunning(false);
        }
    }

    setRotation = (percent) => {
        if (typeof (percent) === 'undefined') {
            percent = this.percent;
        }
        this.percent = percent;


        if (this.percent > 100) {
            this.percent = 100;
        } else if (this.percent < 0) {
            this.percent = 0;
        }

        let deg = 360 * this.percent / 100;
        if (this.percent > 50) {
            this.elem.addClass('gt-50');
        } else {
            this.elem.removeClass('gt-50');
        }
        if (this.percent > 60) {
            this.elem.addClass('gt-60');
        } else {
            this.elem.removeClass('gt-60');
        }
        if (this.percent > 90) {
            this.elem.addClass('gt-90');
        } else {
            this.elem.removeClass('gt-90');
        }
        this.elem.find('.ppc-progress-fill').css('transform', 'rotate(' + deg + 'deg)');
        this.elem.attr('data-percent', this.percent);
    }

    displayValue = (newValue) => {
        newValue = Math.round(newValue);
        this.elem.find('.ppc-number').html(newValue);
    }
}

export const initializePPCs = () => {
    let ppc = window.jQuery('.progress-pie-chart');
    ppc.each(function () {
        let ppcObj = new ppcController(this);
        if (ppcObj.canInitialize) {
            ppcObj.setInitialized(true);
            ppcObj.displayValue(ppcObj.value);
            if (window.jQuery(this).find('input.animation').length && ppcObj.value > 0) {
                let saveValue = ppcObj.value;
                ppcObj.value = 0;
                ppcObj.animate(saveValue, false);
            } else {
                ppcObj.setRotation();
            }
        }
    });
}
