/**
 * A controller object for circular fill indicators
 * @author KCupp, JBonnell
 * @param element
 */
export class circularFill {
    constructor(element) {
        this.element = window.jQuery(element).find('.fill-inner');
        if (this.element.length > 0) {
            this.element.parent().append(window.jQuery('<input type="hidden" class="animate-element"/>'));
            this.animateElement = this.element.siblings('.animate-element');
            this.fillElement = this.element.find('.fill-mask rect');
            this.fillElement.attr('width', this.element.width());
            this.iconEmpty = this.element.find('.fill-icon.empty');
            this.targetPercentage = this.element.attr('data-fill-percentage');
            if (typeof this.targetPercentage === 'undefined' || this.targetPercentage === '') {
                this.targetPercentage = 0;
            }
            this.targetPercentage = parseFloat(this.targetPercentage);

            //Set the starting fill percentage and snap to the position
            this.setFillPercentage(this.targetPercentage, false);
        }
    }


    /**
     * Set the fill percentage and either animate to it, or jump to it
     * @author KCupp, JBonnell
     * @param percentage
     * @param animate
     */
    setFillPercentage = (percentage, animate) => {
        this.targetPercentage = parseFloat(percentage) / 100;
        if (typeof animate === 'undefined') {
            animate = true;
        }

        if (animate) {
            this.animate();
        } else {
            this.snap();
        }
    };

    /**
     * Smoothly transition to the new fill level
     * @author KCupp, JBonnell
     */
    animate = () => {
        let circularFillObject = this;
        this.animateElement.animate({width: this.calculateNewHeight()}, {
            progress: function (animation, progress) {
                let animatedHeight = window.jQuery(animation.elem).width();
                circularFillObject.fillElement.attr('height', animatedHeight);
                circularFillObject.iconEmpty.height(Math.max(0, animatedHeight - circularFillObject.iconEmpty.position().top))
            }
        });
    };

    /**
     * Given the current target percentage, determine how full the circle should be
     * @author KCupp, JBonnell
     * @returns {number}
     */
    calculateNewHeight =  () => {
        return Math.max(0, this.element.height() * (1 - this.targetPercentage));
    };

    /**
     * Jump to the new fill level
     * @author KCupp, JBonnell
     */
    snap = () => {
        let value = this.calculateNewHeight();
        this.animateElement.width(value);
        this.fillElement.attr('height', value);
        this.iconEmpty.height(Math.max(0, value - this.iconEmpty.position().top));
    };
}
