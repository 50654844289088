import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import jdenticon from 'jdenticon';

const Jdenticon = ({ jdenticonValue = null, size = '100%' }) => {
    const icon = useRef(null);
    useEffect(() => {
        jdenticon.update(icon.current, jdenticonValue);
    }, [jdenticonValue]);

    if(!jdenticonValue) return <div />;

    return (
        <div>
            <svg data-jdenticon-value={jdenticonValue} height={size} ref={icon} width={size} />
        </div>
    );
};

Jdenticon.propTypes = {
    size: PropTypes.string,
    value: PropTypes.string.isRequired
};

export default Jdenticon;
