import {useState, useEffect} from "react";
import { store } from "../store";
import produce from "immer"
import axios from "axios";
import {getState, resetStoreKeys, subscribe} from "../helpers";
//import { courseProgressReceived } from "./courseProgress";
import app from "../../setupAxios"
import parse from "html-react-parser/index";
import {exists} from "../../utilities/helpers";
import {DependencyMapper} from "../../util";
/**
 * Translator should simply hold an array for each domain
 */
/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["translationKeys"];
const initialState = {};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
   translationKeysReceived: (state, payload) =>

      produce(state, draft => {
         const { domain, keys } = payload;
         if(!exists(draft,domain)){
            draft[domain] = {};
         }
         Object.keys(keys).forEach(key => { draft[domain][key] = keys[key] });
      }),
    'reset.translationKeys': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
   reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/
export const useTranslations = (domain = 'default') => {
   const [state, setState] = useState(getState(storePath));
   useEffect(() => subscribe(setState,storePath), [setState]);
   return state;
};

export const SetupTranslations = (domain = 'default') =>{
   const [state, setState] = useState(getState(storePath));
   useEffect(() => subscribe(setState,storePath), [setState]);


    useEffect(() => {
      //console.log(state, domain);
        DependencyMapper.on('dataFetchReady',"translations."+domain,() => {
            if (typeof (state[domain]) === "undefined") {
                getTranslationsAsync(domain);
            }
        });
   }, [domain]);
   return state;
};

export const translationKeysReceived = keys => {
   store.dispatch({ type: "translationKeysReceived", payload: keys });
}

/*export const getTranslationFilesAsync = async (domain) => {
   try {
      const response = await app.get(`/api/v1/translations/en_US/${domain}`);
      if (response.status === 200 && response.data) {
         console.log(response.data);
         //translationKeysReceived({"keys": response.data, "domain": domain});
      }
   }catch(e){
      console.error("Error in getTranslationFilesAsync(): ",e);
   }
};*/
export const getTranslationsAsync = async (domain) => {
   //let batchList = {};
   //if(typeof(translationKeyArray) === 'object'){
      //batchList = translationKeyArray;
      //console.log(batchList);
      // The API pulls the key list from _GET $keys = json_decode($_GET['keys']);
      // Without the batch list, the translation API pulls all key value pairs from the default domain
      try {
         const response = await app.get(`/api/v1/translations/en_US/${domain}/batch`);
         if (response.status === 200 && response.data) {
            translationKeysReceived({"keys": response.data, "domain": domain});
            DependencyMapper.reg("translations."+domain);
         }
      }catch(e){
         console.error("Error in getTranslationFilesAsync(): ",e);
      }
   //}

};

export const HandleTranslation = (key,  defaultContent = '', domain = "default") => {
   //const language = 'en_US';

   const translations = useTranslations(domain);
   let translatedValue = defaultContent;

   if(exists(translations,`${domain}.${key}`)){
      translatedValue = parse(translations[domain][key]);
   }
   return translatedValue;
};