import {useEffect} from "react";

const useAHMutationObserver = (
    {
        callback,
        ref = document,
        options = {subtree:true, attributes:true, childList:true}
    }) => {
    useEffect(() => {
        const mutationObserver = new MutationObserver(() => callback());
        mutationObserver.observe(ref, options);
        return () => mutationObserver.disconnect();
    }, [ref, callback, options]);
};

export default useAHMutationObserver;