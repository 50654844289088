import React, {useCallback, useEffect, useRef} from 'react';
import TopMostNav from "../navigation/TopMostNav";
import MainNavV8 from "../navigation/V8/MainNav";
import MainNav from "../navigation/MainNav";
import IncentiveProgressBar from "../IncentiveProgressBar/IncentiveProgressBar";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Skeleton from "react-loading-skeleton";
import {usePHPSession} from "../redux/app/session";

export default (props) => {

    const root = useRef();
    const session = usePHPSession();
    /******
     Triggered when root.current is resize is observed via ResizeObserver
     ******/
    const resized = useCallback(() => {
        let header = root.current;
        if(header){
            let pageContainer = document.getElementById('page-container');
            if(pageContainer) pageContainer.style.marginTop = header.clientHeight+'px';
        }
    },[root]);

    useEffect( () => {
        let header = root.current, mutation_observer, resize_observer;

        if(header){
            /*
                Handle header mutation
            */
            mutation_observer = new MutationObserver(((mutationRecords, mutationObserver) => {
                resized();
            }));
            mutation_observer.observe(header,{subtree:true,attributes:true,childList:true});

            /*
                Handle header resize
            */
            if(typeof ResizeObserver === 'function'){
                resize_observer = new ResizeObserver(resized);
                resize_observer.observe(header);
            }
        }

        return () => {
            if(mutation_observer && mutation_observer.disconnect) mutation_observer.disconnect();
            if(resize_observer && resize_observer.disconnect) resize_observer.disconnect();
        }
    },[resized]);

    return (
        <Navbar id="header" bg={"header-nav"} fixed={"top"} ref={root}>
            <Container className="px-0">
                <div className="row no-gutters w-100">
                    <div className="col-12">
                        <TopMostNav {...props}/>
                    </div>
                    <div className="col-12 d-none d-lg-block">
                        {props.skeleton ?
                            <Skeleton width={1140} height={48}/> :
                            <>
                                {session.version === 8 && <MainNavV8 {...props}/>}
                                {session.version !== 8 && <MainNav {...props}/>}
                            </>
                        }
                    </div>
                    <div className="col-12">
                        {!props.skeleton &&
                            <IncentiveProgressBar {...props}/>
                        }
                    </div>
                </div>
            </Container>
        </Navbar>
    );
}
