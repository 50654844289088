import ReactDOM from "react-dom";
import React from 'react';
import _ from 'lodash';
import {empty} from "../../utilities/helpers";
import {DynComponent} from "../../util";
import {MemoryRouter} from "react-router-dom";
import {DynamicModal} from "./modals/DynamicModal";

class ModalRecord {
    modalRef;
    node;

    constructor(node, modalRef) {
        this.node = node;
        this.modalRef = modalRef;
    }
}

export class ModalManager {
    modals = {};
    modalIdPrefix = 'modal_';

    addModalRecord = (id, node, modalRef) => {
        this.modals[id] = new ModalRecord(node, modalRef);
    }

    removeModalRecord = (id) => {
        delete this.modals[id];
    }

    open = (props, node = null, component = null) => {
        const {childComponentProps={}} = props;
        const modalRef = React.createRef();
        if (!node) {
            node = document.createElement('div');
            document.body.appendChild(node);
        }

        let ChildComponent = null;
        if(!empty(component)) {
            if(typeof(component) === 'string') {
                ChildComponent = () => DynComponent(component,childComponentProps);
            } else {
                ChildComponent = component;
            }
        }

        const id = this.getNextModalId();
        this.addModalRecord(id, node, modalRef);

        ReactDOM.render(
            <>
            {ChildComponent === null &&
            <MemoryRouter>
                <DynamicModal
                    ref={modalRef}
                    key={id}
                    {...props}
                    cleanup={this.cleanup}
                    component={component}
                    close={() => {
                        this.close(id)
                    }}
                    restoreFocus={document.activeElement}
                />
            </MemoryRouter>
            }
            {ChildComponent !== null &&
            <MemoryRouter>
                <DynamicModal
                    ref={modalRef}
                    key={id}
                    {...props}
                    cleanup={this.cleanup}
                    component={component}
                    close={() => {
                        this.close(id)
                    }}
                    restoreFocus={document.activeElement}
                >
                    {component && (typeof ChildComponent !== 'function') && <ChildComponent {...childComponentProps} />}
                    {component && (typeof ChildComponent === 'function') && ChildComponent()}
                </DynamicModal>
            </MemoryRouter>
            }
            </>
            , node);
    }

    /**
     * Closes modal at specific index.
     * @param {string} [id] 1-based index.  If none provided, then closes current modal.
     */
    close = (id,callback=()=>{}) => {
        id = !id ? this.getCurrentModalId() : id;
        let modal = this.getModalById(id);
        modal?.modalRef?.current?.closeModal?.(callback);
    }

    closeAll = () => {
        _.map(this.modals, (modal, id) => {
            this.close(id);
        });

        return true;
    }

    cleanup = (id) => {
        id = !id ? this.getCurrentModalId() : id;
        this.removeModalRecord(id);
    }

    getModalById = (id) => {
        return _.get(this.modals, id, null);
    }

    /**
     * @returns {string}
     */
    getCurrentModalId = () => {
        return this.modalIdPrefix + this.getCurrentModalIndex();
    }

    /**
     * @returns {int}
     */
    getCurrentModalIndex = () => {
        return this.getModalCount();
    }

    /**
     * @param {int} index
     * @returns {string}
     */
    getModalId = (index) => {
        if (index === undefined || index === null) {
            index = this.getCurrentModalIndex();
        }
        return this.modalIdPrefix + index;
    }

    getNextModalId = () => {
        return this.getModalId(this.getModalCount() + 1);
    }

    getModalCount = () => {
        return _.keys(this.modals).length;
    }
}