import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import Video from "../../courses/slots/Video";
import './VideoQuickIcon.scss';

const VideoQuickIcon = props => {
    const {videoItem, visibleIconContent, menuAction, tabIndex, cls} = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const src = {
        "Config":{
            "src": process.env.REACT_APP_ASSETS_URL_OVERRIDE + videoItem.image,
            "tracks": [{
                "en_US": {
                    "src": process.env.REACT_APP_ASSETS_URL_OVERRIDE + videoItem.image
                }
            }],
            "controls": {
                "skip": {
                    "enabled": true,
                    "forward": 5,
                    "backward": 5
                },
                "captions": false,
                "playback-speed": [
                    ".5",
                    "1",
                    "1.5,",
                    ". 2"
                ]
            }
        }
    }

    return (
        <>
            <span onClick={() => {
                menuAction();
                handleShow();
            }} tabIndex={tabIndex}
                  className={cls}
                  role="link" >
                {visibleIconContent}
            </span>

            <Modal show={show} onHide={handleClose} size="lg">
                <button className={"closeButton md-icons close"} onClick={handleClose} />
               <Video {...src} />
            </Modal>
        </>
    );
}
export default VideoQuickIcon