import React from 'react';
import ReactDOM from "react-dom";
import _ from "lodash";
import {DynamicPopover} from "./DynamicPopover"

export const exportPopoverGlobals = () => {
    window['createDynamicPopover'] = createDynamicPopover;
    window['initializeAHPopover'] = initializeAHPopover;
    window['getPopoverContent'] = getPopoverContent;
}

export class PopoverManager {
    popovers = {};

    open = (props, target = null) => {
        let {container = null} = props;

        if (!container) {
            container = target.parentElement || document.body;
        }

        const id = this.getNextPopoverId();
        const wrapperNode = this.createPopoverWrapperNode(id, container);


        const popoverId = window.jQuery(target).attr('data-popoverid');
        if (popoverId) {
            window.jQuery('#' + popoverId).empty();
        }
        window.jQuery(target).attr('data-popoverid', wrapperNode.id);

        this.popovers[id] = {
            container,
            wrapperNode,
            target
        };

        ReactDOM.render(
            <DynamicPopover
                id={id}
                wrapperNode={wrapperNode}
                key={id}
                {...props}
                target={{current: target}}
            />
            , wrapperNode);
    }

    createPopoverWrapperNode(id, container) {
        const wrapperNode = document.createElement('div');
        wrapperNode.id = 'container_' + id;
        if (container instanceof window.jQuery) {
            container.append(wrapperNode);
        } else {
            container.appendChild(wrapperNode);
        }
        return wrapperNode;
    }

    unmount = (id) => {
        const node = this.getPopoverById(id);
        delete this.popovers[id];
        ReactDOM.unmountComponentAtNode(node);
        if(document.body.contains(node)) {
            document.body.removeChild(node);
        }
    }

    unmountAll = () => {
        Object.keys(this.popovers).forEach((popover, id) => {
            this.unmount(id);
        });

        return true;
    }

    getPopoverById = (id) => {
        return _.get(this.popovers, id, null);
    }

    getNextPopoverId = () => {
        return 'popover_' + this.getPopoversCount() + 1;
    }

    getPopoversCount = () => {
        return _.keys(this.popovers).length;
    }
}

export const initializeAHPopover = (selector, options = {}) => {
    createDynamicPopover(selector, options);
}

export const createDynamicPopover = (selector, options = {}) => {
    window.jQuery(selector).each(function () {
        let element = window.jQuery(this);
        let {
            type,
            html,
            timeToDisplay,
            content,
            container,
            placement,
            trigger,
            show,
            dismissOnClick = false
        } = options;

        // process data attributes to look for options
        let dataOptions = processPopoverPluginAttributes(element)

        // instantiate variables to pass into components
        type = type || element.attr('data-popover-type') || '';
        let title = element.attr('title') || dataOptions['title'];
        content = content || dataOptions['content'] || '';
        container = container || dataOptions['container'] || '#pagecontent-inner';
        placement = placement || dataOptions['placement'];
        trigger = trigger || dataOptions['trigger'];

        let elementId = element.attr('id') ? `#${element.attr('id')}` : undefined;

        let target = element[0];

        if (typeof (container) === 'string') {
            container = document.querySelector(container);
        }

        window.popoverManager.open({
            ...options,
            title,
            content,
            target,
            container,
            dismissOnClick,
            elementId,
            placement,
            timeToDisplay,
            trigger,
            show,
            html,
            popoverClass: `popover-${type} ${elementId}`
        }, target);

    });
}

/**
 * This function is intended to encapsulate processing all old popover attributes.
 * If the attribute exists on the element, it returns its value.  If not, it returns it as undefined.
 * All popover options are listed in the Popover Options here: https://www.w3schools.com/bootstrap/bootstrap_ref_js_popover.asp
 * @params {jQuery} popoverElement
 * @returns {{}} Object of key-value pairs of from the data-popover attributes
 */
function processPopoverPluginAttributes(popoverElement) {
    let options = [
        "container", "title", "content", "delay", "placement", "trigger"
    ];
    let popoverAttributes = popoverElement.data();

    let matchingKeys = _.intersection(options, Object.keys(popoverAttributes));

    let foundAttributes = {};

    for (let key of matchingKeys) {
        foundAttributes[key] = popoverAttributes[key];
    }

    return foundAttributes;
}

function getPopoverContent(currentTarget){
    return window.jQuery(currentTarget).find('.popover_content,.popover-content').html();
}
