import {exportLoginPageGlobals} from "./pages/login";
import {exportSettingsPageGlobals} from "./pages/settings";
import {exportHaPageGlobals} from "./pages/ha";
import {exportWhyWallPageGlobals} from "./pages/whyWall";
import {exportTileInitFunctions} from "./pages/tiles";
import {exportTestPageGlobals} from "./pages/tests";
import {exportChallengePageGlobals} from "./pages/challenges";
import {exportTrackingPageGlobals} from "./pages/tracking";
import {exportPTWPageGlobals} from "./pages/ptw";
import {exportMessageCenterPageGlobals} from "./pages/messageCenter";
import {exportEventPageGlobals} from "./pages/events";
import {exportMessageBoardGlobals} from "./pages/messageBoard";
import {exportWcGlobals} from "./pages/wellnessChampions";

export const exportPageGlobals = () => {
    exportLoginPageGlobals();
    exportSettingsPageGlobals();
    exportHaPageGlobals();
    exportWhyWallPageGlobals();
    exportTileInitFunctions();
    exportTestPageGlobals();
    exportChallengePageGlobals();
    exportTrackingPageGlobals();
    exportPTWPageGlobals();
    exportMessageCenterPageGlobals();
    exportEventPageGlobals();
    exportMessageBoardGlobals();
    exportWcGlobals();
}
