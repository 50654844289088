import React, {useState} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import { processAjax} from "../legacy/ajaxActions";
import { HandleTranslation } from "../redux/translations/translator";
import './MobileAppQuickIcon.scss';

const translationDomain = 'main_navigation';

const MobileAppQuickIcon = props => {
    const {visibleIconContent, menuAction, tabIndex, cls} = props;
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const mobileModalTitle = HandleTranslation('mobile_modal_title', 'Mobile App Authorization & PIN Reset Instructions', translationDomain);
	const firstTimeActivationTitle = HandleTranslation('mobile_activation_modal_header', 'First-Time Authorization', translationDomain);
	const firstTimeActivationContent = HandleTranslation('mobile_activation_modal_content', 'Choose this option if you\'re activating the app for the very first time.', translationDomain);
	const firstTimeActivationButtonContent = HandleTranslation('mobile_activation_modal_button_content', 'Authorize App', translationDomain);
	const resetPinTitle = HandleTranslation('mobile_pin_reset_modal_header', 'Resetting your PIN', translationDomain);
	const resetPinContent = HandleTranslation('mobile_pin_reset_modal_content', 'Choose this option if you\'ve forgotten your PIN and need to reset it.', translationDomain);
	const resetPinButtonContent = HandleTranslation('mobile_pin_reset_modal_button_content', 'Reset PIN', translationDomain);
	const closeModalText = HandleTranslation('close_modal', 'Close', translationDomain);

	const authorization = () => {
		processAjax('AHJAppTokens', '', 'generateAppAuthorizationToken');
	}
	const pinReset = () => {
		processAjax('AHJAppTokens', '', 'generatePinResetToken');
	}

	return (
		<>
			<span onClick={() => {
				menuAction();
				handleShow();
			}} tabIndex={tabIndex}
				className={cls}
				  role="link"
			>
            {visibleIconContent}
         </span>

			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{mobileModalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div id="mobile-modal">
						<Row>
							<Col className={"mobile-modal-right-border"}>
								<div className={"mobile-modal-option-header"}>
									<div className={"mobile-modal-header-text"}>
										{firstTimeActivationTitle}
									</div>
									<br />
									<div id="genContent" className={"mobile-modal-body-text"}>
										{firstTimeActivationContent}
										<br />
										<br />
										<Button variant="info" onClick={authorization}>
											{firstTimeActivationButtonContent}
										</Button>
									</div>
									<div id="genToken"/>
								</div>
							</Col>
							<Col>
								<div className={"mobile-modal-option-header"}>
									<div className={"mobile-modal-header-text"}>
										{resetPinTitle}
									</div>
									<br />
									<div id="resetContent"  className={"mobile-modal-body-text"}>
										{resetPinContent}
										<br />
										<br />
										<Button variant="info" onClick={pinReset}>
											{resetPinButtonContent}
										</Button>
									</div>
									<div id ="resetToken"/>
								</div>
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{closeModalText}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
export default MobileAppQuickIcon