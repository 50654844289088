import _ from 'lodash';
import moment from 'moment';
import app from "../setupAxios";
import qs from "qs";
import { matchPath } from "react-router";
import {genericPageRefresh, JSONToXML, mapObject, resetReduxKey, resolveContext} from "./utils";
import { empty } from "../utilities/helpers";
import { maskElement, unMaskElement } from "../components/utils/maskElement";
import {genericCallbacks} from "./utils";
import {setChallengeNav} from "./pages/challenges";
import {getCurrentRoutes} from '../redux/app/routes';
import {getSiteWizard, refreshSessionTimer, usePHPSession} from "../redux/app/session";
import {getState} from "../redux/helpers";
import {Log} from "../log/ConsoleLogger";

export const processAjax = async (page, form, action, options={}) => {
    const { maskElementId=null, postData={} } = options;
    const sessionId = document.getElementById('PHPSESSID').value;

    let requestBody = { ax_type: 'AJAX' };
    if(sessionId) {
        requestBody.PHPSESSID = sessionId;
    }

    _.map(postData, (value, key) => {
        requestBody[key] = value;
    });


    if(!form && !action){
        let split = page.split(',');
        if(split[0]){
            page = split[0];
        }
        if(split[1]){
            form = split[1];
        }
        if(split[2]){
            action = split[2];
        }
    }else{
        let split = page.split(',');
        page = split[0];
    }
    requestBody.ax_action = page;

    let processForm = document.getElementById('process');
    let axActionFormElem = processForm.querySelector('#ax_action');
    if(axActionFormElem){
        axActionFormElem.value = page;
    }

    if(form) {
        let formXml = '<form_data>';
        if(typeof(form) === 'string' || form instanceof Array) {
            if (!(form instanceof Array)) {
                form = [form];
            }

            form.forEach((formValue, formKey) => {
                const formElement = document.getElementById(formValue);
                if (!formElement) return;
                const nodes = formElement.querySelectorAll('input,select,textarea');
                formXml += `<form><name>${formValue}</name><elements>`;
                _.forEach(nodes, (node, key) => {
                    let tag = node.getAttribute('name') ? node.getAttribute('name') : node.getAttribute('id');
                    if (typeof tag !== 'string') return;
                    let value = node.value;
                    if ((node.getAttribute('type') === 'checkbox' || node.getAttribute('type') === 'radio') && !node.checked) {
                        return undefined;
                    }
                    tag = tag.replace('/', '-');

                    if(node.classList.contains('date')) {
                        let date = moment(value);
                        if(date.isValid()) {
                            value = date.format('YYYY-MM-DD')
                        }
                    }

                    if (!empty(value) && value !== 'Please Select...' && tag.length > 1) {
                        formXml += '<' + tag + '><![CDATA[' + value + ']]></' + tag + '>';
                    }
                })

                formXml += '</elements></form>';
            });
        } else if (typeof(form) === 'object') {
            const { formId='jsonForm' } = form;
            formXml += `<form><name>${formId}</name><elements>`;
            delete form.formId;
            formXml += JSONToXML(form);
            formXml += '</elements></form>';
        }
        formXml += '</form_data>';
        requestBody.ax_xmlform = formXml;
    }

    if(action){
        processForm.querySelector('#ax_xmlaction').value = action;
        requestBody.ax_xmlaction = action;
    }

    maskElement({id: maskElementId});
    const response = await app.post(
        window.location.href,
        qs.stringify(requestBody),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
    );

    const actions = response.data;
    processActions(actions, options);
    refreshSessionTimer();
}

export const processJSON = async (page, json, action, options) => {
    if (typeof(options) != 'object') {
        options = {};
    }

    let formId = 'jsonForm';
    if (typeof(json.formId) === 'undefined') {
        json.formId = formId;
    }

    await processAjax(page, json, action, options);
}
/*
    Look up matching route in redux and return legacy property if found

    pathRoot is routes key in redux e.g. 'custom_reporting'
 */
export const isLegacyRoute = (pathRoot) => {
    if(pathRoot === "") pathRoot = 'home';
    const routes = getCurrentRoutes();
    const route = routes[pathRoot];
    if(route) return route;

    let path = '/' + pathRoot;

    // Exact route match
    const pathRoutes = Object.values(routes).filter(r => {
        return (r.path instanceof Array) ? r.path.includes(path) : r.path === path;
    });
    if(pathRoutes.length > 0) return pathRoutes[0];

    // Works with regex routes
    const matchedPaths = Object.values(routes).filter(r => {
        return ((r.path instanceof Array) ?
                !r.path.includes('/') :
                r.path !== '/' ) &&
                getMatchedPath(path,r.path) !== null;
    });
    if(matchedPaths.length > 0) return matchedPaths[0];

    return false; // not found
}
/*
    Check if there is a matching siteWizard path in redux session
 */
export const getSiteWizardConfig = (path) => {
    if(!path) return null;
    const {siteWizardRoutes}  = getState('session');
    if(!Array.isArray(siteWizardRoutes)) return null;
    const siteWizardConfig = siteWizardRoutes.find(config=>matchPath(path,config));
    return siteWizardConfig ? {...siteWizardConfig,...{path}} : null;
}
/*
    Get matched path from react-router
 */
export const getMatchedPath = (path,route) => {
    if(!path || !route) return null;
    return matchPath(path, route);
}
/*
    Fetch site wizard and process actions
 */
export const execSiteWizard = async (swconfig={}) =>
{
    const data = await getSiteWizard(swconfig);
    if(data && data.response){
        let actions = JSON.parse(data.response).filter((action, index) => {
            return !(action.type && action.type === 'portals');
        });
        processActions(actions);
    }
}

export const getCurrentLocationPath = () => {
    let path = null;
    if(typeof(window.reactGlobals) !== 'undefined') {
        if (typeof (window.reactGlobals.location) !== 'undefined') {
            path = window.reactGlobals.location.pathname;
        }
    }

    return path;
}
/*
    parse a path such as:
        (react)
        /courses/course/24

        (legacy - using 2nd path segment to set action)
        /challenges/someAction

        (legacy - using url param ax_xmlaction to set action, then full path (/custom/spirit) is used to match against route path)
        /custom/spirit?ax_xmlaction=spirit,processLayoutRequest

    Note: how the path is parsed depends on the route's "legacy" property (or lack thereof) in redux, also the route's path property, which may contain regular expressions

    returns something like:
    {
        routeKey:'custom',
        legacy:{...},
        action:'spirit,processLayoutRequest',
        matchedPath:{...},
        url:'/custom/spirit'
    }
 */
export const parsePath = (path='') => {
    const result = {};
    const routes = getCurrentRoutes();
    if(typeof path !== "string") return result;

    const pathAndActions = path.split("?");
    if(pathAndActions[0]==='/')pathAndActions[0] = '/home';
    let pathSegments = pathAndActions[0].split('/');

    // routes key in redux
    result.routeKey = pathSegments[1];
    let legacyRoute = isLegacyRoute(result.routeKey);
    result.legacy = legacyRoute && legacyRoute.legacy;
    if(!routes[result.routeKey] && legacyRoute)result.routeKey = legacyRoute.id;
    if(result.legacy) {
        result.action = pathSegments.length > 2 ? pathSegments[2] : {};

        let urlparams = new URLSearchParams(pathAndActions[1]);
        const ax_xmlaction = urlparams.get('ax_xmlaction');
        if(ax_xmlaction !== null) result.action = ax_xmlaction;
    }

    // determine matchedPath from route stored in redux
    const routePath = routes[result.routeKey] ? routes[result.routeKey].path : null;
    result.matchedPath = getMatchedPath(pathAndActions[0],routePath);  // greedily match path
    result.url = result.matchedPath ? result.matchedPath.url : null;   // convenience property

    return result;
}

export class NavigationController {

    followPath = (path, submissionData, options) => {

        let parsed = parsePath(path);
        if(!parsed.url) {
            parsed.url = path; // no matching route found, so we'll just push the path into history and should get 404
        }

        if(!parsed.legacy)
        {
            this.followRoute(path,submissionData,options);
            return;
        }
        refreshSessionTimer();

        if(typeof(submissionData) === 'undefined' || submissionData === null){
            submissionData = {};
        }
        if(typeof(options) === 'undefined' || options === null){
            options = {};
        }

        if(typeof(window.reactGlobals) !== 'undefined') {
            if(typeof(window.reactGlobals.history) !== 'undefined') {
                let location = window.reactGlobals.location;
                if(location.pathname === parsed.url) { //Need to refresh the current page, call genericPageRefresh
                    genericPageRefresh();
                } else {
                    let swconfig = !options.bypassSiteWizard && getSiteWizardConfig(path);
                    if(swconfig){
                        execSiteWizard(swconfig);
                    }
                    else {
                        let history = window.reactGlobals.history;
                        if(typeof options.postCallBack === 'function'){
                            window.postCallBack = options.postCallBack;
                            options.postCallBack = null; // state must be serializable
                        }
                        if (options.replaceHistory === true) {
                            history.replace({
                                pathname: parsed.url,
                                state: {submissionData, options, action: parsed.action}
                            });
                        } else {
                            history.push({
                                pathname: parsed.url,
                                state: {submissionData, options, action: parsed.action}
                            });
                        }
                    }
                }
            }
        }
    }
    /*
        Follow a react route - no actions to perform
     */
    followRoute = (path, submissionData, options) => {

        if(typeof(submissionData) === 'undefined' || submissionData === null){
            submissionData = {};
        }
        if(typeof(options) === 'undefined' || options === null){
            options = {};
        }
        refreshSessionTimer();

        if(typeof(window.reactGlobals) !== 'undefined') {
            if(typeof(window.reactGlobals.history) !== 'undefined') {
                let location = window.reactGlobals.location;
                if(location.pathname === path) { //Need to refresh the current page, call genericPageRefresh
                    genericPageRefresh();
                } else {
                    let swconfig = !options.bypassSiteWizard && getSiteWizardConfig(path);
                    if(swconfig) {
                        execSiteWizard(swconfig);
                    }
                    else{
                        let history = window.reactGlobals.history;
                        if(typeof options.postCallBack === 'function'){
                            window.postCallBack = options.postCallBack;
                            options.postCallBack = null; // state must be serializable PRM-13097
                        }
                        if(options.replaceHistory === true){
                            history.replace({
                                pathname: path,
                                state: {submissionData, options}
                            });
                        }
                        else history.push({
                            pathname: path,
                            state: {submissionData, options}
                        });
                    }
                }

                //TODO handle options
            }
        }
    }
}

export const processActions = (actions, options={}) => {
    const {maskElementId = null, callBack, postCallBack} = options;
    let legacyRoot = document.getElementById('pagecontent-inner');
    let element;

    if (typeof (callBack) === 'function') {
        callBack();
    }

    unMaskElement({id: maskElementId});

    // console.log(actions);
    if (typeof(actions) === "object") {

        _.map(actions, (action) => {
            const {id, type, ...rest} = action;
            element = null;
            switch (type) {
                case 'addClass':
                    element = getLegacyPageElement(legacyRoot, id);
                    if (element) {
                        if(typeof(rest.data) === 'object'){
                            window.jQuery('#' + id).addClass(_.head(Object.values(rest.data)));
                        }
                    }
                    break;
                case 'removeClass':
                    element = getLegacyPageElement(legacyRoot, id);
                    if (element) {
                        if(typeof(rest.data) === 'object'){
                            window.jQuery('#' + id).removeClass(_.head(Object.values(rest.data)));
                        }
                    }
                    break;
                case 'factory':
                    factory(action);
                    break;
                case 'generic_callbacks':
                    genericCallbacks(action.data);
                    break;
                case 'initWizard':
                    initWizard(action);
                    break;
                case 'setMenuTab':
                    console.log("Legacy Action: " + type);
                    break;
                case 'showAlert':
                    window.core_messager.showAlert(mapObject(action.data));
                    break;
                case 'initModal':
                    let options = mapObject(action.data);
                    window.core_messager.initModal(options);
                    break;
                case 'openModal':
                    const {props, component} = action.data;
                    window.modalManager.open(props, null, component);
                    break;
                case 'destroyModals':
                    window.modalManager.closeAll();
                    break;
                case 'updateWizardField':
                    let wizard = window[id];
                    _.map(action.data, (value, prop) => {
                        let fieldID = prop;
                        let fieldHTML = value;
                        try {
                            wizard.refreshField(fieldID, fieldHTML);
                        } catch (err) {
                            console.error(err);
                        }
                    });
                    break;
                case 'closeWizard':
                    if (typeof (window[id]) === 'object') {
                        if (typeof (window[id].close) === 'function') {
                            window[id].close();
                        }
                    }
                    break;
                case 'setWizardFieldVisibility':
                    let fieldId = Object.keys(action.data)[0];
                    let value = action.data[fieldId];
                    if (typeof (window[id]) === 'object') {
                        let wizard = window[id];
                        if (typeof (wizard.setFieldVisibility) === 'function') {
                            wizard.setFieldVisibility(fieldId, value);
                        }
                    }
                    break;
                case 'masonry':
                    const grid = window.jQuery('#' + id).masonry(action.data);
                    grid.imagesLoaded().progress(function () {
                        grid.masonry('layout');
                    });
                    break;
                case 'navigate':
                    let navigator = new NavigationController();
                    navigator.followPath(action.data.path, action.data.submissionData, action.data.options);
                    break;
                case 'initializeChallenge':
                    window.initializeChallenge(action.id, action.data);
                    break;
                case 'setChallengeNav':
                    setChallengeNav(action.data[0], action.id);
                    break;
                case 'initializeChart':
                    window.initializeCharts(window.jQuery(action.id));
                    break;
                case 'initDynamicLayout':
                    window.initDynamicLayout();
                    break;
                case 'openHyperlink':
                    const { value: url } = action.data;
                    window.open(url);
                    break;
                case 'initSelectize':
                    window.initSelectize(id,action.data);
                    break;
                case 'setMessage':
                    window.setMessage(id, action.data.innerHTML);
                    break;
                case 'scrollIntoView':
                    window.jQuery('#' + id)[0].scrollIntoView();
                    break;
                case 'resetReduxKey':
                    window.resetReduxKey && window.resetReduxKey(action.data.storePath,action.data.keys);
                    break;
                default:
                    _.map(action.data, (value, prop1) => {
                        let prop2 = null;

                        if (type === 'changeStyle') {
                            prop2 = prop1;
                            prop1 = 'style';
                        }
                        changeProperty(id, prop1, prop2, value);
                    });

                    if (!(type === 'changeProperty' || type === 'changeStyle')) {
                        console.error('DEFAULT CASE: ', action);
                    }

                    break;
            }
        });
    }
    if(typeof(postCallBack) === 'function') {
        postCallBack();
    }
    if(typeof(window.postCallBack) === 'function') {
        window.postCallBack();
        delete window.postCallBack;
    }
};

const getLegacyPageElement = (legacyRoot, id) => {
  return legacyRoot && legacyRoot.id === id ? legacyRoot : legacyRoot.querySelector('#'+id);
};

const changeProperty = (id, prop1, prop2, value) => {
    const element = document.getElementById(id);
    //let legacyRoot = document.getElementById('pagecontent-inner');
    //const element = legacyRoot.id === id ? legacyRoot : legacyRoot.querySelector('#'+id);
    if (element) {
        if (prop2 !== null) {
            if (typeof (element[prop1]) === 'function') {
                element[prop1](prop2, value);
            } else if (prop1 === 'style') {
                element.style[prop2] = value;
            }
        } else {
            if (prop1 === 'innerHTML') {
                //purge($(id));
                window.jQuery('#'+id).html(value);
                //element.innerHTML = value;
            } else if (prop1 === 'value') {
                element.value = value;
            } else if (typeof (element[prop1]) === 'function') {
                element[prop1](value);
            } else if (element[prop1]) {
                element[prop1] = value;
            }
        }
    }
};

const factory = (action) => {
    let [controllerName, instanceName, params={}] = action.data;
    let classObject = {constructor: resolveContext(controllerName)};
    let object = new classObject.constructor();
    if (typeof object.initialize !== 'undefined') {
        try {
            params = JSON.parse(params);
        } catch (exception) {
            params = {};
        }

        object.initialize(params);

        if (typeof instanceName !== 'undefined') {
            window[instanceName] = object;
        }
    }
};

/*
 * Ajax Actions
 */

const initWizard = (action) => {
    let [wizardId, pageId] = action.data;
    window.InitializeWizard(wizardId, pageId);
}
/*
    Generic Action Definitions
 */
function ActionObjectProcessor () {

    this.link = (action={}) => {
        window.open(action.url,action.target??null,action.features??null);
    }
    this.navigation = (action={}) => {
        navigation.followPath(action.path, action.submissionData,action.options);
    }
    this.js = (action={}) => {
        if(action.js){
            // eslint-disable-next-line no-new-func
            new Function(action.js)();
        }
    }
    this.fn = (action={}) => {
        if(typeof action.fn === "function"){
            action.fn();
        }
    }
    this.modal= (action={})=>{

        switch(action.action){
            case 'init':
                let options = mapObject(action.data);
                window.core_messager.initModal(options);
                break;
            case 'closeAll':
                window.modalManager.closeAll();
                break;
            case 'open':
            default:
                window.modalManager.open(action.props, null, action.component);
                break;
        }
    }
    this.none = () => {}
}
/*
    Do a generic action.

    Pass in object, key 'type' is required. Other keys depend upon the type.

    Example usages:

    genericAction({
        type:'js',
        js:'console.log("i logged this sentence with generic action")'
    });
    genericAction({
        type:'fn',
        fn:()=>{alert('I am a function');}
    });
    genericAction({
        type:'navigation',
        path:'/courses'
    });
    genericAction({
        type:'link',
        url:'https://www.youtube.com/watch?v=qLooSc5ewIA',
        target:"_blank"
    });
    genericAction({
        type:'modal',
        action:'open',
        props:{
            "size":"small",
            "title": 'You are about to be logged out',
            'message': 'To stay logged in click below.',
            'class': 'timeoutModal',
            'dismiss':true,
            buttons:[],
            backdrop:'static',
            onExited:() => {
                alert('exit modal');
            }
        }
    });
*/
export const genericAction = (obj={"type":"none"}) => {
    try{
        if(!(obj && obj.type)) return;
        let acp = new ActionObjectProcessor();
        acp[obj.type](obj);
    }
    catch(e){
        Log.error("genericAction",obj,e);
    }
}

