import {useEffect, useState} from "react";
import {store} from "../store";
import produce from "immer";
import app from '../../setupAxios';
import {getState, resetStoreKeys, subscribe} from "../helpers";
import get from 'lodash/get';

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["app","menus"];
const initialState = {
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
   storeMenu: (state, payload) =>
      produce(state, draft => {
         const {version,id,data} = payload;
         if(!draft[version]) draft[version] = {};
         draft[version][id] = data;
      }),
    mergeMenuState: (state, payload) =>
      produce(state, draft => {
         const {path,...rest} = payload;
         const item = get(draft,path);
         if(item) {
             item['state'] = Object.assign({},item['state'],rest);
         }
      }),
    'reset.app.menus': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
   reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/
/*
    example: loadMenu({version:'v1',id:'main'});
 */
export const loadMenu = async (config) => {
    let response = await app.get(`${process.env.REACT_APP_API_PHP_URL}/api/${config.version}/features/menu/${config.id}`);
    if(response && response.status === 200) storeMenu({...config,data:response.data});
    else storeMenu({...config,data:{error:true}});
}
/*
    example: useMenu({version:'v1',id:'main'});
 */
export const useMenu = (config) => {
    const [menuState, setState] = useState(getState(storePath));
    useEffect(() => subscribe(setState, storePath), [setState]);
    const menu = getState(storePath.concat([config.version,config.id]));

    const result = {
        isLoading:!Boolean(menu),
        isError:menu && menu.error,
        data:menu && menu.error ? null : menu,
        state:(menu && menu.menu && menu.menu.state) ?? {},
        version:config.version,
        id:config.id
    }
    return [result];
}
/*
    example params:
    buildMenuPath('v1','main','activity_tracking','goals');
    returns string path (below storePath) to menu item, if it exists, null otherwise
    example returned path:  v1.main.menu.items.3026_4.children.5411_4
 */
export const buildMenuPath = (version,menuId,itemId=null,...childrenIds) => {

    let stor = getState(storePath);
    let path = `${version}.${menuId}.menu`;
    if(!childrenIds) childrenIds = [];

    // return menu path
    if(itemId === null){
        let menu = get(stor,path,null);
        if(menu !== null) return path;
        else path = null;
    }
    else { // return path to menu item
        path += '.items';
        // return menu item path
        let items = get(stor,path,null);
        let itemOrder = null;
        if(items !== null) {
            items = Object.values(items);
            items = items.filter(item=>item.id === itemId);
            if(items.length > 0) {
                itemOrder = items[0].itemOrder;
                path += "." + items[0].menuItemNo + "_" + itemOrder;
            }
            else path = null;

            // nav thru children
            while(path && itemOrder !== null && childrenIds.length > 0){
                path += ".children";
                let children = get(stor,path,null);
                if(children !== null && !Array.isArray(children)){
                    let childId = childrenIds.shift();
                    children = Object.values(children).filter(child=>child.id === childId);
                    if(children.length > 0) path += "." + children[0].menuItemNo + "_" + itemOrder;
                    else path = null;
                }
                else path = null;
            }
        }
        else path = null;
    }
    // console.log("buildMenuPath:",version,menuId,itemId,childrenIds,"path=",path);
    return path;
}
// export const buildMenuPath = (version,menuId,itemId,childrenIds=[]) => {
//
//     return `${version}.${menuId}.menu.items.${itemId}${childrenIds.length > 0 ? '.children.' + childrenIds.join('.children.') : ''}`;
// }
/*
    example params:
    getMenuItem('v1','main','3026_4',['5411_4']);
    returns menu item if found, null otherwise
 */
export const getMenuItem = (version,menuId,itemId,...childrenIds) => {

    let result = getState(storePath);
    let path = buildMenuPath(version,menuId,itemId,...childrenIds);
    result = get(result,path,null);
    return result;
}
/*
    returns item state, or empty object if not found
 */
export const getMenuItemState = (version,menuId,itemId,...childrenIds) => {

    let result = getMenuItem(version,menuId,itemId,...childrenIds);
    return (result && result.state) ?? {};
}
/*
    returns item state, or false if item not found
 */
export const setMenuItemState = (state={},version,menuId,itemId,...childrenIds) => {

    let path = buildMenuPath(version,menuId,itemId,...childrenIds);
    mergeMenuState(path,state);
}
/*
    sets {isOpen:false} on all menus and menuItems to close all
 */
export const closeAllMenus = () => {

    walkMenus((args)=>{
        mergeMenuState(args.path,{isOpen:false});
    });
}
/*
    Traverse thru the redux menus structure and run function upon items at each step

    function will be passed object with below properties:
    {
        version, // the version of the menu         e.g. v1
        item:    // the menu or menu item object
        key:     // the redux key of the menu/item  e.g.  'main' or '3026_4'
        path:    // the path to the menu / item     e.g. v1.main.menu.items.3026_4.children.5412_4
    }
 */
export const walkMenus = (fn=()=>{}) => {

    if(typeof fn !== 'function') return;

    let versions = getState(storePath);
    Object.entries(versions).forEach(([version,menus],index)=>{

        Object.entries(menus).forEach(([menuId,menuObj]) => {

            const menuPath = `${version}.${menuId}.menu`;
            fn({
                version,
                item:menuObj.menu,
                key:menuId,
                path:menuPath
            });

            let queue = Object.entries(menuObj.menu.items);
            while(queue.length > 0){

                    const [itemKey,item] = queue.shift();

                    const itemPath = `${menuPath}.items.${itemKey}`;
                    fn({
                        version,
                        item,
                        key:itemKey,
                        path:itemPath
                    });

                if(!Array.isArray(item.children)){
                    let childQueue = Object.entries(item.children);
                    let childPath = `${itemPath}`;
                    let childQueueIndex = 0;

                    while(childQueueIndex < childQueue.length){
                        let [childKey,child] = childQueue[childQueueIndex];
                        if(!childQueue[childQueueIndex][2]) childQueue[childQueueIndex][2] = `${childPath}.children.${childKey}`; // Add path
                        fn({
                            version,
                            item:child,
                            key:childKey,
                            path:childQueue[childQueueIndex][2]
                        });
                        if(!Array.isArray(child.children)){
                            Object.entries(child.children).forEach(([key,obj])=>{
                                    childQueue.push([key,obj,childQueue[childQueueIndex][2] + `.children.${key}`]);
                            })
                        }
                        childQueueIndex++;
                    }
                }
            }
        });
    });
}
/*
    Merge state into menu or menu item state
 */
export const mergeMenuState = (path,state) => store.dispatch({ type: "mergeMenuState", payload: {path,...state} });
export const storeMenu = config => store.dispatch({ type: "storeMenu", payload: config });