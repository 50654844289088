export const maskElement = (props) => {
    const { id=null } = props;
    if(id) {
        console.log("Mask Element", id);
        window.jQuery('#' + id).mask_overlay();
    }
};

export const unMaskElement = (props) =>  {
    const { id=null } = props;
    if(id) {
        console.log("UnMask Element", id);
        window.jQuery('#' + id).unmask_overlay();
    }
};
