import { processJSON } from "../ajaxActions";
import {attachmentUploader} from "../lib/attachmentUploader";

export const exportMessageBoardGlobals = () => {
    window['message_board_controller'] = new MessageBoardController();
}

class MessageBoardController {
    constructor() {
        this.id = 'messageBoard'; // id
        this.attachment_uploader = new attachmentUploader();
    }


    displayMessageBoard = async (section, tag) => {
        let obj = {};
        obj.section = section;
        obj.tag = tag;
        await processJSON('AHJMessageBoard', obj, 'displayMessageBoard');
    }

    followPathMessageBoard = async (section, tag) => {
        let obj = {};
        obj.section = section;
        obj.tag = tag;
        window.navigation.followPath('/AHJMessageBoard/displayMessageBoard', obj);
    }

    embedMessageBoard = async (section, tag, container) => {
        let obj = {};
        obj.section = section;
        obj.tag = tag;
        obj.container = container;
        await processJSON('AHJMessageBoard', obj, 'embedMessageBoard');
    }

    displayTopicMessages = async (topicId) => {
        let obj = {};
        obj.curr_topic_id = topicId;
        await processJSON('AHJMessageBoard', obj, 'displayTopicMessages');
        window.scrollTo(0,0);
    }

    refreshTopics = async () => {
        await processJSON('AHJMessageBoard', {}, 'refreshTopics');
    }

    submitPost = (topicId, type, reference, collapseId, id) => {
        let $collapseRef = '';
        let action = 'postTopic';
        let title = '';

        if(collapseId !== undefined){
            $collapseRef = window.jQuery('#'+collapseId).attr('class');
        }

        let $table, $tableId, prev_subj, prev_msg;

        switch(type) {
            case 'new':
                title = 'New Post';
                window.jQuery('#msg_subject').attr('value','');
                window.jQuery('#msg_body').text('');
                break;
            case 'reply':
                title = 'Reply To Topic';
                $table = window.jQuery(reference).closest('.table');
                $tableId = $table.attr('id');
                prev_subj = $table.find('#row1_'+$tableId).find('#subject').find('div').text();
                window.jQuery('#msg_subject').attr('value',prev_subj);
                window.jQuery('#msg_body').text('');
                break;
            case 'edit':
                title = 'Edit Post';
                $table = window.jQuery(reference).closest('.table');
                $tableId = $table.attr('id');
                prev_subj = $table.find('#row1_'+$tableId).find('#subject').find('div').text();
                prev_msg = $table.find('#row2_'+$tableId).find('#content').find('div').html();
                prev_msg = prev_msg.replace(/<br>/g, "\n");
                window.jQuery('#msg_subject').attr('value',prev_subj);
                window.jQuery('#msg_body').html(prev_msg);
                break;
            default:
                break;
        }

        window.core_messager.initModal({'sourceElement':'#newpost_modal_container', 'title':title, 'buttons':[], 'size':'large'});

        const modalBody = window.jQuery('.modal #newpost_modal');
        const uploader = modalBody.find('.doc-upload');
        const socialId = typeof(reference) !== 'undefined' ? window.jQuery('#'+id).attr('id') : '';

        if(uploader.length) {
            const msgSubject = modalBody.find('#msg_subject');
            const uploaderMsgSubject = uploader.find('input[name="message_upload_subject"]');
            if(uploaderMsgSubject.length) {
                uploaderMsgSubject.val(msgSubject.val());
                msgSubject.on('keyup change input', function(e) {
                    uploaderMsgSubject.val(e.target.value);
                });
            }

            const uploaderMsgBody = uploader.find('input[name="message_upload_body"]');
            const msgBody = modalBody.find('#msg_body');
            if(uploaderMsgBody.length) {
                uploaderMsgBody.val(msgBody.val());
                msgBody.on('keyup change input', function(e) {
                    uploaderMsgBody.val(e.target.value);
                });
            }
        }

        const uploaderForm = uploader.find('form#uploader-form');
        if(uploaderForm.length) {
            uploaderForm.find('input[name="message_object"]').val(JSON.stringify({
                curr_topic_id: topicId,
                curr_social_id: socialId,
                curr_post_type: type,
                curr_collapse_id: '',
                action
            }));
        }


        window.jQuery('#post_btn').off('click').click(async () => {
            let $subject = window.jQuery('#msg_subject').val();
            let $message = window.jQuery('#msg_body').val();

            if(typeof $subject === 'undefined' || typeof $message === 'undefined' || !$subject || !$message){
                window.core_messager.showAlert('ERROR: Some required fields were not filled out.  Please review the form and try again.');
                return;
            }

            if((window.jQuery.trim($subject)).length === 0 || (window.jQuery.trim($message)).length === 0){
                window.core_messager.showAlert('ERROR: Some required fields were not filled out.  Please review the form and try again.');
                return;
            }

            // Check if the current post is collapsed or expanded
            // if($collapseRef.search("collapsed") > 0){
            //     collapseId = '';
            // }

            let obj = {};
            obj.curr_topic_id = topicId;
            obj.curr_post_subj = $subject;
            obj.curr_post_msg = $message;
            obj.curr_social_id = socialId;
            obj.curr_post_type = type;
            obj.curr_collapse_id = '';
            obj.action = action;

            window.jQuery('.modal').modal('hide');
            await processJSON('AHJMessageBoard', obj, action, {
                postCallBack: window.modalManager.close
            });
        });

        window.jQuery('#cancel_btn').off('click').click(() => {
           window.modalManager.close();
        });
    };

    deletePost = async (reference, collapseId, id) => {
        let $socialId = window.jQuery('#'+id).attr('id');
        let $collapseRef = window.jQuery('#'+collapseId).attr('class');
        let x = id;

        // Check if the current post is collapsed or expanded
        if($collapseRef.search("collapsed") < 0){
            collapseId = '';
        }

        let obj = {};
        obj.curr_social_id = $socialId;
        obj.curr_collapse_id = collapseId;
        await processJSON('AHJMessageBoard', obj, 'deleteTopic');
    };

    quickPost = async (curr) => {
        let $subject = window.jQuery('#quick_post_subj').val();
        let $message = window.jQuery('#quick_post_msg').val();

        if(typeof $subject === 'undefined' || typeof $message === 'undefined' || !$subject || !$message){
            window.core_messager.showAlert('ERROR: Some required fields were not filled out.  Please review the form and try again.');
            return;
        }

        if((window.jQuery.trim($subject)).length === 0 || (window.jQuery.trim($message)).length === 0){
            window.core_messager.showAlert('ERROR: Some required fields were not filled out.  Please review the form and try again.');
            return;
        }

        let obj = {}
        obj.curr_topic_id = window.jQuery('#quick-post-curr-topic').attr('data-value');
        obj.curr_post_subj = $subject;
        obj.curr_post_msg = $message;
        obj.curr_post_type = 'new';

        await processJSON('AHJMessageBoard', obj, 'quickPostTopic');
    };

    initializeAttachmentUploader = (params={}) => {
        params.successCallBack = 'message_board_controller.handleUploadSuccess';
        this.attachment_uploader.initAttachmentUpload(params);
    }

    handleUploadSuccess = async (e, data) => {
        await processJSON('AHJMessageBoard', {}, 'refreshTopicMessages');
    }
}
