/**
 * @author JBonnell
 * @constructor
 */
export class CarouselController {

    constructor(id) {
        this.id = id;
        this.carousel = null;
        this.controlSelector = '.carousel-control-wrapper';
    }

    init = () => {
        let carouselObj = this;
        this.carousel = window.jQuery('#'+this.id);
        this.carousel.off('slid.bs.carousel').on('slid.bs.carousel', function(){
            carouselObj.setControls();
        });

        this.setControls();
    }

    prevSlide = () => {
        window.jQuery(this.carousel).carousel('prev');
    }

    nextSlide = () => {
        window.jQuery(this.carousel).carousel('next');
    }

    dispose = () => {
        window.core_messager.closeModal();
    }

    enableControl = (side) => {
        this.carousel.find(window.jQuery(this.controlSelector + ' .' + side)).removeClass('hidden');
    }

    disableControl = (side) => {
        this.carousel.find(window.jQuery(this.controlSelector + ' .' + side)).addClass('hidden');
    }

    initControls = () => {
        this.disableControl('left');
        if(this.countSlides() > 1){
            this.enableControl('right');
        }else{
            this.disableControl('right');
        }
    }

    setControls = () => {
        if(this.countSlides() > 1){
            this.enableControl('right');
            this.enableControl('left');
            this.showIndicators();

            if(this.onFirstSlide()){
                this.disableControl('left');
            }else if(this.onLastSlide()){
                this.disableControl('right');
            }
        }else{
            this.disableControl('right');
            this.disableControl('left');
            this.hideIndicators();
        }
    }

    hideIndicators = () => {
        this.carousel.find('.carousel-indicators').addClass('hidden');
    }

    showIndicators = () => {
        this.carousel.find('.carousel-indicators').removeClass('hidden');
    }

    countSlides = () => {
        return this.carousel.find('.carousel-inner .carousel-item').length;
    }

    currentSlideIndex = () => {
        return this.carousel.find('.carousel-inner .carousel-item.active').index() + 1;
    }

    onLastSlide = () => {
        return this.countSlides() == this.currentSlideIndex();
    }

    onFirstSlide = () => {
        return this.currentSlideIndex() == 1;
    }
}
