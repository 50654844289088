class DateToolsController {
    currentDate = new Date();
    setCurrentDate = function(date){
        if(typeof(date) !== 'undefined') {
            this.currentDate = new Date(date);
        }
    };

    /**
     * @author KCupp, JBonnell
     * @returns {Date}
     */
    getToday = () => {
        return this.currentDate;
    };

    /**
     * @author KCupp, JBonnell
     * @returns {number}
     */
    getCurrentTime = () => {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        return new Date(this.getToday().getTime() + now.getTime() - today.getTime());
    };
}

export const dateTools = new DateToolsController();
