import React from 'react';
import {useHistory, useLocation } from "react-router";

const ExportGlobals = () => {
    if (typeof (window.reactGlobals) === 'undefined') {
        window.reactGlobals = {};
    }
    window.reactGlobals.history = useHistory();
    window.reactGlobals.location = useLocation();
    return <></>;
}
export default ExportGlobals;