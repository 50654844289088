
export const exportChallengePageGlobals = () => {
    window['mediaPlay'] = mediaPlay;
    window['mediaStop'] = mediaStop;
    window['mediaUpdate'] = mediaUpdate;
    window['setChallengeNav'] = setChallengeNav;
}

export const mediaPlay = (reference) => {
    window.jQuery(reference).closest('.media-player').find('video, audio').each(function () {
        this.play();
    });
}

export const mediaStop = (reference) => {
    window.jQuery(reference).closest('.media-player').find('video, audio').each(function () {
        this.pause();
    });
}

export const mediaUpdate = (reference) => {
    let progress = Math.floor((100 / reference.duration) * reference.currentTime) + '%';
    let progressBar = window.jQuery(reference).closest('.media-player').find('.progress .progress-bar');
    progressBar.css('width', progress).attr('aria-valuenow', progress);
}

export const setChallengeNav = (challengeId, pillId) => {
    window.jQuery('.' + challengeId + '-nav-item a.nav-link').removeClass('active');
    let activeElement = window.jQuery('#' + pillId + ' a.nav-link');
    activeElement.addClass('active')
    activeElement.focus();
}
