import { processAjax } from "../ajaxActions";

export const exportEventPageGlobals = () => {
    window['confirmRegisterForScreening'] = confirmRegisterForScreening;
    window['registerForScreeningNoParams'] = registerForScreeningNoParams;
    window['unregisterForScreening'] = unregisterForScreening;
    window['updateSMSNotification'] = updateSMSNotification;
    window['toggleEventViewMode'] = toggleEventViewMode;
    window['initTextTruncator'] = initTextTruncator;
}

const confirmRegisterForScreening = (package_id, event_type, screening_id, time_slot, datetime) => {
    let form_id = 'events_submit';
    let screening_id_form_name = 'event_id';
    let package_id_form_name = 'package_id';
    let time_slot_form_name = 'time_slot';
    let screening_type_form_name = 'screening_type';
    let event_type_form_name = 'event_type';
    let form_elem = window.jQuery('#' + form_id);
    let screening_input_elem = form_elem.children('.' + screening_id_form_name);
    screening_input_elem.val(screening_id);
    let package_input_elem = form_elem.children('.' + package_id_form_name);
    package_input_elem.val(package_id);
    let time_slot_elem = form_elem.children('.' + time_slot_form_name);
    time_slot_elem.val(time_slot);
    form_elem.children('.' + event_type_form_name).val(event_type);

    // default sms notification to true
    //form_elem.children('#sms_notification').trigger('click');
    if (window.jQuery('#sms_notification').is(':checked') == false) {
        window.jQuery('#sms_notification').trigger('click');
    }

    form_elem.children('.screening_type').val('');
    let selected_screening_type = window.jQuery('#screening_type_select_' + package_id + '_' + screening_id).val();
    if (selected_screening_type) {
        let screening_type_elem = form_elem.children('.' + screening_type_form_name);
        screening_type_elem.val(selected_screening_type);
    }

    if (time_slot !== "0") {
        window.jQuery('#event_' + package_id + '_' + screening_id).show();
        let btn_text = "Click here to confirm your selected appointment time for " + datetime;
        let btn_elem = '<button type="button" class="btn btn-info" onClick="registerForScreeningNoParams();">' + btn_text + '</button>';
        window.jQuery('#confirm_button').empty().append(btn_elem);
        window.jQuery('.modal-body').animate({
            scrollTop: window.jQuery("#confirm_button").offset().top
        }, 1000);
    } else if (time_slot === "0") {
        registerForScreeningNoParams();
    }

}

const registerForScreeningNoParams = () => {
    let class_name = 'AHJEvents';
    let form_id = 'events_submit';
    let action = window.jQuery('#events_submit .package_id').val() == 'user_events'?'registerForUserEvent':'registerForScreening';

    processAjax(class_name, form_id, action, {maskElementId: 'iCal_eventinner_' + window.jQuery('#events_submit :input[name="event_id"]').val()});
}

const unregisterForScreening = async (package_id, screening_id) => {
    let process_cancellation = true;
    if (typeof screening_id === 'undefined') {
        process_cancellation = false;
    }
    if (typeof package_id === 'undefined') {
        process_cancellation = false;
    }
    if (process_cancellation) {
        window.modalManager.close();
        let class_name = 'AHJEvents';
        let action = package_id === 'user_events' ? 'cancelUserEventRegistration' : 'cancelScreeningRegistration';
        let form_id = 'events_submit';
        let screening_id_form_name = 'event_id';
        let package_id_form_name = 'package_id';
        let form_elem = window.jQuery('#' + form_id);
        let screening_input_elem = form_elem.children('.' + screening_id_form_name);
        screening_input_elem.val(screening_id);
        let package_input_elem = form_elem.children('.' + package_id_form_name);
        package_input_elem.val(package_id);
        await processAjax(class_name, form_id, action);
    }
}

const updateSMSNotification = async (package_id, event_id, timeslot_no) => {
    window.jQuery('#events_submit.package_id').val(package_id);
    window.jQuery('#events_submit .event_id').val(event_id);
    window.jQuery('#events_submit.time_slot').val(timeslot_no);
    await processAjax('AHJEvents','events_submit','updateSMSNotification', {maskElementId: 'iCal_eventinner_' + event_id});
}

const toggleEventViewMode = async (mode) => {
    let initial_load = false;

    if (mode != window.jQuery('#event_view_mode').val()) {
        window.jQuery('#event_view_mode').val(mode);
        if (mode === 'calendar') {
            // Calendar mode
            window.jQuery('#events_list_view').hide();
            window.jQuery('#events_calendar_view').show();
            if (window.jQuery('#events_calendar_view .fc-view-container').height() == 0) {
                // First time render
                window.jQuery('#events_calendar_view').fullCalendar('render');
                //console.log('render');
                initial_load = true;
            }
            if (window.jQuery('#event_filter_month').val() != '') {
                // If in list view and filtered down to a month, have to remove filter and re-query for calendar display
                window.jQuery('#events_all_months').removeClass('btn-outline-secondary').addClass('btn-primary').siblings().removeClass('btn-primary').addClass('btn-outline-secondary');
                window.jQuery('#event_filter_month').val('');
                await processAjax('AHJEvents','events_submit', null, {maskElementId: 'events-page-v5'});
                //console.log('reload events');
            }
            else if (!initial_load) {
                window.jQuery('#events_calendar_view').fullCalendar('refetchEvents');
                //console.log('fetch event');
            }
        }
        else {
            // List mode
            window.jQuery('#events_calendar_view').hide();
            // Have to re-render list view because filters may have changed
            await processAjax('AHJEvents','events_submit', null, {maskElementId: 'events-page-v5'});
        }
    }
}

const TextTruncator = function() {

    /**
     *
     * @param {HTMLElement} element
     * @return {boolean}
     */
    this.isOverflown = function(element) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    };

    /**
     *
     * @param {HTMLElement} btnElement
     */
    this.showHideOverflow = function(btnElement) {
        let container = btnElement.closest(".hidden-overflow-container");
        let description = container.querySelector(".hidden-overflow-text");
        let showHideBtn = container.querySelector(".btn-expand");
        let overflowGradient = container.querySelector(".overflow-gradient");
        let showHideChevron = showHideBtn.querySelector(".glyphicon");

        description.classList.toggle("hidden-overflow-text--showall");

        overflowGradient.classList.toggle("d-block");

        let btnIcon = showHideBtn.querySelector(".md-icons");
        this.toggleText(btnIcon, "expand_more", "expand_less");

        let btnText = showHideBtn.querySelector(".text-expand");
        this.toggleText(btnText, "Show Less", "Show More");
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {string} textA
     * @param {string} textB
     */
    this.toggleText = function (element,textA, textB) {
        if (element.textContent === textA) {
            element.textContent = textB;
        } else {
            element.textContent = textA;
        }
    };

    this.bindShowHide = function() {
        let self = this;
        const textContainers = document.querySelectorAll(".hidden-overflow-container");
        // Show if overflowed
        for (let i = 0; i < textContainers.length; i++) {
            let container = textContainers[i];
            let description = container.querySelector(".hidden-overflow-text");
            let showHideBtn = container.querySelector(".btn-expand");
            let overflowGradient = container.querySelector(".overflow-gradient");
            if (self.isOverflown(description)) {
                overflowGradient.classList.add("d-block");
                showHideBtn.classList.add("d-block");
            }
            showHideBtn.addEventListener("click", function(e) {
                self.showHideOverflow(e.target);
            });
        }
    };
};


const initTextTruncator = function() {
    var textTruncator = new TextTruncator();
    textTruncator.bindShowHide();
}
