import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as ReactDOM from "react-dom";
import {HandleTranslation, SetupTranslations} from "../../redux/translations/translator";
import { exists} from "../../utilities/helpers";
import parse from "html-react-parser/index";
import Skeleton from "react-loading-skeleton";
import {usePage} from "../../redux/app/navigation";
import {getCurrentRoutes} from "../../redux/app/routes";
import {setMenuItemState, useMenu} from "../../redux/app/menus";
import {parsePath} from "../../legacy/ajaxActions";
import {SVGOverlay} from "../../components/overlays/SVGOverlay";
import {getWindowDimensions} from "../../legacy/utils";
import {tabTrap,removeTabTrap} from "../../utilities/keyboardNavigation";
import useAHMutationObserver from "../../components/hooks/useAHMutationObserver"
import "./MainNav.scss";

const translationDomain = 'main_navigation';
const defaultTextColor = '#058299';
const defaultConfig = {menu: {items: []}};

const MainNav = (props) => {

    const translations = SetupTranslations(translationDomain);
    const page = usePage();
    const mainMenuTop = useRef();
    const submenuRefObserver = useRef();
    let currentPathURL = parsePath(page.currentPath).url;
    let routes = getCurrentRoutes();
    const [currentTab, setCurrentTabState] = useState('home');
    const [submenuExpanded, setSubmenuExpanded] = useState(false);
    const [{data, isLoading, isError, version: menuVersion, id: menuId}] = useMenu({version: 'v1', id: 'main'});
    const [config, setConfig] = useState(defaultConfig);
    const [pointerOffset, setPointerOffset] = useState(0);
    const [submenuPosition, setSubmenuPosition] = useState({top:0,left:0});
    const [submenuOpacity, setSubmenuOpacity] = useState("0");
    const [menuTextColor, setMenuTextColor] = useState(defaultTextColor);
    const [isHoveringArr, setIsHoveringArr] = useState([]);
    const selectedClass = 'menu-item-selected';
    const selectedChildClass = 'child-menu-item-selected';

    const internalHandleTranslation = (key, defaultContent = '') => {
        let translatedValue = defaultContent;
        if (exists(translations, `${translationDomain}.${key}`)) {
            translatedValue = parse(translations[translationDomain][key]);
        }
        return translatedValue;
    };

    const closeSubmenu = () => {
        setSubmenuExpanded(false);
    }

    // set current tab and update menu state in redux
    const setCurrentTab = (elId) => {
        if (currentTab !== null) {
            setMenuItemState({isOpen: false}, menuVersion, menuId, currentTab);
        }
        setCurrentTabState(elId);
        if (elId) setMenuItemState({isOpen: true}, menuVersion, menuId, elId);
    }
    const generateNavigationAction = (menuItem = {}) => {
        if (menuItem.customJs) return new Function(menuItem.customJs);

        let url = [menuItem.path];
        if (menuItem.action) {
            if (url.indexOf("?") < 0) url.push('?');
            url.push('&ax_xmlaction=' + menuItem.action);
        }

        return () => navigation.followPath(url.join(""), menuItem.submissionData);
    }

    const positionSubmenu = () => {
        try {
            let header = document.getElementById('header');
            let menuEl = document.querySelector(`#${currentTab}`);
            let submenu = document.getElementsByClassName('main-nav-card')[0];
            let v8main = document.getElementById('dynamic-main-menu-top');
            // let v8main = document.getElementById('v8-main-menu-top');

            if(!(header && menuEl && submenu && v8main)) return;

            let position={
                top:0,
                left:0
            }

            // top position
            let top = header.clientHeight;
            let ipbc = document.querySelector('.incentiveProgressBarContainer');
            if(ipbc?.parentElement) top -= ipbc.parentElement.clientHeight;
            position.top = top;

            // left position
            let wd = getWindowDimensions();
            let v8d = v8main.getBoundingClientRect();
            let menuEld = menuEl.getBoundingClientRect();
            let submenud = submenu.getBoundingClientRect();

            // set left to center of current menu item
            let left = menuEld.x + menuEld.width/2 ;

            // subtract half submenu width to center it
            left -= submenu.offsetWidth / 2;

            // triangle pointer position
            let pointerLeft = submenud.width / 2 - 22; // center position (subtract half pointer width)

            // correct menu position if it extends off page
            if(left + submenu.offsetWidth > wd.w - v8d.x){
                let diff = (left + submenu.offsetWidth) - (wd.w - v8d.x);
                left -= diff;
                pointerLeft += diff;
            }
            else if(left < v8d.x){
                let diff = left - v8d.x;
                left = v8d.x;
                pointerLeft += diff;
            }
            position.left = left;
            setPointerOffset(pointerLeft);
            setSubmenuPosition(position);
        } catch (e) {
            console.error("positionSubmenu", e);
        }
    }
    // set document title
    const site_title = HandleTranslation('site_title', 'Asset Health: Your Health Is Your Most Valuable Asset', translationDomain);

    useEffect(() => {
        if (typeof currentPathURL === "string") {
            let routeKey = currentPathURL.split('/')[1];
            if (!routeKey) routeKey = 'home';
            const route = routes[routeKey];
            let label = "";
            if (route && route.label) label = route.label;
            const routeTranslation = "site_title_route_" + label;
            const site_title_route_label = internalHandleTranslation(routeTranslation, label);
            document.title = site_title_route_label + " - " + site_title;
        }
    }, [currentPathURL]);

    useEffect(() => {
        if (submenuExpanded) {
            setSubmenuOpacity(0);
            setTimeout(()=>{
                positionSubmenu();
                setSubmenuOpacity(1);
                tabTrap('v8-submenu-container');

                let sm = document.querySelector('#v8-submenu-container .main-nav-card-container');
                if(sm){
                    submenuRefObserver.current = null;
                    if(typeof ResizeObserver === 'function'){
                        submenuRefObserver.current = new ResizeObserver(positionSubmenu);
                        submenuRefObserver.current.observe(sm);
                    }
                }
            }, 50);
        }
        else{
            let focusEl = document.querySelector(`#${currentTab} *[tabindex]`);
            removeTabTrap('v8-submenu-container',focusEl);
            if(submenuRefObserver.current && submenuRefObserver.current.disconnect) {
                submenuRefObserver.current.disconnect();
            }
        }
    }, [submenuExpanded]);

    const generateConfig = () => {
        let config = defaultConfig;
        if(!data?.menu?.items) return config;

        // Main menu config
        config.menu.items = Object.entries(data.menu.items).map(([id, item], key) => {
            let pathURL = item.path ? parsePath(item.path).url : item.id;
            let selected = (currentPathURL === pathURL);
            let navigationAction = item.path ? generateNavigationAction(item) : () => {
                setSubmenuExpanded(item.id)
            };

            if(!selected && !item.path) {
                selected = Object.entries(item.children).find(([childId, child], childKey) => {
                    const childPathURL = child.path ? parsePath(child.path).url : child.id;
                    if (currentPathURL === childPathURL) {
                        return true;
                    }
                    return false;
                });
            }

            return {
                id: item.id,
                selected,
                label: internalHandleTranslation('navigation_' + item.id, item.title),
                navigationAction,
                submenu:!!!item.path
            }
        });
        // Submenu config
        Object.entries(data.menu.items).forEach(([id, item], key) => {

            if (!(!item.path && item.state?.isOpen)) return; // only 1 submenu open at a time

            let submenu = {
                parentId: item.id,
                hasImage: false
            };

            if(data.menu.menu_config?.['submenu-orientation']) submenu.orientation = data.menu.menu_config['submenu-orientation'];
            else submenu.orientation = 'horizontal'; // default orientation

            submenu.items = Object.entries(item.children).map(([childId, child], childKey) => {

                let navigationAction = generateNavigationAction(child);

                // set 'selected' on child/parent menu items
                const childPathURL = child.path ? parsePath(child.path).url : child.id;
                let selected = (currentPathURL === childPathURL);
                let parentId = null;
                if (currentPathURL === childPathURL) {
                    let parent = config.menu.items.find(i => i.id === submenu.parentId);
                    if (parent){
                        parent.selected = true;
                        parentId = parent.id;
                    }
                }

                let obj = {
                    id: child.id,
                    parentId,
                    selected,
                    navigationAction,
                    label: internalHandleTranslation('navigation_' + child.id, child.title)
                }
                if(child.image){
                    obj.image = {
                        src: `${process.env.REACT_APP_ASSETS_URL_OVERRIDE + '/' + child.image}`,
                        alt: `Image for ${internalHandleTranslation('navigation_' + child.id, child.title)}`
                    }
                    submenu.hasImage = true;
                }

                return obj;
            });

            config.submenu = submenu;
        });
        return config;
    }

    const updateHoverState = (key, hoverState) => {
        const copy = [...isHoveringArr];
        copy[key] = hoverState;
        setIsHoveringArr(copy);
    };

    useEffect(() => {
        setConfig(generateConfig());
    }, [page, currentPathURL,currentTab,submenuExpanded]);

    useEffect(() => {
        setIsHoveringArr(new Array(config.menu.items.length).fill(false))
    }, [config.menu.items.length]);

    useEffect(()=>{
        window.addEventListener('resize', closeSubmenu);
        return () => {
            window.removeEventListener('resize', closeSubmenu);
        }
    },[]);

    useEffect(() => {
        const { backgroundColor } = (mainMenuTop.current && window.getComputedStyle(mainMenuTop.current)) || { backgroundColor: defaultTextColor};
        setMenuTextColor(backgroundColor);
    }, []);

    // Function that makes the small screen menu (that shows the current page) the same color as main menu.
    const updateSmallMenuColor = useCallback(() => {
        const menuBar = document.querySelector('.menu-bar');
        if (menuBar) menuBar.style.backgroundColor = menuTextColor;
    }, [menuTextColor]);

    // Use the mutation observer to watch the document and run updateSmallMenuColor() .
    useAHMutationObserver({callback: updateSmallMenuColor});

    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {(props.skeleton || isLoading) ?
                <Skeleton width={1140} height={48}/>
                : (data?.menu?.items && Object.keys(data.menu.items).length > 0 &&

                        <div id="dynamic-main-menu-top" className="v8 dynamic-main-menu-top-row" ref={mainMenuTop}>
                            <div className="top-nav-container">
                                {
                                    config.menu?.items?.map((item, key) => {
                                        return (
                                            <div
                                                key={`main-menu-${key}`}
                                                id={item.id}
                                                className={`menu-item-text ${(item.selected || (item.id === currentTab && submenuExpanded))? selectedClass : ''}`}
                                                onMouseOver={() => updateHoverState(key, true)}
                                                onMouseOut={() => updateHoverState(key, false)}
                                            >
                                                <a onClick={(e) => {
                                                    setCurrentTab(item.id);
                                                    if(item.submenu)  {
                                                        if(submenuExpanded !== item.id) {
                                                            setSubmenuExpanded(item.id);
                                                        }
                                                        else {
                                                            setSubmenuExpanded(false);
                                                        }
                                                    }
                                                    else setSubmenuExpanded(false);
                                                    item.navigationAction();

                                                }}
                                                   className={`nav-link item`}
                                                   {...(item.selected || (item.id === currentTab && submenuExpanded) || isHoveringArr[key]) && {style: {color: menuTextColor}}}
                                                   tabIndex={0}
                                                   role={"button"}>
                                                    {item.label}
                                                </a>
                                                {item.submenu &&
                                                    <svg className={'has-submenu'}
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         width="24"
                                                         height="9"
                                                         viewBox="0 0 24 9"
                                                         fill="none">
                                                        <path d="M8.71911 7.14705C10.6005 8.78301 13.3995 8.78301 15.2809 7.14705L23.5 -9.53674e-07H0.5L8.71911 7.14705Z" fill="white"/>
                                                    </svg>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {/**************************
                                 Submenu
                                 ****************************/}
                                {config.submenu?.items?.length > 0 && submenuExpanded &&
                                    <>
                                        <Submenu
                                            config={config}
                                            selectedChildClass={selectedChildClass}
                                            setCurrentTab={setCurrentTab}
                                            setSubmenuExpanded={setSubmenuExpanded}
                                            pointerOffset={pointerOffset}
                                            submenuPosition={submenuPosition}
                                            submenuOpacity={submenuOpacity}
                                            menuTextColor={menuTextColor}
                                            />

                                        {/**************************
                                         Overlay
                                         ****************************/}
                                        <SVGOverlay
                                            handlers={{
                                                click:()=>setSubmenuExpanded(false),
                                                keyUp:(e)=>{
                                                    if(e.key==='Escape')setSubmenuExpanded(false);
                                                }
                                            }}
                                            zIndex={1030}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                )}
        </>
    );
}

export default MainNav;

const Submenu = ({config={},selectedChildClass='',setCurrentTab=()=>{},setSubmenuExpanded=()=>{},pointerOffset=0,submenuPosition={},submenuOpacity="1", menuTextColor = 'black'}) => {

    const [submenuHoverArr, setSubmenuHoverArr] = useState(new Array(config.submenu.items.length).fill(false));
    const trianglePointer = useRef();

    const updateSubmenuHoverState = (key, hoverState) => {
        const copy = [...submenuHoverArr];
        copy[key] = hoverState;
        setSubmenuHoverArr(copy);
    };

    useEffect(()=>{
        trianglePointer.current.style.transform = `translateX(${pointerOffset}px)`;
    },[pointerOffset]);

    return ReactDOM.createPortal(
        <div id={'v8-submenu-container'} style={{position:"fixed",top:(submenuPosition.top??0)+"px",left:(submenuPosition.left??0)+"px",zIndex:"1041",width:"100%",padding:"0",opacity:submenuOpacity}} className={'container'}>
            <svg
                className={'submenu-parent-pointer'}
                ref={trianglePointer}
                xmlns="http://www.w3.org/2000/svg"
                width="43"
                height="19"
                viewBox="0 0 43 19"
                fill="none">
                <path d="M25.0543 2.26749C23.1444 0.480857 20.1813 0.466559 18.2564 2.23468L0.787268 18.2805L42.3875 18.4813L25.0543 2.26749Z" fill="white"/>
            </svg>

            <div className={'main-nav-card'}>

                {config.submenu.orientation === 'horizontal' &&
                    <div className={`main-nav-card-container${config.submenu.hasImage === false ? ' no-image':''}`}>
                        {config.submenu?.items?.map((child, index) => {

                            return (
                                <div
                                    className={`main-nav-card-wrap ${child.selected ? selectedChildClass : ''}${config.submenu.hasImage === false ? ' no-image':''}`}
                                    key={`submenu-${child.id}`}
                                >
                                    <a
                                        onClick={() => {
                                            setCurrentTab(config.submenu.parentId);
                                            setSubmenuExpanded(false);
                                            child.navigationAction();
                                        }}
                                        className={`nav-link item submenu ${child.selected ? selectedChildClass : ''} ${child.image ? 'has-image' : ''}`}
                                        tabIndex={0}>
                                        {child.image &&
                                            <div
                                                className={'main-nav-card-image-container'}>
                                                <img
                                                    alt={`${child.image.alt}`}
                                                    src={child.image.src}
                                                />
                                                <div
                                                    className={'main-nav-card-image-background'} {...(child.id && {id: `submenu-background-${child.id}`})}/>
                                            </div>
                                        }
                                        <div className={'main-nav-card-title' + (!child.image ? '-no-image':'') + (index === 0 ? ' main-nav-card-title-first':'') + ((!child.image && submenuHoverArr[index]) ? ' pseudo-hover':  '')}
                                             id={`submenu-id-${child.id}`}
                                             onMouseOver={() => updateSubmenuHoverState(index, true)}
                                             onMouseOut={() => updateSubmenuHoverState(index, false)}
                                             {...(!child.image && {style: {color: menuTextColor } } )}
                                        >
                                            {child.label}
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                        }
                </div>
                }

                {config.submenu.orientation === 'vertical' &&
                    <div className="main-nav-card-container-v">
                        {config.submenu?.items?.map((child, index) => {

                            return (
                                <a
                                    onClick={() => {
                                        setCurrentTab(config.submenu.parentId);
                                        setSubmenuExpanded(false);
                                        child.navigationAction();
                                    }}
                                    className={`nav-link item ${child.selected ? selectedChildClass : ''} submenu`}
                                    tabIndex={0}>
                                    <div className={'main-nav-card-title' + (submenuHoverArr[index] ? ' pseudo-hover' : '')}
                                         id={`submenu-id-${child.id}`}
                                         onMouseOver={() => updateSubmenuHoverState(index, true)}
                                         onMouseOut={() => updateSubmenuHoverState(index, false)}
                                         style={{color: menuTextColor}}
                                    >
                                        {child.label}
                                    </div>
                                </a>
                            )
                        })
                        }
                    </div>
                }
            </div>
        </div>,
        document.body
    );
}