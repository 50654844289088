import React, {useEffect, useState} from 'react';
import * as ReactDOM from "react-dom";
import {getWindowDimensions, enableBody, disableBody} from "../../legacy/utils";
import './SVGOverlay.scss';

/*
    SVGOverlay will put an overlay over the entire screen

    props (all optional):
    visibleSelectors: array of css selectors - selected elements will show thru the overlay
    arcRadius: svg version of border-radius to give space around visible elements
    mouseEventsOnVisibles: true/false - if true, user can interact with visible elements.
    handlers: object with keyUp and click handling functions.  { keyUp:()=>"key up",click:()=>"click" }
    zIndex: int z-index to set on overlay
    opacity: decimal opacity to set on overlay
 */
export const SVGOverlay = ({visibleSelectors = [],arcRadius=10, mouseEventsOnVisibles=true, handlers={}, zIndex=99999, opacity=.42}) => {

     arcRadius = isNaN(parseInt(arcRadius)) ? 0 : parseInt(arcRadius);

    const getClipPath = () => {
         let wd = getWindowDimensions();
         let path = [`M 0 0 h ${wd.w} v ${wd.h} h ${wd.w * -1} z`]; // mask entire screen

        // unmask each element found with visibleSelectors
        visibleSelectors.forEach(selector => {
            let elements = document.querySelectorAll(selector);
            elements.forEach(element => {
                let rect = element.getBoundingClientRect();
                let width =  Math.max(element.clientWidth,rect.width);
                let height = Math.max(element.clientHeight,rect.height);
                path.push(`M ${rect.left} ${rect.top} v ${height - arcRadius} a ${arcRadius},${arcRadius} 0 0 0 ${arcRadius},${arcRadius} h ${width - arcRadius * 2} a ${arcRadius},${arcRadius} 0 0 0 ${arcRadius},-${arcRadius} v ${(height * -1) + arcRadius * 2} a 20,20 0 0 0 -${arcRadius},-${arcRadius} h ${width * -1 + arcRadius * 2}  a ${arcRadius},${arcRadius} 0 0 0 -${arcRadius},${arcRadius} z`);
            });
        });

         return path.join(" ");
    }
     const [clipPath,setClipPath] = useState('M 0 0 ');

     const updateClipPath = () => {
         setClipPath(getClipPath());
     }
     const keyUpHandler = (e) => {
         if(typeof handlers.keyUp === "function") handlers.keyUp(e);
     }

     useEffect(()=> {
         updateClipPath();
     },[visibleSelectors]);

    useEffect(()=>{

        disableBody(document.body);
        /*
            Handle window resize
         */
        window.addEventListener('resize', updateClipPath);
        window.addEventListener('keyup',keyUpHandler);

        /*
           Handle visible element resize
        */
        let ro = null;
        if(typeof ResizeObserver === 'function'){
            ro = new ResizeObserver(updateClipPath);
            visibleSelectors.forEach(selector => {
                let elements = document.querySelectorAll(selector);
                elements.forEach(element => {
                    ro.observe(element);
                });
            });
        }
        /*
            Disconnect resize listeners
         */
        return () => {
            window.removeEventListener('resize', updateClipPath);
            window.removeEventListener('keyup', keyUpHandler);
            enableBody(document.body);
            if(ro && ro.disconnect) {
                ro.disconnect();
            }
        }
    },[]);

    return ReactDOM.createPortal(
        <div className={'svg-overlay'} onClick={handlers.click ?? null} style={{zIndex}}>
             <svg xmlns="http://www.w3.org/2000/svg"
                 width={"100%"}
                 height={"100%"}
                 style={{opacity:opacity??".42"}}>
                 <defs>
                     <clipPath id="svg-overlay-clip-path">
                         <path d={clipPath} />
                     </clipPath>
                 </defs>
                 <rect
                     x="0"
                     y="0"
                     width={"100%"}
                     height={"100%"}
                     fill="currentColor"
                     clipPath="url(#svg-overlay-clip-path)"
                     style={{pointerEvents: "painted"}}></rect>

                 {mouseEventsOnVisibles === false &&
                     <rect
                         x="0"
                         y="0"
                         width={"100%"}
                         height={"100%"}
                         fill="transparent"
                         style={{pointerEvents: "painted"}}/>
                 }
             </svg>
        </div>,
        document.body
    );
}