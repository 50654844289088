import { processJSON, processAjax } from "../ajaxActions";
import { replaceWithLoader, removeLoader, resolveContext, } from "../utils";
import { circularFill } from "../lib/circularFill";
import { initializeAHPopover } from "../components/popoverManager";
import { scrollToElement } from "../assethealth";
import { SegmentedAnimation } from "../lib/SegmentedAnimation";

export const exportPTWPageGlobals = () => {
    window['PTWController'] = PTWController;
    window['PTWTest'] = PTWTest;
}

class PTWController {
    constructor() {
        this.progressBar = null;
        this.PTWCircularFills = {};
        this.popoverTimeToDisplay = 5000;
    }

    /**
     * @author KCupp
     */
    initialize = () => {
        this.content = window.jQuery('#ptw-program-container');

        this.PTWCircularFills = {};
        let ptwController = this;

        this.content.find('.circular-fill').each(function(){
            let elem = window.jQuery(this);
            ptwController.PTWCircularFills[elem.attr('id')] = new circularFill(elem);
        });

        let progressBarElem = this.content.find('.task-list-progress-bar');
        this.progressBar = new TaskListProgressBar(progressBarElem, this);

        this.content.find("#begin-program-button").off('click').on('click', async function () {
            let button = window.jQuery(this);
            let loaderId = replaceWithLoader(button);
            let submissionParams = {};
            submissionParams.uniqueColumns = ["ee_id", "code", "activity_path", "type", "status_code", "plan_period_no", "active_flag", "deleted_flag", "sub_period_no"];
            submissionParams.activitiesToSave = ptwController.getActivityJSON(button.attr('data-category'), button.attr('data-activity-path'), button.attr('data-activity-timestamp'), 'in_progress', ptwController.content.find('#ptw-program').attr('data-sub-period-no'));
            await processJSON('AHJTracking', submissionParams, 'saveActivities', {
                callBack: function () {
                    ptwController.displayPage(loaderId);
                }
            });
        });

        this.content.find("#move-on-button").off('click').on('click', async function () {
            let button = window.jQuery(this);
            let loaderId = replaceWithLoader(button);
            let submissionParams = {};
            submissionParams.uniqueColumns = ["ee_id", "code", "activity_path", "type", "status_code", "plan_period_no", "active_flag", "deleted_flag", "sub_period_no"];
            submissionParams.activitiesToSave = ptwController.getActivityJSON(button.attr('data-category'), button.attr('data-activity-path'), button.attr('data-activity-timestamp'), 'completed', ptwController.content.find('#ptw-program').attr('data-sub-period-no'));
            await processJSON('AHJTracking', submissionParams, 'saveActivities', {
                callBack: function () {
                    ptwController.displayPage(loaderId);
                }
            });
        });

        this.content.find('#edit-contact-information-btn').off('click').on('click', async function(){
            let button = window.jQuery(this);
            replaceWithLoader(button);
            await processJSON('AHJPTW', {contactInfoOnly:'Y', engagementType:button.attr('data-engagement-type-code')}, 'openEnrollmentWizard', {callBack:function(){
                    removeLoader(button.attr('id'));
                }});
        });

        window.jQuery('.task-list-progress-bar .ptw-task.completed, .task-list-progress-bar .ptw-task').off('click').click(async function(e){
            if(window.jQuery(e.delegateTarget).hasClass('active')) {
                e.preventDefault();
                await processJSON('AHJPTW', {
                    refreshing: true,
                    chapterTaskId: window.jQuery(e.delegateTarget).attr('data-chapter-task-id')
                }, 'displayProgram');
            }
        });

        // bind event handlers to cards
        let assignmentToNudge = this.content.find('.card.active').off('click').on('click', async function () {
            await ptwController.startAssignment(window.jQuery(this));
        }).not('.completed').first();

        window.jQuery('.scheduler-btn').off('click').click(async function(e){
            e.stopPropagation();
            e.preventDefault();
            await ptwController.scheduleAppointment();
        });

        if(window.jQuery('.modal:visible').length === 0) {
            if (!window.jQuery('#ptw-program-inner').hasClass('refreshing')) {
                initializeAHPopover(
                    assignmentToNudge,
                    {
                        show: true,
                        timeToDisplay: this.popoverTimeToDisplay,
                        container: window.jQuery(assignmentToNudge.parent())
                    }
                );
            }
        }

        this.content.find('.card.optional').off('click').on('click', function(){
            let card = window.jQuery(this);
            let type = card.attr('data-type');
            let value = card.attr('data-value');
            let funct = resolveContext('ptwController.'+type+'ResourceAction');
            if(typeof(funct) === 'function'){
                funct(value);
            }
        });

        this.externalResourceAction = function(url){
            window.open(url, '_blank');
        }

        this.internalResourceAction = function(url){
            window.open(url, '_blank');
        }
    }

    /**
     * @author KCupp
     * @param params
     */
    updateProgressBar = (params) => {
        let activeChapterIndex = params.activeChapterIndex;
        let chaptersCompleted = params.chaptersCompleted;
        let chapterCount = params.chapterCount;
        let currentChapterTasksCompleted = params.currentChapterTasksCompleted;
        let currentChapterTaskCount = params.currentChapterTaskCount;

        //All parameters are required
        if(typeof activeChapterIndex !== 'undefined' &&
            typeof chaptersCompleted !== 'undefined' &&
            typeof chapterCount !== 'undefined' &&
            typeof currentChapterTasksCompleted !== 'undefined' &&
            typeof currentChapterTaskCount !== 'undefined'){

            this.progressBar.animate(activeChapterIndex, chaptersCompleted, chapterCount, currentChapterTasksCompleted, currentChapterTaskCount);
        }
    }

    /**
     * @author JBonnell
     * @param loaderId
     */
    displayPage = async (loaderId) => {
        await processJSON('AHJPTW', {}, 'displayPage', {
            callBack: function () {
                removeLoader(loaderId)
            }
        });
    }

    displayHistory = async () => {
        await processJSON('AHJPTW',{},'displayHistory');
    }

    displayCoachingInformation = async () => {
        await processJSON('AHJPTW',{},'displayCoachInformation',{maskElementId:this.content.attr('id')});
    }

    /**
     * @author KCupp
     * @param elem
     */
    startAssignment = async (elem) => {
        let card = window.jQuery(elem);

        if(!card.hasClass('disabled')) {
            card.addClass('disabled'); //prevents multi clicks
            if (typeof card.attr('data-download-only') !== 'undefined') {
                let newTab = window.open('/api/v1/grid/convert/self_service/' + card.attr('data-download-only') + '.pdf?subPeriodNo=' + window.jQuery('#ptw-program').attr('data-sub-period-no') + '&completeGrid=Y', '_blank');
                newTab.onload = new function () {
                    processAjax('AHJPTW', '', '', {
                        callBack: function () {
                            card.removeClass('disabled');
                        }
                    });
                };
            } else {
                await processJSON('AHJTaskList', {
                        taskListMappingNo: card.attr('data-task-list-mapping-no'),
                        taskItemType: card.attr('data-task-item-type'),
                        taskListNo: card.attr('data-task-list-no'),
                        taskNo: card.attr('data-task-no'),
                        taskItemNo: card.attr('data-task-item-no'),
                        taskListId: card.attr('data-task-list-id'),
                        gridClass: card.attr('data-grid-class'),
                        suggestionData: {
                            accessLocation: 'AHJPTW',
                            origin: 'ptw'
                        }
                    }, 'actOnSuggestion'
                    , {
                        callBack: function () {
                            card.removeClass('disabled');
                        }
                    }
                );
            }
        }
    }

    /**
     * @author KCupp
     * @param category
     * @param activityId
     * @param activityTimestamp
     * @param status
     * @param subPeriodNo
     */
    getActivityJSON = (category, activityId, activityTimestamp, status, subPeriodNo) => {
        let submissionData = {};
        if (typeof(submissionData[category]) === 'undefined') {
            submissionData[category] = {};
        }
        if (typeof(submissionData[category][activityId]) === 'undefined') {
            submissionData[category][activityId] = {};
        }
        if (typeof(submissionData[category][activityId][activityTimestamp]) === 'undefined') {
            submissionData[category][activityId][activityTimestamp] = {};
        }
        submissionData[category][activityId][activityTimestamp]['status_code'] = status;
        submissionData[category][activityId][activityTimestamp]['sub_period_no'] = subPeriodNo;

        return submissionData;
    };

    openEmailCoachModal = (elem) => {
        let button = window.jQuery(elem);
        let coachName = button.attr('data-coach-name');
        let coachUserNo = button.attr('data-coach-user-no');

        window.jQuery('#counselor_form input[name="coach_name"]').val(coachName);
        window.jQuery('#counselor_form input[name="coach_user_no"]').val(coachUserNo);
        window.core_messager.initModal({'sourceElement':'#contactCounselor', 'title':'Email your coach', 'size':'large', 'buttons':[]}); return false;
    }

    scheduleAppointment = async () => {
        window.navigation.followPath('/events');
    }

    selectTab = (params) => {
        let subtab = "#sidebar-tab-" + params.taskListId;

        window.jQuery(subtab).addClass('active').siblings('.active').removeClass('active'); scrollToElement( window.jQuery('#pagecontent-inner'));
    }
}

class TaskListProgressBar {
    constructor(element, controller, startingProgressPercentage) {
        /**
         * @type {PTWController}
         */
        this.controller = controller;

        /*
         * Task list DOM properties
         */
        this.element = null;
        this.taskFillElements = null;
        /**
         *
         * @type {SegmentedAnimation}
         */
        this.overallProgressAnimation = null;
        /**
         *
         * @type {SegmentedAnimation}
         */
        this.taskProgressAnimation = null;

        this.chaptersCompleted = 0;
        this.chapterCount = 0;
        this.currentChapterTasksCompleted = 0;
        this.currentChapterTaskCount = 0;
        this.currentChapterAnimationRange = 0;
        this.currentChapterPercentageRange = 0;
        this.activeChapterIndex = 0;

        this.animationTime = 800;

        this.initialize(element);
    }

    /**
     * @author KCupp
     */
    generateRanges = () => {
        let ranges = [];
        let progressBar = this;

        //Add a range for each chapter's circular fill icon.
        let totalWidth = this.element.find('.progress-icons').innerWidth();
        this.taskFillElements.each(function(){
            let taskElement = window.jQuery(this);
            ranges.push(new progressBar.overallProgressAnimation.AnimationRange(
                taskElement,
                taskElement.position().left / totalWidth * 100,
                (taskElement.position().left + taskElement.width()) / totalWidth * 100,
                function(element, percentage){
                    progressBar.controller.PTWCircularFills[element.find('.circular-fill').attr('id')].setFillPercentage(percentage, false);
                }
            ));
        });

        //Add the range for the progress bar background (full range: 0%-100%)
        let progressBarElement = this.element.find('.progress-bar');
        progressBarElement.css('transition', 'unset');
        ranges.push(
            new progressBar.overallProgressAnimation.AnimationRange(progressBarElement, 0, 100, function(element, percentage){
                element.css('width', percentage+'%');
            })
        );

        this.overallProgressAnimation.setRanges(ranges);

        //Add the range for the location indicator (full range: 0%-100%)
        this.taskProgressAnimation.setRanges([
            new progressBar.taskProgressAnimation.AnimationRange(this.element.find('.location-indicator'), 0, 100, function(element, percentage){
                element.css('left', percentage+'%');
            })
        ]);
    };

    /*
     * Animation Methods
                     _                 _   _               __  __      _   _               _
         /\         (_)               | | (_)             |  \/  |    | | | |             | |
        /  \   _ __  _ _ __ ___   __ _| |_ _  ___  _ __   | \  / | ___| |_| |__   ___   __| |___
       / /\ \ | '_ \| | '_ ` _ \ / _` | __| |/ _ \| '_ \  | |\/| |/ _ \ __| '_ \ / _ \ / _` / __|
      / ____ \| | | | | | | | | | (_| | |_| | (_) | | | | | |  | |  __/ |_| | | | (_) | (_| \__ \
     /_/    \_\_| |_|_|_| |_| |_|\__,_|\__|_|\___/|_| |_| |_|  |_|\___|\__|_| |_|\___/ \__,_|___/

     */

    /**
     * This function always animates the progress bar to the position indicated by the data attributes on this.element
     * @author KCupp
     * @param activeChapterIndex
     * @param chaptersCompleted
     * @param chapterCount
     * @param currentChapterTasksCompleted
     * @param currentChapterTaskCount
     */
    animate = (activeChapterIndex, chaptersCompleted, chapterCount, currentChapterTasksCompleted, currentChapterTaskCount) => {
        //First, we break up the 0-100 percentage range into segments based on the layout of the progress bar
        this.generateRanges();

        //Pull the default completion data from the DOM
        this.gatherCompletionData();

        //Override the completion data from the DOM with the parameters if we received any
        this.overrideCompletionData(activeChapterIndex, chaptersCompleted, chapterCount, currentChapterTasksCompleted, currentChapterTaskCount);

        //Check to see if any task icons need to be enabled
        let mostRecentFillElement = window.jQuery(this.taskFillElements.get(this.chaptersCompleted));
        if(mostRecentFillElement.hasClass('inactive')){
            mostRecentFillElement.removeClass('inactive').addClass('active');
            mostRecentFillElement.find('.task-status-indicator .glyphicons').removeClass('line-lock-close-1').addClass('line-check-1');

            if(this.chaptersCompleted > 0){
                let previousFillElement = window.jQuery(this.taskFillElements.get(this.chaptersCompleted-1));
                previousFillElement.addClass('completed');
            }
        }


        //Now, we animate!
        this.overallProgressAnimation.setAnimationTime(this.animationTime);
        let progressBarPercentageTarget = this.calculateProgressBarPercentageTarget();
        this.element.attr('data-progress', progressBarPercentageTarget);
        let taskProgressPercentageTarget = this.calculateTaskProgressPercentageTarget();
        this.element.attr('data-task-indicator-progress', taskProgressPercentageTarget);
        let taskProgressAnimationTime = Math.abs(taskProgressPercentageTarget - this.taskProgressAnimation.getProgressPercentage()) * this.overallProgressAnimation.getAnimationTime() / progressBarPercentageTarget;
        this.overallProgressAnimation.setProgressPercentage(progressBarPercentageTarget);
        this.taskProgressAnimation.setAnimationTime(taskProgressAnimationTime).setProgressPercentage(taskProgressPercentageTarget);
    };

    /**
     * @author KCupp
     */
    gatherCompletionData = () => {
        this.activeChapterIndex = parseInt(this.element.attr('data-active-chapter-index'));
        this.chaptersCompleted = parseInt(this.element.attr('data-chapters-completed'));
        this.chapterCount = parseInt(this.element.attr('data-chapter-count'));
        this.currentChapterTasksCompleted = parseFloat(this.element.attr('data-current-chapter-tasks-completed'));
        this.currentChapterTaskCount = parseFloat(this.element.attr('data-current-chapter-tasks'));
    };

    /**
     * @author KCupp
     * @param activeChapterIndex
     * @param chaptersCompleted
     * @param chapterCount
     * @param currentChapterTasksCompleted
     * @param currentChapterTaskCount
     */
    overrideCompletionData = (activeChapterIndex, chaptersCompleted, chapterCount, currentChapterTasksCompleted, currentChapterTaskCount) => {
        if(typeof activeChapterIndex !== 'undefined'){
            this.activeChapterIndex = activeChapterIndex;
            this.element.attr('data-active-chapter-index', activeChapterIndex);
        }
        if(typeof chaptersCompleted !== 'undefined'){
            this.chaptersCompleted = chaptersCompleted;
            this.element.attr('data-chapters-completed', chaptersCompleted);
        }
        if(typeof chapterCount !== 'undefined'){
            this.chapterCount = chapterCount;
            this.element.attr('data-chapter-count', chapterCount);
        }
        if(typeof currentChapterTasksCompleted !== 'undefined'){
            this.currentChapterTasksCompleted = currentChapterTasksCompleted;
            this.element.attr('data-current-chapter-tasks-completed', currentChapterTasksCompleted);
        }
        if(typeof currentChapterTaskCount !== 'undefined'){
            this.currentChapterTaskCount = currentChapterTaskCount;
            this.element.attr('data-current-chapter-tasks', currentChapterTaskCount);
        }
    };

    /**
     * Given the number of chapters completed, and the number of tasks completed vs. the number of tasks to complete in the current chapter,
     * we calculate and return the percentage fill of the task list progress bar.
     * @returns {number}
     */
    calculateProgressBarPercentageTarget = () => {
        let progressBarPercentageTarget = 0;

        //Get the percentage range covered by the current chapter
        this.currentChapterAnimationRange = this.overallProgressAnimation.ranges[this.chaptersCompleted];
        this.currentChapterPercentageRange = this.currentChapterAnimationRange.stopPercentage - this.currentChapterAnimationRange.startPercentage;

        //The progress percentage is the starting percentage of the current chapter task range, plus the portion of its total range that has been completed so far
        if(this.currentChapterTaskCount !== 0) {
            progressBarPercentageTarget = this.currentChapterAnimationRange.startPercentage + (this.currentChapterPercentageRange * this.currentChapterTasksCompleted / this.currentChapterTaskCount);
        }

        return progressBarPercentageTarget;
    };

    /**
     * @author KCupp
     * @returns {number}
     */
    calculateTaskProgressPercentageTarget = () => {
        let taskProgressPercentageTarget = 0;
        if(this.activeChapterIndex > 0 && this.activeChapterIndex < this.chapterCount) {
            let activeChapterAnimationRange = this.overallProgressAnimation.ranges[this.activeChapterIndex];
            taskProgressPercentageTarget =  activeChapterAnimationRange.startPercentage + ((activeChapterAnimationRange.stopPercentage - activeChapterAnimationRange.startPercentage) / 2);
        } else if(this.activeChapterIndex === 0){
            taskProgressPercentageTarget = this.overallProgressAnimation.ranges[0].startPercentage;
        } else if(this.activeChapterIndex === this.chapterCount){
            taskProgressPercentageTarget = this.overallProgressAnimation.ranges[this.activeChapterIndex].stopPercentage;
        }
        return taskProgressPercentageTarget;
    };

    /*
     * Initialization methods
     */

    /**
     * @author KCupp
     * @param  {jQuery} element
     * @returns {TaskListProgressBar}
     */
    initialize = (element) => {
        this.element = window.jQuery(element);
        let startingProgressPercentage = this.element.attr('data-progress');
        let taskIndicatorStartingProgressPercentage = this.element.attr('data-task-indicator-progress');
        this.taskFillElements = this.element.find('.task-fill');
        this.overallProgressAnimation = new SegmentedAnimation(element, startingProgressPercentage);
        this.taskProgressAnimation = new SegmentedAnimation(this.element.find('.location-indicator'), taskIndicatorStartingProgressPercentage);


        this.overallProgressAnimation.setEasing('linear');
        this.taskProgressAnimation.setEasing('linear');
        this.generateRanges();
        return this;
    };

}

class PTWTest {
    static handleTestButtonSubmit = async (reference) => {
        let validated = false;
        validated = this.validateTestSubmitAnswer(reference);
        if (validated) {
            await processAjax('AHJCourse', 'test_answers', 'submitAnswer');
        }
    }

    static handleTestButtonSubmitSA = async (reference) => {
        let validated = false;
        validated = this.validateTestSubmitAnswer(reference);
        if (validated) {
            let ref = window.jQuery(reference);
            replaceWithLoader(ref);
            await processAjax('AHJTests', 'test_answers', 'submitAnswerSA',{
                callBack: function () {
                    removeLoader(ref.attr('id'));
                }
            });
        }
    }

    static validateTestSubmitAnswer = (reference) => {
        return !window.jQuery(reference).hasClass('disabled');
    }

    static validateTestSubmitButton = () => {
        if (window.jQuery('#test_answers input:checkbox:checked').length != 0 && window.jQuery('#test_answers input:checkbox:checked').length < 4) {
            window.jQuery('#test_submit_btn').removeClass('disabled');
        } else {
            window.jQuery('#test_submit_btn').addClass('disabled');
        }
    }

    static validateTestSubmitButtonTrueFalse = () => {
        window.jQuery('#test_submit_btn').removeClass('disabled');
    }
}