import { processJSON } from "../ajaxActions";
import {replaceWithLoader, removeLoader, isJsonString} from "../utils";
import { scrollElementToPosition } from "../assethealth";
import {loadScript} from "../../utilities/helpers";

export class GridController {
    constructor() {
        this.gridContainerId = 'grid-container';
        this.gridContainer = null;
        this.gridId = null;
        this.subPeriodNo = null;
        this.finalPageReached = false;
        this.noDataText = 'No data found';
    }

    /**
     * @author KCupp
     * @param {{}} parameters
     */
    initialize = (parameters) => {
        let modal = parameters.modal;
        this.gridId = parameters.id;
        this.subPeriodNo = parameters.subPeriodNo;
        this.finalPageReached = false;
        if (typeof (modal) !== 'undefined' && modal) {
            let gridChildID = `${this.gridContainerId}-${parameters.id}`;
            this.gridContainer = window.jQuery(`#${gridChildID}`);
            let gridController = this;
            if (this.gridContainer.length === 0) {
                window.core_messager.initModal({
                    id: parameters.id + '-modal',
                    message: `<div id="${gridChildID}">${parameters.content}</div>`,
                    size: 'xl',
                    buttons: this.buildModalButtons(parameters),
                    onShown: function () {
                        gridController.postRenderLoad();

                        if (gridController.finalPageReached) {
                            gridController.raiseFinalPageReachedEvent();
                        }
                    }
                });
                this.gridContainer = window.jQuery(`#${gridChildID}`);
            } else {
                this.gridContainer.html(parameters.content);
                window.core_messager.updateButtons(parameters.id + '-modal', this.buildModalButtons(parameters));
                if (gridController.finalPageReached) {
                    gridController.raiseFinalPageReachedEvent();
                }
            }
            this.gridContainer.find('.grid').append(this.gridContainer.find('footer').remove());
            this.gridContainer.find('.grid').prepend(this.gridContainer.find('header').remove());
        }
        this.initializeDownloadLink(parameters.id);
        this.initializeCharts(parameters.id);
    };

    initializeDownloadLink = (id) => {
        let downloadLink = window.jQuery('#' + this.gridContainerId + '-' + id + ' .grid-download-link');
        if (downloadLink.length > 0) {
            downloadLink.click(function (event) {
                event.preventDefault();
                window.jQuery(event.delegateTarget).siblings('a').click();
            });
        }
    };

    postRenderLoad = () => {
        this.gridContainer.find('script[data-postrender-load="true"]').each((index, element) => {
            element = window.jQuery(element);
            let props = element.attr('data-props');
            let parsed = null;
            if(isJsonString(props)) {
                parsed = JSON.parse(props);
            }
            loadScript(element.attr('src'), parsed);
        });
    }

    /**
     * @author KCupp
     */
    raiseFinalPageReachedEvent = () => {
        window.applicationEvents.raiseEvent('grid_final_page_reached', {
            'gridId': this.gridId,
            'subPeriodNo': this.subPeriodNo
        });
    };

    /**
     * @author KCupp
     * @param parameters
     * @returns {Array}
     */
    buildModalButtons = (parameters) => {
        let buttons = [];
        if (typeof (parameters.pageIndex) !== 'undefined' && parameters.pageIndex > 0) {
            buttons.push(this.buildPreviousPageButton(parameters));
        }
        if (parameters.pageIndex < parameters.maxPageIndex) {
            buttons.push(this.buildNextPageButton(parameters));
        } else {
            buttons.push(this.buildCompleteGridButton(parameters));
            this.finalPageReached = true;
        }
        return buttons;
    };

    /**
     * @author KCupp
     * @param parameters
     * @returns {CoreMessager.Button}
     */
    buildPreviousPageButton = (parameters) => {
        return new window.core_messager.button(parameters.previousButtonText, function (buttonElement) {
            replaceWithLoader(buttonElement);
            let formData = {
                gridId: parameters.id,
                gridPageId: parameters.page,
                subPeriodNo: parameters.subPeriodNo
            };
            if (typeof parameters.taskListId !== 'undefined') {
                formData.taskListId = parameters.taskListId;
            }
            if (typeof parameters.gridClass !== 'undefined') {
                formData.gridClass = parameters.gridClass;
            }
            processJSON('AHJGrid', formData, 'displayPreviousGridPage', {
                callBack: function () {
                    removeLoader(buttonElement.attr('id'));
                    scrollElementToPosition('.modal-body', 0);
                }
            });
        }, 'default', false, false, null, 'grid-previous-page-button');
    };

    /**
     * @author KCupp
     * @param parameters
     * @returns {CoreMessager.button}
     */
    buildNextPageButton = (parameters) => {
        return new window.core_messager.button(parameters.nextButtonText, function (buttonElement) {
            replaceWithLoader(buttonElement);
            let formData = {
                gridId: parameters.id,
                gridPageId: parameters.page,
                subPeriodNo: parameters.subPeriodNo
            };
            if (typeof parameters.taskListId !== 'undefined') {
                formData.taskListId = parameters.taskListId;
            }
            if (typeof parameters.gridClass !== 'undefined') {
                formData.gridClass = parameters.gridClass;
            }
            processJSON('AHJGrid', formData, 'displayNextGridPage', {
                callBack: function () {
                    removeLoader(buttonElement.id);
                    scrollElementToPosition('.modal', 0);
                }
            });
        }, 'primary', false, false, null, 'grid-next-page-button');
    };

    /**
     * @author KCupp
     * @param parameters
     * @returns {CoreMessager.Button}
     */
    buildCompleteGridButton = (parameters) => {
        return new window.core_messager.button(parameters.completeButtonText, async function (buttonElement) {
            let formData = {
                gridId: parameters.id,
                gridPageId: parameters.page,
                subPeriodNo: parameters.subPeriodNo
            };
            if (typeof parameters.taskListId !== 'undefined') {
                formData.taskListId = parameters.taskListId;
            }
            replaceWithLoader(buttonElement);
            await processJSON('AHJGrid', formData, 'completeGrid', {
                callBack: function () {
                    window.core_messager.closeModal();
                }
            });
        }, 'info', false, parameters.complete, parameters.completeButtonPopover, 'grid-complete-button');
    };

    /**
     * @author KCupp
     * @param {string} gridId
     */
    initializeCharts = (gridId) => {};
}
