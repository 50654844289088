import React, {useEffect, useState} from 'react';
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import {HandleTranslation, SetupTranslations} from "../redux/translations/translator";
import { setFocus } from "../utilities/keyboardNavigation";
import { exists} from "../utilities/helpers";
import parse from "html-react-parser/index";
import Skeleton from "react-loading-skeleton";
import {usePage} from "../redux/app/navigation";
import {getCurrentRoutes} from "../redux/app/routes";
import {buildMenuPath, getMenuItemState, setMenuItemState, useMenu} from "../redux/app/menus";
import {parsePath} from "../legacy/ajaxActions";
import "./MainNav.scss";

const translationDomain = 'main_navigation';

const MainNav = (props) => {
    const translations = SetupTranslations(translationDomain);
    const page = usePage();
    let currentPathURL = parsePath(page.currentPath).url;
    let routes = getCurrentRoutes();
    const [currentTab, setCurrentTabState] = useState('home');
    const [submenuExpanded, setSubmenuExpanded] = useState(false);
    const [{data, isLoading, isError,version:menuVersion,id:menuId}] = useMenu({version:'v1',id:'main'});
    const selectedClass = 'menu-item-selected';
    const selectedChildClass = 'child-menu-item-selected';
    let childSelected = '';

    const internalHandleTranslation = (key, defaultContent = '') => {
        let translatedValue = defaultContent;
        if (exists(translations, `${translationDomain}.${key}`)) {
            translatedValue = parse(translations[translationDomain][key]);
        }
        return translatedValue;
    };

    // set current tab and update menu state in redux
    const setCurrentTab = (elId) => {
        if(currentTab !== null){
            setMenuItemState({isOpen:false},menuVersion,menuId,currentTab);
        }
        setCurrentTabState(elId);
        setMenuItemState({isOpen:true},menuVersion,menuId,elId);
    }
    // keyboard nav - restore focus to parent menu item
    const onExitedOverlayTrigger = (elId) => {
        setSubmenuExpanded(false);
    }
    const onEnteredOverlayTrigger = (args) => {
        if(args && args.parentElement) setSubmenuExpanded(args.parentElement.id);
        setFocus(args);
    }
    const generateNavigationAction = (menuItem={}) => {
        if(menuItem.customJs) return new Function(menuItem.customJs);

        let url = [menuItem.path];
        if(menuItem.action){
            if(url.indexOf("?") < 0) url.push('?');
            url.push('&ax_xmlaction='+menuItem.action);
        }

        return ()=> navigation.followPath(url.join(""),menuItem.submissionData);
    }
    // set document title
    const site_title = HandleTranslation('site_title', 'Asset Health: Your Health Is Your Most Valuable Asset', translationDomain);
    useEffect(() => {
        if(typeof currentPathURL === "string"){
            let routeKey = currentPathURL.split('/')[1];
            if(!routeKey) routeKey = 'home';
            const route = routes[routeKey];
            let label = "";
            if (route && route.label) label = route.label;
            const routeTranslation = "site_title_route_" + label;
            const site_title_route_label = internalHandleTranslation(routeTranslation, label);
            document.title = site_title_route_label + " - " + site_title;
        }
    }, [currentPathURL]);

    const placement = 'bottom';
    return (
        <>
            {isError && <div>Something went wrong ...</div>}
            {(props.skeleton || isLoading) ?
                <Skeleton width={1140} height={48}/>
                : (data && data.menu && data.menu.items && Object.keys(data.menu.items).length > 0 &&
                    <div id="main-menu-top">
                        <Nav id="dynamic-main-menu-top" className="v7 dynamic-main-menu-top-row" variant="pills"
                             defaultActiveKey="/">
                            <div className="top-nav-container">
                                {Object.entries(data.menu.items).map(([id, item], key) => {
                                    let pathURL = item.path? parsePath(item.path).url : item.id;
                                    let selected = (currentPathURL === pathURL) ? selectedClass : '';
                                    let isChildSelected = false;
                                    let navigationAction = generateNavigationAction(item);

                                    return (item.path ?
                                            <Nav.Item key={key}
                                                      id={item.id}
                                                      className={`menu-item-text ${selected}`}>
                                                <a onClick={() => {
                                                    setCurrentTab(item.id);
                                                    navigationAction();
                                                    setFocus('sub-menu-container', true);
                                                }}
                                                   className={`nav-link item`}
                                                   tabIndex={0}
                                                   role={"button"}>
                                                    {internalHandleTranslation('navigation_' + item.id, item.title)}
                                                </a>
                                            </Nav.Item> :

                                            <OverlayTrigger
                                                show={item.state && item.state.isOpen}
                                                // show={currentTab === item.id}
                                                trigger="click"
                                                key={key}
                                                placement={placement}
                                                rootClose={true}
                                                onEntered={onEnteredOverlayTrigger}
                                                onExit={() => onExitedOverlayTrigger(item.id)}
                                                container={() => {
                                                    return document.querySelector(`#${item.id}`);
                                                }}
                                                overlay={
                                                    <Popover id={`popover-positioned-${placement}`}
                                                             style={{overflowX: "auto", maxWidth: "none"}}
                                                             onBlur={(e) => {
                                                                 if (!(e.relatedTarget && e.relatedTarget.classList.contains('submenu'))) setCurrentTab(null); // close submenu
                                                                }
                                                             }
                                                    >
                                                        <Popover.Content style={{padding: "0"}}>
                                                            <CardGroup>
                                                                {Object.entries(item.children).map(([childId, child], childKey) => {
                                                                    //iterate over the children of each main menu item.
                                                                    const childPathURL = child.path ? parsePath(child.path).url : child.id;
                                                                    if (currentPathURL === childPathURL) {
                                                                        isChildSelected = true;
                                                                        childSelected = selectedChildClass;
                                                                    } else {
                                                                        childSelected = '';
                                                                    }
                                                                    let childNavigationAction = generateNavigationAction(child);
                                                                    return <Card className={"main-nav-card"}
                                                                                 key={key + "_" + childKey}>
                                                                        <div className="main-nav-card-container">
                                                                            <Nav.Item id={child.id}>
                                                                                <a
                                                                                    onClick={() => {
                                                                                        setCurrentTab(null);
                                                                                        setFocus('sub-menu-container', true);
                                                                                        childNavigationAction();
                                                                                    }}
                                                                                    className={`nav-link item${selected} submenu`}
                                                                                    tabIndex={0}>
                                                                                    <Card.Img variant="top"
                                                                                              className={"w-100"}
                                                                                              alt={`Image for ${child.title}`}
                                                                                              src={child.image ? child.image : `${process.env.REACT_APP_ASSETS_URL_OVERRIDE}/apps/resources/self_service/images/v5/resources/newsletters/AHTalk_PreviewImage_April_2020.jpg`}/>
                                                                                    <Card.Body>
                                                                                        <Card.Title
                                                                                            className={childSelected}>{internalHandleTranslation('navigation_' + child.id, child.title)}</Card.Title>
                                                                                    </Card.Body>
                                                                                </a>
                                                                            </Nav.Item>
                                                                        </div>
                                                                    </Card>
                                                                })}
                                                            </CardGroup>
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                            >
                                                <Nav.Item key={key} id={item.id}
                                                          className={`menu-item-text ${isChildSelected ? selectedClass : ''}`}>
                                                    <a onClick={() => setCurrentTab(item.id)}
                                                       className={`nav-link item${selected}`}
                                                       tabIndex={0}
                                                       role={"button"}
                                                       aria-expanded={item.state && item.state.isOpen}
                                                       // aria-expanded={submenuExpanded === item.id}
                                                       data-toggle={"popover"}>
                                                        {internalHandleTranslation('navigation_' + item.id, item.title)}
                                                        <span
                                                            className="md-icons menu-item-icon-has-children">arrow_drop_down</span>
                                                    </a>
                                                </Nav.Item>
                                            </OverlayTrigger>
                                    )
                                })}
                            </div>
                        </Nav>
                    </div>
                )}
        </>
    );
};

export default MainNav;
