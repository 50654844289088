import { processJSON } from "../ajaxActions";

export const exportWhyWallPageGlobals = () => {
    window['whyWall'] = new WhyWallController();
}

class WhyWallController {
    postImageUpload = async () => {
        await processJSON('AHJWhyWall', {}, 'postImageUpload');
    };
}
