import { ppcController } from "../lib/ppcController";
import { dateTools } from "../dateTools";
import { strpad } from "../utils";

export const exportTileInitFunctions = () => {
    window['initWellnessPlanCountdown'] = initWellnessPlanCountdown;
    window['initDocUploadTile'] = initDocUploadTile;
}

/*
    Tile initialization functions
 */


export const initWellnessPlanCountdown = () => {
    let today = dateTools.getCurrentTime();
    let utcCurrentTime = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes(), today.getUTCSeconds());
    let stopDateVal = window.jQuery('#wellness_plan_countdown_stop_date').val();
    let stopDateCorrected = stopDateVal.replace(/-/g, "/");
    let stopDate = new Date(stopDateCorrected);
    let utcStopTime = Date.UTC(stopDate.getUTCFullYear(), stopDate.getUTCMonth(), stopDate.getUTCDate(), stopDate.getUTCHours(), stopDate.getUTCMinutes(), stopDate.getUTCSeconds());
    let timeLeft = utcStopTime - utcCurrentTime;
    let timeLeftSec = Math.floor(timeLeft / 1000);
    let timeLeftMin = Math.floor(timeLeftSec / 60);
    let timeLeftHrs = Math.floor(timeLeftMin / 60);
    let timeLeftDays = Math.floor(timeLeftHrs / 24);

    let startDateVal = window.jQuery('#wellness_plan_countdown_start_date').val();
    let startDateCorrected = startDateVal.replace(/-/g, "/");
    let startDate = new Date(startDateCorrected);
    let utcStartTime = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
    let timeTotal = utcStopTime - utcStartTime;
    let timeTotalSec = Math.floor(timeTotal / 1000);
    let timeTotalMin = Math.floor(timeTotalSec / 60);
    let timeTotalHrs = Math.floor(timeTotalMin / 60);
    let timeTotalDays = Math.floor(timeTotalHrs / 24);

    let daysElem = window.jQuery('#days_display strong');
    let daysData = window.jQuery('#ppc_days');
    let hrsElem = window.jQuery('#hours_display strong');
    let hrsData = window.jQuery('#ppc_hours');
    let minElem = window.jQuery('#minutes_display strong');
    let minData = window.jQuery('#ppc_minutes');
    let secElem = window.jQuery('#seconds_display strong');
    let secData = window.jQuery('#ppc_seconds');

    if (timeLeft < 0) {
        daysElem.html("00");
        daysData.attr('data-percent', 0);
        hrsElem.html("00");
        hrsData.attr('data-percent', 0);
        minElem.html("00");
        minData.attr('data-percent', 0);
        secElem.html("00");
        secData.attr('data-percent', 0);

        let ppc_days_obj = new ppcController(window.jQuery('#ppc_days'));
        let ppc_hrs_obj = new ppcController(window.jQuery('#ppc_hours'));
        let ppc_min_obj = new ppcController(window.jQuery('#ppc_minutes'));
        let ppc_sec_obj = new ppcController(window.jQuery('#ppc_seconds'));

        ppc_days_obj.setRotation();
        ppc_hrs_obj.setRotation();
        ppc_min_obj.setRotation();
        ppc_sec_obj.setRotation();

        return false;
    }

    let wellnessPlanCountdownTimer = setInterval(function () {

        if (!window.jQuery('#wellness_plan_countdown_stop_date').length) {
            clearInterval(wellnessPlanCountdownTimer);
            return false;
        }

        let today = dateTools.getCurrentTime();
        let utcCurrentTime = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
        let stopDateVal = window.jQuery('#wellness_plan_countdown_stop_date').val();
        let stopDateCorrected = stopDateVal.replace(/-/g, "/");
        let stopDate = new Date(stopDateCorrected);
        let utcStopTime = Date.UTC(stopDate.getFullYear(), stopDate.getMonth(), stopDate.getDate(), stopDate.getHours(), stopDate.getMinutes(), stopDate.getSeconds());
        let timeLeft = utcStopTime - utcCurrentTime;
        let timeLeftSec = Math.floor(timeLeft / 1000);
        let timeLeftMin = Math.floor(timeLeftSec / 60);
        let timeLeftHrs = Math.floor(timeLeftMin / 60);
        let timeLeftDays = Math.floor(timeLeftHrs / 24);
        let startDateVal = window.jQuery('#wellness_plan_countdown_start_date').val();
        let startDateCorrected = startDateVal.replace(/-/g, "/");
        let startDate = new Date(startDateCorrected);
        let utcStartTime = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        let timeTotal = utcStopTime - utcStartTime;
        let timeTotalSec = Math.floor(timeTotal / 1000);
        let timeTotalMin = Math.floor(timeTotalSec / 60);
        let timeTotalHrs = Math.floor(timeTotalMin / 60);
        let timeTotalDays = Math.floor(timeTotalHrs / 24);

        timeLeftSec = timeLeftSec % 60;
        if (timeLeftSec < 10) timeLeftSec = strpad(timeLeftSec, 2, 'left', 0);
        timeLeftMin = timeLeftMin % 60;
        if (timeLeftMin < 10) timeLeftMin = strpad(timeLeftMin, 2, 'left', 0);
        timeLeftHrs = timeLeftHrs % 24;
        if (timeLeftHrs < 10) timeLeftHrs = strpad(timeLeftHrs, 2, 'left', 0);
        if (timeLeftDays < 10) timeLeftDays = strpad(timeLeftDays, 2, 'left', 0);

        timeTotalSec = timeTotalSec % 60;
        timeTotalMin = timeTotalMin % 60;
        timeTotalHrs = timeTotalHrs % 24;

        let secLeftPercentage = Math.round((timeLeftSec / 60) * 100);
        let minLeftPercentage = Math.round((timeLeftMin / 60) * 100);
        let hrsLeftPercentage = Math.round((timeLeftHrs / 24) * 100);
        let daysLeftPercentage = Math.round((timeLeftDays / timeTotalDays) * 100);

        if (timeLeft > 0) {
            daysElem.html(timeLeftDays);
            daysData.attr('data-percent', daysLeftPercentage);
            hrsElem.html(timeLeftHrs);
            hrsData.attr('data-percent', hrsLeftPercentage);
            minElem.html(timeLeftMin);
            minData.attr('data-percent', minLeftPercentage);
            secElem.html(timeLeftSec);
            secData.attr('data-percent', secLeftPercentage);
        } else if (timeLeft < 0) {
            clearInterval(wellnessPlanCountdownTimer);
            daysElem.html("00");
            daysData.attr('data-percent', 0);
            hrsElem.html("00");
            hrsData.attr('data-percent', 0);
            minElem.html("00");
            minData.attr('data-percent', 0);
            secElem.html("00");
            secData.attr('data-percent', 0);
            return false;
        }

        var ppc_days_obj = new ppcController(window.jQuery('#ppc_days')),
            ppc_hrs_obj = new ppcController(window.jQuery('#ppc_hours')),
            ppc_min_obj = new ppcController(window.jQuery('#ppc_minutes')),
            ppc_sec_obj = new ppcController(window.jQuery('#ppc_seconds'));

        ppc_days_obj.setRotation();
        ppc_hrs_obj.setRotation();
        ppc_min_obj.setRotation();
        ppc_sec_obj.setRotation();

    }, 1000);
}

export const initDocUploadTile = (tile) => {
    window.Upload.init(tile);
}
