/*
    function isValidDateString()

    returns:
        true if str is a valid date string, false otherwise
 */
export const isValidDateString = (str='invalid') => {
    if(typeof str !== 'string') return false;
    let d = new Date(str);
    return d instanceof Date && !isNaN(d);
}
/*
    function isNumericString()

    returns:
        true if str is a string containing only a number, false otherwise
 */
export const isNumericString = (str) => {
    if (typeof str !== "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}
/*
    function dateToMilliseconds()

    args:
        val - a date string, Date object, or # of milliseconds
    returns:
        # of milliseconds in equivalent date object, or null upon failure
 */
export const dateToMilliseconds = (val) => {
    if(val instanceof Date) return val.valueOf();
    if(typeof val === "string")
    {
        if(isNumericString(val)) return parseInt(val);
        if(!isValidDateString(val)) return null;
        return new Date(val).valueOf();
    }
    if(Number.isInteger(val)) return val;
    return null;
}

/*
    function dateToString()

    args:
        val - a date string, Date object, or # of milliseconds
    returns:
        ISO string representation of date, or null upon failure
 */
export const dateToString = (val) => {

    let ms = dateToMilliseconds(val);
    if(ms !== null) return new Date(ms).toISOString();
    return null;
}

export const calendarStartDate = (seed=null) => {
    let subtractNoDays;
    const date = seed !== null ? new Date(seed) : new Date();
    const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const startDayOfWeek = monthStartDate.getDay();

    switch(startDayOfWeek) {
        case 6: //saturday
            subtractNoDays = 6;
            break;
        case 5: //friday
            subtractNoDays = 5;
            break;
        case 4: //thursday
            subtractNoDays = 4;
            break;
        case 3: //wednesday
            subtractNoDays = 3;
            break;
        case 2: //tuesday
            subtractNoDays = 2;
            break;
        case 1: //monday
            subtractNoDays = 1;
            break;
        default: //sunday
            subtractNoDays = 0;
    }

    return new Date(date.getFullYear(), date.getMonth(), 1 -subtractNoDays);

}

export const calendarEndDate = (seed = null) => {
    let addNoDays;
    const date = seed !== null ? new Date(seed) : new Date();
    const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const endDayOfWeek = monthEndDate.getDay();

    switch(endDayOfWeek) {
        case 6: //saturday
            addNoDays = 0;
            break;
        case 5: //friday
            addNoDays = 1;
            break;
        case 4: //thursday
            addNoDays = 2;
            break;
        case 3: //wednesday
            addNoDays = 3;
            break;
        case 2: //tuesday
            addNoDays = 4;
            break;
        case 1: //monday
            addNoDays = 5;
            break;
        default: //sunday
            addNoDays = 6;
    }

    return new Date(date.getFullYear(), date.getMonth() + 1, +addNoDays);

}

export const smallCalendarActiveStartDate = (activeTime) => {
    let subtractNoDays;
    const date = new Date(activeTime);
    const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const startDayOfWeek = monthStartDate.getDay();

    switch(startDayOfWeek) {
        case 6: //saturday
            subtractNoDays = 6;
            break;
        case 5: //friday
            subtractNoDays = 5;
            break;
        case 4: //thursday
            subtractNoDays = 4;
            break;
        case 3: //wednesday
            subtractNoDays = 3;
            break;
        case 2: //tuesday
            subtractNoDays = 2;
            break;
        case 1: //monday
            subtractNoDays = 1;
            break;
        default: //sunday
            subtractNoDays = 0;
    }
    subtractNoDays = 0;  // PRM-13180 - show only events for selected month
    return new Date(date.getFullYear(), date.getMonth(), 1 -subtractNoDays);
};

export const smallCalendarActiveEndDate = (activeTime) => {
    let addNoDays;
    const date = new Date(activeTime);
    const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, -1);
    const endDayOfWeek = monthEndDate.getDay();

    switch(endDayOfWeek) {
        case 6: //saturday
            addNoDays = 0;
            break;
        case 5: //friday
            addNoDays = 1;
            break;
        case 4: //thursday
            addNoDays = 2;
            break;
        case 3: //wednesday
            addNoDays = 3;
            break;
        case 2: //tuesday
            addNoDays = 4;
            break;
        case 1: //monday
            addNoDays = 5;
            break;
        default: //sunday
            addNoDays = 6;
    }
    addNoDays = 0; // PRM-13180 - show only events for selected month
    return new Date(date.getFullYear(), date.getMonth() + 1, +addNoDays);

};

/**
 * Converts time zone from the legacy format into the event service format
 * @param legacyCode
 */
export const convertLegacyTimeZone = (legacyCode) => {
    let timeZone = undefined;

    switch (legacyCode) {
        case 'UTC-05 (EST)':
            timeZone = 'US/Eastern';
            break;
        case 'UTC-06 (CST)':
            timeZone = 'US/Central';
            break;
        case 'UTC-07 (MST)':
            timeZone = 'US/Mountain';
            break;
        case 'UTC-08 (PST)':
            timeZone = 'US/Pacific';
            break;
        case 'UTC-11 (SST)':
            timeZone = 'US/Samoa';
            break;
        case 'UTC-10 (HAST)':
            timeZone = 'US/Hawaii';
            break;
        case 'UTC-09 (AKST)':
            timeZone = 'US/Alaska';
            break;
        case 'UTC-04 (AST)':
            timeZone = 'Canada/Atlantic';
            break;
        case 'UTC+10 (ChST)':
            timeZone = 'Australia/Sydney';
            break;
        case 'UTC+12':
            timeZone = 'Pacific/Fiji';
            break;
        case 'UTC-03:30 (NST)':
            timeZone = 'Canada/Newfoundland';
            break;
        default:
            timeZone = 'US/Eastern';
            break;
    }

    return timeZone;
}


