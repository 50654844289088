import React from 'react';
import PropTypes from 'prop-types';
import Jdenticon from "../components/avatar/Jdenticon";
import Gravatar from 'react-gravatar';
import { processLogout } from "../legacy/utils";
import {HandleTranslation, SetupTranslations} from "../redux/translations/translator";
import Skeleton from "react-loading-skeleton";
import {empty} from "../utilities/helpers";
import "./Logout.scss"


const translationDomain = 'main_navigation';
const Logout = (props) => {
    const translations = SetupTranslations(translationDomain);
    let welcomeText = HandleTranslation('welcome','Welcome, ', translationDomain);
    let logOutText  = HandleTranslation('log_out','Log Out', translationDomain);
    const { user={}, gravatar, jdenticon={} } = props

    if(!user || props.skeleton) {
        return  <div className="d-flex flex-row">
                <div className="mx-3" style={{width:"150px"}}>
                    <Skeleton count={3}/>
                </div>
                <div>
                    <Skeleton width={48} height={48}/>
                </div>
            </div>
    }

    const { firstName='', prefName='', eeId,  emailAddress } = user;

    return (
        <div className="d-flex flex-row">
            <div className="mx-3 logout-section-full-width">
                <div><b>{welcomeText}</b> {!empty(prefName) ? prefName : firstName}</div>
                <a className="text-danger clickable"  onClick={processLogout} tabIndex={0}>
                     <b>{logOutText}</b>
                </a>
            </div>
            <div className="avatar-section">
                {gravatar.enabled && emailAddress
                    ?   <Gravatar email={emailAddress} {...gravatar} />
                    :   null
                }
            </div>
        </div>
    );
}

Logout.defaultProps = {
    user: {},
    gravatar: { enabled: false },
    jdenticon: { size: '48', value: 'Snake' }
}

Logout.propTypes = {
    user: PropTypes.object.isRequired,
    gravatar: PropTypes.object,
    jdenticon: PropTypes.object
}

export default Logout;
