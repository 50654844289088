// Function used to create the custom button dropdown list
export const initButtonDropdowns = (id) => {
    let parent = window.jQuery('#' + id);
    parent.find('.btn-group').each(function () {
        let btnGroup = window.jQuery(this);
        let button = btnGroup.find('button:first-child');
        // Define the click event for each button.  Needs to set the appropriate classes to show the active button
        button.click(function () {
            parent.find('.btn-primary').removeClass('btn-primary');
            button.addClass('btn-primary');
            window.jQuery('#process :input[name="' + (parent.attr('data-tab-type') === 'main' ? 'selected_tab' : 'selected_subtab') + '"]').val(button.attr('id'));
        });


        let selectedItem = null;
        btnGroup.find('li').each(function (index) {
            let item = window.jQuery(this);
            if (item.attr('data-selected') === "1" || index === 0) {
                selectedItem = item;
            }
            item.click(function () {
                // Define the click event for the child item.  It should set the parent button attributes and fire the button's click event.
                button.text(item.text());
                button.attr('data-value', item.attr('data-value'));
                button.trigger('click');
            });
        });
        // Set initial text and value of the button by looking which one of the child items is selected.
        if (selectedItem != null) {
            button.attr('data-value', selectedItem.attr('data-value'));
            button.text(selectedItem.text());
        }
    });

    // Fire the click event on the active button or the first button if none are active
    let activeButton = parent.find('.btn-primary');
    if (activeButton.length === 0) {
        parent.find('.btn:first').trigger('click');
    }
    else {
        parent.find('.btn-primary').trigger('click');
    }
}
