import React, {useEffect, useState} from 'react';
import IncentiveBarComponent from "./IncentiveProgressBarComponents/IncentiveBarComponent";
import IncentiveRatioComponent from "./IncentiveProgressBarComponents/IncentiveRatioComponent";
import IncentiveDetailsComponent from "./IncentiveProgressBarComponents/IncentiveDetailsComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import {useAccordionToggle} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useRequest} from "../util";
import {exists} from "../utilities/helpers";
import {HandleTranslation, SetupTranslations} from "../redux/translations/translator";
import "./IncentiveProgressBar.scss";
import {getIncentiveAsync, useIncentive} from "../redux/incentive/incentiveProgress";


/**
 *  todo: Translate
 *      Title
 *      Hide/show tags
 *      Details
 *      View details button
 */
const IncentiveProgressBar = (props) => {
    const translationDomain = 'main_navigation';
    SetupTranslations(translationDomain);
    let progressBarEnabled = false;
    let displayMode = null;
    let displaySettings = null;
    let tasksToDisplay = null;
    let pointsTaskList = 0;
    let hideIcon = HandleTranslation('incentive_progress_bar_hide', 'Hide', translationDomain);
    let showIcon = HandleTranslation('incentive_progress_bar_show', '=', translationDomain);
    let incentiveBarReturned =  (<></>);
    let rightSectionReturned =  (<></>);
    let ratioSection =          (<></>);
    let detailsSection =        (<></>);
    let showHideSection =       (<></>);
    let enableShowHide =        false;
    let enableRatioSection =    false;
    let enableViewDetails =     false;
    let titleTextSize =         3;
    let barComponentSize =      6;
    let ratioSectionSize =      3;
    let pageRoot = document.getElementById('root');
    const incentiveProgressText = HandleTranslation('incentive_progress_text', 'Incentive Progress: ', translationDomain);

    const data = useIncentive();
    useEffect(() => {
        if(Object.keys(data).length === 0) {
            getIncentiveAsync();
        }
    }, [data]);
    if(data){
        packageConfiguration = data;
        displayMode =          exists(packageConfiguration,'mode') ? packageConfiguration.mode : displayMode;
        displaySettings =      exists(packageConfiguration,'settings') ? packageConfiguration.settings : displaySettings;
        tasksToDisplay =       exists(packageConfiguration,'tasks_to_display') ? packageConfiguration.tasks_to_display : tasksToDisplay;
        pointsTaskList =       exists(packageConfiguration, 'points_task_list') ? packageConfiguration.points_task_list : pointsTaskList;
        progressBarEnabled =   exists(packageConfiguration, 'incentiveBarEnabled') ? packageConfiguration.incentiveBarEnabled : progressBarEnabled;
        displaySettings =      exists(displaySettings, 'show_hide_option.enabled') ? displaySettings : false;
        if(!progressBarEnabled || !displaySettings){
            pageRoot.classList.add("no-incentive-progress-bar");
        } else {
            if(pageRoot.classList.contains("no-incentive-progress-bar")){
                pageRoot.classList.remove("no-incentive-progress-bar");
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
            }

        }
    }

    if(progressBarEnabled){
        if(displaySettings){
            //console.log(displaySettings);
            enableShowHide =     displaySettings.show_hide_option.enabled === 'Y';
            enableRatioSection = displaySettings.value_display.enabled === 'Y' || displaySettings.circular_fill.enabled === 'Y';
            enableViewDetails =  displaySettings.view_details_button.enabled === 'Y';
            /**
             * Control Size of Col
             */
            if(!enableRatioSection) {
                barComponentSize++;
                ratioSectionSize--;
            }
            if(!enableViewDetails) {
                barComponentSize++;
                ratioSectionSize--;
            }
            if(!enableRatioSection && !enableViewDetails){
                barComponentSize++;
                ratioSectionSize--;
            }
            /**
             * Fill in JSX
             */
            if(enableRatioSection){
                ratioSection = (
                    <IncentiveRatioComponent
                        displaySettings={displaySettings}
                        pointsTaskList= {pointsTaskList}
                    />
                );
            }
            if(enableViewDetails){
                detailsSection = (
                    <IncentiveDetailsComponent
                        displaySettings={displaySettings}
                    />
                );
            }
            if(enableShowHide){
                showHideSection = (
                    <div className="incentiveProgressBarToggle">
                        <CustomToggle
                            eventKey="incentiveProgressBarKey"
                            iconActive = {hideIcon}
                            iconInactive = {showIcon}
                        />
                    </div>
                );
            }
            if(ratioSectionSize > 0){
                rightSectionReturned = (
                    <Col sm={ratioSectionSize}>
                        <Row noGutters>
                            {ratioSection}
                            {detailsSection}
                        </Row>
                    </Col>
                );
            }
            /**
             * Build inner return element
             */
            incentiveBarReturned = (
                <Row className="incentiveProgressBarContainer" noGutters>
                    <Col sm={titleTextSize}>
                        <div className="incentiveProgressBarTitleText">
                            {incentiveProgressText}
                        </div>
                    </Col>
                    <Col sm={barComponentSize}>
                        <IncentiveBarComponent
                            displayMode=    {displayMode}
                            displaySettings={displaySettings}
                            pointsTaskList= {pointsTaskList}
                            tasksToDisplay= {tasksToDisplay}
                        />
                    </Col>
                    {rightSectionReturned}
                </Row>
            );
        }
    }

    return (
        <Accordion defaultActiveKey="incentiveProgressBarKey" style={{ backgroundColor: '#fff' }}>
            <Accordion.Collapse eventKey="incentiveProgressBarKey">
                <div>{incentiveBarReturned}</div>
            </Accordion.Collapse>
            {showHideSection}
        </Accordion>
    )
}

export default IncentiveProgressBar;

/**
 * Handles the collapse of the incentive bar
 * @param children
 * @param eventKey
 * @param iconActive
 * @param iconInactive
 * @returns {*}
 * @constructor
 */
function CustomToggle({ children, eventKey, iconActive, iconInactive }) {
    const [icon, setIcon] = useState(iconActive);
    let pageRoot = document.getElementById('root');
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        {
            if(icon === iconActive){
                setIcon(iconInactive);
                pageRoot.classList.add("no-incentive-progress-bar");

            } else {
                setIcon(iconActive)
                pageRoot.classList.remove("no-incentive-progress-bar");
            }
            
            //processActions(); can't use this since the id referenced within must exist within pagecontent-inner. Every page has its own id
        }
    );
    return (
        <Button variant="light" className={"incentive-progress-bar-btn"} onClick={decoratedOnClick}>{icon}</Button>
    );
}

let packageConfiguration = '';
