import {useState, useEffect} from "react";
import { store } from "./store";
import produce from "immer";
import app from '../setupAxios';
import {getState, resetStoreKeys, subscribe} from "./helpers";
import {DependencyMapper} from "../util";
import get from "lodash/get";

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["tours"];
const initialState = {
    summary: {},
    configs: {}
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storeTourSummary: (state, payload) =>
        produce(state, draft => {
            draft.summary = payload;
        }),
    storeTour: (state, payload) =>
        produce(state, draft => {
            const { id,tour } = payload;
            draft.configs[id] = tour;
        }),
    mergeTourSummary: (state, payload) =>
        produce(state, draft => {
            const {page,...rest} = payload;
            let item = get(draft,`summary.${page}`);
            if(item) {
                item = Object.assign({},item,rest);
                draft.summary[page] = item;
            }
        }),
    'reset.tours': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
    reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/
export const useTours = () => {
    const [state, setState] = useState(getState(storePath));
    useEffect(() => subscribe(setState,storePath), [setState]);

    useEffect(() => {
        DependencyMapper.on('dataFetchReady','getTourSummary',() => {
            if(Object.keys(state.summary).length === 0) {
                getTourSummary().catch(console.error);
            }
        });

        if (fetch === true) {
            getTourSummary().catch(console.error);
        }
    }, []);
    return state;
};
export const getTourSummary = async () => {
    try {
        const response = await app.get(`api/v1/tours`);

        if (response.status === 200) {
            storeTourSummary(response.data);
        }

        return response.data;
    }catch(e){
        console.error("Error in getTourSummary(): ",e);
    }
}

export const getTour = async (id) => {
    try {
        const response = await app.get(`${process.env.REACT_APP_API_PHP_URL}/api/v1/tours/${id}`).catch(error=>error);
        if (response.status === 200) {
            const { tour } = response.data;
            storeTour({ id,tour });
            return response.data;
        }
        return response;
    }catch(e){
        console.error("Error in getTour(): ",e);
    }
}

export const setTourStatusStarted = async (id) => {
    try {
        const response = await app.post(`${process.env.REACT_APP_API_PHP_URL}/api/v1/tours/${id}`).catch(error=>error);
        if (response.status === 200) {
            storeTourSummary(response.data);
        }
        return response.data;
    }catch(e){
        console.error("Error in getTour(): ",e);
    }
}

export const setTourStatusCompleted = async (id, eeTourActivityNo) => {
    try {
        const response = await app.patch(`${process.env.REACT_APP_API_PHP_URL}/api/v1/tours/${id}`, {
            'eeTourActivityNo': eeTourActivityNo
        }).catch(error=>error);
        if (response.status === 200) {
            storeTourSummary(response.data);
        }
        return response.data;
    }catch(e){
        console.error("Error in getTour(): ",e);
    }
}
export const mergeTourSummary = (page,state) => store.dispatch({ type: "mergeTourSummary", payload: {page,...state} });
export const storeTourSummary = response => store.dispatch({ type: "storeTourSummary", payload: response });
export const storeTour = payload => store.dispatch({ type: "storeTour", payload });
