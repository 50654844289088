import React, {useEffect, useState} from 'react';
import {Route, Switch, withRouter, useHistory, Redirect} from 'react-router-dom';
import GenericPage from "./pages/GenericPage";
import {DependencyMapper, DynComponent} from "./util";
import ErrorPage from "./pages/ErrorPage";
import {closeAllMenus} from "./redux/app/menus";
import DefaultPage from "./pages/DefaultPage";


function AppRoutes(props) {

    const {routes,skeleton} = props;
    const [pathname,setPathname] = useState(window.location.pathname);
    const history = useHistory();
    const initialPageLoadKey = 'initialPageLoad';
    const cleanupPage = ()=>{
        window.modalManager.closeAll();
        window.closeTour && window.closeTour();
        closeAllMenus();
    }
    /*
        Update key on routes to get similar but non-equivalent routes to cause rerender e.g. /custom/spirit vs /custom/mind
     */
    useEffect(()=>{
        let unlisten = ()=>{};
        if(history && history.listen){
            unlisten = history.listen((location,action)=>{
                cleanupPage();
                if(/^\/custom/i.test(history.location.pathname)){
                    if(pathname !== history.location.pathname) {
                        setPathname(history.location.pathname);
                    }
                }
            });
        }
        return unlisten;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Switch>
            {
                Object.values(routes).map((item, key) => {
                    //Dynamic Component
                    const render =
                        item.legacy
                            ? routeProps => <GenericPage page={item.id} legacy={item.legacy} {...routeProps} {...skeleton} key={pathname}/>
                            : routeProps => DynComponent(item.component,{...routeProps,...skeleton,...{key:pathname}});
                    let atts = {
                        path:item.path,
                        exact:item.exact,
                        render:render
                    };
                    return (
                        <Route
                            {...atts}
                            key={key}
                        />
                        );
                })}
            {/*Removing the redirect didn't work - because url doesn't change and U are logged in, stays on home page*/}

                <Route
                    key={'default'}
                    path={props.client_url}
                    render={routeProps => <DefaultPage {...routeProps} key={'default'}/>}
                />

            {/*<Redirect from={props.client_url} to={`#`} exact={true}/>*/}
            {DependencyMapper.completed(initialPageLoadKey) &&
                <Route
                    render={routeProps => <ErrorPage {...routeProps} errorCode={404} />}
                    key={'ErrorPage'}
                />
            }
        </Switch>
    );
}

export default withRouter(AppRoutes);
