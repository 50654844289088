import { processAjax } from "../ajaxActions";
import { replaceWithLoader, removeLoader } from "../utils";

export const exportTestPageGlobals = () => {
    window['handleTestButtonSubmit'] = handleTestButtonSubmit;
    window['handleTestButtonSubmitSA'] = handleTestButtonSubmitSA;
    window['validateTestSubmitAnswer'] = validateTestSubmitAnswer;
    window['validateTestSubmitButton'] = validateTestSubmitButton;
}

export const handleTestButtonSubmit = async (reference) => {
    var validated = false;
    validated = validateTestSubmitAnswer(reference);
    if (validated) {
        await processAjax('AHJCourse', 'test_answers', 'submitAnswer');
    }
}

//Handle the Stand Alone Test's Submit Button
export const handleTestButtonSubmitSA = async (reference) => {
    var validated = false;
    validated = validateTestSubmitAnswer(reference);
    if (validated) {
        let ref = window.jQuery(reference);
        replaceWithLoader(ref);
        await processAjax('AHJTests', 'test_answers', 'submitAnswerSA',{
            callBack: function () {
                removeLoader(ref.attr('id'));
            }
        });
    }
}

//When someone submits answer on test, make sure they have selected something.
function validateTestSubmitAnswer(reference) {
    if (window.jQuery(reference).hasClass('disabled')) {
        return false;
    }

    return true;
}

export const validateTestSubmitButton = () => {
    if (window.jQuery('#test_answers input:checkbox:checked').length !== 0 && window.jQuery('#test_answers input:checkbox:checked').length < 4) {
        window.jQuery('#test_submit_btn').removeClass('disabled');
    } else {
        window.jQuery('#test_submit_btn').addClass('disabled');
    }
}

export const validateTestSubmitButtonTrueFalse = () => {
    window.jQuery('#test_submit_btn').removeClass('disabled');
}
