import {attachmentUploader} from "../lib/attachmentUploader";
import {processAjax, processJSON} from "../ajaxActions";
import {removeLoader} from "../utils";

export const exportMessageCenterPageGlobals = () => {
    window['message_center'] = new MessageCenterController();
}

class MessageCenterController {
    constructor() {
        this.messageCenterObj = this;
        this.emptyChars = [' ', '\n', '\t'];
        this.attachment_uploader = new attachmentUploader();
    }

    isEmpty = (str) => {
        let empty = true;
        for (let i = 0; i < str.length; i++) {
            if (this.emptyChars.indexOf(str[i]) < 0) {
                empty = false;
                break;
            }
        }
        return empty;
    }

    openMailBox = async (reference, action) => {
        let elem = window.jQuery(reference);
        let data_value = elem.attr('data-value');
        //window.jQuery("#current-tab-mobile").html(data_value.charAt(0).toUpperCase() + data_value.slice(1));
        await processAjax('AHJMessageCenter', {
            'forceRefresh': 'Y',
            'mailBoxType': data_value,
            'currentMailBox': action
        }, 'initializeMessageCenter', {'maskElementId': 'mail-box-container'});
    }

    displayInbox = async () => {
        await processAjax('AHJMessageCenter', 'process', 'displayInboxMessages');
    }

    displaySent = async () => {
        await processAjax('AHJMessageCenter', 'process', 'displaySentMessages');
    }

    /*selectUserTeamInbox = (reference) => {
       let elem = window.jQuery(reference);
       let user_team_no = elem.attr('data-value');
       let recipient_name = elem.attr('data-description');
       //window.jQuery('#compose_form input[name="er_note_no"]').val(er_note_no);
       window.jQuery('#compose_form input[name="user_team_no"]').val(user_team_no);
       window.jQuery('#compose_form input[name="recipient_names"]').val(recipient_name);
       window.jQuery('.recipient_names').val(recipient_name);
       window.jQuery('#compose_form #recipient_type').val('core_team_inbox');
    }*/

    checkForNewMessages = async (button) => {
        window.jQuery(button).closest('#message_center_tab_container').find('ul li').each(function (index) {
            if (index == 0) {
                window.jQuery(this).addClass('active');
            } else {
                window.jQuery(this).removeClass('active');
            }
        });
        await processAjax('AHJMessageCenter', '', 'displayInboxMessages', {'maskElementId': 'inbox_message_list_table'});
    }

    openThread = async (thread_row) => {
        thread_row = window.jQuery(thread_row);

        window.jQuery('.thread.selected').each(function () {
            window.jQuery(this).removeClass('selected');
        });
        thread_row.addClass('selected');

        /*if(isVisibleBreakPoint('xs|sm')){
           window.jQuery('#mail-box-container').addClass('hidden-xs').addClass('hidden-sm');
        }*/

        window.jQuery('#toggle-sidebar-btn').addClass('hidden');
        window.jQuery('#clear-selected-thread-btn').removeClass('hidden');
        window.jQuery('#mail-box-container').addClass('hidden-mobile');
        window.jQuery('#message-container').removeClass('hidden-mobile');

        let json_obj = {};
        json_obj.er_note_no = thread_row.attr('data-er-note-no');
        json_obj.resource_no = thread_row.attr('data-resource-no');
        json_obj.message_source = thread_row.attr('data-message-source');
        await processJSON('AHJMessageCenter', json_obj, 'openThread', {'maskElementId': 'message-container'});
    }

    archiveThread = async (er_note_no) => {
        await processJSON('AHJMessageCenter', {er_note_no: er_note_no}, 'archiveThread', {
            'postCallBack': function () {
                processAjax('AHJMessageCenter', {
                    'forceRefresh': 'Y',
                    'currentMailBox': 'displayInboxThreads'
                }, 'initializeMessageCenter', {'maskElementId': 'mail-box-container'});
                return false;
            }
        });
    }

    restoreThread = async (er_note_no) => {
        await processJSON('AHJMessageCenter', {er_note_no: er_note_no}, 'restoreThread', {
            'postCallBack': function () {
                processAjax('AHJMessageCenter', {
                    'forceRefresh': 'Y',
                    'currentMailBox': 'displayArchivedThreads'
                }, 'initializeMessageCenter', {'maskElementId': 'mail-box-container'});
                return false;
            }
        });
    }

    clearSelectedThread = async () => {
        window.jQuery('.thread.selected').each(function () {
            window.jQuery(this).removeClass('selected');
        });
        window.jQuery('#clear-selected-thread-btn').addClass('hidden');
        window.jQuery('#toggle-sidebar-btn').removeClass('hidden');
        window.jQuery('#selected_msg_div').hide();
        window.jQuery('#no_selected_msg_div').show();
        window.jQuery('#selected_msg_div').html('');
        window.jQuery('#message-container').addClass('hidden-mobile');
        window.jQuery('#mail-box-container').removeClass('hidden-mobile');
        await processJSON('AHJMessageCenter', {}, 'clearSelectedThread');
    }

    triggerRecipientSelect = (pk) => {
        let selectize = window.jQuery('#message_center_selectize')[0].selectize;
        selectize.setValue(pk, true);
    }

    selectRecipient = (value, item) => {
        let pk = value;
        let item_value = window.jQuery(item).find('span').attr('value').split("|");
        let recipient_type = item_value[1];

        window.jQuery('#compose_form input[name="recipient_type"]').val(recipient_type);
        window.jQuery('#compose_form input[name="recipient_pk"]').val(pk);
    }

    messageContentChanged = (ta) => {
        let msgElem = window.jQuery('#compose_form input[name="message"]');
        let html = window.jQuery(ta).val();
        msgElem.val(html);
    }

    sendMessage = async (button) => {
        window.jQuery(button).prop('disabled', true); //disable Post button

        let $valid = this.validateComposeForm();
        if ($valid) {
            await this.performSend();
        }
        window.jQuery(button).prop('disabled', false); //enable Post button
    }

    validateComposeForm = () => {
        let valid = false;
        let recipients = window.jQuery('#compose_form input[name="recipient_pk"]').val();

        if (typeof (recipients) !== 'undefined' && recipients != '') {
            let subject = window.jQuery('#compose_form input[name="subject"]').val();
            let content = window.jQuery('#compose_form input[name="message"]').val();
            if (this.messageCenterObj.isEmpty(subject)) {
                window.core_messager.showAlert("The subject field cannot be empty, please enter a subject");
            } else if (this.messageCenterObj.isEmpty(content)) {
                window.core_messager.showAlert("The message field cannot be empty, please enter a message!");
            } else {
                valid = true;
            }
        } else {
            window.core_messager.showAlert("You have not selected any recipients for this message.");
        }

        return valid;
    }

    composeMessage = async (pk, elem) => {
        let recipient = pk;
        let id = window.jQuery(elem).attr('id');
        await processJSON('AHJMessageCenter', {}, 'composeMessage', {
            postCallBack: () => {
                if (typeof (recipient) !== 'undefined') {
                    this.messageCenterObj.triggerRecipientSelect(recipient);
                }
                removeLoader(id);
            }
        });
    }

    replyToMessage = async (button, event) => {
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        let message_row = window.jQuery(button).closest('.message');
        let json_obj = {}
        json_obj.er_note_no = message_row.attr('data-er-note-no');
        json_obj.recipient_pk = message_row.attr('data-recipient-pk');
        json_obj.resource_no = message_row.attr('data-resource-no');
        json_obj.resource_type = message_row.attr('data-source');

        await processJSON('AHJMessageCenter', json_obj, 'replyToMessage');
    }

    performSend = async () => {
        let messageElem = window.jQuery('#compose_form input[name="message"]');
        let newString = messageElem.val();

        while (newString.indexOf('\n') >= 0) {
            newString = newString.replace('\n', '<br/>');
        }
        messageElem.val(newString);

        await processAjax('AHJMessageCenter', 'compose_form', 'sendNoteToAdminPassByValue', {'maskElementId': 'compose_modal_content',
            postCallBack: () => {
                window.modalManager.close();
            }
        });
    }

    deleteMessage = (button) => {
        let message_row = window.jQuery(button).closest('.message');
        let pk = message_row.attr('data-resource-no');
        let source = message_row.attr('data-source');

        window.core_messager.showYesNo("Are you sure you want to permanently delete this message?", function () {
            processJSON('AHJMessageCenter', {
                'message_pk': pk,
                'message_source': source
            }, 'deleteMessage', {'maskElementId': 'selected_msg_div'});
        }, function () {
        });
    }

    updateUnreadMsgIndicators = (params) => {
        let totalUnread = typeof (params.totalUnread) !== 'undefined' ? params.totalUnread : 0;
        let display = typeof (params.display) !== 'undefined' ? params.display : 'none';

        window.jQuery('.badge-unread-messages').html(totalUnread);
        window.jQuery('.badge-unread-messages').css('display', display);

        //Set unread message badge on thread element
        if (typeof (params.er_note_no) !== 'undefined') {
            window.jQuery('#thread_' + params.er_note_no + ' .thread_unread_msgs .badge').html(totalUnread);
            window.jQuery('#thread_' + params.er_note_no + ' .thread_unread_msgs .badge').css('display', display);
        }
    }

    initAttachmentUpload = (params) => {
        this.attachment_uploader.initAttachmentUpload(params);
    }
}