import set from 'lodash/set';
import { enableES5 } from 'immer';

//IE11 compatibility with immer
enableES5();

const { createStore, combineReducers } = require("redux");

let reducers = {};
export const store = createStore(s => s,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.injectReducer = (path, reducer) => {
    set(reducers, path, reducer);
    store.replaceReducer(combineReducersRecurse(reducers));
};

function combineReducersRecurse(reducers) {
    // If this is a leaf or already combined.
    if (typeof reducers === 'function') {
        return reducers;
    }

    // If this is an object of functions, combine reducers.
    if (typeof reducers === 'object') {
        let combinedReducers = {};
        for (let key of Object.keys(reducers)) {
            combinedReducers[key] = combineReducersRecurse(reducers[key]);
        }
        return combineReducers(combinedReducers);
    }

    // If we get here we have an invalid item in the reducer path.
    throw new Error({
        message: 'Invalid item in reducer tree',
        item: reducers
    });
}
