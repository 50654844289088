import React, {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {slide as Menu} from 'react-burger-menu';
import "./QuickIconsMenu.scss"
import {useRequest} from "../util";
import {HandleTranslation, SetupTranslations} from "../redux/translations/translator";
import TourQuickIcon from "./TourQuickIcon";
import MobileAppQuickIcon from "./MobileAppQuickIcon";
import Skeleton from "react-loading-skeleton";
import {usePage, setQuickMenuOpen} from "../redux/app/navigation";
import {tabTrap,removeTabTrap} from "../utilities/keyboardNavigation";
import {parsePath} from "../legacy/ajaxActions";
import {exists} from "../utilities/helpers";
import {buildMenuPath, mergeMenuState, useMenu} from "../redux/app/menus";
import {useMediaMatches} from "../components/utils/shared";
import VideoQuickIcon from "./V8/VideoQuickIcon";

const menuVersion = 'v1', menuId = 'quick_menu';
const translationDomain = 'main_navigation';
const MyContext = React.createContext();

const MyProvider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false);
    // const page = usePage();
    const [{state:quickMenuState}]= useMenu({version:menuVersion,id:menuId});
    const mediaMatches = useMediaMatches();
    /*
        Open/Close quick menu based on store app.page.quickMenuOpen
    */
    useEffect(()=>{
        let bOpen = Boolean(quickMenuState.isOpen);
        if(bOpen !== menuOpenState) setMenuOpenState(bOpen);
    },[quickMenuState]);

    /*
        Update store app.page.quickMenuOpen based on local state
    */
    useEffect(()=>{

        let bOpen = Boolean(quickMenuState.isOpen);
        let path = 'v1.quick_menu.menu';
        if (bOpen !== menuOpenState) mergeMenuState(path, {isOpen: menuOpenState});

    },[menuOpenState]);

    return (
       <MyContext.Provider value={{
           isMenuOpen: menuOpenState,
           toggleMenu: () => setMenuOpenState(!menuOpenState),
           stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
           shouldAutoOpenTour: mediaMatches.media.large
       }}>
           {props.children}
       </MyContext.Provider>
    )
}

const MenuOpenButton = (props) => {
    let noHamburgerOption = props.noHamburger;
    const ctx = useContext(MyContext)
    return (
        <div className={`quick-icon-wrapper icon-display-count-initial ${noHamburgerOption}`} onClick={ctx.toggleMenu} id={'quick-icon-menu-launch'} tabIndex={0} role="button" aria-expanded={ctx.isMenuOpen}>
            <div className="quick-icon-container mb-1">
                <div className={`md-icons quick-icon hamburger`}>menu</div>
            </div>
            <div className="quick-icon-text">{HandleTranslation('quick_icon_view', "View All", translationDomain)}</div>
        </div>
    )
}

const QuickMenu = (props) => {
    const ctx = useContext(MyContext);
    const {data, currentPathURL} = props;

    return (
       <div id="quick-Icons-menu-hamburger" className="quick-icons-menu-hamburger quick-icon-wrapper m-0" aria-hidden={!ctx.isMenuOpen}>
           <Menu
              width={'435px'}
              right
              outerContainerId={ "quick-Icons-menu-hamburger" }
              customCrossIcon={ <i className="md-icons">close</i> }
              customBurgerIcon={false}
              isOpen={ctx.isMenuOpen}
              onStateChange={(state) => {
                  ctx.stateChangeHandler(state);
                  mergeMenuState(`${menuVersion}.${menuId}.menu`,state);
                  if(state.isOpen) tabTrap("quick-Icons-menu-hamburger");
                  else removeTabTrap("quick-Icons-menu-hamburger"); }}
           >
               {Object.entries(data.menu.items).map(([id,item], key) => {
                   /**
                    * Icons visible inside the hamburger menu
                    */
                   return (
                          <BuildQuickLinkEntity key={item.id} id={`${item.id}-quick-icon-collapsed-menu-item`} item={item} itemId={item.id} index="0" isFromOutside={false} currentPathURL={currentPathURL} />
                   )
               })}
           </Menu>
       </div>
    )
}
const QuickIconsMenu = props => {
    SetupTranslations(translationDomain);
    const [{data, isLoading, isError, state:menuState }] = useMenu({version:menuVersion, id:menuId});
let quickMenuOptions = '';
    let menuConfig = {};
    let pullOutIconExcess = 0;
    if(data) menuConfig = data.menu.menu_config;
    if(exists(menuConfig, 'options')){
        quickMenuOptions = menuConfig.options;
    }
    let page = usePage();
    let currentPathURL = parsePath(page.currentPath).url;
    let menuOptionNoHamburgerOption = '';
    if(quickMenuOptions){
        let optionsLen = quickMenuOptions.length;
        for(let i = 0; i < optionsLen; i++){
            if(quickMenuOptions[i] === 'no_hamburger_lg') menuOptionNoHamburgerOption = " d-lg-inline-block d-xl-none d-xxl-none";
        }
    }
    /*[
        "mobile_app",
        "messages",
        "support"
    ]*/

    return (
        <>
        {isError && <div>Unable to load QuickIconsMenu data</div>}
        {isLoading ? (
            <>
                    <Skeleton width={48} height={48} className={"ml-2"}/>
                    <Skeleton width={48} height={48} className={"ml-2"}/>
                    <Skeleton width={48} height={48} className={"ml-2"}/>
            </>
            ) : (data && data.menu && data.menu.items &&
                <div>


                    <div className="quick-icons-menu-right d-flex justify-content-between align-items-center flex-nowrap ml-2">
                        {Object.entries(data.menu.items).map(([id,item], key) => {
                            /**
                             * Icons visible outside of the hamburger menu
                             */
                            let visibleIcon = (<span key={item.id}/>);
                            let {visible_items: visibleItems=[]} = menuConfig;
                             if(visibleItems){ //database is configured with visible icons
                                if(visibleItems.includes(item.id)){ //The icon id we are looping over exists in the database configuration
                                    return (

                                            <BuildQuickLinkEntity key={item.id} cls="quick-icons-visible d-inline-block" item={item} itemId={item.id} index={++pullOutIconExcess} isFromOutside={true} currentPathURL={currentPathURL} itemCount={visibleItems.length}/>
                                    )
                                }
                            }
                            return visibleIcon
                        })}
                        <MyProvider>
                            <MenuOpenButton noHamburger={menuOptionNoHamburgerOption} />
                            <QuickMenu data={data} currentPathURL={currentPathURL}/>
                        </MyProvider>
                    </div>
                </div>
            )
        }
    </>
    );
}

const BuildQuickLinkEntity = (props) => {
    const {item, index, itemId, isFromOutside, currentPathURL, itemCount, cls=""} = props;
    let visibleIcon =           (<span key={item.id}/>);
    let visibleIconContent =    (<></>);
    let iconIsNavigation =      false;
    let iconIsResourceLink =    false;
    let iconIsProcessJSON =     false;
    let pathURL = item.path? parsePath(item.path).url : itemId;
    const isCurrentlySelected = (pathURL === currentPathURL) ? 'quick-icon-selected' : '';
    const ctx = useContext(MyContext);
    function openMenu(){
        if(ctx.isMenuOpen){
            ctx.toggleMenu();
        }
    }
    function closeMenu(){
        if(!ctx.isMenuOpen){
            ctx.toggleMenu();
        }
    }
    function menuAction(){
        if(!isFromOutside){
            ctx.toggleMenu();
        }
    }
    function getTabIndex(){
        if(isFromOutside) return 0;
        else if(ctx && ctx.isMenuOpen) return 0;
        return -1;
    }
    if(item.path){
        iconIsNavigation =      true;
    } else if(item.custom_js){
        iconIsResourceLink =    true; //usually this is a link to a resource
    } else if(item.action && item.class){
        iconIsProcessJSON =     true; //AHJTracking,"{...}",displayLegacyTrackingHistory
    }
    let pullOutIconClass = 'initial';//"compressed" class will hide on smaller screens
    let excessiveIcons = '';//"compressed" class will hide on smaller screens
    //this will handle setting up the links for each element
    if(index > 2) pullOutIconClass = 'compressed'; //flag icons 3+ as excess for small-screen compression
    if(itemCount > 3) excessiveIcons = 'mini-icons';
    visibleIconContent = (
        <div className={`quick-icon-wrapper icon-display-count-${pullOutIconClass} ${isCurrentlySelected} ${excessiveIcons}`}>
            <div className="quick-icon-container">
                <span className={`${item.class} quick-icon`}>{item.icon}</span>
            </div>
            <div className="quick-icon-text">
                {HandleTranslation('quick_icon_'+itemId, item.title, translationDomain)}
            </div>
        </div>
    );
    
    if(iconIsNavigation){
        visibleIcon = (
            <a onClick={() => { if(!isFromOutside){ctx.toggleMenu();} navigation.followPath(item.path); }} tabIndex={getTabIndex()} className={(isFromOutside ? "" : "d-block") + " " + cls}>
                {visibleIconContent}
            </a>
        );
    } else if(iconIsResourceLink){
        visibleIcon = (
            <span onClick={ () => {if(!isFromOutside){ctx.toggleMenu();} }} tabIndex={getTabIndex()} className={(isFromOutside ? "" : "d-block") + " " + cls}>
                {visibleIconContent}
            </span>
        );
    } else if(iconIsProcessJSON){
        visibleIcon = (
            <span onClick={ () => {if(!isFromOutside){ctx.toggleMenu();}}} tabIndex={getTabIndex()} className={(isFromOutside ? "" : "d-block") + " " + cls}>
                {visibleIconContent}
            </span>
        );
    } else {
        visibleIcon = (
            <span tabIndex={getTabIndex()} className={(isFromOutside ? "" : "d-block") + " " + cls}>
                {visibleIconContent}
            </span>
        );
    }
    if(item.id === 'tour'){

        visibleIcon = (
            <>
                <TourQuickIcon
                   visibleIconContent={visibleIconContent}
                   menuAction={menuAction}
                   closeMenu={closeMenu}
                   openMenu={openMenu}
                   tabIndex={getTabIndex()}
                   shouldAutoOpenTour={ctx?.shouldAutoOpenTour ?? false}
                   cls={(isFromOutside ? "" : "d-block") + " " + cls}
                />
            </>
        );
    } else if(item.id === 'mobile_app'){

        visibleIcon = (
           <>
               <MobileAppQuickIcon
                  visibleIconContent={visibleIconContent}
                  menuAction={menuAction}
                  tabIndex={getTabIndex()}
                  cls={(isFromOutside ? "" : "d-block") + " " + cls}
               />
           </>
        );
    } else if(item.id === 'video') {

        visibleIcon = (
            <>
                <VideoQuickIcon
                    videoItem={item}
                    visibleIconContent={visibleIconContent}
                    menuAction={menuAction}
                    tabIndex={getTabIndex()}
                    cls={(isFromOutside ? "" : "d-block") + " " + cls}
                />
            </>
        );
    }

    return visibleIcon
}

export default QuickIconsMenu;
