import React from 'react';

class GenericErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Todo send error to an error logging service. Log to console as well.
        console.error(error.name + ': ' + error.message, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="my-5">
                    <h1><b>Something went wrong.</b></h1>
                    <h3>Please try again. If the issue persists, reach out to the Asset Health Development Team</h3>
                </div>
            );
        }

        return this.props.children;
    }
}

export default GenericErrorBoundary;
