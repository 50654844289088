import { processJSON } from "../ajaxActions";

export class StandaloneTestController {
    constructor() {
        this.controller = this;
    }

    initialize = (params) => {
        this.testCode = typeof(params.testCode) !== 'undefined' ? params.testCode : null;
        this.testType = typeof(params.testType) !== 'undefined' ? params.testType : null;

        this.content = window.jQuery('#standalone-test-container');
        this.modal = this.content.closest('.modal');
        this.completeBtn = this.modal.find('#standalone-test-pass-btn');
        this.retakeBtn = this.modal.find('#standalone-test-fail-btn');
        this.closeBtn = this.modal.find('#standalone-test-close-btn');

        this.completeBtn.addClass('hidden').off('click').on('click', function(){
            this.controller.completeTest();
        });

        this.retakeBtn.addClass('hidden').off('click').on('click', function(){
            this.controller.retakeTest();
        });
    }

    setControls = (params) => {
        let id = typeof(params.id) !== 'undefined' ? params.id : '';

        if(id === 'complete'){
            this.completeBtn.removeClass('hidden');
            this.retakeBtn.addClass('hidden');
            this.closeBtn.addClass('hidden');
        }

        if(id === 'retake'){
            if(this.retakeBtn.length > 0){
                this.retakeBtn.removeClass('hidden');
                this.closeBtn.removeClass('hidden');
                this.completeBtn.addClass('hidden');
            }else{
                this.completeBtn.removeClass('hidden');
                this.retakeBtn.addClass('hidden');
                this.closeBtn.addClass('hidden');
            }
        }

    }

    completeTest = () => {
        this.close();
    }

    retakeTest = async () => {
        await processJSON('AHJTests',
            {
                retakeTest:'Y',
                testCode:this.testCode,
                testType:this.testType
            }, 'openTestModal');
    }

    close = () => {
        this.modal.modal('hide');
    }
}