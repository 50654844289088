export class DeveloperToolsController {
    displayMessage = params => {
        if(typeof(params.message) !== 'undefined'){
            console.log(params.message);
        }
    }

    displayWarning = params => {
        if(typeof(params.message) !== 'undefined'){
            console.warn(params.message);
        }
    }

    displayError = params => {
        if(typeof(params.message) !== 'undefined'){
            console.error(params.message);
        }
    }
}
