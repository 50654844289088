import { get } from "lodash";
import {store} from "./store";

/**********************************************************************************************************************
 REDUCER HELPERS
 **********************************************************************************************************************/
export const getState = (path) => get(store.getState(),path);

// returns unsubscribe function for clean-up when used in hook
export const subscribe = (f,path) => {
    let lastState = getState(path);
    return store.subscribe(
        () => lastState !== getState(path) && f((lastState = getState(path)))
    );
};
// reset all or specific keys under the storePath where this reducer function is called
export const resetStoreKeys = (state,draft,initialState,payload) => {
    if(Array.isArray(payload) && payload.length > 0){
        // reset specific keys
        payload.forEach(key=> {
            if(typeof draft[key] !== 'undefined') draft[key] = initialState[key];
        });
    }
    else {
        // reset all keys
        Object.keys(draft).forEach(key => {
            draft[key] = initialState[key];
        });
    }
}