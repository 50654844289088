import React from 'react';
import cloneDeep from "lodash/cloneDeep";
import { languages } from "../../utilities/languages";
import _ from "lodash";
/*
    // Example video slot config:

{
  "src": "public/self_service/web/videos/general/VGS Intro-720p-201027.1.mp4",
  "tracks": [{
    "en_US": {
      "src": "public/self_service/web/videos/general/vtt/en_US/VGS%20Intro-720p-201027.1.mp4.vtt"
    }
  }],
  "controls": {
    "skip": {
      "enabled": true,
      "forward": 5,
      "backward": 5
    },
    "captions": false,
    "playback-speed": [
      ".5",
      "1",
      "1.5,",
      ". 2"
    ]
  }
}


 */
export default (props) => {

    const { Config: props_config } = props;
    const config = cloneDeep(props_config);

    // if(config && config.src && !/http/.test(config.src)) config.src = process.env.REACT_APP_API_PHP_URL + '/' + config.src; //HACK for development

    let language = 'en_US';
    let languageConfig = languages()[language];

    const { tracks=[] } = (config ?? {});

    return (
        <>
            {config && config.src &&
                <video style={{ width: "100%" }} controls src={config.src}>
                    {tracks &&
                        _.map(tracks, (track, key) => {
                            const { src='' } = track[language];
                            return <track label={languageConfig.label} kind="subtitles" srcLang={languageConfig.id} src={src} key={key}/>
                        })
                    }
                </video>
            }
        </>
    );
};
