/*
 * IE 11 Polyfills
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import './polyfills/closest';

import { NavigationController, processAjax, processJSON } from "./legacy/ajaxActions";
import { exportCoreMessagerGlobals } from "./legacy/core_messager";
import {exportAhGlobals, initHttpsAccess} from "./legacy/assethealth";
import { exportUtilsGlobals } from "./legacy/utils";
import { exportPageGlobals } from "./legacy/pages";
import { exportLibGlobals } from "./legacy/lib";
import { exportDynamicLayoutGlobals } from "./legacy/dynamicLayout";
import { ModalManager } from "./legacy/components/modalManager";
import { exportPopoverGlobals, PopoverManager} from "./legacy/components/popoverManager";
import { SelfServiceController } from "./controllers/selfService";
import { dateTools } from "./legacy/dateTools";
import { Log } from './log/ConsoleLogger';

Log.debug('Logger initialized');

if (typeof (window.reactGlobals) === 'undefined') {
    window.reactGlobals = {};
}



window.processAjax = processAjax;
window.processJSON = processJSON;
window.navigation = new NavigationController();
window.dateTools = dateTools;
window.selfService = new SelfServiceController();
window.modalManager = new ModalManager();
window.popoverManager = new PopoverManager();

exportCoreMessagerGlobals();
exportPopoverGlobals();
exportAhGlobals();
exportUtilsGlobals();
exportPageGlobals();
exportLibGlobals();
exportDynamicLayoutGlobals();
initHttpsAccess();

/*
 * Since we need to load the React build on all pages of the site but only perform a
 * react render on the home page, we need a custom event so the rest of the application
 * knows when the React build has been loaded.
 */

const event = new Event('ui-build-loaded');
Log.info('Dispatch ui-build-loaded event');

window.dispatchEvent(event);
