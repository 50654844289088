export class SegmentedAnimation {
    constructor(element, startingProgressPercentage) {
        /**
         * @author KCupp
         * @param element
         * @param startPercentage
         * @param stopPercentage
         * @param {function} callback
         * @constructor
         */
        this.AnimationRange = function(element, startPercentage, stopPercentage, callback){
            this.minPercentage = 0;
            this.maxPercentage = 100;
            this.startPercentage = this.minPercentage;
            this.stopPercentage = this.maxPercentage;
            this.callback = callback;
            this.element = null;

            if(typeof(element) === 'undefined'){
                throw "An animation range must have an element!";
            } else {
                this.element = element;
            }

            if(typeof(startPercentage) !== 'undefined'){
                this.startPercentage = startPercentage;
            }

            if(typeof(stopPercentage) !== 'undefined'){
                this.stopPercentage = stopPercentage;
            }


            /**
             *
             * @param startPercentage
             * @returns {TaskListProgressBar.AnimationRange}
             */
            this.setStartPercentage = function(startPercentage){
                this.startPercentage = Math.max(this.minPercentage, startPercentage);
                if(this.startPercentage > this.stopPercentage){
                    this.stopPercentage = this.startPercentage;
                }
                return this;
            };

            /**
             * @author KCupp
             * @param stopPercentage
             * @returns {TaskListProgressBar.AnimationRange}
             */
            this.setStopPercentage = function(stopPercentage){
                this.stopPercentage = Math.min(this.maxPercentage, stopPercentage);
                if(this.startPercentage > this.stopPercentage){
                    this.startPercentage = this.stopPercentage;
                }
                return this;
            };

            this.setStartPercentage(startPercentage);
            this.setStopPercentage(stopPercentage);

            /**
             * @author KCupp
             */
            this.tick = function(overallPercentage){
                let percentageWindow = (this.stopPercentage - this.startPercentage);
                let relativePercentage = this.minPercentage;
                if(percentageWindow > 0) {
                    //Get a relative percentage from startPercentage to stopPercentage, and bound between minPercentage and maxPercentage
                    relativePercentage = Math.min(this.maxPercentage, Math.max(this.minPercentage, (Math.min(this.stopPercentage, Math.max(this.startPercentage, overallPercentage)) - this.startPercentage) / percentageWindow * 100));
                } else if (overallPercentage >= this.startPercentage) {
                    relativePercentage = this.maxPercentage;
                }
                this.callback(this.element, relativePercentage);
            }
        };

        /**
         * @type {Array}
         */
        this.ranges = [];

        this.animationEnabled = true;
        this.progressPercentage = 0;
        this.defaultAnimationTime = 4000;
        this.animationTime = this.defaultAnimationTime;
        this.previousAnimationTime = this.animationTime;
        this.element = null;
        this.easing = 'swing';

        /*
         * Error Strings
         */
        this.ERROR_INVALID_ANIMATION_RANGE = 'Invalid animation range!';

        /*
        * Initialization
          _____       _ _   _       _ _          _   _
         |_   _|     (_) | (_)     | (_)        | | (_)
           | |  _ __  _| |_ _  __ _| |_ ______ _| |_ _  ___  _ __
           | | | '_ \| | __| |/ _` | | |_  / _` | __| |/ _ \| '_ \
          _| |_| | | | | |_| | (_| | | |/ / (_| | |_| | (_) | | | |
         |_____|_| |_|_|\__|_|\__,_|_|_/___\__,_|\__|_|\___/|_| |_|

         */


        /**
         * @author KCupp
         * @param element
         * @param startingProgressPercentage
         */
        this.initialize = function (element, startingProgressPercentage) {
            this.element = window.jQuery(element);
            this.enableAnimation();
            if (typeof (startingProgressPercentage) === 'undefined') {
                startingProgressPercentage = this.element.attr('data-progress');
            }
            if (typeof (startingProgressPercentage) === 'string') {
                startingProgressPercentage = parseFloat(startingProgressPercentage);
            }

            if (typeof (startingProgressPercentage) !== 'number') {
                startingProgressPercentage = 0;
            }

            this.element.prop('value', '0');

            this.disableAnimation();
            this.setProgressPercentage(startingProgressPercentage);
            this.enableAnimation();
        };


        /*
         * Get / Set
           _____      _         __   _____      _
          / ____|    | |       / /  / ____|    | |
         | |  __  ___| |_     / /  | (___   ___| |_
         | | |_ |/ _ \ __|   / /    \___ \ / _ \ __|
         | |__| |  __/ |_   / /     ____) |  __/ |_
          \_____|\___|\__| /_/     |_____/ \___|\__|

         */


        /**
         * @author KCupp
         * @returns {TaskListProgressBar}
         */
        this.enableAnimation = function () {
            if (!this.animationEnabled) {
                if (this.previousAnimationTime === 0) {
                    this.previousAnimationTime = this.defaultAnimationTime;
                }
                this.animationTime = this.previousAnimationTime;
                this.animationEnabled = true;
            }
            return this;
        };

        /**
         * @author KCupp
         * @returns {TaskListProgressBar}
         */
        this.disableAnimation = function () {
            if (this.animationEnabled) {
                this.previousAnimationTime = this.animationTime;
                this.animationTime = 0;
                this.animationEnabled = false;
            }
            return this;
        };

        /**
         * @author KCupp
         * @returns {boolean}
         */
        this.isAnimationEnabled = function () {
            return this.animationEnabled;
        };

        /**
         * @author KCupp
         * @param {int} milliseconds
         * @returns {TaskListProgressBar}
         */
        this.setAnimationTime = function (milliseconds) {
            if (this.isAnimationEnabled()) {
                this.animationTime = milliseconds;
            }
            this.previousAnimationTime = milliseconds;
            return this;
        };

        /**
         * @author KCupp
         * @returns {number|int}
         */
        this.getAnimationTime = function () {
            return this.animationTime;
        };

        /**
         * @author KCupp
         * @param {string} easing
         */
        this.setEasing = function (easing) {
            this.easing = easing;
        };

        /**
         * @author KCupp
         * @returns {string}
         */
        this.getEasing = function () {
            return this.easing;
        };

        /**
         * @author KCupp
         * @param progressPercentage
         */
        this.setProgressPercentage = function (progressPercentage) {
            this.progressPercentage = progressPercentage;
            this.animate();
            return this;
        };

        /**
         * @author KCupp
         * @returns {number}
         */
        this.getProgressPercentage = function () {
            return this.progressPercentage;
        };

        /**
         * @author KCupp
         */
        this.clearRanges = function () {
            this.ranges = [];
            return this;
        };

        /**
         * @author KCupp
         * @param ranges
         */
        this.setRanges = function (ranges) {
            if (Array.isArray(ranges)) {
                for (let i = 0; i < ranges.length; i++) {
                    this.addRange(ranges[i]);
                }
            } else {
                throw this.ERROR_INVALID_ANIMATION_RANGE;
            }
            return this;
        };

        /**
         * @author KCupp
         * @param range
         * @returns {SegmentedAnimation}
         */
        this.addRange = function (range) {
            if (range instanceof this.AnimationRange) {
                this.ranges.push(range);
            } else {
                throw this.ERROR_INVALID_ANIMATION_RANGE;
            }
            return this;
        };

        /**
         * @author KCupp
         * @returns {Array}
         */
        this.getRanges = function () {
            return this.ranges;
        };


        /*
         * Animation
                         _                 _   _
             /\         (_)               | | (_)
            /  \   _ __  _ _ __ ___   __ _| |_ _  ___  _ __
           / /\ \ | '_ \| | '_ ` _ \ / _` | __| |/ _ \| '_ \
          / ____ \| | | | | | | | | | (_| | |_| | (_) | | | |
         /_/    \_\_| |_|_|_| |_| |_|\__,_|\__|_|\___/|_| |_|


         */


        /**
         * @author KCupp
         */
        this.animate = function () {
            let segmentedAnimation = this;
            //Now we animate the hidden element
            window.jQuery(this.element).stop(true, true).animate({
                'value': this.progressPercentage / 100
            }, {
                progress: function (animation, progress) {
                    segmentedAnimation.animationTick(animation, progress * 100);
                },
                duration: this.animationTime,
                easing: this.easing
            });
        };

        /**
         * @author KCupp
         * @param animation
         * @param progress
         */
        this.animationTick = function (animation, progress) {
            for (let i = 0; i < this.ranges.length; i++) {
                this.ranges[i].tick(parseFloat(this.element.prop('value')) * 100);
            }
        };

        //Initialize this object!
        this.initialize(element, startingProgressPercentage);
    }
}