import React from 'react';
import { useHistory } from 'react-router-dom';
import './errorPage.scss';

export const ErrorComponent = (props) => {

    return (
        <>
            <div className={'errorComponent row'}>
                <div className={'errorCodeArea col-12 col-lg-4'}>
                    <div className={'errorCodeTitle'}>{props.errorCode > 0 && "Error Code"}</div>
                    <div className={'errorCode'}>{props.errorCode > 0 && props.errorCode}</div>
                </div>
                <div className={'errorTextArea col-12 col-lg-8'}>
                    <div className={'errorTitle'}>{props.title}</div>
                    <div className={'errorText'}>{props.errorText}</div>
                    <div className={'buttonArea'}>
                        {
                            props.buttons.map((btn,index) => {
                                return <button className={"btn " + (btn.className ?? "btn-primary")} onClick={btn.click} key={index}>{btn.text}</button>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={'errorImageArea'}>
                <img {...props.imageAtts} className={'errorImage'}/>
            </div>
        </>
    )
}

export default  (props) => {

    const history = useHistory();

    const defaultButtons = [{
        text:"Go Back",
        click: ()=> history.goBack()
    }];

    const { errorCode = 0, buttons = defaultButtons } = props;

    const getTitle = () => {
        if(errorCode >= 400 && errorCode < 500){
            return 'Hmm...';
        }
        if(errorCode >= 500 && errorCode < 600){
            return 'Hmm...';
        }
        return 'Houston we have a problem.';
    }
    const getErrorText = () => {
        if(errorCode >= 400 && errorCode < 500){
            return "We can't find the page you're looking for.";
        }
        if(errorCode >= 500 && errorCode < 600){
            return "A server error occurred - we'll try to fix that.";
        }
        return '';
    }
    const getImageAtts = () => {
        if(errorCode >= 400 && errorCode < 500){
            return {
                src:`${process.env.REACT_APP_ASSETS_URL_OVERRIDE}/public/self_service/web/images/errors/404_CodeTear_Cropped-01.png`,
                alt:"Image of torn page",
                "aria-label":"An image depicting a torn page to indicate a software error",
            }
        }
        if(errorCode >= 500 && errorCode < 600){
            return {
                src:`${process.env.REACT_APP_ASSETS_URL_OVERRIDE}/public/self_service/web/images/errors/404_CodeTear_Cropped-01.png`,
                alt:"Image of torn page",
                "aria-label":"An image depicting a torn page to indicate a software error",
            }
        }
        return {
            src:`${process.env.REACT_APP_ASSETS_URL_OVERRIDE}/public/self_service/web/images/errors/404_CodeTear_Cropped-01.png`,
            alt:"Image of torn page",
            "aria-label":"An image depicting a torn page to indicate a software error",
        }
    }

    const title = getTitle();
    const errorText = getErrorText();
    const imageAtts = getImageAtts();

    return (
        <>
            <div id="sub-menu-container" tabIndex={-1}>
                <div id="sub-menu" className={`col-12 col-sm-12 no-padding pull-none inline-block valign-center`}/>
                <div id="sub-menu-right" className={`col-12 col-sm-12 col-md-6 no-padding pull-none inline-block valign-center`}/>
            </div>
            <div id="pagecontent-inner" aria-live={"polite"} style={{backgroundColor:"white",minHeight:"618px"}}>
                <ErrorComponent
                    title={title}
                    errorText={errorText}
                    errorCode={errorCode}
                    imageAtts={imageAtts}
                    buttons={buttons}
                />
            </div>
        </>
    );
};

