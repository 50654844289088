import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import { processActions } from "../legacy/ajaxActions";
import app from "../setupAxios";
import {maskElement} from "../components/utils/maskElement";
import {initDynamicLayout, resizeWidgets} from "../legacy/dynamicLayout";
import PageContentSkeleton from "../components/skeletons/PageContentSkeleton";
import {DependencyMapper, DynComponent} from "../util";
import {exists} from "../utilities/helpers";
import {setLoadingPage, storeCurrentPath} from "../redux/app/navigation";
import GenericErrorBoundary from "../errorsBoundaries/GenericErrorBoundary";
import registry from "../components/registry";

export default  (props) => {
    const { page, history={},match={} } = props;
    const { className, action, formData, options } = setAjaxParams(props, history);
    const { maskElementId=null} = options;
    const [fetchDataSkeleton, setFetchDataSkeleton]  = useState(true);
    const [readyToPortal, setReadyToPortal] = useState(false);
    const [portals, setPortals] = useState([]);
    const response = useRef(null);

    let portalActions = [];

    storeCurrentPath(match.url);

    const getResponse = () => {
        return response.current;
    }
    const setResponse = (data) => {
        response.current = data;
    }

    useEffect(()=>{
        let resp = getResponse();
        if(resp !== null && fetchDataSkeleton === false){
            if(typeof(resp.data.response) !== 'undefined') {
                let actions = JSON.parse(resp.data.response).filter((action, index) => {
                    let isPortalAction = action.type && action.type === 'portals';
                    if(isPortalAction && typeof(action.data) != 'undefined') portalActions = portalActions.concat(action.data);
                    return !isPortalAction;
                });
                processActions(actions, options);

                setReadyToPortal(true);
                setPortals(portalActions);
                initDynamicLayout();
            }
            setResponse(null);
            setLoadingPage(null);
        }
    },[fetchDataSkeleton]);

    useEffect(() => {

        maskElement({id: maskElementId});
        const fetchData = async () => {
            setLoadingPage(page);
            const r = await app.get(`/api/v1/legacy/page/${page}`, {
                params: {
                    class: className,
                    action,
                    formData
                },
                withCredentials: true
            });
            setResponse(r);
            setFetchDataSkeleton(false);
        }
        DependencyMapper.on('dataFetchReady', `page.${page}`,()=>{
            setFetchDataSkeleton(true);
            fetchData();
        },true);
        window.addEventListener('resize', resizeWidgets);

        return () => {
            window.removeEventListener('resize', resizeWidgets);
        }
    }, [page]);

    function setAjaxParams(props, history) {
        const { legacy={} } = props;
        let { className, action, formData={}, options={} } = legacy;

        //navigation overrides
        action  = exists(history, `location.state.action`) ? history.location.state.action : action;
        formData  = exists(history, `location.state.submissionData`) ? history.location.state.submissionData : formData;
        options = exists(history, `location.state.options`) ? history.location.state.options : options;
        return {
            className,
            formData,
            action,
            options
        }
    }
    function createPortals() {
        if(portals.length === 0) return null;

        return (
            <>
                {portals.map(config => {
                    const portalContainer = document.getElementById(config.containerId);
                    if(!portalContainer) return null;
                    const registration = registry[config.component];
                    if(!(registration && registration.path)) return null;
                    return ReactDOM.createPortal(DynComponent(registration.path,Object.assign({},config.props,{portalContainer})), portalContainer);
                })}
            </>
        )
    }

    return (
    <GenericErrorBoundary>
        {(props.skeleton || fetchDataSkeleton) &&
        <PageContentSkeleton page={page} />
        }
        {!(props.skeleton || fetchDataSkeleton) &&
        <div id="sub-menu-container" tabIndex={-1}>
            <div id="sub-menu" className={`col-12 col-sm-12 no-padding pull-none inline-block valign-center`}/>
            <div id="sub-menu-right"
                 className={`col-12 col-sm-12 col-md-6 no-padding pull-none inline-block valign-center`}/>
        </div>
        }
            <div id="pagecontent-inner" key={page} aria-live={"polite"}/>
            {readyToPortal && portals &&
            createPortals()
            }
    </GenericErrorBoundary>
    );
};

