import { resolveContext } from "../utils";

export class attachmentUploader {
    constructor() {
        this.selector = '.doc-upload';
        this.filesCache = {};
        this.uploadTotalSize = 0;
        this.uploaderObj = this;
    }

    initAttachmentUpload = (params) => {
        this.selector = params.selector !== undefined ? params.selector : this.selector;
        let $container = window.jQuery(this.selector);
        let $upload_drop = $container.find('.upload_drop');
        let $file_form = $container.find('form');
        let $filename_input = $file_form.find('input[type="text"]:visible');
        let $browse_button = $file_form.find('.btn-browse');
        let $progress_bar = $container.find('.progress > .progress-bar');
        let $send_button = window.jQuery('.modal').find('.btn-send');
        let $cancel_button = window.jQuery('.modal').find('.btn-cancel');
        let $upload_button = window.jQuery('.modal').find('.btn-upload');
        let validationCallBack = function(){ return true };
        let successCallBack = function(){};
        let failCallBack = function(){};
        let validation_params = {};
        if(typeof(params.validation_function) !== 'undefined'){
            validationCallBack = resolveContext(params.validation_function);
            validation_params = typeof(params.validation_params) !== 'undefined' ? params.validation_params : {};
        }

        if(typeof(params.successCallBack) !== 'undefined'){
            successCallBack = resolveContext(params.successCallBack);
        }

        if(typeof(params.failCallBack) !== 'undefined'){
            failCallBack = resolveContext(params.failCallBack);
        }

        let uploaderObj = this;
        $container.fileupload({
            dataType: 'json',
            autoUpload: false,
            singleFileUploads: true,
            sequentialUploads: true,
            replaceFileInput: false,
            dragover: function(e, data) {
                e.preventDefault();
                if(!window.dropZoneTimeout) {
                    $upload_drop.fadeTo(250, 0.75).css('line-height',$upload_drop.height()+'px');
                } else {
                    clearTimeout(window.dropZoneTimeout);
                }
                window.dropZoneTimeout = setTimeout(function() {
                    window.dropZoneTimeout = null;
                    $upload_drop.fadeTo(250, 0, function(){window.jQuery(this).hide();});
                }, 100);
            },
            drop: function(e, data) {
                e.preventDefault();
            }
        });
        let uploadTotal = 0;

        $container.find('form.doc-upload-form input[type="file"]').fileupload({
            dataType: 'json',
            autoUpload: false,
            singleFileUploads: true,
            sequentialUploads: true,
            replaceFileInput: false,
            add: function(e, data) {  // per file added to queue
                // Reset display elements
                $progress_bar.closest('.progress').css('visibility', 'hidden');
                $upload_button.css('display', 'none');

                // Front-end limit selected files
                let acceptFileTypes = new RegExp($file_form.find('input[name="acceptFileTypes"]').val(), 'i');
                let maxFileSize = parseInt($file_form.find('input[name="maxFileSize"]').val());
                for(let i = 0; i < data.files.length; i++) {
                    if(!acceptFileTypes.test(data.files[i].name)) {
                        window.core_messager.initModal({message:$container.find('.UPLOAD_ERR_FILE_EXTENSION, .UPLOAD_ERR_DEFAULT').eq(0).html()});
                        return false;
                    }
                    if(typeof(data.files[i].size) !== 'undefined' && data.files[i].size > maxFileSize) {
                        window.core_messager.initModal({message:$container.find('.UPLOAD_ERR_FORM_SIZE, .UPLOAD_ERR_DEFAULT').eq(0).html()});
                        return false;
                    }
                }

                $progress_bar.closest('.progress').css('visibility', 'visible');
                for(let i=0; i<data.files.length; i++){
                    let attachmentId = uploaderObj.generateAttachmentID();
                    let full_filename = data.files[i].name.replace(/\\/g,'/').replace(/.*\//,'');
                    let filename = full_filename.length > 30 ? window.jQuery.trim(full_filename).substring(0,26).trim(this) + '...' : full_filename;
                    let size = uploaderObj.formatFileSize(data.files[i]['size']);
                    window.jQuery('#uploaded_docs_container').append('<span id="attachment_'+attachmentId+'" class="attachment upload border-0" data-uid="'+attachmentId+'" title="'+full_filename+'"><span class="md-icons image icon-36 align-middle mr-2"></span><span class="filename">'+filename+'</span><span class="filesize">'+size+'</span><span class="remove-attachment md-icons highlight_remove align-middle clickable"></span>');
                    window.jQuery('#attachment_'+attachmentId+' .remove-attachment').on('click', function(){uploaderObj.removeAttachment('attachment_'+attachmentId);})
                    uploaderObj.filesCache[attachmentId] = data.files[i];
                }

                // Calculate uploadTotal
                uploadTotal = uploaderObj.calculateUploadSize();

                $send_button.css('display','none');
                $upload_button.removeClass('hidden');
                $upload_button.css('display', '').unbind('click').bind('click', function() {
                    $upload_button.css('disabled', 'true');
                    $progress_bar.addClass('progress-bar-striped active');
                    $browse_button.addClass('disabled');

                    //custom validation function to prevent submission
                    let $valid = validationCallBack(validation_params);
                    if($valid){
                        data.submit();
                    }
                });
            },
            submit: function(e, data) {  // AJAX submit
                let formData = {};
                $file_form.find('input[name]').not('[type="file"]').each(function() {
                    let $input = window.jQuery(this);
                    let $value = $input.val();
                    if(typeof($value) !== 'undefined' && $value != ''){
                        formData[$input.attr('name')] = $input.val();
                    }
                });

                data.formData = formData;
                data.files = uploaderObj.setFiles();
            },
            progressall: function(e, data) {  // AJAX total upload progress
                let percent = Math.floor(data.loaded*100/data.total);
                $progress_bar.width(percent+'%').html(percent+'%');
            },
            done: function(e, data) {  // AJAX success
                $progress_bar.removeClass('progress-bar-primary progress-bar-striped active');
                for(let i = 0; i < data.files.length; i++) {  // look for PHP failures
                    if(typeof(data.result.files) === 'undefined' || typeof(data.result.files[i].error) !== 'undefined') {
                        $progress_bar.addClass('progress-bar-danger');
                        $browse_button.removeClass('disabled');
                        window.core_messager.initModal({message:$container.find('.' + data.result.files[i].error + ', .UPLOAD_ERR_DEFAULT').eq(0).html()});
                        failCallBack(e, data);
                        return;
                    }
                }
                $progress_bar.addClass('progress-bar-success');
                successCallBack(e, data);

                $upload_button.css('disabled', '');
                $browse_button.removeClass('disabled');


                //uploader cleanup before closing
                uploaderObj.filesCache = {};
                $container.fileupload('destroy');
                $container.find('form.doc-upload-form input[type="file"]').val('');
                $container.find('form.doc-upload-form input[type="file"]').fileupload('destroy');
                window.modalManager.close();
            },
            fail: function(e, data) {  // AJAX fail
                $progress_bar.removeClass('progress-bar-primary active').addClass('progress-bar-danger');
                $browse_button.removeClass('disabled');
                window.core_messager.initModal({message:$container.find('.UPLOAD_ERR_DEFAULT').eq(0).html()});
                failCallBack(e, data);
            }
        });

        this.calculateUploadSize = function(){
            uploadTotal = 0;
            for(let i = 0; i < uploaderObj.filesCache.length; i++) {
                if(typeof(uploaderObj.filesCache[i].size) !== 'undefined') {
                    uploadTotal += uploaderObj.filesCache[i].size;
                } else {
                    uploadTotal += 1;
                }
            }

            return uploadTotal;
        }

        this.formatFileSize = function(bytes){
            if (typeof bytes !== 'number') {
                return '';
            }
            if (bytes >= 1000000000) {
                return (bytes / 1000000000).toFixed(2) + ' GB';
            }
            if (bytes >= 1000000) {
                return (bytes / 1000000).toFixed(2) + ' MB';
            }
            return (bytes / 1000).toFixed(2) + ' KB';
        }

        this.removeAttachment = function(attachment_id){
            let attachment = window.jQuery('#'+attachment_id);
            let uid = attachment.attr('data-uid');

            attachment.fadeOut();
            attachment.remove();
            delete uploaderObj.filesCache[uid];

            if(this.getFilesCacheLength() <= 0){
                $upload_button.css('display','none').unbind('click');
                $progress_bar.closest('.progress').css('visibility', 'hidden');
                $send_button.css('display','');
            }
        }

        this.generateAttachmentID = function(){
            while(true){
                //let randomNumber = Math.floor(Math.random() * (4 - 0 + 1)).toString();
                let uid = ("0000" + ((Math.random() * Math.pow(36, 4)) | 0).toString(36)).slice(-4);
                if(uploaderObj.filesCache.hasOwnProperty(uid)){
                    continue;
                }

                return uid;
            }
        }

        /* Returns an array of File Objects. This format is required by the plugin */
        this.setFiles = function(){
            let files = [];
            for (let key in uploaderObj.filesCache) {
                if (uploaderObj.filesCache.hasOwnProperty(key)) {
                    files.push(uploaderObj.filesCache[key]);
                }
            }

            return files;
        }

        this.getFilesCacheLength = function(){
            let total = 0;
            for (let key in uploaderObj.filesCache) {
                if (uploaderObj.filesCache.hasOwnProperty(key)) {
                    total++;
                }
            }

            return total;
        }
    }
}
