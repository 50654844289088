import {useState, useEffect} from "react";
import { store } from "../store";
import produce from "immer";
import axios from "axios";
import {getState, resetStoreKeys, subscribe} from "../helpers";
import { setRefreshTokenTimer, getFormattedTimeout} from "../../auth";
import {DependencyMapper} from "../../util";


/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["auth"];
const initialState = {
    accessToken: null,
    refreshTimeoutHandler: null,
    timeout: getFormattedTimeout()
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storeTimeoutHandler: (state, payload) =>
        produce(state, draft => {
            draft.refreshTimeoutHandler = payload;
        }),
    storeTimeout: (state, payload) =>
        produce(state, draft => {
           draft.timeout = payload.timeout;
        }),
    storeAccessToken: (state, payload) =>
        produce(state, draft => {
            const { jwt } = payload;
            draft.accessToken = jwt;
        }),
    'reset.auth': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
    reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/

export const storeTimeoutHandler = (handler) => {
    store.dispatch({ type: "storeTimeoutHandler", payload: handler });
}

export const storeTimeout = (handler) => {
    store.dispatch({ type: "storeTimeout", payload: handler });
}

export const useAccessToken = () => {
    const [state, setState] = useState(getState(storePath));
    useEffect(() => subscribe(setState,storePath), [setState]);
    useEffect(() => {
        //console.log('useAccessToken');
        if(!state.accessToken) {
            DependencyMapper.on(null,'getAccessToken',() => {
                getAccessToken();
            });
        }
    })
    return state;
};

export const getAccessToken = async () => {
    try {
        let state = getState(storePath);
        const response = await axios.get('api/v1/auth/jwt');
        const { jwt, expiryDuration=null } = response.data;
        if (response.status === 200 && jwt) {
            storeAccessToken({ jwt });
            storeTimeout({ timeout: getFormattedTimeout(expiryDuration) });
            setRefreshTokenTimer(state.refreshTimeoutHandler, state.timeout);
            DependencyMapper.reg("accessToken");
        }
    }catch(e){
        console.error("Error in getAccessToken(): ",e);
    }
};

export const refreshToken = async () => {
    try {
        let state = getState(storePath);
        const response = await axios.get('api/v1/auth/jwt/refresh');
        const { jwt, expiryDuration=null } = response.data;
        if (response.status === 200 && jwt) {
            storeAccessToken({ jwt });
            storeTimeout({ timeout: getFormattedTimeout(expiryDuration) });
            setRefreshTokenTimer(state.refreshTimeoutHandler, state.timeout);
        }
    } catch (e) {
        console.error("Error in refreshToken(): ",e);
    }
}

export const storeAccessToken = response => store.dispatch({ type: "storeAccessToken", payload: response });
