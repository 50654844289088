import React from 'react';
import {empty} from "../utilities/helpers";

export default (props) => {
    const { config } = props;
    const { client_logo, client_logo_corner} = config;

    let src = null;
    if(!empty(client_logo_corner)) {
        src = client_logo_corner;
    }

    if(!src && !empty(client_logo)) {
        src = client_logo;
    }

    if(!src) {
        src = 'apps/resources/admin/images/v3/green_ahw.png';
    }

    const goHome = async () => {
        await window.navigation.followPath('/home');
        window.scroll(0,0);
        return false;
    };

    return (
        <>
            <a
                aria-label="Home"
                tabIndex="0"
                style={{ cursor: "pointer" }}
                onClick={async () => await goHome()}
            >
                <img className="client-logo" alt="Company Logo" src={src} />
            </a>
        </>
    );
}

