export class AhRadioTemplate {
    getValues = (radio) => {
        let values;
        try {
            values = window.jQuery.parseJSON(radio.find('input.values').val());
        } catch (exception) {
            values = {enabledValue: 'Y', disabledValue: 'N'}
        }
        return values;
    };

    toggle = (elem) => {
        elem = window.jQuery(elem);
        let radio = elem.closest('.ah-radio');
        let button = radio.find('.btn');
        let values = this.getValues(radio);
        if (button.hasClass('on')) {
            button.removeClass('on');
            button.addClass('off');
            radio.find('input.radio-value').val(values.disabledValue);
        } else {
            button.removeClass('off');
            button.addClass('on');
            radio.find('input.radio-value').val(values.enabledValue);
        }
    }

    applyValue = (elem, value) => {
        elem = window.jQuery(elem);
        let radio = elem.closest('.ah-radio');
        let button = radio.find('.btn');
        let values = this.getValues(radio);
        if (value == 'Y') {
            button.removeClass('off').addClass('on');
            radio.find('input.radio-value').val(values.enabledValue);
        } else {
            button.removeClass('on').addClass('off');
            radio.find('input.radio-value').val(values.disabledValue);
        }
    }
}
