import {useState, useEffect} from "react";
import { store } from "../store";
import produce from "immer";
import axios from 'axios';
import app from '../../setupAxios';
import {getState, resetStoreKeys, subscribe} from "../helpers";
import {DependencyMapper} from "../../util";
import {processActions} from "../../legacy/ajaxActions";
import { processLogout } from "../../legacy/utils";
import { processAjax } from "../../legacy/ajaxActions";
import {convertLegacyTimeZone} from "../../events/util";

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["session"];
const initialState = {
    sessionTime : null,
    sessionTimeout : null,
    currentDate : null,
    deepLink : null,
    siteWizardRoutes : null,
    timezones:{},
    version:null // app version
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storeSessionData: (state, payload) =>

        produce(state, draft => {
            // Do not put PHPSSESSID into store
            draft.sessionTime =     payload.time;
            draft.sessionTimeout =  payload.timeout;
            draft.currentDate =     payload.currentDate;
            draft.deepLink =        payload.deepLink;
            draft.siteWizardRoutes =      payload.siteWizardRoutes ?? draft.siteWizardRoutes;
            draft.timezones =       payload.timezones ?? {};
            draft.version =         payload.version ? parseInt(payload.version,10) : null;
        }),
    storeSessionTimeoutRef: (state, payload) =>
        produce(state, draft => {
            draft.timeoutRef =     payload;
        }),
    storeRefreshSessionTimeoutRef: (state, payload) =>
        produce(state, draft => {
            draft.refreshSessionTimeoutRef =     payload;
        }),
    'reset.session': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
    reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/

export const usePHPSession = () => {

    const [state, setState] = useState(getState(storePath));
    const [sessionId, setSessionId] = useState(null);
    const [sessionTime, setSessionTime] = useState(null);
    const [sessionTimeout, setSessionTimeout] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);
    const [deepLink, setDeepLink] = useState(null);
    const [timezones, setTimezones] = useState({});
    const [version, setVersion] = useState(null);

    useEffect(() => subscribe(setState,storePath), [setState]);

    useEffect(() => {
        const getSessionContext = async () => {
            const response = await app.get('/api/v1/session');
            if (response.status === 200) {
                const { PHPSESSID=null, time:sessionTime=null, timeout:sessionTimeout=null, currentDate=null, deepLink=null, timezones={}, version=null } = response.data;
                setSessionId(PHPSESSID);
                setSessionTime(sessionTime);
                setSessionTimeout(sessionTimeout);
                setCurrentDate(currentDate);
                setDeepLink(deepLink);
                setTimezones(timezones);
                setVersion(parseInt(version,10));

                storeSessionData(response.data);

                DependencyMapper.reg("sessionId");

                if(state.timeoutRef === undefined)
                {
                    // kickoff the session expiration timer
                    _refreshSessionTimer(false);
                }
            }
        }
        getSessionContext();

        return () => {
            resetSession();
        }
    }, []);

    return {sessionId, sessionTime, sessionTimeout, currentDate, deepLink, timezones, version};
}

export const storeSessionData = response => store.dispatch({ type: "storeSessionData", payload: response });
export const storeSessionTimeoutRef = timeoutRef => store.dispatch({ type: "storeSessionTimeoutRef", payload: timeoutRef });
export const storeRefreshSessionTimeoutRef = timeoutRef => store.dispatch({ type: "storeRefreshSessionTimeoutRef", payload: timeoutRef });
export const resetSession = () => store.dispatch({ type: "reset.session", payload: null });

//public method for refreshing session. refreshes after small delay to allow multiple calls to refreshSessionTimer to only trigger 1 api call
export const refreshSessionTimer = () => {

    const refreshDelay = 5; // seconds until actual session refresh occurs
    const state = getState(storePath);

    let refreshSessionTimeoutRef = state.refreshSessionTimeoutRef;
    if(refreshSessionTimeoutRef) clearTimeout(refreshSessionTimeoutRef);
    storeRefreshSessionTimeoutRef(setTimeout(_refreshSessionTimer,refreshDelay * 1000));
}
// not for export - actually refresh the session and restart the session timeout
const _refreshSessionTimer = async (onServer = true) => {
    // return false;
    const { auth={} } = store.getState();
    const { accessToken } = auth;
    if(accessToken === null) return;

    const state = getState(storePath);
    let refreshSessionTimeoutRef = state.refreshSessionTimeoutRef;
    if(refreshSessionTimeoutRef) clearTimeout(refreshSessionTimeoutRef);
    let success = !onServer;

    try{
        if(onServer === true){
            // use axios directly to avoid interceptors
            let { auth={} } = store.getState();
            let { accessToken } = auth;
            const response = await axios.get('/api/v1/session/refresh',{
                headers:{'Authorization': 'Bearer ' + accessToken}
            });

            if (response.status === 200) {
                success = true;
                const { PHPSESSID=null, time:sessionTime=null, timeout:sessionTimeout=null, currentDate=null, deepLink=null, timezones={}, version=null } = response.data;

                storeSessionData(response.data);
            }
        }
    }
    catch(e){
        if(e.response){
            if(e.response.status >= 400 && e.response.status < 500){
                // session expired or is invalid - logout
                logOutModal();
            }
            else { // some other error
                console.group("Error in _refreshSessionTimer():");
                Object.keys(e).forEach(key => console.log(key,": ",e[key]));
                console.groupEnd();
            }
        }
    }
     // success = true;
    if(success){
        let state = getState(storePath);
        if(state.timeoutRef) {
            clearTimeout(state.timeoutRef);
        }
        let timeoutRef = setTimeout(()=>sessionTimeoutModal(),(state.sessionTimeout - 60) * 1000); // show modal 60 secs b4 logout
        storeSessionTimeoutRef(timeoutRef);
    }
}
// notify user they will be logged out, but give 'stay logged in' button to refresh session
const sessionTimeoutModal = () => {

    const logOutDelay = 60; // seconds until logout is triggered automatically
    const logoutTimer = setTimeout(()=>logOutModal(),logOutDelay * 1000);
    var buttonPressed = null;

    const buttons = [{
            'type':'primary',
                'text':'Stay Logged In',
                'action':function(){
                    buttonPressed = 1;
                    // Resetting the session has been moved to the onExited event.  Too many ways to bypass clicking a button on this modal.
            }
        },
        {
            'type':'default',
                'text':'Logout',
                'action':function(){
                    buttonPressed = 2;
                    logOutModal();
            }
        }];
        const actions = [
            {
                "id":"sessionTimeoutModal",
                "type":"openModal",
                "data":{
                    "props":{
                        "size":"small",
                        "title": 'You are about to be logged out',
                        'message': 'To stay logged in click below.',
                        'class': 'timeoutModal',
                        'dismiss':false,
                        buttons,
                        backdrop:'static',
                        onExited:() => {
                            if (buttonPressed != 2) {
                                _refreshSessionTimer();
                            }
                            clearTimeout(logoutTimer);
                        }
                    }
                }
            }
        ];
        processActions(actions);
        return true;
}
// logout user with a modal notifying them that they are being logged out
const logOutModal = () => {

    window.modalManager.closeAll();
    const buttons = [];
    const actions = [
        {
            "id":"loggingOutModal",
            "type":"openModal",
            "data":{
                "props":{
                    "size":"small",
                    'message': 'Logging out',
                    'class': 'timeoutModal',
                    'dismiss':false,
                    buttons,
                    backdrop:'static'
                }
            }
        }
    ];
    processActions(actions);
    setTimeout(processLogout,1);
}
/*
    SiteWizard API Call:
        https://test.assethealth.com/api/v1/legacy/page/home?class=AHJSiteWizard&legacyPageId=events&path=%2Fevents

    params should be similar to:
    {
        legacyPageId:'events',
        path:'/events'
    }
    see also:
     session.siteWizardRoutes in redux
 */
export const getSiteWizard = async (params={}) => {
    try {
        let config  = {...params, class: "AHJSiteWizard"};
        const response = await app.get(`${process.env.REACT_APP_API_PHP_URL}/api/v1/legacy/page/home`,{params:config}).catch(error=>error);
        if(response.status === 200){
            return response.data;
        }
        return response;
    } catch (e) {
        console.log("getSiteWizard ", e);
    }
}