
const LOG_LEVEL_DEBUG = 'debug';
const LOG_LEVEL_INFO = 'info';
const LOG_LEVEL_NOTICE = 'notice';
const LOG_LEVEL_WARNING = 'warning';
const LOG_LEVEL_ERROR = 'error';

const DEFAULT_LOG_LEVEL = LOG_LEVEL_WARNING;

window['loggingEnabled'] = true;

const LOG_LEVELS = {
    debug: 0,
    info: 1,
    notice: 2,
    warning: 3,
    error: 4
};

class ConsoleLogger {
    enabled = true;
    level = LOG_LEVEL_DEBUG;

    log = (level, ...args) => {
        if(this.isEnabled() && this.checkLogLevel(level)) {
            switch(level) {
                case LOG_LEVEL_DEBUG:
                    console.debug(`%c[${LOG_LEVEL_DEBUG.toUpperCase()}]`, 'color:green', ...args);
                    break;
                case LOG_LEVEL_INFO:
                    console.log(`%c[${LOG_LEVEL_INFO.toUpperCase()}]`, 'color:lightblue', ...args);
                    break;
                case LOG_LEVEL_NOTICE:
                    console.log(`%c[${LOG_LEVEL_NOTICE.toUpperCase()}]`, 'color:blue', ...args);
                    break;
                case LOG_LEVEL_WARNING:
                    console.log(`%c[${LOG_LEVEL_WARNING.toUpperCase()}]`, 'color:orange', ...args);
                    break;
                case LOG_LEVEL_ERROR:
                    console.error(`%c[${LOG_LEVEL_ERROR.toUpperCase()}]`, 'color:red', ...args);
                    break;
                default:
                    console.log(`%c[UNKNOWN_LOG_LEVEL]`, 'color:purple', ...args);
                    break;
            }
        }
    }

    setLogLevel = (levelStr) => {
        this.level = LOG_LEVELS[levelStr] ?? LOG_LEVELS[DEFAULT_LOG_LEVEL];
    }

    checkLogLevel = (level) => {
       return LOG_LEVELS[level] >= LOG_LEVELS[this.level];
    }

    /* Enabled/disable logging wrapper functions */
    enable = () => this.enabled = true;
    disable = () => this.enabled = false;
    isEnabled = () => this.enabled === true && window['loggingEnabled'] === true;

    /* Log level wrapper functions */
    debug = (...args) => this.log(LOG_LEVEL_DEBUG, ...args);
    info = (...args) => this.log(LOG_LEVEL_INFO, ...args);
    notice = (...args) => this.log(LOG_LEVEL_NOTICE, ...args);
    warn = (...args) => this.log(LOG_LEVEL_WARNING, ...args);
    error = (...args) => this.log(LOG_LEVEL_ERROR, ...args);
}

const Log = new ConsoleLogger();
window['GlobalLog'] = Log;

export {
    Log,
    ConsoleLogger
};