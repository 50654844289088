import React from 'react';

export default () => {
    return (
        <div id="device-breakpoints">
            <div className="breakpoint device-xs d-block d-sm-none" data-value="xs" />
            <div className="breakpoint device-sm d-none d-sm-block d-md-none" data-value="sm"/>
            <div className="breakpoint device-md d-none d-md-block d-lg-none" data-value="md"/>
            <div className="breakpoint device-lg d-none d-lg-block d-xl-none" data-value="lg"/>
            <div className="breakpoint device-lg d-none d-xl-block" data-value="xl"/>
        </div>
    );
}
