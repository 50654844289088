export const languages = () => {
    return {
        'en_US': {
            'label': 'English',
            'id': 'en'
        },
        'es_US': {
            'label': 'Spanish',
            'id': 'es'
        },
        'fr_CA': {
            'label': 'French Canadian',
            'id': 'fr-ca'
        }
    };
}
