import {processJSON} from "../ajaxActions";

export class ApplicationEvents {
    /**
     * @author KCupp
     */
    initialize = async () => {
        await processJSON(
            'AHJApplicationEvents',
            {
                event: 'page_ready'
            },
            'raiseEvent'
        );
    };

    /**
     * @author KCupp
     * @param {string} event
     * @param {{}} parameters
     */
    raiseEvent = async (event, parameters) => {
        if (typeof (parameters) === 'undefined') {
            parameters = {};
        }
        await processJSON(
            'AHJApplicationEvents',
            {
                event: event,
                parameters: parameters
            },
            'raiseEvent'
        );
    };
}