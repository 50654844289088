import _ from 'lodash';
import {useLocation} from "react-router";
import { empty } from "../utilities/helpers";
import {getIncentiveAsync} from "../redux/incentive/incentiveProgress";
import {getUser} from "../redux/user";
import {store} from '../redux/store';
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

export const exportUtilsGlobals = () => {
    window['resolveContext'] = resolveContext;
    window['resolveAndCall'] = resolveAndCall;
    window['getHeight'] = getHeight;
    window['strpad'] = strpad;
    window['JSONToForm'] = JSONToForm;
    window['formToJSON'] = formToJSON;
    window['finishJSONToFormRequest'] = finishJSONToFormRequest;
    window['processLogout'] = processLogout;
    window['replaceWithLoader'] = replaceWithLoader;
    window['removeLoader'] = removeLoader;
    window['closeClosestModal'] = closeClosestModal;
    window['lcfirst'] = lcfirst;
    window['ucfirst'] = ucfirst;
    window['genericPageRefresh'] = genericPageRefresh;
    window['refreshProgressBar'] = refreshProgressBar;
    window['refreshUserData'] = getUser;
    window['scrollToId'] = scrollToId;
    window['resetReduxKey'] = resetReduxKey;
}

/**
 * Given a '.' delimited string, find the referenced variable starting in the global context
 * @author kcupp, JBonnell
 * @param path
 * @returns Function
 */
export const resolveContext = (path) => {
    let pieces = path.split('.');
    let context = window;
    let valid = true;
    for (let key in pieces) {
        if (typeof(context[pieces[key]]) != 'undefined') {
            context = context[pieces[key]];
        } else {
            valid = false;
            break;
        }
    }
    if (valid) {
        return context;
    }
}

/**
 * Given a '.' delimited string, find the referenced variable starting in the global context, and then call it (if it's a function), passing in the given parameters
 * @author kcupp, JBonnell
 * @param varString
 * @param params
 */
export const resolveAndCall = (varString, params) => {
    let varPieces = varString.split('.');
    let context = window;
    let previousContext = window;
    let valid = true;
    for (let key in varPieces) {
        if (typeof(context[varPieces[key]]) !== 'undefined') {
            previousContext = context;
            context = context[varPieces[key]];
        } else {
            valid = false;
            break;
        }
    }
    if (valid) {
        if (typeof(context) === 'function') {
            context.apply(previousContext, params);
        }
    }
    return valid;
}

export const getHeight = (el) => {
    const styles = window.getComputedStyle(el);
    const height = el.offsetHeight;
    const borderTopWidth = parseFloat(styles.borderTopWidth);
    const borderBottomWidth = parseFloat(styles.borderBottomWidth);
    const paddingTop = parseFloat(styles.paddingTop);
    const paddingBottom = parseFloat(styles.paddingBottom);
    return height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom;
}
export const getWindowDimensions = () => {
    const w = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
    )
    const h = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    )
    return { w, h }
}
/*
    Disable body scroll
 */
export const disableBody = target => {
    try {
        disableBodyScroll(target);

        // disable html scroll
        let html = document.getElementsByTagName("html").item(0);
        if(html){
            html.dataset.styleOverflow = html.style.overflow;
            html.style.overflow = "hidden";
        }

    } catch (e) {
    }
}
/*
    Enable body scroll
 */
export const enableBody = target => {
    try {
        enableBodyScroll(target);

        // enable html scroll
        let html = document.getElementsByTagName("html").item(0);
        if(html){
            html.style.overflow = html.dataset.styleOverflow;
            delete html.dataset.styleOverflow;
        }
    } catch (e) {
    }
}
export const JSONToXML = (json) => {
    let xml = "";

    _.map(json, (value, key) =>{
        if (isValidXMLNodeName(key)) {
            if (typeof(value) === 'object' && !Array.isArray(value)) {
                xml += '<' + key + '>' + JSONToXML(value) + '</' + key + '>';
            } else if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    xml += '<' + key + '><![CDATA[' + value[i] + ']]></' + key + '>';
                }
            } else {
                xml += '<' + key + '><![CDATA[' + value + ']]></' + key + '>';
            }
        } else {
            throw new Error('ERROR: input JSON cannot be converted to XML, because one or more property ids within the object are not valid node names');
        }
    });

    return xml;
}

export const isValidXMLNodeName = (text) => {
    let regexp = new RegExp('^[a-zA-Z_][a-zA-Z0-9_-]*$');
    return regexp.test(text);
}

export const strpad = (str, length, dir, padCharacter) => {
    if (typeof(str) != 'string') {
        str = '' + str;
    }
    if (typeof(padCharacter) === 'undefined') {
        padCharacter = 0;
    }
    if (typeof(dir) === 'undefined') {
        dir = 'left';
    }
    while (str.length < length) {
        switch (dir) {
            case 'left':
                str = padCharacter + str;
                break;
            case 'right':
                str += padCharacter;
                break;
            default:
                break;
        }
    }
    return str;
}

//Take a javascript object and convert it into an HTML form so that it can be submitted with processAjax
export const JSONToForm = (json) => {

    if (typeof(json.data) === 'undefined') {
        json.data = {};
    }

    let formId = 'jsonForm';
    if (typeof(json.formId) != 'undefined') {
        formId = json.formId;
    }
    let form = window.jQuery('<form id="' + formId + '"></form>');
    for (let datumName in json.data) {
        let datum = json.data[datumName];
        if (datum.indexOf('"') >= 0) {
            datum = encodeURIComponent(datum);
        }
        form.append('<input type="hidden" name="' + datumName + '" value="' + datum + '"/>');
    }
    window.jQuery('body').append(form);
    return formId;
}

//Clean up the dynamically created form used in a JSON form request
export const finishJSONToFormRequest = (formId) => {
    let form = window.jQuery('#' + formId);
    if (form) {
        form.remove();
    }
}

/* convert a form to JSON and merge with existing JSON */
export const formToJSON = (id, jsonObj, excludeEmpty) => {
    let form = window.jQuery('#' + id);
    if(typeof jsonObj === 'undefined'){
        jsonObj = {};
    }
    form.children('input, select, textarea').each(function () {
        let elem = window.jQuery(this);
        let name = elem.attr('name');
        if (excludeEmpty == undefined || (excludeEmpty === true & !empty(elem.val()))) {
            jsonObj[name] = elem.val();
        }

    });

    return jsonObj;
}

export const processLogout = () => {
    let process = window.jQuery('#process');
    process.find('#ax_action').val('AHLogout');
    process.submit();
}

export const replaceWithLoader = (elem, classesToAdd) => {
    if(typeof(window.loaderCount) === 'undefined') {
        window.loaderCount = 0;
    }

    if (typeof(classesToAdd) === 'undefined') {
        classesToAdd = '';
    }
    elem = window.jQuery(elem);
    let elemId = elem.attr('id');
    if(typeof elemId === 'undefined' || elem.id === ''){
        elemId = 'loader-'+window.loaderCount;
        elem.attr('id', elemId);
    }
    window.loaderCount++;
    var wrapperStyle =
        'position:' + elem.css('position') + ';' +
        ' left:' + elem.css('left') + ';' +
        ' right:' + elem.css('right') + ';' +
        ' top:' + elem.css('top') + ';' +
        ' bottom:' + elem.css('bottom') + ';' +
        ' margin:' + elem.css('margin') + ';' +
        ' vertical-align:middle;' +
        ' transform:' + elem.css('transform') + ';' +
        ' width:' + elem[0].getBoundingClientRect().width + 'px;' +
        ' height:' + Math.max(34, elem.outerHeight()) + 'px;' +
        ' float:' + elem.css("float");
    elem.after('<div rel="' + elemId + '" class="loader-wrapper inline-block text-center m-auto" style="' + wrapperStyle + '"><div class="inline-block vertical-center"><div class="loader ' + classesToAdd + '"/></div></div>');
    elem.hide();
    return elemId;
}

export const removeLoader = (id) => {
    let loader = window.jQuery('div.loader-wrapper[rel="' + id + '"]');
    window.jQuery('#' + loader.attr('rel')).show();
    loader.remove();
}

//If the elem passed as a parameter is inside a modal, close that modal
export const closeClosestModal = (elem) => {
    elem = window.jQuery(elem);
    let modalElem = elem.closest('.modal');
    if (modalElem.length !== 0) {
        modalElem.modal('hide');
    }
}

/**
 * Make the first character of the given string lowercase
 * @author KCupp
 * @param str {string}
 * @returns {string}
 */
export const lcfirst = (str) => {
    return typeof(str) === 'string' ? str.charAt(0).toLowerCase() + str.substr(1) : str;
}

/**
 * Make the first character of the given string uppercase
 * @param str {string}
 * @returns {string}
 */
export const ucfirst = (str) => {
    return typeof(str) === 'string' ? str.charAt(0).toUpperCase() + str.substr(1) : str;
}

export const genericPageRefresh = () => {
    let history  = window.reactGlobals.history;
    let location = window.reactGlobals.location;
    let current  = location.pathname;
    let state    = (typeof(location.state) !== 'undefined') ? location.state : null;
    refreshProgressBar();
    history.push({ pathname: '/reload' });
    history.replace(current, state);
}

export const refreshProgressBar = () => {
    getIncentiveAsync();
}

/**
 * Parses nested stringified JSON within object and reassigns it to its property
 * Returns new object
 * @param data {Object}
 * @returns {Object}
 */
export const mapObject = (data) => {
    let newObject = {};

    // creates array of values
    _.map(data, (value, prop) => {
        if (isJsonString(value)) {
            value = JSON.parse(value);
        }

        newObject[prop] = value
    });

    return newObject;
}
/**
 * Resets all or specific keys in redux store at storePath
 * Returns new object
 * @param storePath {string}
 * @param keys {array}
 * @returns void
 */
export const resetReduxKey = (storePath,keys=[]) => {
    store.dispatch({type:`reset.${storePath}`,payload: keys});
}

/**
 * @param {string} jsonString
 * @returns {boolean}
 * @author https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
 */
export const isJsonString = (jsonString) => {
    try {
        let json = JSON.parse(jsonString);
        return (json && typeof json === 'object'); // checks for situation where null (as null === 'object')
    } catch (e) {
        return false;
    }
}

/**
 *
 * @param action
 */
export const genericCallbacks = (action) => {
    let [callback, params] = action;
    let scope = null;

    let path = callback.split('.');
    if (path.length > 1) {
        scope = resolveContext(path[0]);
    }

    callback = resolveContext(callback);

    if (typeof (callback) === 'function') {
        try {
            params = JSON.parse(params);
        } catch (exception) {
            params = {};
        }
        callback.call(scope,params);
    }
}
/**
 * Duplicate href="#id" functionality on anchor tags, which stopped working with React BrowserRouter implementation
 * @param id
 */
export const scrollToId = (id) => {
    if(typeof id !== "string") return;
    id = id.trim();
    const el = document.getElementById(id.replace(/^#/,""));
    if(el) el.scrollIntoView({behavior:"smooth"});
}
