import React from "react";
import Col from "react-bootstrap/Col";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./IncentiveRatioComponent.scss";

let isCircularFillEnabled = false;
let isUnitEnabled =         false;
let isUnitLocationEnabled = false;
let isValueDisplayEnabled = false;
let isValueMaxEnabled =     false;
let unitValue =             false;
let unitLocation =          false;
let valueDisplayIcon =      false;
let taskListValue =         false;
let valueMax =              0;


/**
 * @returns {string}
 * @constructor
 * @param percentage
 * @param icon
 */
const incentiveRatioPointsFillDisplay = (percentage, icon) => {

    let ratioOutput = '';
    //"value_display_icon"
    ratioOutput = (
        <div className="circularFill">
            <CircularProgressbarWithChildren value={percentage}>
                <div className="incentiveRatioIcon">{icon}</div>
            </CircularProgressbarWithChildren>
        </div>

    );
    return ratioOutput;
}
/**
 *
 * @param isCircularFillEnabled
 * @param isValueDisplayEnabled
 * @param taskListValue
 * @param valueMax
 * @param isUnitEnabled
 * @param unitLocation
 * @param isValueMaxEnabled
 * @param unitValue
 * @returns {string}
 * @constructor
 */
const incentiveRatioPointsDisplay = (isCircularFillEnabled, isValueDisplayEnabled, taskListValue, valueMax, isUnitEnabled, unitLocation, isValueMaxEnabled, unitValue) => {
    /**
     * todo: translate unitValue
     * @type {string}
     */
    //let incentive_progress_bar_unitValue_translated = 'txt';
    let ratioContent = '';
    if(isUnitEnabled && unitLocation === 'before'){
        ratioContent = unitValue;
    }
    ratioContent = ratioContent + taskListValue;
    if(isValueMaxEnabled){
        ratioContent = ratioContent + '/';
        if(isUnitEnabled && unitLocation === 'before'){
            ratioContent = ratioContent + unitValue;
        }
        ratioContent = ratioContent + valueMax;
    }

    let icon = (<span className="md-icons ratio-display-icon">check</span>);
    let progressPercent = 0;
    if(valueMax > 0 && taskListValue > 0){
        progressPercent = (taskListValue / valueMax) * 100;
    }
    let circleFill = (
        <>
        </>
    );
    if (isCircularFillEnabled){
        circleFill = incentiveRatioPointsFillDisplay(progressPercent, icon);
    }


    return (
        <div id="incentiveProgressBarRatio">
            {circleFill}
            {isValueDisplayEnabled ?
                <>
                    {ratioContent}
                </>
                :
                <></>
            }
            {isValueDisplayEnabled && isUnitEnabled && unitLocation === 'after' ?
                <>
                    <br/>{unitValue}
                </>
                :
                <></>
            }

        </div>
    );
}


const IncentiveRatioComponent = (props) => {
    /**
     * Set Variables from API
     * */
    let ratioDisplay =  '';
    let ratioFillSize = '';
    let ratioTextSize = '';
    let ratioReturned = '';

    if(props.displaySettings !== null){
        isCircularFillEnabled =  props.displaySettings.circular_fill.enabled === 'Y';
        isValueDisplayEnabled =  props.displaySettings.value_display.enabled === 'Y';
        valueDisplayIcon =       props.displaySettings.value_display_icon.enabled === 'Y';
        isUnitEnabled =          props.displaySettings.unit.enabled === "Y";
        isUnitLocationEnabled =  props.displaySettings.unit_location.enabled === "Y"; //Not used in legacy?
        isValueMaxEnabled =      props.displaySettings.value_max.enabled === 'Y';
        if(isUnitEnabled) {
            unitValue =          props.displaySettings.unit.value;
            unitLocation =       props.displaySettings.unit_location.value;
        }
    }

    if(props.pointsTaskList !== null){
        taskListValue = props.pointsTaskList.value > 0 ? props.pointsTaskList.value : 0;
        valueMax =      props.pointsTaskList.value_max > 0 ? props.pointsTaskList.value_max : 0;
    }

    if(isCircularFillEnabled || isValueDisplayEnabled){
            ratioDisplay = incentiveRatioPointsDisplay(
                isCircularFillEnabled,
                isValueDisplayEnabled,
                taskListValue,
                valueMax,
                isUnitEnabled,
                unitLocation,
                isValueMaxEnabled,
                unitValue);
    }

    /**
     * Switch CSS Classes
     */
    ratioFillSize = (isCircularFillEnabled && isValueDisplayEnabled) ? 'incentiveRatioFillSmall' : 'incentiveRatioFillLarge';
    ratioTextSize = (isUnitEnabled && unitLocation === 'after') ? 'progressRatioTextSmall' : 'progressRatioTextLarge';

    if(isCircularFillEnabled || isValueDisplayEnabled){
        ratioReturned = (
            <Col>
                <div className={`${ratioTextSize} ${ratioFillSize}`}>
                    {ratioDisplay}
                </div>
            </Col>
        );
    }

    return ratioReturned;
}
export default IncentiveRatioComponent;
