import { processJSON, processAjax } from "../ajaxActions";
import { ucfirst, lcfirst, replaceWithLoader, removeLoader } from "../utils";
import { initializePPCs } from "../lib/ppcController";
import { circularFill } from "../lib/circularFill";
import { dateTools } from "../dateTools";
import { genericPageRefresh, refreshProgressBar } from "../utils";
import {recalculateIncentive} from "../../redux/incentive/incentiveProgress";
import { initializeAHPopover} from "../components/popoverManager";
import {TinyMceEditor} from "../lib/TinyMceEditor";

export const exportTrackingPageGlobals = () => {
    window['tracking'] = new Tracking();
    window['submitPromoCode'] = submitPromoCode;
    window['GetMiniChallengeData'] = GetMiniChallengeData;
    window['submitMiniChallengeActivity'] = submitMiniChallengeActivity;
    window['GoalReporting'] = GoalReporting;
    window['StandardReporting'] = StandardReporting;
    window['CustomReporting'] = CustomReporting;
    window['ARCResourceReporting'] = ARCResourceReporting;
    window['MiniChallengeController'] = MiniChallengeController;
    window['DocumentUploader'] = DocumentUploader;
    window['LegacyTrackingHistory'] = LegacyTrackingHistory;
}

class Tracking {
    /**
     * Creates an instance of objectName in global scope as lcfirst(objectName)
     * @author KCupp
     * @param params
     */
    factory = (params) => {
        if(typeof window[params[0]] !== 'undefined'){
            let initializeEntries;
            if(Array.isArray(params[1])){
                initializeEntries = params[1]
            }else{
                initializeEntries = [{id:null}];
            }
            if(initializeEntries.length === 0){
                initializeEntries = [{id:null}];
            }
            for(let i = 0; i < initializeEntries.length; i++) {
                let initializeEntry = initializeEntries[i];
                let variableName = lcfirst(params[0]) + (typeof(initializeEntry.id) !== 'undefined' && initializeEntry.id !== null ? ucfirst(initializeEntry.id) : '');
                window[variableName] = new window[params[0]]();
                //Call initialize from the correct context
                if (typeof(window[variableName].initialize) !== 'undefined') {
                    window[variableName].initialize(initializeEntry);
                }
            }
        }
    };
}

class GoalReporting {
    goals = null;
    futureGoals = null;
    goalIndex = null;

    initialize = (params) => {
        if(typeof(params.id) !== 'undefined'){
            this.goals = window.jQuery('#goal-'+params.id);
            this.futureGoals = window.jQuery('#goal-'+params.id+'.future-goal');
            this.beforeGoals = window.jQuery('#goal-'+params.id+'.before-goal');
            this.goalIndex = params.id;
            let goalReportingObj = this;
            let displayDateHeader = window.jQuery('#standard-reporting .standard-activity-reporting .date-header').children().length == 0;
            this.goals.each(function(){
                let goalPanel = window.jQuery(this);
                if(displayDateHeader){
                    goalPanel.find('.date-header').removeClass('d-none');
                }

                initializeAHPopover(goalPanel.find('[data-toggle="popover"]'));
                goalPanel.find('.favorite-goal').off('click').on('click', function(event){
                    let button = window.jQuery(event.delegateTarget);
                    //What should we set the favorite flag to?
                    let icon = button.find('.glyphicons');
                    icon.toggleClass('glyphicons-heart').toggleClass('line-heart');
                    processJSON('AHJTracking', {goalIndex:button.closest('.goal-reporting-card').attr('data-goal-index')}, 'toggleGoalFavoriteFlag');
                    event.preventDefault();
                });
                goalPanel.find('.delete-goal').off('click').on('click', function(event){
                    let button = window.jQuery(event.delegateTarget);
                    window.core_messager.showYesNo({
                        sourceElement:'#delete-goal-confirmation-container',
                        yes:{type:'info pull-right', text:'Yes I\'m Sure', action:function(){
                                processJSON('AHJTracking', {goalIndex:button.closest('.goal-reporting-card').attr('data-goal-index')}, 'deleteGoal', {callBack:function(){
                                        processAjax('AHJRefresh');
                                    }});
                            }},
                        no:{type:'primary-alt pull-left', text:'No Go Back'}
                    });
                    event.preventDefault();
                });
                goalPanel.find('.img-circle.previous-week').off('click').on('click', function(event){
                    let button = window.jQuery(event.delegateTarget);
                    goalReportingObj.displayPreviousWeek(button.closest('.goal-reporting-card').attr('data-goal-index'),goalPanel);
                });
                goalPanel.find('.img-circle.next-week').off('click').on('click', function(event){
                    let button = window.jQuery(event.delegateTarget);
                    goalReportingObj.displayNextWeek(button.closest('.goal-reporting-card').attr('data-goal-index'),goalPanel);
                });
                goalPanel.find('.circular-fill').each(function(){
                    let fill = window.jQuery(this);
                    window['circularFill'+fill.attr('id')] = new circularFill(fill);
                    let newProgress = fill.parent().attr('data-new-progress');
                    if(typeof newProgress !== 'undefined' && newProgress !== ''){
                        window['circularFill'+fill.attr('id')].setFillPercentage(newProgress);
                    }
                });

                let trackingObj = new StandardReporting({
                    context:'goalReporting',
                    reportingContainer:goalPanel,
                    submissionAction:'saveGoalActivities',
                    submissionCallback: function(){
                        if(window.jQuery('#journey-summary').length > 0) {
                            processJSON('AHJMissions', {type:goalPanel.attr('data-mission-type')}, 'defaultAction');
                        }
                    },
                    categoryId:goalPanel.attr('data-category-id')
                });
                trackingObj.disableInputsOutOfRange();
                //Attempt to initialize all inputs that might exist within the goal
                //TODO: If we ever allow a different type of goal reporting (not binary or text input) add an initialization function for that type, and call it here
                trackingObj.initializeDefaultInputs();
                trackingObj.initializeBinaryInputs();
                trackingObj.initializeSubmitButtons({
                    category:goalPanel.attr('data-category-id'),
                    activity:goalPanel.attr('data-activity-id')
                });
            });

            this.futureGoals.each(function(){
                let goalPanel = window.jQuery(this);
                goalPanel.find('.future-goal-overlay').off('click mouseenter mouseleave').on({
                    click: function(){
                        let elem = window.jQuery(this);
                        if(elem.hasClass('show-unlock')){
                            elem.remove(); //element has already triggered the click or mouseenter events, remove the lock element
                        }else{
                            elem.addClass('show-unlock');
                        }
                    },
                    mouseenter: function(){
                        window.jQuery(this).addClass('show-unlock');
                    },
                    mouseleave: function(){
                        window.jQuery(this).removeClass('show-unlock');
                    }
                });
            });

            this.beforeGoals.each(function(){
                let goalPanel = window.jQuery(this);
                goalPanel.find('.before-goal-overlay').off('click mouseenter mouseleave').on({
                    click: function(){
                        let elem = window.jQuery(this);
                        if(elem.hasClass('show-unlock')){
                            elem.remove(); //element has already triggered the click or mouseenter events, remove the lock element
                        }else{
                            elem.addClass('show-unlock');
                        }
                    },
                    mouseenter: function(){
                        window.jQuery(this).addClass('show-unlock');
                    },
                    mouseleave: function(){
                        window.jQuery(this).removeClass('show-unlock');
                    }
                });
            });
        }
    };

    /**
     * Show the previous week within the given goal
     * @author KCupp
     * @param goalIndex
     * @param goalPanel
     */
    displayPreviousWeek = async (goalIndex, goalPanel) => {
        let pastGoal = goalPanel.hasClass('past-goal') ? 'Y' : 'N';
        await processJSON('AHJTracking', {goalIndex:goalIndex, increment:'-1 week', pastGoal: pastGoal}, 'changeGoalCurrentDate');
    };

    /**
     * Show the next week within the given goal
     * @author KCupp
     * @param goalIndex
     * @param goalPanel
     */
    displayNextWeek = async (goalIndex, goalPanel) => {
        let pastGoal = goalPanel.hasClass('past-goal') ? 'Y' : 'N';
        await processJSON('AHJTracking', {goalIndex:goalIndex, increment:'+1 week', pastGoal: pastGoal}, 'changeGoalCurrentDate');
    };
}

class StandardReporting {
    constructor(params={}) {
        this.modificationKeyRegex = /^(?:.|(?:Backspace))$/;

        /**
         * A reference to the element containing the standard reporting page within the DOM
         * @type {window.jQuery}
         */
        this.reportingContainer = null;
        if(typeof(params.reportingContainer) !== 'undefined'){
            this.reportingContainer = window.jQuery(params.reportingContainer);
        }

        this.submissionAction = 'saveActivities';
        if(typeof(params.submissionAction) !== 'undefined'){
            this.submissionAction = params.submissionAction;
        }

        this.submissionPage = 'AHJTracking';
        if(typeof(params.submissionPage) !== 'undefined'){
            this.submissionPage = params.submissionPage;
        }

        this.submissionCallback = null;
        if(typeof(params.submissionCallback) !== 'undefined'){
            this.submissionCallback = params.submissionCallback;
        }

        this.context = 'standardReporting';
        if(typeof(params.context) !== 'undefined'){
            this.context = params.context;
        }

        /**
         * The id of the current reporting category
         * @type {string}
         */
        this.categoryId = "";
        if(typeof(params.categoryId) !== 'undefined'){
            this.categoryId = params.categoryId;
        }
    }

    /**
     * Set up the JS for the standard reporting page
     * @author KCupp
     */
    initialize = () => {
        let standardReportingObject = this;

        //Click handlers to all of the category buttons
        this.reportingContainer = window.jQuery('#standard-reporting');
        this.categoryId = this.reportingContainer.attr('data-category-id');
        let categoryNavButtons = this.reportingContainer.find('.category.tile-button');
        categoryNavButtons.off('click').click(function(event){
            standardReportingObject.selectCategory(event)
        });

        //Click handlers for the date nav buttons
        let previousWeekButton = this.reportingContainer.find('#previous-week');
        previousWeekButton.off('click').click(this.displayPreviousWeek);
        let nextWeekButton = this.reportingContainer.find('#next-week');
        nextWeekButton.off('click').click(this.displayNextWeek);

        this.disableInputsOutOfRange();

        //Initialize all input types
        this.initializeDefaultInputs();
        this.initializeWYSIWYGInputs();
        this.initializeBinaryInputs();

        let reportingType = this.reportingContainer.find('.standard-activity-reporting').attr('data-reporting-type');
        let functionName = "initialize"+ucfirst(reportingType)+"ActivityReporting";
        if(typeof(this[functionName]) !== 'undefined'){
            this[functionName]();
        }

        //Initialize them there submit buttons!
        this.initializeSubmitButtons();

        this.initializeDisabledAAGWPopovers();
    };

    /**
     * @author KCupp
     */
    disableInputsOutOfRange = () => {
        //Disable all future inputs
        this.reportingContainer.find('.standard-reporting-input').each(function(){
            let input = window.jQuery(this);
            //If the current input is in the future, then we disable it by adding '.disabled'
            if(dateTools.getCurrentTime() < parseFloat(input.attr('data-activity-timestamp'))*1000 || input.hasClass('disabled')){
                input.addClass('disabled').attr('disabled', 'disabled');
            }
        });
    };

    /**
     * Set up the submit buttons!
     * @author KCupp
     * @param submissionParams {{}}
     */
    initializeSubmitButtons = (submissionParams) => {
        //Click handlers for the submit buttons
        let standardReportingObject = this;
        let submitButtons = this.reportingContainer.find('.submit .btn');
        submitButtons.off('click').click(function(event){
            standardReportingObject.submitModifiedActivities(event, submissionParams);
            window.jQuery(event.delegateTarget).popover('hide');
        });
    };

    /**
     * Set up the single activity reporting screen
     * @author KCupp
     */
    initializeSingleActivityReporting = () => {
        this.reportingContainer.find('.day-selection-list .list-group-item').each(function(){
            let dayButton = window.jQuery(this);
            if(dateTools.getCurrentTime() > parseFloat(dayButton.attr('data-day-time'))*1000){
                dayButton.off('click').on('click', function(event){
                    let dayButton = window.jQuery(event.delegateTarget);
                    //If the clicked day isn't already active...
                    if(!dayButton.hasClass('active')){
                        //Deactivate the active day, and set the clicked day to active
                        dayButton.siblings().removeClass('active');
                        dayButton.addClass('active');

                        //Make the currently visible activity invisible, and set the one that corresponds to the clicked day to visible
                        let activityWrapper = dayButton.closest('.standard-activity-reporting');
                        activityWrapper.find('.activity:visible').hide();
                        activityWrapper.find('#'+dayButton.attr('data-activity-wrapper-id')).fadeIn();
                    }
                    event.preventDefault();
                });
            }else{
                dayButton.addClass('disabled');
            }
        });
    };

    /**
     * Set up default reporting inputs
     * @author KCupp
     * @param event
     */
    initializeDefaultInputs = () => {
        let standardReportingObject = this;
        let inputs = this.reportingContainer.find('.standard-reporting-input[data-input-type="default"]');
        if(inputs.length > 0) {
            this.setDailyGoalIndicatorStatus(inputs);
            let enabledInputs = inputs.filter(':not(.disabled)');
            let handleInputChange = function (event) {
                standardReportingObject.handleInputChange(event);
            };
            enabledInputs.off('keyup').off('paste').keyup(handleInputChange).on('paste', handleInputChange);
            enabledInputs.off('focus').focus(function(event){
                event.preventDefault();
                standardReportingObject.handleOnFocus(event);
            });
        }
    };

    /**
     * Set up the binary reporting inputs
     * @author KCupp
     */
    initializeBinaryInputs = () => {
        //Click handlers for the binary inputs
        let standardReportingObject = this;
        let binaryControls = this.reportingContainer.find('.binary-reporting .status-circle:not(.disabled)');
        binaryControls.off('click').click(function(event){
            standardReportingObject.toggleBinaryControl(event);
        });
    };

    /**
     * Set up WYSIWYG reporting inputs
     * @author KCupp
     * @param event
     */
    initializeWYSIWYGInputs = () => {
        let inputs = this.reportingContainer.find('.standard-reporting-input[data-input-type="WYSIWYG"]:not(.disabled)');
        if(inputs.length > 0){

            let standardReportingObject = this;
            let height = 497;

            inputs.each(function(){
                let input = window.jQuery(this);
                //Make sure that the editor for this input isn't still floating around somewhere
                let editor = TinyMceEditor.getEditorInstance(input.attr('id'));
                if (editor !== null) {
                    TinyMceEditor.clearEditor(input.attr('id'));
                }

                let handleEditorChanged = function(event){
                    const editor = TinyMceEditor.getEditorInstance(window.jQuery(event.currentTarget).attr('data-id'));
                    input.siblings('.hidden-input').val(editor.getContent()).addClass('modified');
                    standardReportingObject.reportingContainer.find('.submit .btn').removeClass('disabled')
                };

                //Create a new instance of window.tinymce for this input
                const tinyMceEditor = new TinyMceEditor(input.attr('id'));
                tinyMceEditor.initialize({
                    selector: '#' + input.attr('id'),
                    height: height,
                    init_instance_callback:function(editor){
                        editor.on('KeyUp', handleEditorChanged);
                        editor.on('Paste', handleEditorChanged);
                    }
                });
            });
        }
    };

    /**
     * @author KCupp
     * @param event
     */
    selectCategory = (event) => {
        let button = window.jQuery(event.delegateTarget);
        let activeClass = 'tile-selected';
        let categoryId = button.attr('data-category-id');
        if(!button.hasClass(activeClass)) {
            processJSON('AHJTracking', {categoryId:categoryId}, 'standardReportingChooseCategory');
            button.siblings().removeClass(activeClass);
            button.addClass(activeClass);
            this.reportingContainer.attr('data-category-id', categoryId);
            this.categoryId = categoryId;
            let mobileLearnMore = window.jQuery('.mobile-learn-more-' + categoryId);
            let mobileLearnMoreDisplay = 'mobile-learn-more-display';
            mobileLearnMore.siblings().removeClass(mobileLearnMoreDisplay);
            mobileLearnMore.addClass(mobileLearnMoreDisplay);

        }
    };

    /**
     * @author KCupp
     * @param event
     */
    handleInputChange = (event) => {
        let input = window.jQuery(event.delegateTarget);
        let inputMaximum = input.attr('max') != '' && !isNaN(+input.attr('max')) ? +input.attr('max') : false;
        let currentValue = input.val();

        if(event.type === 'paste'){
            if(typeof(event.originalEvent.clipboardData) !== 'undefined') {
                currentValue = event.originalEvent.clipboardData.getData('text/plain');
            }else{
                currentValue = window.clipboardData.getData('text')
            }
        }

        currentValue = currentValue.replace(/[^\d.-]/g, '');
        currentValue = inputMaximum ? (+currentValue > inputMaximum ? String(inputMaximum) : currentValue) : currentValue;
        input.val(currentValue);

        //Check to make sure that the key the user just pressed would result in a value change
        if(input.attr('data-previous-value') != currentValue) {
            input.addClass('modified');
            input.closest('.row').find('.submit .btn').removeClass('disabled');
            if(currentValue != '') {
                input.attr('data-previous-value', currentValue);
            }else{
                input.attr('data-previous-value', '0');
            }
        }

        this.promptSubmitActivity(input);
    };

    handleOnFocus = (event) => {
        let input = window.jQuery(event.delegateTarget);
        event.stopPropagation();
        if(this.context === 'standardReporting'){
            this.promptCreateGoal(input);
        }
    }

    /**
     * Show the correct version of the daily goal indicator when an input is changed.
     * @author KCupp
     * @param inputs
     */
    setDailyGoalIndicatorStatus = (inputs) => {
        inputs = window.jQuery(inputs);
        inputs.each(function(){
            let input = window.jQuery(this);

            //Skip indicators for future goals
            if(input.closest('div.goal-reporting-card').hasClass('future-goal') || input.closest('div.goal-reporting-card').hasClass('before-goal')){
                return true;
            }

            if(input.val() !== "" && input.val() !== null) {
                let dailyGoalIndicator = input.siblings('.daily-goal-indicator');
                let dailyGoalMin = dailyGoalIndicator.attr('data-daily-min');
                let dailyGoalMax = dailyGoalIndicator.attr('data-daily-max');
                let rangeFound = (typeof(dailyGoalMax) !== 'undefined' && dailyGoalMax !== '' && typeof(dailyGoalMin) !== 'undefined' && dailyGoalMin !== '');
                //Only show the indicators if there is actually a daily goal.
                if(dailyGoalIndicator.attr('data-daily-goal') !== '' || rangeFound) {
                    let success = false;
                    if (rangeFound) {
                        let dailyGoalMinValue = parseFloat(dailyGoalMin);
                        let dailyGoalMaxValue = parseFloat(dailyGoalMax);
                        success = dailyGoalMinValue <= input.val() && input.val() <= dailyGoalMaxValue;
                    } else {
                        let dailyGoalValue = parseFloat(dailyGoalIndicator.attr('data-daily-goal'));
                        success = dailyGoalValue <= input.val();
                    }
                    if (success) {
                        dailyGoalIndicator.addClass('success').removeClass('failure');
                    } else {
                        dailyGoalIndicator.removeClass('success').addClass('failure');
                    }
                    //Set the popover content
                    if (dailyGoalIndicator.hasClass('success')) {
                        let popoverContentElem = input.parent().find('.popover-content .daily-success-indicator');
                        dailyGoalIndicator.popover('dispose');
                        initializeAHPopover(dailyGoalIndicator, {
                            'placement': 'top',
                            'content': popoverContentElem.html(),
                            'trigger': 'hover',
                            'type': popoverContentElem.attr('data-popover-type')
                        });
                    } else if (dailyGoalIndicator.hasClass('failure')) {
                        let popoverContentElem = input.parent().find('.popover-content .daily-failure-indicator');
                        dailyGoalIndicator.popover('dispose');
                        initializeAHPopover(dailyGoalIndicator, {
                            'placement': 'auto',
                            'content': popoverContentElem.html(),
                            'trigger': 'hover',
                            'type': popoverContentElem.attr('data-popover-type')
                        });
                    }

                    if (!dailyGoalIndicator.is(':visible')) {
                        dailyGoalIndicator.fadeIn();
                    }
                }
            }else{
                let dailyGoalIndicator = input.siblings('.daily-goal-indicator');
                if(dailyGoalIndicator.is(':visible')){
                    dailyGoalIndicator.fadeOut();
                }
            }
        });
    };

    /**
     * @author KCupp
     * @param event
     */
    toggleBinaryControl = (event) => {
        let control = window.jQuery(event.delegateTarget);
        let value = control.attr('data-value');
        if(value === '1'){
            value = '0';
            control.removeClass('success');
        }else{
            value = '1';
            control.addClass('success');
        }
        control.attr('data-value', value);
        control.addClass('modified');
        control.closest('.row').find('.submit .btn').removeClass('disabled');

        if(this.context === 'standardReporting'){
            this.promptCreateGoal(control);
        }

        this.promptSubmitActivity(control);
    };

    /**
     * Send the modified activities to the server for saving!
     * @author KCupp
     * @param event {{}}
     * @param submissionParams {{}}
     */
    submitModifiedActivities = (event, submissionParams) => {
        //Clear the submit button popover
        clearTimeout(window['prompt_submit_activity_timeout']);

        let button = window.jQuery(event.delegateTarget);
        let submissionData = {};
        let foundData = false;
        let standardReportingObject = this;
        this.reportingContainer.find('.standard-reporting-input.modified').each(function(){
            foundData = true;
            let input = window.jQuery(this);
            let activityId = input.attr('data-activity-id');
            let activityTimestamp = 'activity_time_'+input.attr('data-activity-timestamp');

            //Make sure the necessary objects exist
            if(typeof(submissionData[standardReportingObject.categoryId]) === 'undefined'){
                submissionData[standardReportingObject.categoryId] = {};
            }
            if(typeof(submissionData[standardReportingObject.categoryId][activityId]) === 'undefined'){
                submissionData[standardReportingObject.categoryId][activityId] = {};
            }
            if(typeof(submissionData[standardReportingObject.categoryId][activityId][activityTimestamp]) === 'undefined') {
                submissionData[standardReportingObject.categoryId][activityId][activityTimestamp] = {};
            }
            let value = input.val();
            //If the input does not have a value property, pull it from the data-value attribute
            if(typeof(value) === 'undefined' || value === null || value === ""){
                value = input.attr('data-value');
            }
            //If the value if still empty, get a value from the previous-value attribute
            if(typeof(value) === 'undefined'){
                value = input.attr('data-previous-value');
            }
            submissionData[standardReportingObject.categoryId][activityId][activityTimestamp][input.attr('name')] = value;
        }).removeClass('modified');

        this.reportingContainer.find('.submit .btn').addClass('disabled');

        if(foundData) {
            replaceWithLoader(button);
            if(typeof(submissionParams) === 'undefined'){
                submissionParams = {};
            }
            submissionParams.activitiesToSave = submissionData;
            let inputs = this.reportingContainer.find('.standard-reporting-input[data-input-type="default"]');
            processJSON(this.submissionPage, submissionParams, this.submissionAction, {
                callBack: function () {
                    removeLoader(button.attr('id'));
                    standardReportingObject.setDailyGoalIndicatorStatus(inputs);
                    if(typeof(standardReportingObject.submissionCallback) === 'function') {
                        standardReportingObject.submissionCallback();
                    }
                }
            });
        }
    };

    /**
     * Move to the previous week
     * @author KCupp
     * @param event
     */
    displayPreviousWeek = (event) => {
        processJSON('AHJTracking', {increment:"-1 week"}, 'standardReportingChangeCurrentDate');
    };

    /**
     * Move to the next week
     * @author KCupp
     * @param event
     */
    displayNextWeek = (event) => {
        processJSON('AHJTracking', {increment:"+1 week"}, 'standardReportingChangeCurrentDate');
    };

    promptCreateGoal = (reference) => {
        let id = 'prompt_create_goal_timeout';
        let elem = window.jQuery(reference);
        let row = elem.closest('.activity-reporting-row');
        let container = elem.closest('.reporting-container');
        let delay = 800;
        if(row.attr('data-popover-create-goal') === 'Y'){
            row.attr('data-popover-create-goal', 'N');

            clearTimeout(window[id]);
            window[id] = setTimeout(function(){
                let content = row.find('.activity_create_goal_popover').html();
                initializeAHPopover(elem, {
                    html:true,
                    container:container,
                    trigger:'focus',
                    type:'primary',
                    placement:'bottom',
                    content:content,
                    show:true
                });
            }, delay, reference);
        }
    }

    promptSubmitActivity = (reference) => {
        let id = 'prompt_submit_activity_timeout'
        let elem = window.jQuery(reference);
        let row = elem.closest('.reporting-row');
        let container = elem.closest('.reporting-container');
        let delay = 800;
        if(container.attr('data-popover-submit-activity') === 'Y'){
            container.attr('data-popover-submit-activity', 'N');

            clearTimeout(window[id]);
            window[id] = setTimeout(function(){
                let content = row.find('div.submit .activity_submit_popover').html();
                let submit_btn = row.find('div.submit .btn');
                initializeAHPopover(submit_btn, {
                    html:true,
                    container:container,
                    trigger:'click',
                    type:'primary',
                    placement:'bottom',
                    content:content,
                    show:true,
                    timeToDisplay:5000
                });
            }, delay, reference);
        }
    }
    /**
     *
     * @author soconnor
     *
     */
    initializeDisabledAAGWPopovers = () => {
        let plusButtons = window.jQuery('.aagw-plus-disabled');
        plusButtons.each(function() {
            let plusButton = window.jQuery(this);
            initializeAHPopover(plusButton, {
                'placement': 'left',
                'content': plusButton.attr('data-content'),
                'trigger': 'hover',
                'type': plusButton.attr('data-popover-type')
            });
        });
    }
}

class CustomReporting {
    documentUploader = {};

    initialize = () => {
        let customReporting = window.jQuery('#custom-reporting');
        let customReportingController = this;

        //Hide the loose activities panel if it's empty
        let looseActivities = window.jQuery('.activity-card[data-activity-id="loose_activities"]');
        let definedActivities = window.jQuery('.activity-card[data-activity-id!="loose_activities"]');
        if((looseActivities.find('.card-body .activity').length === 0 || looseActivities.find('.card-body').length === 0) && definedActivities.length > 0){
            looseActivities.hide();
        }

        window.jQuery('.custom-category-nav .category').off('click').on('click', function(e){
            let button = window.jQuery(e.delegateTarget);
            processJSON('AHJTracking', {category:button.attr('data-category-id')}, 'displayCustomReporting');
        });

        //window.jQuery(window).off('resize', null, this.reizeCategoryNavigation).on('resize', null, this.resizeCategoryNavigation);

        customReporting.find('.activity-card .submit').off('click').on('click', function(e){
            let button = window.jQuery(e.delegateTarget);
            customReportingController.openNewActivityForm(button, button.closest('.activity-card'));
        });

        customReporting.find('.activity-card .activity').each(function(){
            let activityElement = window.jQuery(this);
            activityElement.find('.edit-activity,.view-activity').off('click').on('click', function(e){
                let button = window.jQuery(e.delegateTarget);
                customReportingController.openExistingActivityForm(button, button.closest('.activity'));
                e.preventDefault();
            });
            activityElement.find('.delete-activity').off('click').on('click', function(e){
                let button = window.jQuery(e.delegateTarget);
                window.core_messager.initModal({sourceElement:'#delete-confirmation-modal', buttons:[new window.core_messager.button('Cancel', function(){}, 'default'), new window.core_messager.button('Yes, Delete', function(){
                        customReportingController.deleteActivity(button, button.closest('.activity'));
                    }, 'info')]});
                e.preventDefault();
            });
        });

        customReporting.find('[data-toggle="tooltip"]').tooltip();

        this.documentUploader = new DocumentUploader();
    };

    resizeCategoryNavigation = () => {};

    /**
     * Given an activity primary key, open the documents modal if it's incomplete
     * @author KCupp
     * @param {{}} params
     */
    openIncompleteDocumentModal = (params) => {
        window.jQuery('#'+params.eeActivityNo+'_documentation').parent().find('.layered-circle').click();
    };

    openNewActivityForm = async (button, activityPanelElement) => {
        let loaderId = replaceWithLoader(button);
        await processJSON('AHJTracking', {categoryId:activityPanelElement.attr('data-category-id'), activityId:activityPanelElement.attr('data-activity-id')}, 'displayCustomActivityForm', {callBack:function(){
                removeLoader(loaderId);
            }});
    };

    openExistingActivityForm = async (button, activityElement) => {
        let activityPanelElement = activityElement.closest('.activity-card');
        let loaderId = replaceWithLoader(button);
        await processJSON('AHJTracking', {readOnly:button.hasClass('view-activity') ? 'Y' : 'N', categoryId:activityPanelElement.attr('data-category-id'), activityId:activityPanelElement.attr('data-activity-id'), activityPrimaryKey:activityElement.attr('data-activity-primary-key')}, 'displayCustomActivityForm', {callBack:function(){
                removeLoader(loaderId);
            }});
    };

    /**
     * @author KCupp
     * @param {window.jQuery} button
     * @param {window.jQuery} activityElement
     */
    deleteActivity = async (button, activityElement) => {
        let loaderId = replaceWithLoader(button);
        let activityPanelElement = activityElement.closest('.activity-card');
        await processJSON('AHJTracking', {activityPrimaryKey:activityElement.attr('data-activity-primary-key'), category:activityPanelElement.attr('data-category-id')}, 'deleteCustomActivity', {
            callBack: async () => {
                removeLoader(loaderId);
                await refreshProgressBar();
            }
        });
    };

    /**
     * @author KCupp
     * @param {window.jQuery} formElement
     */
    submitActivityForm = async (formElement) => {
        if(typeof formElement === 'undefined'){
            formElement = window.jQuery('form.custom-activity-report-form');
        }
        let reportFields = {};
        console.log('submitting activity form');
        formElement.find('input.report-field').each(function(){
            let reportField = window.jQuery(this);
            reportFields[reportField.attr('id')] = reportField.val();
        });
        await processAjax('AHJTracking', {
            activityDate:formElement.find('#activity-date').val(),
            category:formElement.find('#category').val(),
            activity:formElement.find('#activity').val(),
            reportFields:reportFields
        }, 'saveCustomActivity', {
            callBack: async () => {
                await genericPageRefresh();
            }
        });
    };
}
/*
await processJSON('AHJTracking', submissionParams, 'saveActivities', {
            callBack: async () => {
                removeLoader(button.attr('id'));
                await genericPageRefresh();
            }
        });
 */
class ARCResourceReporting {
    initialize = () => {
        window.jQuery('#resource-reporting-btn').off('click').on('click', (e) => {
            let elem = window.jQuery(this);
            this.downloadResource(e, elem);
        });
    }

    downloadResource = (event, reference) => {
        let elem = window.jQuery(reference);
        let category = elem.attr('data-category');
        let activityId = elem.attr('data-activity-path');
        let filePath = elem.attr('data-resource-path');
        let itemId = elem.attr('data-item-id');
        let value = elem.attr('data-value');
        let activityTimestamp = elem.attr('data-activity-timestamp');
        let taskCompleted = elem.attr('data-task-completed');
        window.open(filePath);

        if(!taskCompleted){
            this.submitActivity(event, category, activityId, activityTimestamp, itemId, value)
        }
    }

    submitActivity = async (event, categoryId, activityId, activityTimestamp, itemId, value) => {
        let button = window.jQuery(event.delegateTarget);
        let submissionData = {};

        if(typeof(activityTimestamp) === 'undefined' || !activityTimestamp){
            activityTimestamp = '';
        }else{
            activityTimestamp = 'activity_time_'+activityTimestamp;
        }

        if(typeof(value) === 'undefined' || !value){
            value = 'arc_resource';
        }

        if(typeof(itemId) === 'undefined' || !itemId){
            itemId = 'task_item_type';
        }

        if(typeof(submissionData[categoryId]) === 'undefined'){
            submissionData[categoryId] = {};
        }
        if(typeof(submissionData[categoryId][activityId]) === 'undefined'){
            submissionData[categoryId][activityId] = {};
        }
        if(typeof(submissionData[categoryId][activityId][activityTimestamp]) === 'undefined') {
            submissionData[categoryId][activityId][activityTimestamp] = {};
        }

        submissionData[categoryId][activityId][activityTimestamp][itemId] = value;

        replaceWithLoader(button);

        let submissionParams = {
            activitiesToSave: submissionData
        };
        await processJSON('AHJTracking', submissionParams, 'saveActivities', {
            callBack: async () => {
                removeLoader(button.attr('id'));
                await genericPageRefresh();
            }
        });
    }
}

class MiniChallengeController {
    constructor(params={}) {
        if(typeof(params.miniChallengeContainer) === 'undefined'){
            params.miniChallengeContainer = '#mini-challenge-reporting';
        }
        this.miniChallengeContainer = window.jQuery(params.miniChallengeContainer);
    }

    initialize = () => {
        let miniChallengeControllerObj = this;
        let popoverContent = window.jQuery('#mini-challenge-popover-content').html();
        this.miniChallengeContainer.find('.mini-challenge-btn').each(function(){
            let button = window.jQuery(this);
            if(typeof(button.attr('data-toggle')) !== 'undefined' && button.attr('data-toggle') === 'popover'){
                initializeAHPopover(button, {
                    placement:'auto',
                    content:popoverContent,
                });
            }else{
                button.off('click').on('click', async function(event){
                    let buttonState = window.jQuery(this);
                    if(typeof(buttonState.attr('disabled')) === 'undefined' && buttonState.attr('disabled') !== 'disabled'){
                        let button = window.jQuery(event.delegateTarget);
                        replaceWithLoader(button);
                        //Set disabled attribute on all mini challenge buttons to prevent users from being able to click more than one while loading
                        window.jQuery('#mini-challenge-reporting').find('.mini-challenge-btn').attr('disabled','disabled')
                        let activityId = 'mini_challenge_'+button.attr('data-mini-challenge-tag');
                        let activityData = GetMiniChallengeData(activityId, button.attr('data-today'));
                        await processJSON('AHJMiniChallenges', {challenge_index:button.attr('data-mini-challenge-tag'), completion:'Y', activityData:activityData}, 'saveChallengeCompletion', {callBack: async () => {
                                await processJSON('AHJMissions', {}, 'refreshMiniChallenges');
                        }});
                    }
                });
            }
        });
    }
};

const GetMiniChallengeData = function(miniChallengeId, time) {
    let activityData = {hac_minichallenge:{}};
    activityData.hac_minichallenge[miniChallengeId] = {};
    activityData.hac_minichallenge[miniChallengeId]['activity_time_'+time] = {
        completion: 'Y',
        activityPath: miniChallengeId,
        activityCategory:'hac_minichallenge|categories'
    };
    return activityData;
};

/**
 * @author KCupp
 * @param button
 * @param challengeIndex
 * @param challengeId
 * @param challengeDate
 */
const submitMiniChallengeActivity = async (button, challengeIndex, challengeId, challengeDate) => {
    let id = window.jQuery(button).closest('.tile').attr('id');
    await processAjax(
        'AHJMiniChallenges',
        {challenge_index:challengeIndex, completion:'Y', activityData:GetMiniChallengeData(challengeId, challengeDate)},
        'saveChallengeCompletion',
        {
            maskElementId:id,
            'postCallBack': async () => {
                await processAjax('AHJRefresh', '', '', {maskElementId:id});
            }
        }
    );
};

class DocumentUploader {
    // Documentation uploader modal open
    docuPopup = (clicked) => {
        let $clicked = window.jQuery(clicked);
        let $holder = $clicked.closest('.activity').find('.documentation-section');
        let alert_num = window.core_messager.initModal({
            'sourceElement': $holder,
            'title': 'Activity Documentation',
            'size': 'medium',
            'buttons': [new window.core_messager.button('Close')],
            'onClose': async (modal) => {
                let $form_control_file = window.jQuery(modal).find('.form-control-file');
                if($form_control_file.val() !== '') {
                    replaceWithLoader($clicked);
                    let categoryId = $clicked.closest('.activity-card').attr('data-category-id');
                    await processJSON('AHJTracking', {category:categoryId}, 'displayCustomReporting', {callBack: async () => {
                            removeLoader($clicked.attr('id'));
                            //await processJSON('AHJIncentiveBar', {}, 'refresh');
                            await recalculateIncentive(); // fake uploads get points upon submit
                            await refreshProgressBar();
                        }});
                }
            }
        });

        let $modal = window.jQuery('.modal-content');
        let $file = $modal.find('.btn-file input[type=file]');
        let $progress_bar = $file.closest('.modal-body').find('.progress .progress-bar');
        let $btn_file = $file.closest('.btn-file');

        // Initialize the uploader
        $file.fileupload({
            dataType: 'json',
            autoUpload: false,

            add: function(e, data) {  // per file added to queue
                let $file = window.jQuery(e.target);
                let $upload_err = $file.closest('.modal-body').find('.UPLOAD_ERR').slideUp();

                // Hide previosly shown progress bar, submit button
                let $progress = $file.closest('.modal-body').find('.progress');
                let $progress_bar =  $progress.find('.progress-bar');
                $progress.slideUp();
                let $button = $file.closest('.modal-body').find('button');
                $button.slideUp();

                // Validate new files
                let $file_form = $file.closest('form');
                let acceptFileTypes = new RegExp( $file_form.find('input[name="acceptFileTypes"]').val(), 'i' );
                let maxFileSize = Number($file_form.find('input[name="maxFileSize"]').val());
                for(let i = 0; i < data.files.length; i++) {
                    // Limit file extension
                    if(!acceptFileTypes.test(data.files[i].name)) {
                        $upload_err.filter('.UPLOAD_ERR_FILE_EXTENSION, .UPLOAD_ERR_DEFAULT').eq(0).stop().slideDown();
                        return false;
                    }
                    // Limit file size (IE9- won't work)
                    if(typeof(data.files[i].size) !== 'undefined') {
                        if(data.files[i].size > maxFileSize) {
                            $upload_err.filter('.UPLOAD_ERR_FORM_SIZE, .UPLOAD_ERR_DEFAULT').eq(0).stop().slideDown();
                            return false;
                        }
                    }
                }

                // Change input box text
                let $form_control_file = $file.closest('.modal-body').find('.form-control-file');
                let filename = '';
                //if($file.attr('multiple') !== undefined){ $filename = $form_control_file.val(); }
                for(let i = 0; i < data.files.length; i++) {
                    if(filename !== ''){ filename += ', '; }
                    filename += data.files[i].name.replace(/\\/g,'/').replace(/.*\//,'');
                }
                $form_control_file.val(filename);

                // Reset and show the progress bar
                $progress_bar.width('0%').html('0%').removeClass('progress-bar-success progress-bar-danger').addClass('progress-bar-primary');
                $progress.stop().slideDown();

                // Re-bind and show submit button
                $button.unbind('click').bind('click', function(){
                    $button.slideUp();
                    $progress_bar.addClass('progress-bar-striped active');
                    $file.closest('.btn-file').addClass('disabled');
                    data.submit();
                });
                $button.stop().slideDown();
            },

            done: function(e, data) {  // AJAX success
                $progress_bar.removeClass('progress-bar-primary progress-bar-striped active');
                let $upload_err = window.jQuery(e.target).closest('.modal-body').find('.UPLOAD_ERR').slideUp();
                for(let i = 0; i < data.files.length; i++) {  // PHP fail
                    if(typeof(data.result.files) === 'undefined' || typeof(data.result.files[i].error) !== 'undefined') {
                        $progress_bar.addClass('progress-bar-danger');
                        $btn_file.removeClass('disabled');
                        $upload_err.filter('.'+data.result.files[i].error+', .UPLOAD_ERR_DEFAULT').eq(0).stop().slideDown();
                        return;
                    }
                }
                $progress_bar.addClass('progress-bar-success');
            },

            fail: function(e, data) {  // AJAX fail
                $progress_bar.removeClass('progress-bar-primary active').addClass('progress-bar-danger');
                $btn_file.removeClass('disabled');
                let $upload_err = window.jQuery(e.target).closest('.modal-body').find('.UPLOAD_ERR').slideUp();
                $upload_err.filter('.UPLOAD_ERR_DEFAULT').eq(0).stop().slideDown();
            },

            progress: function(e, data) {  // AJAX single upload progress
                let percent = Math.floor(data.loaded*100/data.total);
                $progress_bar.width(percent+'%').html(percent+'%');
            }
        });
    };

    // Documentation uploader download document
    docuDownload = (clicked) => {
        let $clicked = window.jQuery(clicked);
        let document_no = $clicked.closest('.activity').find('.document-no').val();
        window.open('/shared/displayResource.php?prim=ah_documents&no=' + document_no, '_self');
    };

    // Initialize the fake ARC v4 report_field type=upload
    initFake = () => {
        window.jQuery('#tracking .btn-file:visible input[type=file]').each(function() {
            window.jQuery(this).fileupload({
                add: function(e, data) {
                    let $file = window.jQuery(e.target);
                    let $form_control_file = $file.closest('.input-group').find('.form-control-file');
                    let filename = '';
                    //if($file.attr('multiple') !== undefined){ $filename = $form_control_file.val(); }
                    for(let i = 0; i < data.files.length; i++) {
                        if(filename !== ''){ filename += ', '; }
                        filename += data.files[i].name.replace(/\\/g,'/').replace(/.*\//,'');
                    }
                    $form_control_file.val(filename);
                }
            });
        });
    }
};

class LegacyTrackingHistory {
    initialize = () => {
        initializePPCs();

        //Attach a click handler for collapsing or expanding the category sections
        window.jQuery('.category-title.list-group-item').off('click').on('click', function(e){
            let categoryButton = window.jQuery(e.delegateTarget);
            let categoryData = categoryButton.next('.category-data');
            if(categoryData.hasClass('collapse')){
                categoryData.collapse('show');
                categoryButton.addClass('list-group-item-info');
            }else{
                categoryData.collapse('hide');
                categoryButton.removeClass('list-group-item-info');
            }
        });
    }
};

let submitPromoCode = async (tile) => {

    // Remove previous error messages on submit event
    window.jQuery('#empty_code_message').css('display','none');
    window.jQuery('#used_code_error_message').css('display','none');
    window.jQuery('#invalid_code_error_message').css('display','none');

    if(window.jQuery('#redemption_code').val() !== ''){
        let elem = window.jQuery(tile);
        if(!elem.hasClass('tile')){
            elem = elem.closest('.tile');
        }
        tile = elem;

        let formId = tile.find('.tile-body form.promo-activity-save-form').attr('id');
        window.submittingObj = this;

        let options = { formId, maskElementId: tile.attr('id')};
        await processAjax('AHJARC','activity_submission','savePromoCodeActivity', options);
    }else{
        window.jQuery('#empty_code_message').css('display','block');
        setTimeout(function(){
            window.jQuery('#empty_code_message').css('display','none');
        }, 2500)
    }
}
