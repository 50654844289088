import {useState, useEffect} from "react";
import { store } from "../store";
import produce from "immer";
import app from '../../setupAxios';
import {getState, resetStoreKeys, subscribe} from "../helpers";

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["app","page"];
const initialState = {
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storeThePath: (state, payload) =>

      produce(state, draft => {
          draft.currentPath = payload.currentPath;
      }),
    loadingPage: (state, payload) =>
      produce(state, draft => {
         draft['loadingPage'] = payload;
      }),
    storeQuickMenuOpen: (state, payload) =>
      produce(state, draft => {
         draft["quickMenuOpen"] = payload.open;
      }),
    'reset.app.page': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
   reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/
export const usePage = (nestedPath=[]) => {
    const subpath = storePath.concat(nestedPath);
   const [state, setState] = useState(getState(subpath));
   useEffect(() => subscribe(setState,subpath), [setState]);
   return state;
};
export const storeCurrentPath = (currentPath) => {
    if(typeof currentPath === "string" && currentPath.length > 0) storeThePath({ currentPath });
};
export const setQuickMenuOpen = (open) => {
    storeQuickMenuOpen({ open });
};
/*
    Set loadingPage to currentPath when the page is loading, set to null when it completes
 */
export const setLoadingPage = (page) => {
    store.dispatch({type:"loadingPage",payload:page});
};
export const storeThePath = payload => store.dispatch({ type: "storeThePath", payload });
export const storeQuickMenuOpen = payload => store.dispatch({ type: "storeQuickMenuOpen", payload });