import React, {useRef, useState} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import _ from "lodash";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "./IncentiveBarComponent.scss";


const getValueBasedDisplay = (value, valueMax) => {
    const now = ( value / valueMax ) * 100;
    return (
        <OverlayTrigger
            placement="bottom"
            rootClose
            overlay={
                <Popover id={`popover-positioned-bottom`} className="completedSegmentPopover">
                    <Popover.Content>
                        <div className="segmentPopoverText">{value} / {valueMax}</div>
                    </Popover.Content>
                </Popover>
            }
        >
            <ProgressBar className="incentiveBarHeight incentiveBarMargin" variant="success" now={now} label={`${now}%`} srOnly />
        </OverlayTrigger>
    );
}

const getTaskBasedDisplay = (tasksToDisplay, task_settings) => {

    function buildSegment(segmentContent){
        /**
         * todo: Translate popover content, add popovers
         * ${isb_tasks/id}_incentive_progress_bar_popover
         * task_settings.(icon_completed|icon_failed|icon_in_progress).value 
         */
        //let overlayComponentText = segmentContent.name;
        let sectionDisplayClass = 'incompleteSegment';
        if(segmentContent.completed){
            sectionDisplayClass = 'completedSegment'
        } else if(segmentContent.failed) {
            sectionDisplayClass = 'failedSection'
        }

        //an attempt to remove the strict mode warning by converting sections into a class and handling refs...
        /*return (
            <IncentiveBarSegment
                sectionDisplayClass={sectionDisplayClass}
                segmentContent={segmentContent}
                key={segmentContent.task_no}
            />
            );*/
//console.log(task_settings);
        return (
            <OverlayTrigger
                key={segmentContent.task_no}
                placement="bottom"
                rootClose
                overlay={
                    <Popover id={`popover-positioned-bottom`} className={`${sectionDisplayClass}Popover`}>
                        <Popover.Content>
                            <div className="segmentPopoverText">{segmentContent.name}</div>
                        </Popover.Content>
                    </Popover>
                }
            >
                <Col className={`barSegment ${sectionDisplayClass}`}>
                    <div className="segmentStatusIcon">
                        {segmentContent.completed ? <span className="md-icons segment-icon-complete">done</span> : '-'}
                    </div>
                </Col>
            </OverlayTrigger>
       );
    }
    const barSegments = _.map(tasksToDisplay, buildSegment);
    return (
        <Row className="incentiveBar">
            {barSegments}
        </Row>
    );

}

const IncentiveBarComponent = props =>  {
    let progressBarDisplayContent = 'Loading...';
    if(props.displayMode !== null){
        if(props.displayMode === 'value_based'){
            progressBarDisplayContent = getValueBasedDisplay(props.pointsTaskList.value, props.pointsTaskList.value_max);
        }
        else if(props.displayMode === 'task_based'){
            progressBarDisplayContent = getTaskBasedDisplay(props.tasksToDisplay, props.displaySettings);
        }
    }
    return (
            <div>{progressBarDisplayContent}</div>
    );
}

export default IncentiveBarComponent;
