import {resolveContext} from "./utils";
import { initializePPCs } from "./lib/ppcController";

export const exportDynamicLayoutGlobals = () => {
    window['initDynamicLayout'] = initDynamicLayout;
    window['resizeWidgets'] = resizeWidgets;
}

export const initDynamicLayout = () => {
    let layout = window.jQuery('.dynamic-layout');
    if (layout !== null) {
        window.initializeCharts(layout);
        resizeWidgets();
        initDynamicWidgets();
        initializePPCs();
        initializeCarousels();
    }
}

/*export const initializeCharts = (elemId) => {
    let elemIdType = typeof(elemId);
    if(elemIdType === 'undefined'){
        let charts = document.querySelectorAll('.chartJSON');
    }else if(elemIdType == 'object'){
        let charts = elemId.querySelectorAll('.chartJSON');
    }else if(elemIdType == 'string'){
        let elem = document.getElementById(elemId);
        let charts = elem.querySelectorAll('.chartJSON');
    }

    if(typeof(charts) != 'undefined'){
        if(typeof(window['charts']) === 'undefined'){
            window['charts'] = {};
        }
        charts.each(function(){
            let chartJSONElem = jQuery(this);
            let chartContainerId = jQuery(this).siblings('.chart').getAttribute('id').replace(/-/g, "__");
            let chartObj = new chart(chartJSONElem.parent());
        });
    }
}*/


export const initDynamicWidgets = () => {
    let widgets = document.querySelectorAll('.dynamic-layout .tile');
    widgets.forEach(function (widget) {
        if(widget.id.includes("banner_carousel")){
            window.jQuery('#'+widget.id).removeClass('tile');
        }
        let initAction = widget.querySelector('.init-action');
        if (initAction !== null) {
            initAction = resolveContext(initAction.value);
            if (typeof(initAction) === 'function') {
                initAction(widget);
            }
        }
    });
}

export const resizeWidgets = () => {
    let rows = document.querySelectorAll('.dynamic-layout div.dynamic-layout-row:not(.incentive-status-bar-tile)')
    //let rows = document.querySelectorAll('.dynamic-layout div.dynamic-layout-row:not(.incentive-status-bar-tile .dynamic-layout div.dynamic-layout-row)')
    rows.forEach((row) => {
        let widgetCandidates = row.querySelectorAll('.widget');
        let widgets = [];

        widgetCandidates.forEach(function(widget){
            let parent = widget.parentElement
            let valid = true;
            while(!parent.isSameNode(row)){
                if(parent.classList.contains('dynamic-layout-row')){
                    valid = false;
                    break;
                }
                parent = parent.parentElement;
            }
            if(valid){
                //Let all widgets be their natural height
                widget.style.minHeight = 'unset';
                widgets.push(widget);
            }
        });

        //First, find the tallest widget on the row
        let maximumHeight = 0;
        widgets.forEach((sibling) => {
            let prevHeight = sibling.style.height;
            let prevMinHeight = sibling.style.minHeight;
            sibling.style.height = sibling.getAttribute('data-preferred-height');
            sibling.style.minHeight = '0px';
            let height = sibling.offsetHeight;
            if (height > maximumHeight) {
                maximumHeight = Math.ceil(height);
            }
            sibling.style.height = prevHeight;
            sibling.style.minHeight = prevMinHeight;
        });


        let dynamicLayoutRow = row.closest('.dynamic-layout-row');
        //Now, set the minimum height of all widgets on the row to the height of the tallest widget
        widgets.forEach((widget) => {
            //If the widget is taking up the full width of the layout container, then there's no need to restrict the widget's height based on its tallest sibling's height
            if ((widget.parentElement.offsetWidth === dynamicLayoutRow.offsetWidth && !widget.parentElement.isSameNode(dynamicLayoutRow)) || (widget.parentElement.isSameNode(dynamicLayoutRow) && dynamicLayoutRow.offsetWidth === widget.offsetWidth)) {
                let preferredHeight = widget.getAttribute('data-preferred-height');
                widget.style.minHeight = '0px';
                widget.style.height = preferredHeight;
            } else {
                //Otherwise, make this widget at least as tall as its tallest sibling.
                //let height = widget.getAttribute('data-height');
                widget.style.height = `${maximumHeight}px`;
            }
        });
    });
}

function initializeCarousels(){
    let carousels = window.jQuery(".carousel[data-auto-rotate='Y']");
    carousels.carousel();
}
