import { processAjax } from "../ajaxActions";

export const exportHaPageGlobals = () => {
    window['ha_controller'] = new HealthAssessmentController();
}

class HealthAssessmentController {

    constructor() {
        this.referrer = null;
    }

    switchGrpBtn = function (params) {
        let currentTab = window.jQuery('#' + params.currentTab + 'btn');
        let newTab = window.jQuery('#' + params.newTab + 'btn');

        newTab.removeClass('disabled');
        newTab.siblings('.sidebar-active').removeClass('sidebar-active');
        newTab.addClass("sidebar-active");

        currentTab.addClass('completed');
        currentTab.find('.category-icon').removeClass('current');
        currentTab.find('.category-description').removeClass('current');
        newTab.find('.category-icon').addClass('current');
        newTab.find('.category-description').addClass('current');
    }

    goToHREPage = async (reference, sectionId) => {
        let elem = window.jQuery(reference);
        if (elem.hasClass('disabled')) {
            return false;
        }

        window.jQuery('#hre_data_form input[name="go_to_section_id"]').val(sectionId);
        await processAjax('AHJHRE', 'hre_data_form', 'goToHREPage');
    }
    goToReferrer = (referrer, resetReferrer = true) => {
        if(typeof referrer === 'string' && referrer.trim() !== '') {
            window.navigation.followPath(`${referrer}`)
        } else if(this.referrer && this.referrer.url) {
            window.navigation.followPath(this.referrer.url);
        } else {
            window.navigation.followPath('/ha');
        }
        if(resetReferrer) this.resetReferrer();
    }

    selectRadioButton = function(reference, name){
        let elem = window.jQuery(reference);
        let value = elem.val();

        window.jQuery('input[name="'+name+'"]').each(function(){
            window.jQuery(this).closest('.radio').removeClass('radio-checked');
        });

        elem.closest('.radio').addClass('radio-checked');
    }

    confirmBioSelectRadio = function(type){
        if(type === 'screen'){
            window.jQuery('#submit_next').css('display','none');
            window.jQuery('#submit_full').css('display','');
            window.jQuery('#hre_progress_submit').val('last');
        }else{
            window.jQuery('#submit_full').css('display','none');
            window.jQuery('#submit_next').css('display','');
            window.jQuery('#hre_progress_submit').val('');
        }
    }

    readMoreToggle = function(reference, questionId){
        let elem = window.jQuery(reference);
        let type = elem.hasClass('more') ? 'less' : 'more';
        let elem2 = elem.parent().find('.read_more.'+type);

        elem.fadeTo(200, 0, function () {
            window.jQuery(this).css({ visibility: "hidden" });
        });

        elem2.fadeTo(200, 1, function () {
            window.jQuery(this).css({ visibility: "visible" });
        });

        window.jQuery('#readmore_'+questionId).slideToggle(500);
    }

    saveAndExit = async (action = '', referrer = '') => {
        let haController  = this;
        await window.processAjax('AHJHRE', 'hre_data_form', 'saveHRESubQ,incomplete', {maskElementId: 'pagecontent-inner', callBack:function (){
                haController.goToReferrer(referrer);
        }});
    }

    cancel = async (action = '', referrer = '') => {
        this.goToReferrer(referrer);
    }

    saveHa = async (action= '', referrer = '', btn='') => {
        let haController  = this;
        await window.processAjax('AHJHRE', 'hre_data_form', action, {maskElementId: 'pagecontent-inner', callBack:function(){
            window.scrollToElement('body');
            const skipLink = document.querySelector('.skiptocontent a');
            if(skipLink) skipLink.click(); //move focus to top of hre
            if(btn==='submit_full') haController.handleSaveComplete(referrer);
        }});
    }

    handleSaveComplete = async (referrer='') => {
        this.goToReferrer(referrer, false);
    }

    toggleHREQuestion = (value, id, code) => {
        let ids = id.split('|');
        let codes = code.split('|');
        let lastChanged = '';

        if (ids.length === codes.length) {
            for (let i = 0; i < ids.length; i++) {
                // If the question has already been shown, ignore (don't re-hide)
                if (lastChanged === 'question_' + ids[i]) {
                    continue;
                }

                if (codes[i] === value) {
                    if (window.jQuery('#question_' + ids[i]).length) {
                        window.jQuery('#question_' + ids[i]).css('display', '');
                        lastChanged = 'question_' + ids[i];
                    }
                } else {
                    if (window.jQuery('#question_' + ids[i]).length) {
                        window.jQuery('#question_' + ids[i]).css('display', 'none');
                    }
                }
            }
        }
    }
    resetReferrer = () => {
        this.referrer = null;
    }
    setReferrer = (referrer) => {
        this.referrer = referrer;
    }

}
