import React, {useEffect, useState, useRef} from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import parse from "html-react-parser/index";

export const DynamicPopover = (props) => {
    let {
        id,
        title,
        content = '',
        placement = "auto",
        target,
        wrapperNode,
        timeToDisplay = undefined,
        trigger = 'focus',
        show: appearOnOpen = false,
        html = false,
        popoverClass,
        onEnter=()=>{},
        onEntered=()=>{},
        onEntering=()=>{},
        onExit=()=>{},
        onExited=()=>{},
        onExiting=()=>{}
    } = props;
    const [show, setShow] = useState(appearOnOpen);
    let currentTarget = target.current;
    let targetRef = useRef(currentTarget);

    if (timeToDisplay === undefined && trigger === 'manual') {
        // give default display time since manual trigger is no longer supported in React Bootstrap
        timeToDisplay = 3000;
    }

    if (typeof (content) === 'function') {
        content = content(currentTarget);
    }

    useEffect(() => {
        if (trigger === 'click') {
            currentTarget.addEventListener('click', togglePopover);

            return () => {
                currentTarget.removeEventListener('click', togglePopover);
            }
        }

        if (trigger === 'hover') {
            currentTarget.addEventListener('mouseenter', showPopover);
            currentTarget.addEventListener('mouseleave', hidePopover);
        }
    });

    useEffect( () => {
        currentTarget.addEventListener('blur', hidePopover);

        return () => {
            currentTarget.removeEventListener('blur', hidePopover);
        }
    })

    useEffect(() => {
        function handleClickOutside(event) {
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                hidePopover();
            }
        }

        if (trigger === 'focus') {
           document.addEventListener('mouseup', handleClickOutside)
            return () => {
               document.removeEventListener('mouseup', handleClickOutside);
            }
        }
    }, [targetRef])

    useEffect(() => {
        if (timeToDisplay !== undefined) {
            setInterval(() => {
                setShow(false);
            }, timeToDisplay)
        }

    }, [])

    function togglePopover() {
        console.log('TOGGLE SHOW POPOVER', show, !show);
        setShow(!show);
    }

    function showPopover() {
        setShow(true);
    }

    function hidePopover() {
        console.log('HIDE POPOVER');
        setShow(false);
    }

    return (
        <Overlay
            container={wrapperNode}
            target={currentTarget}
            placement={placement}
            flip={true}
            show={show}
            onEnter={onEnter}
            onEntered={onEntered}
            onEntering={onEntering}
            onExit={onExit}
            onExited={onExited}
            onExiting={onExiting}
        >
            <Popover id={id} className={popoverClass}>
                {title &&
                    <Popover.Title as="h3">{parse(title)}</Popover.Title>
                }

                {html ? (
                    <Popover.Content dangerouslySetInnerHTML={{__html: content}} />
                ) : (
                    <Popover.Content>
                        {parse(content)}
                    </Popover.Content>
                )}
            </Popover>
        </Overlay>
    );
}
