import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";


export default  (props) => {
    const history = useHistory();
    useEffect(()=>{
        window.location.hash = "#/";
    },[]);
    return null;
}