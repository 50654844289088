import { resolveAndCall } from "../utils";

export const exportLoginPageGlobals = () => {
    window['openLoginHelp'] = openLoginHelp;
    window['loginPage'] = new LoginPageController();
    window['launchSite'] = launchSite;
    window['initializeFeatures'] = initializeFeatures;
}

export const openLoginHelp = () => {
    window.core_messager.initModal({
        'sourceElement': '#help',
        'size': 'medium',
        'buttons': []
    });
    window.jQuery('#help_backup, #help_main_password, #help_main_userid, #help_question').hide();  // div
    window.jQuery('#login_support_result, #pass_request_result, #user_request_result, #qa_result').hide()  // .alert
    window.jQuery('#help_select, #help_last').show();  // div
}

class LoginPageController {
    bypassBlackOutPage = () => {
        window.jQuery("#mainlogin_blackout").addClass('d-none');
        window.jQuery("#mainlogin_page").removeClass('d-none');
    }
}

const launchSite = (er_no) => {
    window.jQuery('#process #ax_action').val('AHLaunchSite');
    window.jQuery('#process #ax_xmlform').val('<data><er_no>' + er_no + '</er_no></data>');
    window.jQuery('#process #ax_xmlaction').val("");
    window.jQuery('#process').submit();
}

const initializeFeatures = (featureCallbacks) => {
    if (featureCallbacks != null && typeof featureCallbacks.length !== 'undefined') {
        for (let i = 0; i < featureCallbacks.length; i++) {
            resolveAndCall(featureCallbacks[i]);
        }
    }
}
