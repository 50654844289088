import React, {useState} from 'react';
import { resolveContext } from "./utils";
import Button from "react-bootstrap/Button";

export const exportCoreMessagerGlobals = () => {
    window.core_messager = new CoreMessager();
    window.setMessage = setMessage;
    window.showAlert = showAlert;
}

/**
 * @author KCupp, TGrube, JBonnell
 * @constructor
 */
class CoreMessager {

    alertCount = 0;
    alertOrigHtml = [];

    /**
     * @author KCupp
     * @param options
     * @returns {number}
     */
    initModal = (options) => {
        //If 'options' is a string, then assume that it is the content for the modal
        if (typeof (options) === 'string') {
            options = {'message': options};
        }

        let {
            title='',
            message='',
            buttons,
            id='',
            className=typeof(options.class) === 'undefined'?'':options.class,
            size='large',
            closable=null,
            backdrop=true,
            fixedHeight=true,
            skipFade: animation=true,
            onClose=()=>{},
            onShown=()=>{},
            sourceElement
        } = options;

        let dismiss = true;
        if(closable !== null){
            closable = closable !== 'false';
            backdrop = closable ? 1 : 0;
            dismiss = closable;
        }

        if(typeof(buttons)==='object'){
            buttons = Object.entries(buttons);
            let buttonList = [];
            for (const [key, value] of Object.entries(buttons)) {
                const{text, type, dismiss, disabled, popover} = value[1];
                if(text === 'undefined'){
                    break;
                }
                const id = value[0];
                let action = () => {};
                if(typeof(value[1].action) === 'function'){
                    action = value[1].action;

                } else if (typeof(value[1].action) !== 'undefined'){
                    try {
                        action = new Function(value[1].action);
                    }
                    catch(err){
                        //console.log('The following is not a valid action passed to core_messager ');
                        //console.log(value[1].action);
                    }
                }
                buttonList.push(new this.button(text, action, type, dismiss, disabled, popover, id));
            }
            buttons = buttonList;
        }

        if(typeof(buttons) === 'undefined') {
            buttons = [new this.button('OK')];
        }


        if(!Array.isArray(buttons)) {
            buttons = [];
        }

        //Options.sourceElement allows the caller to specify that the content of the modal should originate from some other HTML element on the page
        if (typeof(sourceElement) === 'string') {
            sourceElement = window.jQuery(sourceElement);
        }

        if (typeof(sourceElement) === 'object') {
            message = window.jQuery(sourceElement).html();
            sourceElement.html('');
        }

        if(typeof(onClose) === 'string') {
            onClose = resolveContext(onClose);
        }

        if(typeof(onClose) === 'function') {
            let func = onClose;
            onClose = () => {
                // console.log('ON CLOSE', sourceElement, message);
                if (typeof (sourceElement) === 'object') { //restore the sourceElement
                    window.jQuery(sourceElement).html(message);
                }

                if(typeof(func) === 'function'){
                    func();
                }
            };
        }

        if(typeof(onShown) === 'string') {
            let func = resolveContext(onShown);
            onShown = () => {};
            if(typeof(func) === 'function'){
                onShown = func;
            }
        }

        //Determine the class name to be applied to the modal based on the size parameter
        let maxHeight;
        switch (size) {
            case 'auto':
                maxHeight = '800px';
                size = 'auto';
                break;
            case 'small':
                maxHeight = '250px';
                size = 'sm';
                break;
            case 'medium':
                maxHeight = '550px';
                size = 'md';
                break;
            case 'large':
                maxHeight = '800px';
                size = 'lg';
                break;
            case 'xl':
                maxHeight = '800px';
                size = 'xl';
                break;
            default:
                maxHeight = '800px';
                size = 'lg';
        }


        if(!isNaN(parseInt(backdrop))) {
            switch(parseInt(backdrop)) {
                case 0:
                    backdrop = 'static';
                    break;
                case 1:
                default:
                    backdrop = true;
                    break;
            }
        }

        window.modalManager.open({
            title,
            message,
            buttons,
            id,
            className,
            backdrop,
            animation,
            onClose,
            onShown,
            size,
            sourceElement,
            dismiss,
            maxHeight,
            fixedHeight,
            onExited: onClose,
            onEntering: () => {},
            onEntered: onShown
        });
        /*if (options.closable) {
            modal_header.append(window.jQuery('<button class="close" data-dismiss="modal" type="button">&times;</button>'));
        }*/
    };

    button = class Button {
        constructor(
            text = '',
            action = () => {},
            type = 'secondary',
            dismiss = true,
            disabled = false,
            popover = null,
            id
        ) {
            this.text = text;
            this.action = action;
            this.type = type;
            this.dismiss = dismiss;
            this.disabled = disabled;
            this.popover = popover;
            this.id = id;
        }
    }

    /**
     * @author KCupp
     * @param alertWindow
     */
    clearButtons = (alertWindow) => {
        if(typeof(alertWindow) === 'string'){
            alertWindow = window.jQuery('#'+alertWindow);
        } else {
            alertWindow = window.jQuery(alertWindow);
        }
        alertWindow.find('.modal-footer').html("");
    };

    /**
     * @author KCupp
     * @param {string} id
     * @param buttons
     */
    updateButtons = (id, buttons) => {
        let modalDialog = window.jQuery('#'+id);
        if(modalDialog.length > 0){
            let alertWindow = modalDialog.parent();
            this.clearButtons(alertWindow);
            for(let i = 0; i < buttons.length; i++){
                this.appendButton(alertWindow, buttons[i]);
            }
        }
    };

    /**
     * @author KCupp
     * @param modal
     */
    resizeModal = (modal) => {
        modal = window.jQuery(modal);
        let pageContent = window.jQuery('#pagecontent-inner');
        let visible = modal.is(':visible');
        if (!visible) {
            modal.show();
        }
        let modalBody = modal.find('.modal-body');
        let modalDialog = modal.find('.modal-dialog');

        let modalContent = modal.find('.modal-content');
        let contentBorderHeight = modalContent.outerHeight() - modalContent.innerHeight();

        let marginTop = modalDialog.css('margin-top');
        marginTop = parseFloat(marginTop.substr(0, marginTop.length - 2));

        let marginBottom = modalDialog.css('margin-bottom');
        marginBottom = parseFloat(marginBottom.substr(0, marginBottom.length - 2));

        let modalFooter = modalBody.siblings('.modal-footer');
        let footerHeight = modalFooter.outerHeight(true);

        let modalHeader = modalBody.siblings('.modal-header');
        let headerHeight = modalHeader.outerHeight(true);

        modalBody.css({
            'max-height': (window.jQuery(window).height() - marginTop - headerHeight - footerHeight - marginBottom - contentBorderHeight) + 'px'
        });

        //Set max width for modal-auto size
        modalDialog.css({
            'max-width': pageContent.width() + 'px'
        });

        if (!visible) {
            modal.hide();
        }
    };

    showAlert = (options, ok) => {

        if (typeof (ok) === 'string') {
            ok = new this.button(ok, function () {});
        } else if (typeof (ok) === 'function') {
            ok = new this.button('OK', ok);
        } else if (typeof (ok) === 'undefined') {
            ok = new this.button('OK', function () {});
        }
        if (typeof(options) !== 'object') {
            options = {'message': options};
        }
        if (typeof(options.button) === 'undefined') {
            options.buttons = [ok];
        }

        return this.initModal(options);
    };

    /**
     * @author KCupp
     * @param message
     * @returns {number}
     */
    showMessage = (message) => {
        return this.showAlert(message);
    };

    /**
     * @author KCupp
     * @param count
     */
    closeAlert = (count,callback) => {
        let modalID = window.modalManager.getModalId(count)
        window.modalManager.close(modalID,callback);
    };

    /**
     * @author KCupp
     * @param count
     */
    closeModal = (count,callback) => {
        this.closeAlert(count,callback);
    };

    /**
     * @author KCupp
     * @param alert_window
     * @param button
     */
    appendButton = (alert_window, button) => {
        let buttonElem = this.buildButton(button);
        button.element = buttonElem;
        alert_window.find('.modal-footer').append(buttonElem);
    };

    /**
     * @author KCupp
     * @param button
     * @returns {*|Window.window.jQuery.fn.init}
     */
    buildButton = (button) => {
        if (typeof(button.dismiss) === 'undefined') {
            button.dismiss = true;
        }
        let buttonElem = window.jQuery('<button class="btn btn-' + button.type + (button.disabled === true ? ' disabled' : '') + '"' + (typeof(button.id) !== undefined ? ' id="'+button.id+'"' : '') + (button.dismiss === true ? 'data-dismiss="modal"' : '') + ' type="button">' + button.text + '</button>');
        if (typeof(button.action) === 'function') {
            buttonElem.click(function () {
                if(!buttonElem.hasClass('disabled')) {
                    button.action(buttonElem);
                }
            });
        } else {
            buttonElem.attr('onclick', button.action);
        }
        return buttonElem
    };

    /**
     * @author KCupp
     * @param options
     * @param yes
     * @param no
     * @returns {number}
     */
    showYesNo = (options, yes, no) => {
        if (typeof(options) === 'string') {
            options = {'message': options};
            if (typeof(yes) !== 'undefined') {
                options.yes = yes;
            }
            if (typeof(no) !== 'undefined') {
                options.no = no;
            }
        }

        //Create the 'yes' button as a button object if it isn't already
        if (typeof(options.yes) === 'undefined') {
            options.yes = new Button('Yes', function () {
            });
        } else if (typeof(options.yes) === 'function') {
            options.yes = new Button('Yes', options.yes);
        } else if (typeof(options.yes) === 'string') {
            options.yes = new Button(options.yes, function () {
            });
        }

        //Create the 'no' button as a button object if it isn't already
        if (typeof(options.no) === 'undefined') {
            options.no = new Button('No', function () {
            });
        } else if (typeof(options.no) === 'function') {
            options.no = new Button('No', options.no);
        } else if (typeof(options.no) === 'string') {
            options.no = new Button(options.no, function () {
            });
        }
        options.buttons = [options.yes, options.no];
        delete options.yes;
        delete options.no;
        return this.initModal(options);
    };

    /**
     * @author TGrube
     * @author KCupp
     * @param type
     * @param message
     * @param title
     * @param callback
     * @param options
     */
    /*TODO REMOVE IF NOT ERRORS ARE CAUSED BY COMMENTING
      showModal = (type, message, title, callback, options) => {
        let buttons;
        switch (type) {
            case 'notification':
                if (title === undefined) title = 'System Notification';
                buttons = [
                    {
                        text: 'OK',
                        class: 'btn btn-default',
                        click: function () {
                            window.jQuery(this).dialog("close");
                            window.jQuery(this).dialog("destroy");
                        }
                    }
                ];
                break;
            case 'warning':
                if (title === undefined) title = 'Alert';
                buttons = [
                    {
                        text: 'OK',
                        class: 'btn btn-default',
                        click: function () {
                            window.jQuery(this).dialog("close");
                            window.jQuery(this).dialog("destroy");
                        }
                    }
                ];
                break;
            case 'confirm':
                if (title === undefined) title = 'Please Confirm';
                buttons = [
                    {
                        text: 'No',
                        class: 'btn btn-default',
                        click: function () {
                            window.jQuery(this).dialog("close");
                            window.jQuery(this).dialog("destroy");
                        }
                    },
                    {
                        text: 'Yes',
                        class: 'btn btn-primary',
                        click: function () {
                            let fn = new Function(callback);
                            fn();
                            window.jQuery(this).dialog("close");
                            window.jQuery(this).dialog("destroy");
                        }
                    }
                ];
                break;
            case 'form-large':
            case 'form-medium':
            case 'form-small':
                // Make sure no other modals are open
                if (window.jQuery('.ah-modal').length === 1) {
                    return;
                }

                if (!callback) {
                    buttons = [
                        {
                            text: 'Close',
                            class: 'btn btn-default',
                            click: function () {
                                window.jQuery(this).dialog("close");
                                window.jQuery(this).dialog("destroy");
                            }
                        }
                    ]
                }
                else {
                    buttons = [
                        {
                            text: 'Cancel',
                            class: 'btn btn-default',
                            click: function () {
                                window.jQuery(this).dialog("close");
                                window.jQuery(this).dialog("destroy");
                            }
                        },
                        {
                            text: 'Save',
                            class: 'btn btn-primary',
                            style: 'margin-left: 10px',
                            click: function () {
                                if (callback) {
                                    let fn = new Function(callback);
                                    if (fn()) {
                                        window.jQuery(this).dialog("close");
                                        window.jQuery(this).dialog("destroy");
                                    }
                                }
                            }
                        }
                    ];
                }
                break;
            default:
                break;
        }

        if (options && options.buttons) {
            window.jQuery.each(options.buttons, function (index, value) {
                buttons.push({
                    text: value.text,
                    class: 'btn btn-primary',
                    style: 'margin-left: 10px',
                    click: function () {
                        let fn = new Function(value.callback);
                        if (fn()) {
                            window.jQuery(this).dialog("close");
                            window.jQuery(this).dialog("destroy");
                        }
                    }
                });
            });
        }

        window.jQuery('<div id="ah-modal"><div class="ah-modal-title">' + title + '</div><div class="ah-modal-body">' + message + '</div></div>').dialog({
            //show: 'fade',
            width: 'auto',
            height: 'auto',
            modal: true,
            title: title,
            buttons: buttons,
            closeOnEscape: false,
            resizable: false,
            dialogClass: 'ah-modal ah-modal-' + type
            //open: function() {window.jQuery('html').css('overflow-y','hidden');},
            //close: function() {if (window.jQuery('.ui-dialog').length == 1) window.jQuery('html').css('overflow-y','visible')},
        });

        let height = (window.jQuery(window).height() - window.jQuery('.ah-modal-title').height() - window.jQuery('.ui-dialog-buttonpane').height()) * .75;
        window.jQuery('.ah-modal-body').css('max-height', height + 'px');
        this.centerModal();
    };*/

    /**
     * @author TGrube
     */
    /*TODO REMOVE IF NOT NEEDED
    centerModal = () => {
        window.jQuery('.ah-modal').position({"at": "center center", "of": window.jQuery(window)});
    }*/
}

export const setMessage = (id, message) => {
    window.core_messager.showAlert(message);
}

export const showAlert = (message) => {
    window.core_messager.showAlert(message);
}
