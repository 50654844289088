import _ from 'lodash';

const defaultProps = {
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks fullscreen',
      'insertdatetime table paste'
    ],
    toolbar: 'insertfile undo redo | styleselect | bold italic | fontsizeselect | forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
    menubar: 'file edit insert view format table',
    height: 200,
    resize: false,
    browser_spellcheck: true,
    contextmenu: false,
};

export class TinyMceEditor {
    constructor(id) {
        this.id = id;
        this.editor = null;
    }

    initialize = (props) => {
        if(!props) props = {};

        props = _.merge(defaultProps, props);

        try {
            TinyMceEditor.clearEditor(this.id);
        } catch (e) {
            console.error(e.message)
        }

        if(typeof(props.init_instance_callback) !== 'function') {
            props.init_instance_callback = () => {};
        }

        if(typeof(props.urlconverter_callback) !== 'function') {
            props.urlconverter_callback = urlConverterCallback;
        }

        this.editor = window.tinymce.init(props);
    }

    static clearEditor = (id) => {
        let editor = window.tinymce.get(id);

        if (editor) {
            window.tinymce.remove(editor);
        }
    }

    setHeight = (height) => {
        let editor = this.getInstance();
        TinyMceEditor.setHeightStatic(editor, height);
    }

    getInstance = () => {
        return window.tinymce.get(this.id);
    }

    static setHeightStatic = (editor, height) => {
        editor.editorContainer.style.height = height;
    }

    static getEditorInstance = (id) => {
        return window.tinymce.get(id);
    }
}

const urlConverterCallback = (url, node, onSave, name) => {
    //Only validate if the attributes being changed is 'src'
    if (name === 'src') {
        url = url.replace(/\\/g, '/');
        if (url.match(/^http:\/\/www\.assethealth\.com\/|^https:\/\/www\.assethealth\.com\/|^www\.assethealth\.com\/|^apps\/resources\//) != null) {
            return url;
        } else {
            return '';
        }
    } else {
        return url;
    }
}
