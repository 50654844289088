import { processAjax } from "../ajaxActions";

export const exportSettingsPageGlobals = () => {
    window['filterDisplay'] = filterDisplay;
    window['showFrequencyDay'] = showFrequencyDay;
    window['validateDependentRegistration'] = validateDependentRegistration;
    window['valRequiredFormFields'] = valRequiredFormFields;
}

const filterDisplay = (selected) => {
    console.log('filterDisplay');
    let tileSelected = document.getElementById('filter-' + selected);
    let menuItemSelected = document.getElementById('menu-' + selected);
    let iknowledge = document.getElementById('filter-portfolio_iknowledge');
    let vgs = document.getElementById('course_123');
    // if menuItemSelected does not exist then go to the page and open 1st tab
    if (menuItemSelected == null) {
        return;
    }
    if (selected === "all") {
        window.jQuery(".filter-item").fadeIn("slow", "linear");
        window.jQuery(iknowledge).hide();
        window.jQuery(vgs).hide();
    } else {
        if (window.jQuery('#view_resources_content').length > 0) {
            // Layout based resource pages
            window.jQuery('.filter-item').parent().addClass('d-none');
            let item = window.jQuery('#filter-' + selected);
            item.parent().removeClass('d-none');
            item.parent().parent().hide().fadeIn(1000, 'linear');
            window.jQuery(vgs).hide();
        } else {
            // Overridden resources pages (h-d and livewell)
            window.jQuery('.filter-item').hide();
            let item = window.jQuery(tileSelected);
            item.removeClass('d-none');
            window.jQuery(tileSelected).fadeIn(1000, "linear");
        }
    }
    window.jQuery('.menu-items').removeClass('sidebar-active');
    window.jQuery(menuItemSelected).addClass('sidebar-active');
}


const showFrequencyDay = (value, idsuffix) => {
    // idsuffix allows us to address multiple frequency controls for
    // separate emails. The default of '' recreates the earlier
    // behavior for controls which do not specify.
    if (typeof (idsuffix) === 'undefined') idsuffix = '';

    if (value == '3') {
        // Show frequency options for monthly reminders:
        window.jQuery('#eremind_frequency_day_row' + idsuffix).show();
        window.jQuery('#eremind_weekly_row' + idsuffix).hide();
        window.jQuery('#eremind_weekly' + idsuffix).val('');
        window.jQuery('#eremind_monthly_row' + idsuffix).show();
    } else if (value == '2') {
        // Show frequency options for weekly reminders:
        window.jQuery('#eremind_frequency_day_row' + idsuffix).show();
        window.jQuery('#eremind_monthly_row' + idsuffix).hide();
        window.jQuery('#eremind_monthly' + idsuffix).val('');
        window.jQuery('#eremind_weekly_row' + idsuffix).show();
    } else {
        // No frequency options for daily reminders or "don't remind me"
        window.jQuery('#eremind_weekly_row' + idsuffix).hide();
        window.jQuery('#eremind_monthly_row' + idsuffix).hide();
        window.jQuery('#eremind_frequency_day_row' + idsuffix).hide();
    }
}

const validateDependentRegistration = async (elem) => {
    let form = window.jQuery(elem).parents('form#dependent_registration_form');
    let form_error = false;
    let form_error_msg = form.find('#dependent_required_fields_error_msg');
    let required_fields = form.find('[required="true"]');

    form_error_msg.hide();

    required_fields.each(function () {
        if (!window.jQuery(this).val()) {
            form_error = true;
            return false;
        }
    });

    if (!form_error) {
        form.find('#dep_reg_delete').val('');
        form.find('#dep_reg_delete').remove();
        form.find('#dep_reg_ax_action').val('sendDependentSaveRequest');
        await processAjax('AHJMyAccount', 'dependent_registration_form', 'sendRequest', {maskElementId: 'dependentregistration-tile'});
    } else {
        form_error_msg.show();
    }
}

const valRequiredFormFields = async ($form_id) => {
    window.jQuery('.alert').css('display', 'none'); //hide currently visible alerts
    let form = window.jQuery('#' + $form_id);
    let valid = true;
    let email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    form.find('.form-control[name$=-req]:visible').each(function () {
        let input = window.jQuery(this);
        let input_val = input.val().trim();
        if (typeof input_val === 'undefined' || input_val == '') {
            //required field empty
            window.jQuery('#objupdateResultError').html('<span class="glyphicons glyphicons-xs glyphicons-warning-sign" />&nbsp;&nbsp;All fields marked with an asterisk <sup>*</sup> are required.');
            window.jQuery('#objupdateResultError').css('display', '');
            valid = false;
            return false;
        } else {
            //required field set
            if (input.attr('name').toLowerCase().indexOf("email") >= 0) { //email field
                if (!email_regex.test(input_val)) {
                    window.jQuery('#objupdateResultError').html('<span class="glyphicons glyphicons-xs glyphicons-warning-sign" />&nbsp;&nbsp;Invalid email address!');
                    window.jQuery('#objupdateResultError').css('display', '');
                    valid = false;
                    return false;
                }
            }
            window.jQuery('#objupdateResultError').html('');
            window.jQuery('#objupdateResultError').css('display', 'none');
        }
    });

    let preferredNameInput = form.find('.form-control.preferred-name');
    if (preferredNameInput.length > 0) {
        if (preferredNameInput.val() !== '') {
            if (!preferredNameInput.val().match(/^[a-zA-Z '-]*$/)) {
                valid = false;
                jQuery('#objupdateResultError').html('<span class="glyphicons glyphicons-xs glyphicons-warning-sign" />&nbsp;&nbsp;Invalid characters in preferred name!').css('display', '');
                return false;
            }
        }
    }

    let phoneNumberInput = form.find('.form-control.phone-number');
    if (phoneNumberInput.length > 0) {
        if (phoneNumberInput.val() != '') {
            if (!phoneNumberInput.val().match(/^\s*\(?\s*[0-9]{3}\s*\)?\s*[0-9]{3}\s*-?\s*[0-9]{4}\s*$/i)) {
                valid = false;
                window.jQuery('#objupdateResultError').html('<span class="glyphicons glyphicons-xs glyphicons-warning-sign" />&nbsp;&nbsp;Invalid mobile phone number!').css('display', '');
                return false;
            } else {
                phoneNumberInput.val(phoneNumberInput.val().replace(/[^0-9]/g, ''));
            }
        } else {
            phoneNumberInput.val('__EMPTY_VALUE__');
        }
    }

    if (valid) {
        await processAjax('AHJMyAccount', 'objupdate_form', 'sendRequest');
    }
    if (phoneNumberInput.val() === '__EMPTY_VALUE__') {
        phoneNumberInput.val('');
    }
}
