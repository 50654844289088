/*
    registries: array of object registries, will be merged together
    key: lookup key
 */
// function useComponentRegistry(registries = [], key) {
//     const [mergedRegistries, setRegistries] = useState(Object.assign({}, ...registries));
//
//     return mergedRegistries;
// }


const registry = {
    "StandardIncentiveTileV1" : {
        "path":"./components/tiles/Incentive/StandardIncentiveTileV1"
    },
    "StandardIncentiveTabV1" : {
        "path":"./components/tiles/Incentive/Tab/StandardTabV1"
    },
    "IncentivePillNavTabV1" : {
        "path":"./components/tiles/Incentive/Tab/PillNavTabV1"
    },
    "StandardIncentiveGroupV1" : {
        "path":"./components/tiles/Incentive/Group/StandardIncentiveGroupV1"
    },
    "IncentiveTileTaskCircle" : {
        "path":"./components/tiles/Incentive/Item/IncentiveTileTaskCircle"
    },
    "IncentiveTileTaskFillUp" : {
        "path":"./components/tiles/Incentive/Item/IncentiveTileTaskFillUp"
    },
    "IncentiveTileTaskSummary" : {
        "path":"./components/tiles/Incentive/Item/IncentiveTileTaskSummary"
    },
    "AhxRecommendedCourseTile" : {
        "path":"./components/tiles/Course/AhxRecommendedCourseTile"
    },
    "V7RecommendedCourseTile" : {
        "path":"./components/tiles/Course/V7RecommendedCourseTile"
    },
    "AhxSsoTileV1" : {
        "path":"./components/tiles/Sso/AhxSsoTileV1"
    },
    "SsoTileV1" : {
        "path":"./components/tiles/Sso/SsoTileV1"
    },
    "V7EventsTile" : {
        "path":"./components/tiles/Events/V7EventsTile"
    },
    "AhxEventsTile" : {
        "path":"./components/tiles/Events/AhxEventsTile"
    },
    "AhxCoachingTile" : {
        "path":"./components/tiles/Coaching/AhxCoachingTile"
    },
    "V7CoachingTile" : {
        "path":"./components/tiles/Coaching/V7CoachingTile"
    },
    "AhxWellnessPlanCountdownTile" : {
        "path":"./components/tiles/WellnessPlanCountdown/AhxWellnessPlanCountdownTile"
    },
    "V7WellnessPlanCountdownTile" : {
        "path":"./components/tiles/WellnessPlanCountdown/V7WellnessPlanCountdownTile"
    },
    "AhxMessageCenterTile" : {
        "path":"./components/tiles/MessageCenter/AhxMessageCenterTile"
    },
    "V7MessageCenterTile" : {
        "path":"./components/tiles/MessageCenter/V7MessageCenterTile"
    },
    "AhxViaTile" : {
        "path":"./components/tiles/Via/AhxViaTile"
    },
    "V8DeviceTile" : {
        "path":"./components/tiles/Device/V8DeviceTile"
    },
    "V8HealthMeasuresTile" : {
        "path":"./components/tiles/HealthMeasures/V8HealthMeasuresTile"
    },
    "V8BioMeasuresTile" : {
        "path":"./components/tiles/HealthMeasures/V8BioMeasuresTile"
    },
    "V8HealthScoreTile" : {
        "path":"./components/tiles/HealthMeasures/V8HealthScoreTile"
    },
    "V8OADTile" : {
        "path":"./components/tiles/HealthMeasures/V8OADTile"
    },
    "V8LinkTile" : {
        "path":"./components/tiles/Link/V8LinkTile"
    },
    "V8BannerTile" : {
        "path":"./components/tiles/Banner/V8BannerTile"
    },
    "V8PromoCodeTile" : {
        "path":"./components/tiles/PromoCode/V8PromoCodeTile"
    },
    "V8PointsTile" : {
        "path":"./components/tiles/Points/V8PointsTile"
    },
    "V8CallToActionTile" : {
        "path":"./components/tiles/CallToAction/V8CallToActionTile"
    },
    "V8SixRsTile" : {
        "path":"./components/tiles/SixRs/V8SixRsTile"
    },
    "V8JournalTile" : {
        "path":"./components/tiles/Journal/V8JournalTile"
    },
    "V8AttestUploadTile" : {
        "path":"./components/tiles/Upload/V8AttestUploadTile"
    },
    "V8AttestUploadWizard" : {
        "path":"./components/tiles/Upload/V8AttestUploadWizard"
    },
    "V8AttestUploadHistory" : {
        "path":"./components/tiles/Upload/V8AttestUploadHistory"
    },
    "V8QuickLinksTile" : {
        "path":"./components/tiles/QuickLinks/V8QuickLinksTile"
    }
}
export default registry;