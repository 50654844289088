import {useState, useEffect} from "react";
import { store } from "./store";
import produce from "immer";
import app from '../setupAxios';
import {getState, resetStoreKeys, subscribe} from "./helpers";
import {useAccessToken} from "./auth/auth";
import {DependencyMapper} from "../util";

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["client"];
const initialState = {
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storeClient: (state, payload) =>
        produce(state, draft => {
            const { client } = payload;
            Object.keys(draft).forEach(key => { delete draft[key]; });
            Object.assign(draft,client);
        }),
    'reset.client': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
    reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/

export const useClient = () => {
    const [state, setState] = useState(getState(storePath));
    useEffect(() => subscribe(setState,storePath), [setState]);

    useEffect(() => {
        DependencyMapper.on('dataFetchReady','getClient',() => {
            if(Object.keys(state).length === 0) {
            getClient();
        }
        });

    }, [])
    return state;
};

export const getClient = async () => {
    try {
        const response = await app.get('api/v2/clients/summary');
        const { client } = response.data;
        if (response.status === 200) {
            storeClient({ client });
            //storeUser({ user });
            DependencyMapper.reg("clientState");
        }
    }catch(e){
        console.error("Error in getClient(): ",e);
    }
};

export const storeClient = response => store.dispatch({ type: "storeClient", payload: response });
