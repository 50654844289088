import React from 'react';
import Logout from "./Logout";
import QuickIconsMenu from "./QuickIconsMenu";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useUser } from "../redux/user";
import Skeleton from "react-loading-skeleton";
import ClientLogo from "./ClientLogo";
import {useClient} from "../redux/client";
import CollapsedMenu from "./CollapsedMenu";
import {useMenu} from "../redux/app/menus";
import {exists} from "../utilities/helpers";
import "./TopMostNav.scss"


export default (props) => {
    const client = useClient();
    const user = useUser();
    const [{data, isLoading, isError }] = useMenu({version:'v1', id:'quick_menu'});
    let menuConfig                          = {};
    let quickMenuOptions                    = '';
    let menuOptionNoHamburgerOption         = false;
    let clientLogoSectionWidth              = 6;
    let logoutAndQuickIconsSectionWidthSm   = 6;
    let logoutSectionWidth                  = 6;
    let quickIconSectionWidth               = 6;
    let menuItemCount                       = 0;

    if(exists(data, 'menu.menu_config')) menuConfig = data.menu.menu_config;

    if(exists(menuConfig, 'visible_items')){
        menuItemCount = menuConfig.visible_items.length;
    }
    if(exists(menuConfig, 'options')){
        quickMenuOptions = menuConfig.options;
    }
    if(quickMenuOptions){
        let optionsLen = quickMenuOptions.length;
        for(let i = 0; i < optionsLen; i++){
            if(quickMenuOptions[i] === 'no_hamburger_lg') menuOptionNoHamburgerOption = true;
        }
    }
    if(!menuOptionNoHamburgerOption) menuItemCount++;
    if(menuItemCount > 4){
        quickIconSectionWidth = 7;
        logoutSectionWidth = 5;
        clientLogoSectionWidth = 5;
        logoutAndQuickIconsSectionWidthSm = 7;
        if(menuItemCount > 5){
            clientLogoSectionWidth = 4;
            logoutAndQuickIconsSectionWidthSm = 8;
        }
    }

    return (
        <Row className="d-flex flex-row justify-content-between">
            <Col className="d-flex align-items-center" xs="4" lg="6" xl={clientLogoSectionWidth}>
                {props.skeleton ?
                    <Skeleton height={55} width={220}/> :
                    <ClientLogo config={client.config} />
                }
            </Col>
            <Col xs="8" lg="6" xl={logoutAndQuickIconsSectionWidthSm}>
                <Row noGutters={true}>
                    <Col className="top-most-nav-pull-right border-right my-auto" sm="9" md="8" lg="7" xl={logoutSectionWidth}>
                        <Logout user={user} {...props}/>
                    </Col>
                    <Col className="d-none d-lg-block" sm="3" md="4" lg="5" xl={quickIconSectionWidth}>
                        {props.skeleton ?
                            <>
                                <Skeleton width={48} height={48} className={"ml-2"}/>
                                <Skeleton width={48} height={48} className={"ml-2"}/>
                                <Skeleton width={48} height={48} className={"ml-2"}/>
                            </> :
                            <QuickIconsMenu {...props}/>
                        }
                    </Col>
                    <Col className="top-most-nav-pull-right d-lg-none">
                        {props.skeleton ?
                            <>
                                <Skeleton width={48} height={48} className={"ml-2"}/>
                            </> :
                            <CollapsedMenu {...props}/>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
