import React, {Suspense, useState, useEffect, useReducer } from "react";
import setupAxios from './setupAxios';
import axios from 'axios';
import GenericErrorBoundary from "./errorsBoundaries/GenericErrorBoundary";
import {storeCurrentPath} from "./redux/app/navigation";
import {exists} from "./utilities/helpers";
import {useMenu} from "./redux/app/menus";
import {getState} from "./redux/helpers";
import get from 'lodash/get';

if(window.reactComponentsCache === undefined) window.reactComponentsCache = new Map();

/**
 *
 * @param paths - either pass single string path to component, or array of two paths: [preferred-path, fallback-path].
 * @param props
 * @param suspense
 * @returns {null|*}
 * @constructor
 */
export const DynComponent = (paths, props={}, suspense = '') => {

    const { location,match={} } = props;
    if(exists(location, `pathname`)){
        storeCurrentPath(match.url);
    }

    // By caching components, screen jumping while waiting for components to reload is prevented
    if(Array.isArray(paths))
    {
        for(let i = 0; i < paths.length; i++)
        {
            if(window.reactComponentsCache.has(paths[i])){
                const Component = window.reactComponentsCache.get(paths[i]).default;
                return <><Component {...props}/></>;
            }
        }
    }
    else if(window.reactComponentsCache.has(paths)){
        const Component = window.reactComponentsCache.get(paths).default;
        return <><Component {...props}/></>;
    }


    // lazy-load the component
    const Component = React.lazy(() =>
    {
            if(Array.isArray(paths))
            {
                return  import(`${paths[0]}`)
                        .then((component) =>
                        {
                            window.reactComponentsCache.set(paths[0],component);             //cache component

                            return component;
                        })
                        .catch(() =>
                        {
                            if(paths[1])
                                return import(`${paths[1]}`)
                                .then((component) =>
                                {
                                    window.reactComponentsCache.set(paths[1],component);      //cache component
                                    return component;
                                })
                         });
            }
            else
            {
                return  import(`${paths}`)
                    .then((component) =>
                    {
                        window.reactComponentsCache.set(paths,component);                   //cache component
                        return component;
                    });
            }
    });


    if(!Component) return null;

    return  <Suspense fallback={suspense}>
                 <GenericErrorBoundary>
                     <Component {...props}/>
                 </GenericErrorBoundary>
            </Suspense>;
}

const dataRequestReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};
/**
 *
 * @param initialUrl
 * @param options
 * @returns { isLoading, isError, and data returned from request}
 */
export const useRequest = (initialUrl, options = {}) => {

    const [url, setUrl] = useState(initialUrl);
    const [state, dispatch] = useReducer(dataRequestReducer, {
        isLoading: false,
        isError: false,
        data: null,
    });
    const defaultOptions = {
        method: 'get',
        url:initialUrl
    }
    const useOptions = Object.assign({}, defaultOptions, options);
    const optionsChanged = JSON.stringify(useOptions);
    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT' });
            try {
                let result; // TODO: Remove bearer request - only needed for development
                if(options.use_bearer) result = await setupAxios(useOptions);
                else result = await axios(useOptions);
                if(!didCancel) dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
            } catch (error) {
                if(!didCancel) dispatch({ type: 'FETCH_FAILURE' });
            }
        };

        fetchData();
        // function to cancel the request
        return () => {
            didCancel = true;
        };
    }, [url,optionsChanged]);

    return [state];
};
/*
    Singleton class to allow waiting for dependencies to complete before doing something

        Add a new pending function call:

        DependencyMapper.on(['accessToken','sessionId','coursesLoaded','initialPageLoad'],'showCourses',()=>{console.log('show courses')});

        - the function will run after:
            all events ('accessToken','sessionId','coursesLoaded') have been registered using DependencyMapper.reg(event)
            or waiting dependencies (e.g. 'initialPageLoad') have completed (all its dependencies completed)
        - a new event key named 'showCourses' will be created, which can be used by other pending function calls as a dependency

        Dependency Mapper only runs the function associated with a key once (assuming all dependencies completed).
        To run it again, call DependencyMapper.on() again, and either:
         - pass true for reset argument.
         - pass #milliseconds for reset argument - function will run once, only if it hasn't been run yet or the time-elapsed
           since the last run is greater than the number of ms in reset argument.
 */
function DepMapper() {
    let instance;
    const reg =  new Map();    // all registered events
    const deps = new Map();    // all waiting on dependencies
    const done = new Map();    // completed (dependencies fulfilled)
    const data = new Map();    // stored data

    const createInstance = function () {
        /*
            args:
             events - array of event names to wait on
             key - name used as event key for this pending function
             fn - function to run
             reset - default: false - this function will only be run once
                   - if true, reset so that this key can be run again now if it has already been completed
                   - if a number, only run this function now if milliseconds since last run have elapsed, otherwise ignore it
         */
        this.on = (events=null, key, fn = ()=>{},reset=false) => {

            if(done.has(key) && reset === false) return; //already ran it, nothing to do

            if(!events) events = [];
            if(typeof events === 'string')  events = [events];

            //register event dependencies
            events.forEach(event => {
                if(!reg.has(event))reg.set(event,false);
            });
            if(!reg.has(key))reg.set(key,false);

            //store config, incl function to run
            if(!deps.has(key)){
                    deps.set(key,{
                        fn,
                        events,
                        reset,
                    });
                this.check(key);
            }
        }

        /*
            args:
                event - name of event to register as having occurred - waiting dependencies will be executed
         */
        this.reg = (event) => {
                reg.set(event,true);
                this.check();
        }

        /*
            check all dependencies (or just those for key, if passed) and run waiting functions if they are fulfilled
        */
        this.check = (key=null) => {
            let recheck = false;
            if(key && deps.has(key)){   // check specific dep
                let config = deps.get(key);
                if(this.completed(config.events)){

                    config.executionTime = Date.now(); // record execution time

                    if(Number.isInteger(config.reset) && done.has(key)) {

                        let doneConfig = done.get(key);
                        deps.delete(key); // we don't retry when reset=number. run it if elapsed time passed only.

                        // check enough time has passed since last run, otherwise ignore
                        if (config.executionTime - doneConfig.executionTime > config.reset) {
                            recheck = true;
                            reg.set(key, true);
                            done.set(key, config);
                            // console.log("DependencyMapper - executing (" + key + ") for Events: ", config.events.join(","));
                            config.fn();
                        }
                    }
                    else {
                        recheck = true;
                        reg.set(key, true);
                        done.set(key, config);
                        deps.delete(key);
                        // console.log("DependencyMapper - executing (" + key + ") for Events: ", config.events.join(","));
                        config.fn();
                    }
                }
            }
            else if(!key){
                deps.forEach( (config, key) => {    // check all deps
                    if(this.completed(config.events)){
                        recheck = true;
                        config.executionTime = Date.now(); // record execution time
                        reg.set(key,true);
                        done.set(key,config);
                        deps.delete(key);
                        // console.log("DependencyMapper - executing (" + key + ") for Events: ", config.events.join(","));
                        config.fn();
                    }
                });
            }

            if(recheck) this.check(); //if a key has been set done, then anything awaiting that key may need to be executed
        }

        /*
            return boolean whether all listed events are registered
         */
        this.registered = (events) => {

            if(typeof events === 'string') events = [events];

            return events.filter(event => reg.has(event)).length === events.length;
        }

        /*
            return boolean whether all listed events are registered and completed
         */
        this.completed = (events) => {

            if(typeof events === 'string') events = [events];

            return events.filter(event => reg.has(event) && reg.get(event) === true).length === events.length;
        }

        /*
            store value at key in data map, or pass in array of objects with key/value labels for key arg (then value arg ignored)

            Note: keys are registered as events in DependencyMapper, so to run function when data is set:
                DependencyMapper.on("my_data_is_set","doMyThing",()=>console.log(DependencyMapper.getData("my_data_is_set")));
                // when below line is executed, above pending console.log function will run
                DependencyMapper.setData("my_data_is_set","Developers are awesome!");
            e.g.
            DependencyMapper.setData("myKey","myValue");
            OR
            DependencyMapper.setData([{key:"key1",value:someObject},{key:"key2",value:"some string"}]);
         */
        this.setData = (key=null,value) => {

            if(key !== null && key !== undefined) {
                if(Array.isArray(key)){// array of key/value objects passed in
                    key.forEach(obj=>{
                        if(obj.key !== null && obj.key !== undefined){
                            data.set(obj.key,obj.value);
                            reg.set(obj.key,true);
                        }
                    });
                }
                else {
                    data.set(key,value);
                    reg.set(key,true);
                }
                this.check();
            }
        }

        /*
            return value at key from data map
         */
        this.getData = (key=null) => {

            return data.get(key);
        }

        this.show = () => {
             console.group("DEPENDENCY MAPPER: ");
             console.log("reg: ", [...reg]);
             console.log("deps: ", [...deps]);
             console.log("done: ", [...done]);
             console.log("data: ", [...data]);
             console.groupEnd();
        }
    }

    if (!instance) instance = new createInstance(); // Singleton
    return instance;

};
export const DependencyMapper = new DepMapper();

//TODO: DELETE showMenuItem
export const showMenuItem = (path=[],menu=null) => {
    /*
        Pseudo code:
        findMenuItem from redux:
            if(menu !== null) only look for path in redux.app.v?.[menu].items
            //I'm assuming they won't know the menuItemNo, so using ids instead - but menuItemNo would be easier to use with pathMap
            follow path to get menu
            execute code to open menu and submenus until done with path
            - wait for menu to be open - using onStateChange callback? maybe need to expand and then open
                ... can use series() to wait for actions to complete?

       Note:
            there's 3 menus: main, quickmenu, collapsedmenu (plus any future menus, or parts of other pages)

       Also:
            this would be way simpler with jsonPath

       once this is working - add as option to TourActionEngine, then see if there's a way to make it more generic: "i.e. implements Tourable interface"
       Note: if need custom code, use new Function(customJs) and execute when desired.

     */
    let menus = getState(['app','menus']); // object
    if(menu !== null) menus = get(menus,`${menu.version}.${menu.id}`,{}); // object // limit search to specified menu
    else{
        let arrayMenus = [];
        console.log("menus is")
        Object.entries(menus).forEach(([version,obj])=>{
            Object.entries(obj).forEach(([key,value])=> {
                arrayMenus.push(value);
            });
        })
        menus = arrayMenus; // array
    }
    if(!Array.isArray(menus)) menus = [menus]; // array

    // have array of menus, now find item at path
    console.log("showMenuItem:",path,menus);
    let foundItem = null;
    let foundItems = [];
    let found = false;
    menus.forEach(menuId => {
        let searchPath = path.concat([]);

        while(searchPath.length > 0 && !found){ //first level
            let search = searchPath.shift();
            console.log("search:",search,"menu:",menuId);
            foundItems = Object.values(menuId.menu.items).filter(item=>item.id === search);

            if(searchPath.length > 0 && foundItems.length > 0) {
                let childMenus = [];
                foundItems.forEach(ary=>{
                    childMenus = childMenus.concat(Object.values(ary.children));
                });
                foundItems = childMenus;
            }
            else if(searchPath.length === 0 && foundItems.length > 0){
                found = true;
            }
            while(foundItems.length > 0 && searchPath.length > 0){ // children levels
                console.log("foundItems:",foundItems,"searchPath:",searchPath.join("."));
                search = searchPath.shift();
                foundItems = foundItems.filter(child => child.id === search);
            }
            if(searchPath.length === 0 && foundItems.length > 0) {
                found = true;
            }
            console.log("sub-remaining found items:",foundItems);
        }
        console.log("Final remaining found items:",foundItems);
    })

    // path.forEach(segment => {
    //     menus.filter(menu => {
    //         Object.values(menu.items).filter(item=>item.id === segment)
    //     })
    // })


}
// run async functions in series. by default, return false from a function to stop execution. Note: use Promise.all() to run in parallel
export const series = async (functionList=[],stop=(r)=>r===false) => {

    for(let fn of functionList)
    {
        let result = await fn();
        if(stop(result)) return false;
    }
    return true;
}