import ReactDOM from "react-dom";
import React from "react";
import App from "../App";
import * as serviceWorker from "../serviceWorker";
import '../index.css';
import  "../setupAxios";
import {store} from "../redux/store";
import {Provider} from "react-redux";
import {Log} from '../log/ConsoleLogger';
// import {DependencyMapper} from "../util";
// import axe from '@axe-core/react';
//
// if (process.env.NODE_ENV === 'development') {
//     // axe didn't work with skeleton page, so waiting for initial page load
//     DependencyMapper.on('initialPageLoad','@axe-core/react',() => axe(React, ReactDOM, 1000));
// }

export class SelfServiceController {

    renderSite = () => {
        ReactDOM.render(
            <React.StrictMode>
                <React.Suspense fallback={<h3>Loading ...</h3>}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </React.Suspense>
            </React.StrictMode>,
            document.getElementById('root')
        );

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();

        const event = new Event('ui-build-rendered');
        Log.info('Dispatch ui-build-rendered event');

        window.dispatchEvent(event);
    }
}
