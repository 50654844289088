import React, {useEffect, useState, useRef} from 'react';
import Tour from 'reactour';
import parse from "html-react-parser/index";
import {getTour, useTours, setTourStatusStarted, setTourStatusCompleted, mergeTourSummary} from "../redux/tours";
import {getState} from "../redux/helpers";
import {buildMenuPath, closeAllMenus, mergeMenuState} from "../redux/app/menus";
import cloneDeep from "lodash/cloneDeep";
import {usePage} from "../redux/app/navigation";
import {DependencyMapper} from "../util";
import {enableBody, disableBody} from "../legacy/utils";
import {Log} from "../log/ConsoleLogger";


import './TourQuickIcon.scss';

const TourQuickIcon = props => {

  const START_STEP = 0, AWAIT_ELEMENT_VISIBLE_TIMEOUT = 1000, PAUSE_TIMEOUT = 100;

  const {visibleIconContent, tabIndex, cls = ""} = props;
  const tourStore = useTours();
  const currentStep = useRef(-1);
  const tourIsOpen = useRef(false);
  const tourIsOpening = useRef(false);
  const [isTourOpen, setIsTourOpenState] = useState(false);
  const [tourData, setTourData] = useState([]);
  const [triggerAutoOpen, setTriggerAutoOpen] = useState(false);
  // shouldAutoOpenTour: there are two TourQuickIcon components on page (from CollapsedMenu and QuickIconsMenu),
  // only one should trigger auto-open at a given time.
  const shouldAutoOpenTour = useRef(props.shouldAutoOpenTour ?? true);//props.shouldAutoOpenTour ??
  const [currentStepState, setCurrentStepState] = useState(START_STEP);
  let path = usePage(["currentPath"]);
  if(path === '/') path = '/home';
  let currentPage = path.split("/")[1];
  let loadingPage = usePage(["loadingPage"]);
  const abortController = useRef(new AbortController());

  const qIndex = useRef(-1);
  const functionQueue = useRef([]);
  const getQIndex = () => qIndex.current;
  const setQIndex = (i) => qIndex.current = i;
  const getFunctionQ = () => functionQueue.current;
  const setFunctionQ = (q) => functionQueue.current = q;
  const getIsTourOpen = (bool=false) => {
    return tourIsOpen.current;
  }
  const setIsTourOpen = (bool=false) => {
    tourIsOpen.current = bool;
    setIsTourOpenState(bool);
  }
  const getIsTourOpening = (bool=false) => {
    return tourIsOpening.current;
  }
  const setIsTourOpening = (bool=false) => {
    tourIsOpening.current = bool;
  }

  /*
    Get preActions for backwards-compatibility
   */
  const getLegacyPreActions = (selector="") => {
    let id = null;
    if(/^\s*#/.test(selector)) id = selector.replace(/^\s*#/,"");
    let actions = [];

    if(window.jQuery(selector).closest('.bm-item-list').length > 0){

      // openHamburgerMenu();

      actions.push({
        "type": "mergeMenuState",
          "arguments": {
            path: "v1.quick_menu",
            state: {isOpen: true}
          }
      });
    } else if (window.jQuery(selector).children(".nav-link").children().hasClass('menu-item-icon-has-children')){

      // openMainMenuParent(selector); // legacy code opened menu, but only highlights the parent menu item, not the submenu item

      actions.push({
        "type": "mergeMenuState",
        "arguments": {
          path: "v1.main",
          state: {isOpen: true}
        }
      });
      if(id){
        actions = actions.concat([{
          "type": "mergeMenuState",
          "arguments": {
            path: `v1.main.${id}`,
            state: {isOpen: true}
          }
        },
          {
            "type": "awaitElementVisible",
            "arguments": {
              selector: "#popover-positioned-bottom, .bm-menu", // code will choose one
            }
          }
          ,{
            "type": "awaitElementVisible",
            "arguments": {
              selector: `${selector}, ${selector}-collapsed`, // code will choose one
              setTourSelector: true
            }
          }
          ]);
      }
    }
    else {
      // closeHamburgerMenu();
      // closeMainMenuParent();

      actions.push({
        "type": "mergeMenuState",
        "arguments": {
          path: "v1.quick_menu",
          state: {isOpen: false}
        }
      });
      actions.push({
        "type": "mergeMenuState",
        "arguments": {
          path: "v1.main",
          state: {isOpen: false}
        }
      });
      if(id){
        actions.push({
          "type": "mergeMenuState",
          "arguments": {
            path: `v1.main.${id}`,
            state: {isOpen: false}
          }
        });
      }
    }
    Log.debug("getLegacyPreActions for",selector,"returns",actions);
    return actions;
  }
  /*
    Get postActions for backwards-compatibility
   */
  const getLegacyPostActions = (selector="") => {
    // closeHamburgerMenu();
    // closeMainMenuParent();

    let id = null;
    if(/^\s*#/.test(selector)) id = selector.replace(/^\s*#/,"");
    let actions = [];
    // if(window.jQuery(selector).parent().hasClass('bm-item-list')) {
    if(window.jQuery(selector).closest('.bm-item-list').length > 0) {

      actions.push({
        "type": "mergeMenuState",
        "arguments": {
          path: "v1.quick_menu",
          state: {isOpen: false}
        }
      });
    }
    else if(window.jQuery(selector).children(".nav-link").children().hasClass('menu-item-icon-has-children')) {
      actions.push({
        "type": "mergeMenuState",
        "arguments": {
          path: "v1.main",
          state: {isOpen: false}
        }
      });
      if (id) {
        actions.push({
          "type": "mergeMenuState",
          "arguments": {
            path: `v1.main.${id}`,
            state: {isOpen: false}
          }
        });
      }
    }
    Log.debug("getLegacyPostActions for",selector,"returns",actions);
    return actions;
  }
  /*
    Executes one function in functionQueue at qIndex, if any, otherwise resets qIndex to -1 and empties the functionQueue
   */
  const processQueue = () => {
    try {
      let fq = getFunctionQ();
      let fn = fq[getQIndex()];
      if (typeof fn === 'function') {
        Log.debug("processQueue: running function at index=", getQIndex());
        fn();
      } else {
        Log.debug("Q is empty at ", getQIndex(), fn);
        setFunctionQ([]);
        setQIndex(-1);
      }
    } catch (e) {
      Log.warn("Error in TourQuickIcon::processQueue: index=", getQIndex(), e);
    }
  }
  /*
    Execute the next function in the functionQueue
   */
  const next = () => {
    setQIndex(getQIndex() + 1);
    processQueue();
  }
  /*
    Push a new function or array of functions into the functionQueue
  */
  const push = (fn) => {
    if (typeof fn === 'function') {
      let newQ = getFunctionQ().concat([fn]);
      setFunctionQ(newQ);
    } else if (Array.isArray(fn)) {
      let filtered = fn.filter(f => typeof f === 'function');
      setFunctionQ(getFunctionQ().concat(filtered));
    }
  }

  const getCurrentStep = () => {
    return currentStep.current;
  }
  const setCurrentStep = (num) => {
    if (num !== getCurrentStep()) {
      currentStep.current = num;
    }
  }

  useEffect(() => {
    setTourData([]);
    let tourRegistered = DependencyMapper.registered(`getTour.${currentPage}`);
    DependencyMapper.on(null, `getTour.${currentPage}`, async () => {
      if (typeof getState(`tours.configs.${currentPage}`) === 'undefined') {
        if(!tourRegistered)
          await getTour(currentPage);
      }
      loadTourData();
      tourAutoOpenHandler();
    },true);
  }, [currentPage, tourStore.configs,loadingPage]);

  const tourAutoOpenHandler = () => {
    let summary = getState(`tours.summary`);
    if(typeof(summary[currentPage]) !== 'undefined') {
      let tourSummary = summary[currentPage];
      let { eeTourActivityNo, autoOpen, eeStatusCode } = tourSummary;
      if (autoOpen === 'Y' && !eeTourActivityNo && !eeStatusCode && !getIsTourOpen() && shouldAutoOpenTour) {
        Log.info('ENABLE setTriggerAutoOpen', triggerAutoOpen, tourSummary);
        mergeTourSummary(currentPage,{eeStatusCode:"started"}); // Synchronously update eeStatusCode to started
        setTriggerAutoOpen(true);
      }
    }
  }

  useEffect(() => {
    Log.debug("useEffect: triggerAutoOpen=",triggerAutoOpen,"canOpenTour()=",canOpenTour(),"tourData=",tourData);
    if(triggerAutoOpen === true && canOpenTour()) {
      openTour();
    }
  }, [triggerAutoOpen,tourData]);

  const loadTourData = () => {
    let config = getState(`tours.configs.${currentPage}`);
    if (config && Object.keys(config).length > 0 && loadingPage !== currentPage) {
      setTourData(buildTourData(config));
    }
  }

  const canOpenTour = ()=>{
    return tourData && tourData.length > 0 && !getIsTourOpening()  && !getIsTourOpen();
  }
  const openTour = () => {
    closeAllMenus();
    loadTourData();
    if (canOpenTour()) {
      Log.debug("tour opened");
      setTriggerAutoOpen(false);
      setIsTourOpening(true);
      const summary = getState(`tours.summary.${currentPage}`);
      const { eeTourActivityNo } = summary;
      Log.debug("Set tour status to started?", eeTourActivityNo);
      if(!eeTourActivityNo) {
        setTourStatusStarted(currentPage)
            .then(() => {
              Log.debug('Tour status set to started');
            })
            .catch(Log.error);
      }

      handlePreAndPostActions(START_STEP);
      push(()=>{
        Log.debug("Open Tour")
        setIsTourOpen(true);
        setIsTourOpening(false);
        next();
      });
      next();
    }
  };

  const closeTour = () => {
    enableBody(document.body);
    if(!isTourOpen) return;
    doPostActions();
    closeAllMenus();
    if(getCurrentStep() >= tourData.length - 1) {
      const summary = getState(`tours.summary.${currentPage}`);
      const { eeTourActivityNo, eeStatusCode} = summary;
      Log.debug('Check if we need to mark tour completed.', eeTourActivityNo, eeStatusCode);
      if(eeTourActivityNo && eeStatusCode === 'started') {
        setTourStatusCompleted(currentPage, eeTourActivityNo)
            .then(() => {
              Log.debug('Tour status set to completed');
            })
            .catch(Log.error);
      }
    }

    setIsTourOpen(false);
    setCurrentStep(-1);
    setCurrentStepState(START_STEP);
    abortController.current.abort();
  }
  /*
      Return true if the step or action config's "if tests" passed, false otherwise.

      Two if tests are available:
        "ifSelector":"some css selector"    e.g. "#myId"
        "ifMedia"   :"some media query"     e.g. "(max-width: 990px)"

      If any or both tests are present, they must all pass. If none are present, true is returned.
   */
  const ifTestsPassed = (config = {}) => {
    let testsPassed = true;
    const failedTests = [];
    try {
      if (typeof config.ifSelector === "string" && document.querySelectorAll(config.ifSelector).length === 0){
        testsPassed = false;
        failedTests.push(config.ifSelector);
      }
      if (typeof config.ifMedia === "string") {
        const m = window.matchMedia(config.ifMedia);
        if (!m.matches) {
          testsPassed = false;
          failedTests.push(config.ifMedia);
        }
      }
    } catch (e) {
      testsPassed = false;
      failedTests.push("Error caught:",e.message);
      Log.warn("An error occurred when checking ifTestsPassed on this object:", config);
    }
    if(!testsPassed) Log.debug("ifTestsPassed() - failed:",failedTests,"on",config);
    return testsPassed;
  }
    /*
      return true if a given config should be included in the Tour, false if it should be filtered out
     */
  const includeTourDataConfig = (config = {}) => {

    if (ifTestsPassed(config) &&
        (window.jQuery(config.selector).length > 0 ||    // element exists in dom
        (Array.isArray(config.preActions) && config.preActions.length > 0))) return true; // there's preActions so assume they will put element into dom
    return false;
  }

  const buildTourData = (rawTourData) => {

    let formattedTourData = [];

    for (const [key, value] of Object.entries(rawTourData)
        .sort((a, b) => {
          return (a[1].tourElementOrder || 0) - (b[1].tourElementOrder || 0);
        })) {
      if (includeTourDataConfig(value)) {

        formattedTourData.push({
          selector: value.selector,
          content: ({goTo, inDOM}) => (
              <div className={'tour-element'}>
                {parse(value.content)}
                <br/>
                {inDOM}
              </div>
          ),
          position: 'top',
          action: (node) => {

            if (node !== undefined) {
              node.focus()
            }
          },
          style: {
            backgroundColor: '#fff',
          },
          stepInteraction: true,
          navDotAriaLabel: 'Go to step',
          preActions: (value.preActions || value.postActions) ? value.preActions : getLegacyPreActions(value.selector),
          postActions: (value.preActions || value.postActions) ? value.postActions : getLegacyPostActions(value.selector)
        });
      }
    }

    return formattedTourData;
  }
  // Function triggered each time current step change - stepNum is zero-based current step
  const onStepChange = (stepNum) => {
    setStepNum(stepNum);
  }
  const handlePreAndPostActions = (nextStepNum) => {

      Log.debug('handlePreAndPostActions:', nextStepNum);

      const currentStep = getCurrentStep();
      let currentConfig = tourData[currentStep];

      let clone = cloneDeep(tourData);
      if (currentConfig) {
        // restore original selector
        if(currentConfig.selectorOriginal){
          clone[currentStep].selector = tourData[currentStep].selectorOriginal;
          delete clone[currentStep].selectorOriginal;
          setTourData(clone);
        }
      }
      if (nextStepNum !== currentStep) {

        if (currentStep !== undefined) {
          doPostActions(nextStepNum);
          next();
        }

        let config = tourData[nextStepNum];
        if (config) {

          if (Array.isArray(config.preActions)) {

            TourActionEngine(config.preActions,nextStepNum);

          }
        }
        push([
          () => {
            setStepNum(nextStepNum);
            next();
          }
        ]);
        next();
      }
  }
  const setStepNum = (num) => {
      Log.debug("setStepNum:", num);
      setCurrentStep(num);
      setCurrentStepState(num);
  }
  /*
    Push pre/post actions for tour steps into the functionQueue

    Each case added here should push a function into the local functionList variable, and should call next() when it's done.
   */
  const TourActionEngine = async (actions = [], stepNum) => {
    let functionList = [];
    actions.forEach(action => {

      if(ifTestsPassed(action)){
        switch (action.type) {
            /*
              Note: deliberately not including "closeAllMenus" as an available action, so that opposing mergeMenuState commands in post/pre actions can be removed
             */
          case "mergeMenuState":
            functionList.push(async () => {
              Log.debug("TourActionEngine - executing: ", action);
              let path = buildMenuPath(...action.arguments.path.split('.'));
              mergeMenuState(path, action.arguments.state);
              next();
            });
            break;
          case "pause":
            functionList.push(async () => {
              Log.debug("TourActionEngine - executing: ", action);
              setTimeout(()=>next(),((action.arguments ?? {}).timeout ?? PAUSE_TIMEOUT));
            });
            break;
          case 'awaitElementVisible':
            let selectors = [];
            let targets = [];
            functionList.push(async () => {
              Log.debug("TourActionEngine - executing: ", action);
              let targetFound = false;
              let callback = (entries, observer) => {
                entries.forEach(entry => {
                  if (entry.isIntersecting && entry.intersectionRatio === 1 && targetFound === false && (entry.target.offsetWidth > 0 && entry.target.offsetHeight > 0)) {
                    targetFound = true;
                    Log.debug("our element is visible", entry.target);
                    const uuid = crypto.randomUUID();
                    entry.target.setAttribute("data-tour-selector", uuid);
                    if (action.arguments.setTourSelector && tourData[stepNum]) {
                      let clone = cloneDeep(tourData);
                      clone[stepNum].selectorOriginal = tourData[stepNum].selector;
                      clone[stepNum].selector = `[data-tour-selector='${uuid}']`;
                      setTourData(clone);
                    }
                    observer.disconnect();  // this disconnects all targets
                    next();
                  }
                })
              }

              let options = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
              }
              let observer = new IntersectionObserver(callback, options);
              selectors = action.arguments.selector.split(',');
              targets = [];
              selectors.forEach(s => {
                // let nodeList = document.querySelectorAll(s);
                // nodeList.forEach(node=>{
                //   targets.push(node);
                // });
                let node = document.querySelector(s);
                if(node) targets.push(node);
              });

              targets.forEach(target => {
                if (target instanceof Element) observer.observe(target)
              });

              // An auto-disconnect that always happens, in case element does not appear
              setTimeout(()=> {
                if(!targetFound){
                  observer.disconnect();
                  next();
                }
              },((action.arguments ?? {}).timeout ?? AWAIT_ELEMENT_VISIBLE_TIMEOUT));
            });
            break;
          default:
            functionList.push(async () => {
              Log.warn("TourActionEngine - unhandled action: ", action);
              next();
            });
        }
      }
    });

    push(functionList);
  }

  /*
    Do postActions on current step before moving to next step
   */
  const doPostActions = (nextStepNum=null) => {

    let previousConfig = tourData[getCurrentStep()];
    if (previousConfig) {
      if (Array.isArray(previousConfig.postActions)) {
        Log.debug('Executing postActions for step ', getCurrentStep());
        let postActions = cloneDeep(previousConfig.postActions);

        if(nextStepNum !== null){
          // clean up postActions so they don't interfere with nextStep preActions
          let nextConfig = tourData[nextStepNum];
          if(nextConfig && Array.isArray(nextConfig.preActions)){

            for(let i = postActions.length-1; i>-1; i--){
              let postaction = postActions[i];
              switch (postaction.type) {
                case "mergeMenuState":
                  let samePath = nextConfig.preActions.filter(preaction=>preaction.type === postaction.type && preaction.arguments?.path && preaction.arguments?.path === postaction.arguments?.path);
                  if(samePath.length > 0) postActions.splice(i,1); // skip this action
                  break;
              }
            }
            Log.debug("after cleanup:",postActions);
          }
        }

        TourActionEngine(postActions,getCurrentStep());
      }
    }
  }
  /*
    When user clicks on tour UI, this figures out which tour index they are going to
   */
  const getTourIndexFromEvent = (event ={}) => {
    let tourIndex = null;
    let target = event.target;
    if(!target) return tourIndex;

    // event is on goToStep button
    if(target.nodeName.toUpperCase() === "BUTTON" && !['left-arrow','right-arrow'].includes(target.dataset.tourElem)){
      if(target.disabled) return tourIndex;

      tourIndex = 0;
      let ps = target.previousSibling;
      while(ps !== null){
        tourIndex++;
        ps = ps.previousSibling;
      }
    }
    // event is on tour forward/back arrows
    else {
      while(target && target.nodeName.toUpperCase() !== "BUTTON"){
        target = target.parentElement;
      }
      if(!target || target.disabled) return tourIndex;

      if(target.dataset.tourElem === 'left-arrow') tourIndex = Math.max(0, getCurrentStep() - 1);
      else if(target.dataset.tourElem === 'right-arrow') tourIndex = Math.min(tourData.length -1, getCurrentStep() + 1);
    }
    return tourIndex;
  }

  /*
      Add/remove click event-listener to tour UI.
      Prevents click from being passed to tour component - this prevents it from moving before all pre/post actions are done
      Handles pre/post actions.
   */
  useEffect(()=>{
    if(getIsTourOpen()){
        const controls = document.querySelector('[data-tour-elem="controls"]');
        if(!controls) return null;

        controls.addEventListener('click', (e)=>{
            e.stopPropagation();
            e.preventDefault();
            let tourIndex = getTourIndexFromEvent(e);
            if(tourIndex === null) return;
            handlePreAndPostActions(tourIndex);
          },
            {
              signal:abortController.signal
            }
        );

      window.addEventListener('keydown', (e)=>{
            if(!getIsTourOpen()) return;
            const currentStep = getCurrentStep();
            let config, nextStep;
            switch(e.key){
              case 'ArrowRight':
                nextStep = currentStep + 1;
                config = tourData[nextStep];
                if(config) handlePreAndPostActions(nextStep);
                break;
              case 'ArrowLeft':
                nextStep = currentStep - 1;
                config = tourData[nextStep];
                if(config) handlePreAndPostActions(nextStep);
                break;
              case 'Escape':
                closeTour();
                break;
              default: return;
            }
            e.stopPropagation();
            e.preventDefault();
          },
          {
            signal:abortController.signal
          }
      );

        return ()=>{
          abortController.current && abortController.current.abort();
        }
    }
    else if(abortController.current) abortController.current.abort();
  },[isTourOpen]);



  window['openTour'] = openTour;
  window['closeTour'] = closeTour;
  return (
      <>
        {(tourData.length > 0) ?
            (<>
              <Tour
                  startAt={START_STEP}
                  goToStep={currentStepState} // updating currentStepState is what causes tour to go to that step
                  onRequestClose={closeTour}
                  steps={tourData}
                  isOpen={isTourOpen}
                  maskClassName="mask-op04"
                  className="helper"
                  rounded={5}
                  onAfterOpen={disableBody}
                  onBeforeClose={enableBody}
                  badgeContent={(curr, tot) => `${curr} of ${tot}`}
                  update={currentPage}
                  getCurrentStep={onStepChange}
                  disableKeyboardNavigation={true}
              />
              <span onClick={() => {
                openTour();
              }}
                    role="link"
                    tabIndex={tabIndex}
                    className={cls}>
                  {visibleIconContent}
              </span>
            </>)
            :
            (<span className={`no-tour-for-path ${cls}`} tabIndex={-1}>
              {visibleIconContent}
          </span>)
        }
      </>
  );
}
export default TourQuickIcon;
