import React from 'react';

export default (props) => {
    const { PHPSESSID = null } = props

    return (
        <form id="process" method="post" acceptCharset="utf-8">
               <span>
                  <input type="hidden" id="ax_action" name="ax_action" value="AJTest" />
                  <input type="hidden" id="ax_xmlform" name="ax_xmlform" value="" />
                  <input type="hidden" id="ax_xmlaction" name="ax_xmlaction" value="" />
                  <input type="hidden" id="oid" name="oid" value="" />
                  <input type="hidden" id="eeview" name="eeview" value="1" />
                  { PHPSESSID && <input type="hidden" id="PHPSESSID" name="PHPSESSID" value={PHPSESSID} /> }
               </span>
        </form>
    );
}
