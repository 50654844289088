import { processAjax } from "./ajaxActions";
import { initButtonDropdowns } from "./lib/buttonDropdown";
import { AhRadioTemplate } from "./lib/ahRadio";
import { UploadController } from "./lib/uploadController";
import { circularFill } from "./lib/circularFill";
import { DeveloperToolsController } from "./lib/developerTools";
import { trackLink } from "./lib/trackLink";
import { contentEditableController } from "./lib/contentEditableController";
import { initializePPCs, ppcController } from "./lib/ppcController";
import { SegmentedAnimation } from './lib/SegmentedAnimation';
import { GridController } from "./lib/gridController";
import { ApplicationEvents } from "./lib/applicationEvents";
import { ApplicationNotifications } from "./lib/applicationNotifications";
import { StandaloneTestController } from "./lib/StandaloneTestController";
import { attachmentUploader } from "./lib/attachmentUploader";
import { V8MiniChallengeTileController } from "./lib/v8MiniChallengeTileController";

export const exportLibGlobals = () => {
    window['initButtonDropdowns'] = initButtonDropdowns;
    window['ahRadio'] = new AhRadioTemplate();
    window['changeSiteLanguage'] = changeSiteLanguage;
    window['Upload'] = new UploadController();
    window['UploadController'] = UploadController;
    window['circularFill'] = circularFill;
    window['dev_tools'] = new DeveloperToolsController();
    window['trackLink'] = trackLink;
    window['contentEditableController'] = contentEditableController;
    window['initializePPCs'] = initializePPCs;
    window['ppcController'] = ppcController;
    window['SegmentedAnimation'] = SegmentedAnimation;
    window['gridController'] = new GridController();
    window['StandaloneTestController'] = StandaloneTestController;
    window['attachmentUploader'] = attachmentUploader;
    window['applicationEvents'] = new ApplicationEvents();
    window['applicationNotifications'] = new ApplicationNotifications();
    window['V8MiniChallengeTileController'] = new V8MiniChallengeTileController();
}

const changeSiteLanguage = async (language_in) => {
    let language = language_in;
    let temp = {};
    temp.callBack = function () {
        window.location.reload();
    };
    await processAjax('AHLogin', 'process', 'setLang_' + language, temp);
}
