export class V8MiniChallengeTileController {

    #mcRotateTimer = null;
    #skipInterval = false;
    #mcStoredSliderIdx = 'mcSliderIndex';
    #mcResizeObserver = null;

    start = () => {
        if (this.getMiniChallengesArray().length > 1) {
            this.removeButtonListeners();
            this.addButtonListeners();
            this.initializeStyling();
            this.loadFreshSlider();
            this.startRotation();
            this.initResizeObserver();
        }
    }

    slideToMiniChallenge = (target, miniChallenges = null, isHumanEvent = false, focusQuery = null) => {
        if (this.#skipInterval) {
            this.#skipInterval = false;
            if (!isHumanEvent) return;
        }

        if (target === null || target === undefined) return;
        if (typeof target === 'string') target = +target;
        if (!miniChallenges) miniChallenges = this.getMiniChallengesArray();

        if (!Number.isInteger(target) || (target > miniChallenges.length - 1)) return;

        for (const miniChallenge of miniChallenges) {
            const mcIdx = +miniChallenge.getAttribute('data-minichallenge-index');
            if (mcIdx === target) {
                miniChallenge.classList.contains('invisible') && miniChallenge.classList.remove('invisible');
                if (typeof focusQuery === 'string') {
                    const btn = miniChallenge.querySelector(focusQuery);
                    btn && btn.focus();
                }
            } else {
                miniChallenge.classList.add('invisible');
            }
        }
        const sliders = [...document.querySelectorAll('.slider-dot')];
        for (const slider of sliders) {
            const sliderIdx = +slider.getAttribute('data-slider-idx');
            if (sliderIdx === target) {
                slider.classList.add('slider-dot-selected');
            } else {
                slider.classList.contains('slider-dot-selected') && slider.classList.remove('slider-dot-selected');
            }
        }
    }

    getMiniChallengesArray = () => {
        return [...document.querySelectorAll('.single-v8-mini-challenge')];
    }

    getVisibleChallenge = () => {
        return [...document.querySelectorAll('.single-v8-mini-challenge')].filter((c) => !c.classList.contains('invisible'))[0];
    }

    #wrapIndex = (idx, numItems) => (idx % numItems + numItems) % numItems;

    incrementMiniChallenge = (eleOrEvt, increment = 1) => {
        const ele = (eleOrEvt instanceof PointerEvent) ? eleOrEvt.target : eleOrEvt;
        if (!(ele instanceof HTMLElement)) return;
        const focusQuery = (eleOrEvt instanceof PointerEvent) && eleOrEvt.pointerType !== 'mouse' &&
            (ele.classList.contains('slider-left') ? '.slider-left' : '.slider-right');
        increment = (eleOrEvt instanceof PointerEvent) ? (ele.classList.contains('slider-left') ? -1 : 1) : increment;
        const current = +ele.getAttribute('data-minichallenge-index');
        const miniChallenges = this.getMiniChallengesArray();
        const next = this.#wrapIndex(current + increment, miniChallenges.length);
        this.slideToMiniChallenge(next, miniChallenges, true, focusQuery);
    }

    addButtonListeners = () => {
        const buttons = [...document.querySelectorAll('.slider-lr-button')];
        for (const button of buttons) {
            button.addEventListener('click', this.incrementMiniChallenge);

        }
    }

    removeButtonListeners = () => {
        const buttons = [...document.querySelectorAll('.slider-lr-button')];
        for (const button of buttons) {
            button.removeEventListener('click', this.incrementMiniChallenge);
        }
    }

    submit = async (button, challengeIndex, challengeId, challengeDate, sliderIndex) => {
        this.stopRotation();
        try {
            await window.submitMiniChallengeActivity(button, challengeIndex, challengeId, challengeDate);
        } catch(e) {
            console.log('error in v7 version of submitting challenge activity');
        }
        try{
            sessionStorage.setItem(this.#mcStoredSliderIdx, sliderIndex);
        }catch(e){
            console.log('sessionStorage.setItem() unsupported browser');
        }
    }

    loadFreshSlider = () => {
        if (window.sessionStorage && window.sessionStorage.length) {
            try {
                this.slideToMiniChallenge(window.sessionStorage.getItem(this.#mcStoredSliderIdx));
                window.sessionStorage.removeItem(this.#mcStoredSliderIdx);
            } catch(e) {
                console.log('unsupported sessionStorage browser');
            }
        }
    }

    handleSliderClick = (idx = 0) => {
        this.slideToMiniChallenge(idx, null, true);
    }

    autoRotate = () => {
        try {
            const visibleChallenge = this.getVisibleChallenge();
            this.incrementMiniChallenge(visibleChallenge, 1);
        } catch (e) {
            this.stopRotation();
            this.cleanupResizeObserver();
        }
    }

    stopRotation = () => {
        if (this.#mcRotateTimer) {
            clearInterval(this.#mcRotateTimer);
            this.#mcRotateTimer = null;
        }
    }

    startRotation = () => {
        this.stopRotation();
        const rotationEnabledEle = document.getElementById('mc-enable-rotation');
        if (!(rotationEnabledEle && rotationEnabledEle.innerHTML && rotationEnabledEle.innerHTML === 'Y')) return;
        const rotationIntervalEle = document.getElementById('mc-rotation-interval');
        if (rotationIntervalEle && rotationIntervalEle.innerHTML) {
            const rotationInterval = (+rotationIntervalEle.innerHTML)*1000;
            if (Number.isInteger(rotationInterval) && rotationInterval > 2000) {
                this.#mcRotateTimer = setInterval(this.autoRotate, rotationInterval);
            }
        }
    }

    setMaxContentHeight = () => {
        const contentArr = [...document.querySelectorAll('.mini-challenge-content')];
        const rawHeights = contentArr.map(ele => window.getComputedStyle(ele).height);
        const heights = rawHeights.map((rawHeight) => +(rawHeight.split('px')[0]));
        const maxHeight = heights.every(item => window.isNumeric(item)) ? Math.max(...heights) : null;
        if (maxHeight) {
            for (const contentEle of contentArr) {
                contentEle.style.height = maxHeight + 'px';
            }
        }
    }

    initializeStyling = (visibleIdx = 0) => {
        this.setMaxContentHeight();
        const miniChallenges = this.getMiniChallengesArray();
        for (let [idx, challenge] of miniChallenges.entries()) {
            idx !== visibleIdx && challenge.classList.add('invisible');
        }
    }

    cleanupResizeObserver = () => {
        if (this.#mcResizeObserver) {
            this.#mcResizeObserver.disconnect();
            this.#mcResizeObserver = null;
        }
    }

    initResizeObserver = () => {
        if (typeof ResizeObserver !== 'undefined') {
            this.cleanupResizeObserver();
            const miniChallengeParent = document.querySelector('.mini-challenges-array');
            if (miniChallengeParent) {
                this.#mcResizeObserver = new ResizeObserver(() => {
                    this.#skipInterval = true;

                    const miniChallengesArray = [...miniChallengeParent.children];
                    const visibleIdx = miniChallengesArray.findIndex(item => !item.classList.contains('invisible'));
                    for (const mc of miniChallengesArray) {
                        mc.classList.contains('invisible') && mc.classList.remove('invisible');
                    }
                    const contentArr = [...document.querySelectorAll('.mini-challenge-content')];
                    for (const contentEle of contentArr) {
                        contentEle.style.height = '';
                    }
                    this.initializeStyling(visibleIdx);
                });
                this.#mcResizeObserver.observe(miniChallengeParent);
            }
        }
    }

}